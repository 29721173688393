/*=========================================================================================
	File Name: horizontal-menu.scss
	Description: A classic horizontal menu for easy navingation & support all devices.
	It support light & dark version, filpped layout, right side icons, borders menu for
	item seperation.
	----------------------------------------------------------------------------------------
	Item Name: Robust - Responsive Admin Template
	Version: 2.1
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
/* html body.horizontal-menu{
	padding-top:0;
} */
.horizontal-menu .navbar-horizontal .nav-item i
{
    font-size: 1.2rem;
}

.horizontal-menu .navbar-horizontal .nav-item a span
{
    font-size: 1.1rem;
}

.horizontal-menu .navbar-horizontal ul#main-menu-navigation > li > a
{
    padding: 1.8rem 1rem;
}

.horizontal-menu #main-menu-navigation ul li a span
{
    display: inline-block; 

    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease;
}
.horizontal-menu #main-menu-navigation ul li a span:hover,
.horizontal-menu #main-menu-navigation ul li a span .hover
{
    -webkit-transform: translateX(4px);
       -moz-transform: translateX(4px);
        -ms-transform: translateX(4px);
         -o-transform: translateX(4px);
            transform: translateX(4px);
}

.horizontal-menu.menu-collapsed #main-menu-navigation .nav-item a span
{
    display: none;
}

.horizontal-menu .navbar-container .dropdown-menu .dropdown-submenu.show .dropdown-menu
{
    overflow-y: scroll; 

    max-height: 300px;
}

.horizontal-menu .navigation-header
{
    font-family: inherit;
    font-size: 1rem; 

    padding: 8px 20px;

    color: #929292;
}

.horizontal-menu .navbar-flipped .navbar-nav .nav-item
{
    float: right;
}

.horizontal-menu .navbar-light ul#main-menu-navigation > li.active > a
{
    background: whitesmoke;
}

.horizontal-menu .navbar-light .active > a
{
    font-weight: 500; 

    background: whitesmoke;
}

.horizontal-menu .navbar-dark .nav-item.active > a
{
    background-color: rgba(255, 255, 255, .05);
}

.horizontal-menu .navbar-dark .nav-item:hover,
.horizontal-menu .navbar-dark .nav-item .hover
{
    background-color: rgba(255, 255, 255, .05);
}

.horizontal-menu .navbar-dark .active > a
{
    font-weight: 500; 

    background: whitesmoke;
}

/*=========================================================================================
	File Name: vertical-overlay-menu.scss
	Description: A overlay style vertical menu with show and hide support. It support 
	light & dark version, filpped layout, right side icons, native scroll and borders menu 
	item seperation.
	----------------------------------------------------------------------------------------
	Item Name: Robust - Responsive Admin Template
	Version: 2.1
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.vertical-overlay-menu .content
{
    margin-left: 0;
}

.vertical-overlay-menu .navbar .navbar-header
{
    float: left;

    width: 240px;
}

.vertical-overlay-menu .navbar.navbar-brand-center .navbar-container
{
    margin-left: 0;
}

.vertical-overlay-menu .navbar.navbar-brand-center .navbar-header
{
    float: left;

    width: auto;
}

.vertical-overlay-menu .main-menu,
.vertical-overlay-menu.menu-hide .main-menu
{
    left: -240px; 

    width: 240px;

    -webkit-transition: width .25s,opacity .25s,-webkit-transform .25s;
       -moz-transition: width .25s,opacity .25s,transform .25s,-moz-transform .25s;
         -o-transition: width .25s,opacity .25s,-o-transform .25s;
            transition: width .25s,opacity .25s,-webkit-transform .25s;
            transition: width .25s,opacity .25s,transform .25s;
            transition: width .25s,opacity .25s,transform .25s,-webkit-transform .25s,-moz-transform .25s,-o-transform .25s;
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);

    opacity: 0;
}
.vertical-overlay-menu .main-menu .navigation .navigation-header .ft-minus
{
    display: none;
}
.vertical-overlay-menu .main-menu .navigation > li > a > i
{
    margin-right: 12px;
}
.vertical-overlay-menu .main-menu .navigation > li > a > i:before
{
    font-size: 1.2rem;

    -webkit-transition: 200ms ease all;
       -moz-transition: 200ms ease all;
         -o-transition: 200ms ease all;
            transition: 200ms ease all;
}
.vertical-overlay-menu .main-menu .navigation li.has-sub > a:not(.mm-next):after
{
    font-family: 'FontAwesome';
    font-size: 1rem;

    position: absolute;
    top: 12px;
    right: 20px;

    display: inline-block;

    content: '\f105';
    transition: -webkit-transform .2s ease-in-out; 
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
}
.vertical-overlay-menu .main-menu .navigation li.has-sub .has-sub > a:not(.mm-next):after
{
    top: 8px;
}
.vertical-overlay-menu .main-menu .navigation li.open > a:not(.mm-next):after
{
    -webkit-transform: rotate(90deg);
       -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
}
.vertical-overlay-menu .main-menu .main-menu-footer
{
    bottom: 55px;
}
.vertical-overlay-menu .main-menu .main-menu-footer
{
    width: 240px;
}

.vertical-overlay-menu.menu-open .main-menu
{
    -webkit-transition: width .25s,opacity .25s,-webkit-transform .25s;
       -moz-transition: width .25s,opacity .25s,transform .25s,-moz-transform .25s;
         -o-transition: width .25s,opacity .25s,-o-transform .25s;
            transition: width .25s,opacity .25s,-webkit-transform .25s;
            transition: width .25s,opacity .25s,transform .25s;
            transition: width .25s,opacity .25s,transform .25s,-webkit-transform .25s,-moz-transform .25s,-o-transform .25s; 
    -webkit-transform: translate3d(240px, 0, 0);
       -moz-transform: translate3d(240px, 0, 0);
            transform: translate3d(240px, 0, 0);

    opacity: 1;
}

.vertical-overlay-menu.menu-flipped .main-menu
{
    right: -240px;
    left: inherit;
}

.vertical-overlay-menu.menu-flipped .navbar .navbar-container
{
    margin: 0;
    margin-right: 240px;
}

.vertical-overlay-menu.menu-flipped .navbar .navbar-header
{
    float: right;
}

.vertical-overlay-menu.menu-flipped.menu-open .main-menu
{
    -webkit-transform: translate3d(-240px, 0, 0);
       -moz-transform: translate3d(-240px, 0, 0);
            transform: translate3d(-240px, 0, 0);
}

@media (max-width: 991.98px)
{
    .vertical-overlay-menu .main-menu .main-menu-footer
    {
        bottom: 0;
    }
}
