.bg-gradient-x-white .card-header,
.bg-gradient-x-white .card-footer,
.bg-gradient-y-white .card-header,
.bg-gradient-y-white .card-footer,
.bg-gradient-directional-white .card-header,
.bg-gradient-directional-white .card-footer,
.bg-gradient-radial-white .card-header,
.bg-gradient-radial-white .card-footer,
.bg-gradient-striped-white .card-header,
.bg-gradient-striped-white .card-footer,
.bg-gradient-x2-white .card-header,
.bg-gradient-x2-white .card-footer,
.bg-gradient-y2-white .card-header,
.bg-gradient-y2-white .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-black .card-header,
.bg-gradient-x-black .card-footer,
.bg-gradient-y-black .card-header,
.bg-gradient-y-black .card-footer,
.bg-gradient-directional-black .card-header,
.bg-gradient-directional-black .card-footer,
.bg-gradient-radial-black .card-header,
.bg-gradient-radial-black .card-footer,
.bg-gradient-striped-black .card-header,
.bg-gradient-striped-black .card-footer,
.bg-gradient-x2-black .card-header,
.bg-gradient-x2-black .card-footer,
.bg-gradient-y2-black .card-header,
.bg-gradient-y2-black .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-primary
{
    background-image: -webkit-gradient(linear, left top, right top, from(#704acf), to(#ac92ec));
    background-image: -webkit-linear-gradient(left, #704acf 0%, #ac92ec 100%);
    background-image:    -moz-linear-gradient(left, #704acf 0%, #ac92ec 100%);
    background-image:      -o-linear-gradient(left, #704acf 0%, #ac92ec 100%);
    background-image:         linear-gradient(to right, #704acf 0%, #ac92ec 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-primary
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#704acf), to(#ac92ec));
    background-image: -webkit-linear-gradient(top, #704acf 0%, #ac92ec 100%);
    background-image:    -moz-linear-gradient(top, #704acf 0%, #ac92ec 100%);
    background-image:      -o-linear-gradient(top, #704acf 0%, #ac92ec 100%);
    background-image:         linear-gradient(to bottom, #704acf 0%, #ac92ec 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-primary
{
    background-image: -webkit-linear-gradient(45deg, #704acf, #ac92ec);
    background-image:    -moz-linear-gradient(45deg, #704acf, #ac92ec);
    background-image:      -o-linear-gradient(45deg, #704acf, #ac92ec);
    background-image:         linear-gradient(45deg, #704acf, #ac92ec);
    background-repeat: repeat-x;
}

.bg-gradient-x2-primary
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ac92ec), color-stop(50%, #967adc), to(#bcaae9));
    background-image: -webkit-linear-gradient(left, #ac92ec, #967adc 50%, #bcaae9);
    background-image:    -moz-linear-gradient(left, #ac92ec, #967adc 50%, #bcaae9);
    background-image:      -o-linear-gradient(left, #ac92ec, #967adc 50%, #bcaae9);
    background-image:         linear-gradient(to right, #ac92ec, #967adc 50%, #bcaae9);
    background-repeat: no-repeat;
}

.bg-gradient-y2-primary
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ac92ec), color-stop(50%, #967adc), to(#bcaae9));
    background-image: -webkit-linear-gradient(#ac92ec, #967adc 50%, #bcaae9);
    background-image:    -moz-linear-gradient(#ac92ec, #967adc 50%, #bcaae9);
    background-image:      -o-linear-gradient(#ac92ec, #967adc 50%, #bcaae9);
    background-image:         linear-gradient(#ac92ec, #967adc 50%, #bcaae9);
    background-repeat: no-repeat;
}

.bg-gradient-radial-primary
{
    background-image: -webkit-radial-gradient(circle, #704acf, #ac92ec);
    background-image:    -moz-radial-gradient(circle, #704acf, #ac92ec);
    background-image:      -o-radial-gradient(circle, #704acf, #ac92ec);
    background-image:         radial-gradient(circle, #704acf, #ac92ec);
    background-repeat: no-repeat;
}

.bg-gradient-striped-primary
{
    background-image: -webkit-linear-gradient(45deg, #bcaae9 25%, transparent 25%, transparent 50%, #bcaae9 50%, #bcaae9 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #bcaae9 25%, transparent 25%, transparent 50%, #bcaae9 50%, #bcaae9 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #bcaae9 25%, transparent 25%, transparent 50%, #bcaae9 50%, #bcaae9 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #bcaae9 25%, transparent 25%, transparent 50%, #bcaae9 50%, #bcaae9 75%, transparent 75%, transparent);
}

.bg-gradient-x-primary .card-header,
.bg-gradient-x-primary .card-footer,
.bg-gradient-y-primary .card-header,
.bg-gradient-y-primary .card-footer,
.bg-gradient-directional-primary .card-header,
.bg-gradient-directional-primary .card-footer,
.bg-gradient-radial-primary .card-header,
.bg-gradient-radial-primary .card-footer,
.bg-gradient-striped-primary .card-header,
.bg-gradient-striped-primary .card-footer,
.bg-gradient-x2-primary .card-header,
.bg-gradient-x2-primary .card-footer,
.bg-gradient-y2-primary .card-header,
.bg-gradient-y2-primary .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-success
{
    background-image: -webkit-gradient(linear, left top, right top, from(#298d74), to(#48cfad));
    background-image: -webkit-linear-gradient(left, #298d74 0%, #48cfad 100%);
    background-image:    -moz-linear-gradient(left, #298d74 0%, #48cfad 100%);
    background-image:      -o-linear-gradient(left, #298d74 0%, #48cfad 100%);
    background-image:         linear-gradient(to right, #298d74 0%, #48cfad 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-success
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#298d74), to(#48cfad));
    background-image: -webkit-linear-gradient(top, #298d74 0%, #48cfad 100%);
    background-image:    -moz-linear-gradient(top, #298d74 0%, #48cfad 100%);
    background-image:      -o-linear-gradient(top, #298d74 0%, #48cfad 100%);
    background-image:         linear-gradient(to bottom, #298d74 0%, #48cfad 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-success
{
    background-image: -webkit-linear-gradient(45deg, #298d74, #48cfad);
    background-image:    -moz-linear-gradient(45deg, #298d74, #48cfad);
    background-image:      -o-linear-gradient(45deg, #298d74, #48cfad);
    background-image:         linear-gradient(45deg, #298d74, #48cfad);
    background-repeat: repeat-x;
}

.bg-gradient-x2-success
{
    background-image: -webkit-gradient(linear, left top, right top, from(#48cfad), color-stop(50%, #37bc9b), to(#60d0b4));
    background-image: -webkit-linear-gradient(left, #48cfad, #37bc9b 50%, #60d0b4);
    background-image:    -moz-linear-gradient(left, #48cfad, #37bc9b 50%, #60d0b4);
    background-image:      -o-linear-gradient(left, #48cfad, #37bc9b 50%, #60d0b4);
    background-image:         linear-gradient(to right, #48cfad, #37bc9b 50%, #60d0b4);
    background-repeat: no-repeat;
}

.bg-gradient-y2-success
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#48cfad), color-stop(50%, #37bc9b), to(#60d0b4));
    background-image: -webkit-linear-gradient(#48cfad, #37bc9b 50%, #60d0b4);
    background-image:    -moz-linear-gradient(#48cfad, #37bc9b 50%, #60d0b4);
    background-image:      -o-linear-gradient(#48cfad, #37bc9b 50%, #60d0b4);
    background-image:         linear-gradient(#48cfad, #37bc9b 50%, #60d0b4);
    background-repeat: no-repeat;
}

.bg-gradient-radial-success
{
    background-image: -webkit-radial-gradient(circle, #298d74, #48cfad);
    background-image:    -moz-radial-gradient(circle, #298d74, #48cfad);
    background-image:      -o-radial-gradient(circle, #298d74, #48cfad);
    background-image:         radial-gradient(circle, #298d74, #48cfad);
    background-repeat: no-repeat;
}

.bg-gradient-striped-success
{
    background-image: -webkit-linear-gradient(45deg, #60d0b4 25%, transparent 25%, transparent 50%, #60d0b4 50%, #60d0b4 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #60d0b4 25%, transparent 25%, transparent 50%, #60d0b4 50%, #60d0b4 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #60d0b4 25%, transparent 25%, transparent 50%, #60d0b4 50%, #60d0b4 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #60d0b4 25%, transparent 25%, transparent 50%, #60d0b4 50%, #60d0b4 75%, transparent 75%, transparent);
}

.bg-gradient-x-success .card-header,
.bg-gradient-x-success .card-footer,
.bg-gradient-y-success .card-header,
.bg-gradient-y-success .card-footer,
.bg-gradient-directional-success .card-header,
.bg-gradient-directional-success .card-footer,
.bg-gradient-radial-success .card-header,
.bg-gradient-radial-success .card-footer,
.bg-gradient-striped-success .card-header,
.bg-gradient-striped-success .card-footer,
.bg-gradient-x2-success .card-header,
.bg-gradient-x2-success .card-footer,
.bg-gradient-y2-success .card-header,
.bg-gradient-y2-success .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-info
{
    background-image: -webkit-gradient(linear, left top, right top, from(#228eb6), to(#4fc1e9));
    background-image: -webkit-linear-gradient(left, #228eb6 0%, #4fc1e9 100%);
    background-image:    -moz-linear-gradient(left, #228eb6 0%, #4fc1e9 100%);
    background-image:      -o-linear-gradient(left, #228eb6 0%, #4fc1e9 100%);
    background-image:         linear-gradient(to right, #228eb6 0%, #4fc1e9 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-info
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#228eb6), to(#4fc1e9));
    background-image: -webkit-linear-gradient(top, #228eb6 0%, #4fc1e9 100%);
    background-image:    -moz-linear-gradient(top, #228eb6 0%, #4fc1e9 100%);
    background-image:      -o-linear-gradient(top, #228eb6 0%, #4fc1e9 100%);
    background-image:         linear-gradient(to bottom, #228eb6 0%, #4fc1e9 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-info
{
    background-image: -webkit-linear-gradient(45deg, #228eb6, #4fc1e9);
    background-image:    -moz-linear-gradient(45deg, #228eb6, #4fc1e9);
    background-image:      -o-linear-gradient(45deg, #228eb6, #4fc1e9);
    background-image:         linear-gradient(45deg, #228eb6, #4fc1e9);
    background-repeat: repeat-x;
}

.bg-gradient-x2-info
{
    background-image: -webkit-gradient(linear, left top, right top, from(#4fc1e9), color-stop(50%, #3bafda), to(#6ec4e4));
    background-image: -webkit-linear-gradient(left, #4fc1e9, #3bafda 50%, #6ec4e4);
    background-image:    -moz-linear-gradient(left, #4fc1e9, #3bafda 50%, #6ec4e4);
    background-image:      -o-linear-gradient(left, #4fc1e9, #3bafda 50%, #6ec4e4);
    background-image:         linear-gradient(to right, #4fc1e9, #3bafda 50%, #6ec4e4);
    background-repeat: no-repeat;
}

.bg-gradient-y2-info
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#4fc1e9), color-stop(50%, #3bafda), to(#6ec4e4));
    background-image: -webkit-linear-gradient(#4fc1e9, #3bafda 50%, #6ec4e4);
    background-image:    -moz-linear-gradient(#4fc1e9, #3bafda 50%, #6ec4e4);
    background-image:      -o-linear-gradient(#4fc1e9, #3bafda 50%, #6ec4e4);
    background-image:         linear-gradient(#4fc1e9, #3bafda 50%, #6ec4e4);
    background-repeat: no-repeat;
}

.bg-gradient-radial-info
{
    background-image: -webkit-radial-gradient(circle, #228eb6, #4fc1e9);
    background-image:    -moz-radial-gradient(circle, #228eb6, #4fc1e9);
    background-image:      -o-radial-gradient(circle, #228eb6, #4fc1e9);
    background-image:         radial-gradient(circle, #228eb6, #4fc1e9);
    background-repeat: no-repeat;
}

.bg-gradient-striped-info
{
    background-image: -webkit-linear-gradient(45deg, #6ec4e4 25%, transparent 25%, transparent 50%, #6ec4e4 50%, #6ec4e4 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #6ec4e4 25%, transparent 25%, transparent 50%, #6ec4e4 50%, #6ec4e4 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #6ec4e4 25%, transparent 25%, transparent 50%, #6ec4e4 50%, #6ec4e4 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #6ec4e4 25%, transparent 25%, transparent 50%, #6ec4e4 50%, #6ec4e4 75%, transparent 75%, transparent);
}

.bg-gradient-x-info .card-header,
.bg-gradient-x-info .card-footer,
.bg-gradient-y-info .card-header,
.bg-gradient-y-info .card-footer,
.bg-gradient-directional-info .card-header,
.bg-gradient-directional-info .card-footer,
.bg-gradient-radial-info .card-header,
.bg-gradient-radial-info .card-footer,
.bg-gradient-striped-info .card-header,
.bg-gradient-striped-info .card-footer,
.bg-gradient-x2-info .card-header,
.bg-gradient-x2-info .card-footer,
.bg-gradient-y2-info .card-header,
.bg-gradient-y2-info .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-warning
{
    background-image: -webkit-gradient(linear, left top, right top, from(#efa50b), to(#ffce54));
    background-image: -webkit-linear-gradient(left, #efa50b 0%, #ffce54 100%);
    background-image:    -moz-linear-gradient(left, #efa50b 0%, #ffce54 100%);
    background-image:      -o-linear-gradient(left, #efa50b 0%, #ffce54 100%);
    background-image:         linear-gradient(to right, #efa50b 0%, #ffce54 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-warning
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#efa50b), to(#ffce54));
    background-image: -webkit-linear-gradient(top, #efa50b 0%, #ffce54 100%);
    background-image:    -moz-linear-gradient(top, #efa50b 0%, #ffce54 100%);
    background-image:      -o-linear-gradient(top, #efa50b 0%, #ffce54 100%);
    background-image:         linear-gradient(to bottom, #efa50b 0%, #ffce54 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-warning
{
    background-image: -webkit-linear-gradient(45deg, #efa50b, #ffce54);
    background-image:    -moz-linear-gradient(45deg, #efa50b, #ffce54);
    background-image:      -o-linear-gradient(45deg, #efa50b, #ffce54);
    background-image:         linear-gradient(45deg, #efa50b, #ffce54);
    background-repeat: repeat-x;
}

.bg-gradient-x2-warning
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ffce54), color-stop(50%, #f6bb42), to(#f9d07c));
    background-image: -webkit-linear-gradient(left, #ffce54, #f6bb42 50%, #f9d07c);
    background-image:    -moz-linear-gradient(left, #ffce54, #f6bb42 50%, #f9d07c);
    background-image:      -o-linear-gradient(left, #ffce54, #f6bb42 50%, #f9d07c);
    background-image:         linear-gradient(to right, #ffce54, #f6bb42 50%, #f9d07c);
    background-repeat: no-repeat;
}

.bg-gradient-y2-warning
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffce54), color-stop(50%, #f6bb42), to(#f9d07c));
    background-image: -webkit-linear-gradient(#ffce54, #f6bb42 50%, #f9d07c);
    background-image:    -moz-linear-gradient(#ffce54, #f6bb42 50%, #f9d07c);
    background-image:      -o-linear-gradient(#ffce54, #f6bb42 50%, #f9d07c);
    background-image:         linear-gradient(#ffce54, #f6bb42 50%, #f9d07c);
    background-repeat: no-repeat;
}

.bg-gradient-radial-warning
{
    background-image: -webkit-radial-gradient(circle, #efa50b, #ffce54);
    background-image:    -moz-radial-gradient(circle, #efa50b, #ffce54);
    background-image:      -o-radial-gradient(circle, #efa50b, #ffce54);
    background-image:         radial-gradient(circle, #efa50b, #ffce54);
    background-repeat: no-repeat;
}

.bg-gradient-striped-warning
{
    background-image: -webkit-linear-gradient(45deg, #f9d07c 25%, transparent 25%, transparent 50%, #f9d07c 50%, #f9d07c 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #f9d07c 25%, transparent 25%, transparent 50%, #f9d07c 50%, #f9d07c 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #f9d07c 25%, transparent 25%, transparent 50%, #f9d07c 50%, #f9d07c 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #f9d07c 25%, transparent 25%, transparent 50%, #f9d07c 50%, #f9d07c 75%, transparent 75%, transparent);
}

.bg-gradient-x-warning .card-header,
.bg-gradient-x-warning .card-footer,
.bg-gradient-y-warning .card-header,
.bg-gradient-y-warning .card-footer,
.bg-gradient-directional-warning .card-header,
.bg-gradient-directional-warning .card-footer,
.bg-gradient-radial-warning .card-header,
.bg-gradient-radial-warning .card-footer,
.bg-gradient-striped-warning .card-header,
.bg-gradient-striped-warning .card-footer,
.bg-gradient-x2-warning .card-header,
.bg-gradient-x2-warning .card-footer,
.bg-gradient-y2-warning .card-header,
.bg-gradient-y2-warning .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-danger
{
    background-image: -webkit-gradient(linear, left top, right top, from(#bc2534), to(#ed5565));
    background-image: -webkit-linear-gradient(left, #bc2534 0%, #ed5565 100%);
    background-image:    -moz-linear-gradient(left, #bc2534 0%, #ed5565 100%);
    background-image:      -o-linear-gradient(left, #bc2534 0%, #ed5565 100%);
    background-image:         linear-gradient(to right, #bc2534 0%, #ed5565 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-danger
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#bc2534), to(#ed5565));
    background-image: -webkit-linear-gradient(top, #bc2534 0%, #ed5565 100%);
    background-image:    -moz-linear-gradient(top, #bc2534 0%, #ed5565 100%);
    background-image:      -o-linear-gradient(top, #bc2534 0%, #ed5565 100%);
    background-image:         linear-gradient(to bottom, #bc2534 0%, #ed5565 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-danger
{
    background-image: -webkit-linear-gradient(45deg, #bc2534, #ed5565);
    background-image:    -moz-linear-gradient(45deg, #bc2534, #ed5565);
    background-image:      -o-linear-gradient(45deg, #bc2534, #ed5565);
    background-image:         linear-gradient(45deg, #bc2534, #ed5565);
    background-repeat: repeat-x;
}

.bg-gradient-x2-danger
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ed5565), color-stop(50%, #da4453), to(#e47782));
    background-image: -webkit-linear-gradient(left, #ed5565, #da4453 50%, #e47782);
    background-image:    -moz-linear-gradient(left, #ed5565, #da4453 50%, #e47782);
    background-image:      -o-linear-gradient(left, #ed5565, #da4453 50%, #e47782);
    background-image:         linear-gradient(to right, #ed5565, #da4453 50%, #e47782);
    background-repeat: no-repeat;
}

.bg-gradient-y2-danger
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ed5565), color-stop(50%, #da4453), to(#e47782));
    background-image: -webkit-linear-gradient(#ed5565, #da4453 50%, #e47782);
    background-image:    -moz-linear-gradient(#ed5565, #da4453 50%, #e47782);
    background-image:      -o-linear-gradient(#ed5565, #da4453 50%, #e47782);
    background-image:         linear-gradient(#ed5565, #da4453 50%, #e47782);
    background-repeat: no-repeat;
}

.bg-gradient-radial-danger
{
    background-image: -webkit-radial-gradient(circle, #bc2534, #ed5565);
    background-image:    -moz-radial-gradient(circle, #bc2534, #ed5565);
    background-image:      -o-radial-gradient(circle, #bc2534, #ed5565);
    background-image:         radial-gradient(circle, #bc2534, #ed5565);
    background-repeat: no-repeat;
}

.bg-gradient-striped-danger
{
    background-image: -webkit-linear-gradient(45deg, #e47782 25%, transparent 25%, transparent 50%, #e47782 50%, #e47782 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #e47782 25%, transparent 25%, transparent 50%, #e47782 50%, #e47782 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #e47782 25%, transparent 25%, transparent 50%, #e47782 50%, #e47782 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #e47782 25%, transparent 25%, transparent 50%, #e47782 50%, #e47782 75%, transparent 75%, transparent);
}

.bg-gradient-x-danger .card-header,
.bg-gradient-x-danger .card-footer,
.bg-gradient-y-danger .card-header,
.bg-gradient-y-danger .card-footer,
.bg-gradient-directional-danger .card-header,
.bg-gradient-directional-danger .card-footer,
.bg-gradient-radial-danger .card-header,
.bg-gradient-radial-danger .card-footer,
.bg-gradient-striped-danger .card-header,
.bg-gradient-striped-danger .card-footer,
.bg-gradient-x2-danger .card-header,
.bg-gradient-x2-danger .card-footer,
.bg-gradient-y2-danger .card-header,
.bg-gradient-y2-danger .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-red
{
    background-image: -webkit-gradient(linear, left top, right top, from(#d32f2f), to(#e57373));
    background-image: -webkit-linear-gradient(left, #d32f2f 0%, #e57373 100%);
    background-image:    -moz-linear-gradient(left, #d32f2f 0%, #e57373 100%);
    background-image:      -o-linear-gradient(left, #d32f2f 0%, #e57373 100%);
    background-image:         linear-gradient(to right, #d32f2f 0%, #e57373 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-red
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#d32f2f), to(#e57373));
    background-image: -webkit-linear-gradient(top, #d32f2f 0%, #e57373 100%);
    background-image:    -moz-linear-gradient(top, #d32f2f 0%, #e57373 100%);
    background-image:      -o-linear-gradient(top, #d32f2f 0%, #e57373 100%);
    background-image:         linear-gradient(to bottom, #d32f2f 0%, #e57373 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-red
{
    background-image: -webkit-linear-gradient(45deg, #d32f2f, #e57373);
    background-image:    -moz-linear-gradient(45deg, #d32f2f, #e57373);
    background-image:      -o-linear-gradient(45deg, #d32f2f, #e57373);
    background-image:         linear-gradient(45deg, #d32f2f, #e57373);
    background-repeat: repeat-x;
}

.bg-gradient-x2-red
{
    background-image: -webkit-gradient(linear, left top, right top, from(#e57373), color-stop(50%, #f44336), to(#ef9a9a));
    background-image: -webkit-linear-gradient(left, #e57373, #f44336 50%, #ef9a9a);
    background-image:    -moz-linear-gradient(left, #e57373, #f44336 50%, #ef9a9a);
    background-image:      -o-linear-gradient(left, #e57373, #f44336 50%, #ef9a9a);
    background-image:         linear-gradient(to right, #e57373, #f44336 50%, #ef9a9a);
    background-repeat: no-repeat;
}

.bg-gradient-y2-red
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e57373), color-stop(50%, #f44336), to(#ef9a9a));
    background-image: -webkit-linear-gradient(#e57373, #f44336 50%, #ef9a9a);
    background-image:    -moz-linear-gradient(#e57373, #f44336 50%, #ef9a9a);
    background-image:      -o-linear-gradient(#e57373, #f44336 50%, #ef9a9a);
    background-image:         linear-gradient(#e57373, #f44336 50%, #ef9a9a);
    background-repeat: no-repeat;
}

.bg-gradient-radial-red
{
    background-image: -webkit-radial-gradient(circle, #d32f2f, #e57373);
    background-image:    -moz-radial-gradient(circle, #d32f2f, #e57373);
    background-image:      -o-radial-gradient(circle, #d32f2f, #e57373);
    background-image:         radial-gradient(circle, #d32f2f, #e57373);
    background-repeat: no-repeat;
}

.bg-gradient-striped-red
{
    background-image: -webkit-linear-gradient(45deg, #ef9a9a 25%, transparent 25%, transparent 50%, #ef9a9a 50%, #ef9a9a 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #ef9a9a 25%, transparent 25%, transparent 50%, #ef9a9a 50%, #ef9a9a 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #ef9a9a 25%, transparent 25%, transparent 50%, #ef9a9a 50%, #ef9a9a 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #ef9a9a 25%, transparent 25%, transparent 50%, #ef9a9a 50%, #ef9a9a 75%, transparent 75%, transparent);
}

.bg-gradient-x-red .card-header,
.bg-gradient-x-red .card-footer,
.bg-gradient-y-red .card-header,
.bg-gradient-y-red .card-footer,
.bg-gradient-directional-red .card-header,
.bg-gradient-directional-red .card-footer,
.bg-gradient-radial-red .card-header,
.bg-gradient-radial-red .card-footer,
.bg-gradient-striped-red .card-header,
.bg-gradient-striped-red .card-footer,
.bg-gradient-x2-red .card-header,
.bg-gradient-x2-red .card-footer,
.bg-gradient-y2-red .card-header,
.bg-gradient-y2-red .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-pink
{
    background-image: -webkit-gradient(linear, left top, right top, from(#c2185b), to(#f06292));
    background-image: -webkit-linear-gradient(left, #c2185b 0%, #f06292 100%);
    background-image:    -moz-linear-gradient(left, #c2185b 0%, #f06292 100%);
    background-image:      -o-linear-gradient(left, #c2185b 0%, #f06292 100%);
    background-image:         linear-gradient(to right, #c2185b 0%, #f06292 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-pink
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#c2185b), to(#f06292));
    background-image: -webkit-linear-gradient(top, #c2185b 0%, #f06292 100%);
    background-image:    -moz-linear-gradient(top, #c2185b 0%, #f06292 100%);
    background-image:      -o-linear-gradient(top, #c2185b 0%, #f06292 100%);
    background-image:         linear-gradient(to bottom, #c2185b 0%, #f06292 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-pink
{
    background-image: -webkit-linear-gradient(45deg, #c2185b, #f06292);
    background-image:    -moz-linear-gradient(45deg, #c2185b, #f06292);
    background-image:      -o-linear-gradient(45deg, #c2185b, #f06292);
    background-image:         linear-gradient(45deg, #c2185b, #f06292);
    background-repeat: repeat-x;
}

.bg-gradient-x2-pink
{
    background-image: -webkit-gradient(linear, left top, right top, from(#f06292), color-stop(50%, #e91e63), to(#f48fb1));
    background-image: -webkit-linear-gradient(left, #f06292, #e91e63 50%, #f48fb1);
    background-image:    -moz-linear-gradient(left, #f06292, #e91e63 50%, #f48fb1);
    background-image:      -o-linear-gradient(left, #f06292, #e91e63 50%, #f48fb1);
    background-image:         linear-gradient(to right, #f06292, #e91e63 50%, #f48fb1);
    background-repeat: no-repeat;
}

.bg-gradient-y2-pink
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f06292), color-stop(50%, #e91e63), to(#f48fb1));
    background-image: -webkit-linear-gradient(#f06292, #e91e63 50%, #f48fb1);
    background-image:    -moz-linear-gradient(#f06292, #e91e63 50%, #f48fb1);
    background-image:      -o-linear-gradient(#f06292, #e91e63 50%, #f48fb1);
    background-image:         linear-gradient(#f06292, #e91e63 50%, #f48fb1);
    background-repeat: no-repeat;
}

.bg-gradient-radial-pink
{
    background-image: -webkit-radial-gradient(circle, #c2185b, #f06292);
    background-image:    -moz-radial-gradient(circle, #c2185b, #f06292);
    background-image:      -o-radial-gradient(circle, #c2185b, #f06292);
    background-image:         radial-gradient(circle, #c2185b, #f06292);
    background-repeat: no-repeat;
}

.bg-gradient-striped-pink
{
    background-image: -webkit-linear-gradient(45deg, #f48fb1 25%, transparent 25%, transparent 50%, #f48fb1 50%, #f48fb1 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #f48fb1 25%, transparent 25%, transparent 50%, #f48fb1 50%, #f48fb1 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #f48fb1 25%, transparent 25%, transparent 50%, #f48fb1 50%, #f48fb1 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #f48fb1 25%, transparent 25%, transparent 50%, #f48fb1 50%, #f48fb1 75%, transparent 75%, transparent);
}

.bg-gradient-x-pink .card-header,
.bg-gradient-x-pink .card-footer,
.bg-gradient-y-pink .card-header,
.bg-gradient-y-pink .card-footer,
.bg-gradient-directional-pink .card-header,
.bg-gradient-directional-pink .card-footer,
.bg-gradient-radial-pink .card-header,
.bg-gradient-radial-pink .card-footer,
.bg-gradient-striped-pink .card-header,
.bg-gradient-striped-pink .card-footer,
.bg-gradient-x2-pink .card-header,
.bg-gradient-x2-pink .card-footer,
.bg-gradient-y2-pink .card-header,
.bg-gradient-y2-pink .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-purple
{
    background-image: -webkit-gradient(linear, left top, right top, from(#7b1fa2), to(#ba68c8));
    background-image: -webkit-linear-gradient(left, #7b1fa2 0%, #ba68c8 100%);
    background-image:    -moz-linear-gradient(left, #7b1fa2 0%, #ba68c8 100%);
    background-image:      -o-linear-gradient(left, #7b1fa2 0%, #ba68c8 100%);
    background-image:         linear-gradient(to right, #7b1fa2 0%, #ba68c8 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-purple
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#7b1fa2), to(#ba68c8));
    background-image: -webkit-linear-gradient(top, #7b1fa2 0%, #ba68c8 100%);
    background-image:    -moz-linear-gradient(top, #7b1fa2 0%, #ba68c8 100%);
    background-image:      -o-linear-gradient(top, #7b1fa2 0%, #ba68c8 100%);
    background-image:         linear-gradient(to bottom, #7b1fa2 0%, #ba68c8 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-purple
{
    background-image: -webkit-linear-gradient(45deg, #7b1fa2, #ba68c8);
    background-image:    -moz-linear-gradient(45deg, #7b1fa2, #ba68c8);
    background-image:      -o-linear-gradient(45deg, #7b1fa2, #ba68c8);
    background-image:         linear-gradient(45deg, #7b1fa2, #ba68c8);
    background-repeat: repeat-x;
}

.bg-gradient-x2-purple
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ba68c8), color-stop(50%, #9c27b0), to(#ce93d8));
    background-image: -webkit-linear-gradient(left, #ba68c8, #9c27b0 50%, #ce93d8);
    background-image:    -moz-linear-gradient(left, #ba68c8, #9c27b0 50%, #ce93d8);
    background-image:      -o-linear-gradient(left, #ba68c8, #9c27b0 50%, #ce93d8);
    background-image:         linear-gradient(to right, #ba68c8, #9c27b0 50%, #ce93d8);
    background-repeat: no-repeat;
}

.bg-gradient-y2-purple
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ba68c8), color-stop(50%, #9c27b0), to(#ce93d8));
    background-image: -webkit-linear-gradient(#ba68c8, #9c27b0 50%, #ce93d8);
    background-image:    -moz-linear-gradient(#ba68c8, #9c27b0 50%, #ce93d8);
    background-image:      -o-linear-gradient(#ba68c8, #9c27b0 50%, #ce93d8);
    background-image:         linear-gradient(#ba68c8, #9c27b0 50%, #ce93d8);
    background-repeat: no-repeat;
}

.bg-gradient-radial-purple
{
    background-image: -webkit-radial-gradient(circle, #7b1fa2, #ba68c8);
    background-image:    -moz-radial-gradient(circle, #7b1fa2, #ba68c8);
    background-image:      -o-radial-gradient(circle, #7b1fa2, #ba68c8);
    background-image:         radial-gradient(circle, #7b1fa2, #ba68c8);
    background-repeat: no-repeat;
}

.bg-gradient-striped-purple
{
    background-image: -webkit-linear-gradient(45deg, #ce93d8 25%, transparent 25%, transparent 50%, #ce93d8 50%, #ce93d8 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #ce93d8 25%, transparent 25%, transparent 50%, #ce93d8 50%, #ce93d8 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #ce93d8 25%, transparent 25%, transparent 50%, #ce93d8 50%, #ce93d8 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #ce93d8 25%, transparent 25%, transparent 50%, #ce93d8 50%, #ce93d8 75%, transparent 75%, transparent);
}

.bg-gradient-x-purple .card-header,
.bg-gradient-x-purple .card-footer,
.bg-gradient-y-purple .card-header,
.bg-gradient-y-purple .card-footer,
.bg-gradient-directional-purple .card-header,
.bg-gradient-directional-purple .card-footer,
.bg-gradient-radial-purple .card-header,
.bg-gradient-radial-purple .card-footer,
.bg-gradient-striped-purple .card-header,
.bg-gradient-striped-purple .card-footer,
.bg-gradient-x2-purple .card-header,
.bg-gradient-x2-purple .card-footer,
.bg-gradient-y2-purple .card-header,
.bg-gradient-y2-purple .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-deep-purple
{
    background-image: -webkit-gradient(linear, left top, right top, from(#512da8), to(#9575cd));
    background-image: -webkit-linear-gradient(left, #512da8 0%, #9575cd 100%);
    background-image:    -moz-linear-gradient(left, #512da8 0%, #9575cd 100%);
    background-image:      -o-linear-gradient(left, #512da8 0%, #9575cd 100%);
    background-image:         linear-gradient(to right, #512da8 0%, #9575cd 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-deep-purple
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#512da8), to(#9575cd));
    background-image: -webkit-linear-gradient(top, #512da8 0%, #9575cd 100%);
    background-image:    -moz-linear-gradient(top, #512da8 0%, #9575cd 100%);
    background-image:      -o-linear-gradient(top, #512da8 0%, #9575cd 100%);
    background-image:         linear-gradient(to bottom, #512da8 0%, #9575cd 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-deep-purple
{
    background-image: -webkit-linear-gradient(45deg, #512da8, #9575cd);
    background-image:    -moz-linear-gradient(45deg, #512da8, #9575cd);
    background-image:      -o-linear-gradient(45deg, #512da8, #9575cd);
    background-image:         linear-gradient(45deg, #512da8, #9575cd);
    background-repeat: repeat-x;
}

.bg-gradient-x2-deep-purple
{
    background-image: -webkit-gradient(linear, left top, right top, from(#9575cd), color-stop(50%, #673ab7), to(#b39ddb));
    background-image: -webkit-linear-gradient(left, #9575cd, #673ab7 50%, #b39ddb);
    background-image:    -moz-linear-gradient(left, #9575cd, #673ab7 50%, #b39ddb);
    background-image:      -o-linear-gradient(left, #9575cd, #673ab7 50%, #b39ddb);
    background-image:         linear-gradient(to right, #9575cd, #673ab7 50%, #b39ddb);
    background-repeat: no-repeat;
}

.bg-gradient-y2-deep-purple
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#9575cd), color-stop(50%, #673ab7), to(#b39ddb));
    background-image: -webkit-linear-gradient(#9575cd, #673ab7 50%, #b39ddb);
    background-image:    -moz-linear-gradient(#9575cd, #673ab7 50%, #b39ddb);
    background-image:      -o-linear-gradient(#9575cd, #673ab7 50%, #b39ddb);
    background-image:         linear-gradient(#9575cd, #673ab7 50%, #b39ddb);
    background-repeat: no-repeat;
}

.bg-gradient-radial-deep-purple
{
    background-image: -webkit-radial-gradient(circle, #512da8, #9575cd);
    background-image:    -moz-radial-gradient(circle, #512da8, #9575cd);
    background-image:      -o-radial-gradient(circle, #512da8, #9575cd);
    background-image:         radial-gradient(circle, #512da8, #9575cd);
    background-repeat: no-repeat;
}

.bg-gradient-striped-deep-purple
{
    background-image: -webkit-linear-gradient(45deg, #b39ddb 25%, transparent 25%, transparent 50%, #b39ddb 50%, #b39ddb 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #b39ddb 25%, transparent 25%, transparent 50%, #b39ddb 50%, #b39ddb 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #b39ddb 25%, transparent 25%, transparent 50%, #b39ddb 50%, #b39ddb 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #b39ddb 25%, transparent 25%, transparent 50%, #b39ddb 50%, #b39ddb 75%, transparent 75%, transparent);
}

.bg-gradient-x-deep-purple .card-header,
.bg-gradient-x-deep-purple .card-footer,
.bg-gradient-y-deep-purple .card-header,
.bg-gradient-y-deep-purple .card-footer,
.bg-gradient-directional-deep-purple .card-header,
.bg-gradient-directional-deep-purple .card-footer,
.bg-gradient-radial-deep-purple .card-header,
.bg-gradient-radial-deep-purple .card-footer,
.bg-gradient-striped-deep-purple .card-header,
.bg-gradient-striped-deep-purple .card-footer,
.bg-gradient-x2-deep-purple .card-header,
.bg-gradient-x2-deep-purple .card-footer,
.bg-gradient-y2-deep-purple .card-header,
.bg-gradient-y2-deep-purple .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-indigo
{
    background-image: -webkit-gradient(linear, left top, right top, from(#303f9f), to(#7986cb));
    background-image: -webkit-linear-gradient(left, #303f9f 0%, #7986cb 100%);
    background-image:    -moz-linear-gradient(left, #303f9f 0%, #7986cb 100%);
    background-image:      -o-linear-gradient(left, #303f9f 0%, #7986cb 100%);
    background-image:         linear-gradient(to right, #303f9f 0%, #7986cb 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-indigo
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#303f9f), to(#7986cb));
    background-image: -webkit-linear-gradient(top, #303f9f 0%, #7986cb 100%);
    background-image:    -moz-linear-gradient(top, #303f9f 0%, #7986cb 100%);
    background-image:      -o-linear-gradient(top, #303f9f 0%, #7986cb 100%);
    background-image:         linear-gradient(to bottom, #303f9f 0%, #7986cb 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-indigo
{
    background-image: -webkit-linear-gradient(45deg, #303f9f, #7986cb);
    background-image:    -moz-linear-gradient(45deg, #303f9f, #7986cb);
    background-image:      -o-linear-gradient(45deg, #303f9f, #7986cb);
    background-image:         linear-gradient(45deg, #303f9f, #7986cb);
    background-repeat: repeat-x;
}

.bg-gradient-x2-indigo
{
    background-image: -webkit-gradient(linear, left top, right top, from(#7986cb), color-stop(50%, #3f51b5), to(#9fa8da));
    background-image: -webkit-linear-gradient(left, #7986cb, #3f51b5 50%, #9fa8da);
    background-image:    -moz-linear-gradient(left, #7986cb, #3f51b5 50%, #9fa8da);
    background-image:      -o-linear-gradient(left, #7986cb, #3f51b5 50%, #9fa8da);
    background-image:         linear-gradient(to right, #7986cb, #3f51b5 50%, #9fa8da);
    background-repeat: no-repeat;
}

.bg-gradient-y2-indigo
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#7986cb), color-stop(50%, #3f51b5), to(#9fa8da));
    background-image: -webkit-linear-gradient(#7986cb, #3f51b5 50%, #9fa8da);
    background-image:    -moz-linear-gradient(#7986cb, #3f51b5 50%, #9fa8da);
    background-image:      -o-linear-gradient(#7986cb, #3f51b5 50%, #9fa8da);
    background-image:         linear-gradient(#7986cb, #3f51b5 50%, #9fa8da);
    background-repeat: no-repeat;
}

.bg-gradient-radial-indigo
{
    background-image: -webkit-radial-gradient(circle, #303f9f, #7986cb);
    background-image:    -moz-radial-gradient(circle, #303f9f, #7986cb);
    background-image:      -o-radial-gradient(circle, #303f9f, #7986cb);
    background-image:         radial-gradient(circle, #303f9f, #7986cb);
    background-repeat: no-repeat;
}

.bg-gradient-striped-indigo
{
    background-image: -webkit-linear-gradient(45deg, #9fa8da 25%, transparent 25%, transparent 50%, #9fa8da 50%, #9fa8da 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #9fa8da 25%, transparent 25%, transparent 50%, #9fa8da 50%, #9fa8da 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #9fa8da 25%, transparent 25%, transparent 50%, #9fa8da 50%, #9fa8da 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #9fa8da 25%, transparent 25%, transparent 50%, #9fa8da 50%, #9fa8da 75%, transparent 75%, transparent);
}

.bg-gradient-x-indigo .card-header,
.bg-gradient-x-indigo .card-footer,
.bg-gradient-y-indigo .card-header,
.bg-gradient-y-indigo .card-footer,
.bg-gradient-directional-indigo .card-header,
.bg-gradient-directional-indigo .card-footer,
.bg-gradient-radial-indigo .card-header,
.bg-gradient-radial-indigo .card-footer,
.bg-gradient-striped-indigo .card-header,
.bg-gradient-striped-indigo .card-footer,
.bg-gradient-x2-indigo .card-header,
.bg-gradient-x2-indigo .card-footer,
.bg-gradient-y2-indigo .card-header,
.bg-gradient-y2-indigo .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-blue
{
    background-image: -webkit-gradient(linear, left top, right top, from(#1976d2), to(#64b5f6));
    background-image: -webkit-linear-gradient(left, #1976d2 0%, #64b5f6 100%);
    background-image:    -moz-linear-gradient(left, #1976d2 0%, #64b5f6 100%);
    background-image:      -o-linear-gradient(left, #1976d2 0%, #64b5f6 100%);
    background-image:         linear-gradient(to right, #1976d2 0%, #64b5f6 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-blue
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#1976d2), to(#64b5f6));
    background-image: -webkit-linear-gradient(top, #1976d2 0%, #64b5f6 100%);
    background-image:    -moz-linear-gradient(top, #1976d2 0%, #64b5f6 100%);
    background-image:      -o-linear-gradient(top, #1976d2 0%, #64b5f6 100%);
    background-image:         linear-gradient(to bottom, #1976d2 0%, #64b5f6 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-blue
{
    background-image: -webkit-linear-gradient(45deg, #1976d2, #64b5f6);
    background-image:    -moz-linear-gradient(45deg, #1976d2, #64b5f6);
    background-image:      -o-linear-gradient(45deg, #1976d2, #64b5f6);
    background-image:         linear-gradient(45deg, #1976d2, #64b5f6);
    background-repeat: repeat-x;
}

.bg-gradient-x2-blue
{
    background-image: -webkit-gradient(linear, left top, right top, from(#64b5f6), color-stop(50%, #2196f3), to(#90caf9));
    background-image: -webkit-linear-gradient(left, #64b5f6, #2196f3 50%, #90caf9);
    background-image:    -moz-linear-gradient(left, #64b5f6, #2196f3 50%, #90caf9);
    background-image:      -o-linear-gradient(left, #64b5f6, #2196f3 50%, #90caf9);
    background-image:         linear-gradient(to right, #64b5f6, #2196f3 50%, #90caf9);
    background-repeat: no-repeat;
}

.bg-gradient-y2-blue
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#64b5f6), color-stop(50%, #2196f3), to(#90caf9));
    background-image: -webkit-linear-gradient(#64b5f6, #2196f3 50%, #90caf9);
    background-image:    -moz-linear-gradient(#64b5f6, #2196f3 50%, #90caf9);
    background-image:      -o-linear-gradient(#64b5f6, #2196f3 50%, #90caf9);
    background-image:         linear-gradient(#64b5f6, #2196f3 50%, #90caf9);
    background-repeat: no-repeat;
}

.bg-gradient-radial-blue
{
    background-image: -webkit-radial-gradient(circle, #1976d2, #64b5f6);
    background-image:    -moz-radial-gradient(circle, #1976d2, #64b5f6);
    background-image:      -o-radial-gradient(circle, #1976d2, #64b5f6);
    background-image:         radial-gradient(circle, #1976d2, #64b5f6);
    background-repeat: no-repeat;
}

.bg-gradient-striped-blue
{
    background-image: -webkit-linear-gradient(45deg, #90caf9 25%, transparent 25%, transparent 50%, #90caf9 50%, #90caf9 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #90caf9 25%, transparent 25%, transparent 50%, #90caf9 50%, #90caf9 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #90caf9 25%, transparent 25%, transparent 50%, #90caf9 50%, #90caf9 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #90caf9 25%, transparent 25%, transparent 50%, #90caf9 50%, #90caf9 75%, transparent 75%, transparent);
}

.bg-gradient-x-blue .card-header,
.bg-gradient-x-blue .card-footer,
.bg-gradient-y-blue .card-header,
.bg-gradient-y-blue .card-footer,
.bg-gradient-directional-blue .card-header,
.bg-gradient-directional-blue .card-footer,
.bg-gradient-radial-blue .card-header,
.bg-gradient-radial-blue .card-footer,
.bg-gradient-striped-blue .card-header,
.bg-gradient-striped-blue .card-footer,
.bg-gradient-x2-blue .card-header,
.bg-gradient-x2-blue .card-footer,
.bg-gradient-y2-blue .card-header,
.bg-gradient-y2-blue .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-light-blue
{
    background-image: -webkit-gradient(linear, left top, right top, from(#0288d1), to(#4fc3f7));
    background-image: -webkit-linear-gradient(left, #0288d1 0%, #4fc3f7 100%);
    background-image:    -moz-linear-gradient(left, #0288d1 0%, #4fc3f7 100%);
    background-image:      -o-linear-gradient(left, #0288d1 0%, #4fc3f7 100%);
    background-image:         linear-gradient(to right, #0288d1 0%, #4fc3f7 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-light-blue
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#0288d1), to(#4fc3f7));
    background-image: -webkit-linear-gradient(top, #0288d1 0%, #4fc3f7 100%);
    background-image:    -moz-linear-gradient(top, #0288d1 0%, #4fc3f7 100%);
    background-image:      -o-linear-gradient(top, #0288d1 0%, #4fc3f7 100%);
    background-image:         linear-gradient(to bottom, #0288d1 0%, #4fc3f7 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-light-blue
{
    background-image: -webkit-linear-gradient(45deg, #0288d1, #4fc3f7);
    background-image:    -moz-linear-gradient(45deg, #0288d1, #4fc3f7);
    background-image:      -o-linear-gradient(45deg, #0288d1, #4fc3f7);
    background-image:         linear-gradient(45deg, #0288d1, #4fc3f7);
    background-repeat: repeat-x;
}

.bg-gradient-x2-light-blue
{
    background-image: -webkit-gradient(linear, left top, right top, from(#4fc3f7), color-stop(50%, #03a9f4), to(#81d4fa));
    background-image: -webkit-linear-gradient(left, #4fc3f7, #03a9f4 50%, #81d4fa);
    background-image:    -moz-linear-gradient(left, #4fc3f7, #03a9f4 50%, #81d4fa);
    background-image:      -o-linear-gradient(left, #4fc3f7, #03a9f4 50%, #81d4fa);
    background-image:         linear-gradient(to right, #4fc3f7, #03a9f4 50%, #81d4fa);
    background-repeat: no-repeat;
}

.bg-gradient-y2-light-blue
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#4fc3f7), color-stop(50%, #03a9f4), to(#81d4fa));
    background-image: -webkit-linear-gradient(#4fc3f7, #03a9f4 50%, #81d4fa);
    background-image:    -moz-linear-gradient(#4fc3f7, #03a9f4 50%, #81d4fa);
    background-image:      -o-linear-gradient(#4fc3f7, #03a9f4 50%, #81d4fa);
    background-image:         linear-gradient(#4fc3f7, #03a9f4 50%, #81d4fa);
    background-repeat: no-repeat;
}

.bg-gradient-radial-light-blue
{
    background-image: -webkit-radial-gradient(circle, #0288d1, #4fc3f7);
    background-image:    -moz-radial-gradient(circle, #0288d1, #4fc3f7);
    background-image:      -o-radial-gradient(circle, #0288d1, #4fc3f7);
    background-image:         radial-gradient(circle, #0288d1, #4fc3f7);
    background-repeat: no-repeat;
}

.bg-gradient-striped-light-blue
{
    background-image: -webkit-linear-gradient(45deg, #81d4fa 25%, transparent 25%, transparent 50%, #81d4fa 50%, #81d4fa 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #81d4fa 25%, transparent 25%, transparent 50%, #81d4fa 50%, #81d4fa 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #81d4fa 25%, transparent 25%, transparent 50%, #81d4fa 50%, #81d4fa 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #81d4fa 25%, transparent 25%, transparent 50%, #81d4fa 50%, #81d4fa 75%, transparent 75%, transparent);
}

.bg-gradient-x-light-blue .card-header,
.bg-gradient-x-light-blue .card-footer,
.bg-gradient-y-light-blue .card-header,
.bg-gradient-y-light-blue .card-footer,
.bg-gradient-directional-light-blue .card-header,
.bg-gradient-directional-light-blue .card-footer,
.bg-gradient-radial-light-blue .card-header,
.bg-gradient-radial-light-blue .card-footer,
.bg-gradient-striped-light-blue .card-header,
.bg-gradient-striped-light-blue .card-footer,
.bg-gradient-x2-light-blue .card-header,
.bg-gradient-x2-light-blue .card-footer,
.bg-gradient-y2-light-blue .card-header,
.bg-gradient-y2-light-blue .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-cyan
{
    background-image: -webkit-gradient(linear, left top, right top, from(#0097a7), to(#4dd0e1));
    background-image: -webkit-linear-gradient(left, #0097a7 0%, #4dd0e1 100%);
    background-image:    -moz-linear-gradient(left, #0097a7 0%, #4dd0e1 100%);
    background-image:      -o-linear-gradient(left, #0097a7 0%, #4dd0e1 100%);
    background-image:         linear-gradient(to right, #0097a7 0%, #4dd0e1 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-cyan
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#0097a7), to(#4dd0e1));
    background-image: -webkit-linear-gradient(top, #0097a7 0%, #4dd0e1 100%);
    background-image:    -moz-linear-gradient(top, #0097a7 0%, #4dd0e1 100%);
    background-image:      -o-linear-gradient(top, #0097a7 0%, #4dd0e1 100%);
    background-image:         linear-gradient(to bottom, #0097a7 0%, #4dd0e1 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-cyan
{
    background-image: -webkit-linear-gradient(45deg, #0097a7, #4dd0e1);
    background-image:    -moz-linear-gradient(45deg, #0097a7, #4dd0e1);
    background-image:      -o-linear-gradient(45deg, #0097a7, #4dd0e1);
    background-image:         linear-gradient(45deg, #0097a7, #4dd0e1);
    background-repeat: repeat-x;
}

.bg-gradient-x2-cyan
{
    background-image: -webkit-gradient(linear, left top, right top, from(#4dd0e1), color-stop(50%, #00bcd4), to(#80deea));
    background-image: -webkit-linear-gradient(left, #4dd0e1, #00bcd4 50%, #80deea);
    background-image:    -moz-linear-gradient(left, #4dd0e1, #00bcd4 50%, #80deea);
    background-image:      -o-linear-gradient(left, #4dd0e1, #00bcd4 50%, #80deea);
    background-image:         linear-gradient(to right, #4dd0e1, #00bcd4 50%, #80deea);
    background-repeat: no-repeat;
}

.bg-gradient-y2-cyan
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#4dd0e1), color-stop(50%, #00bcd4), to(#80deea));
    background-image: -webkit-linear-gradient(#4dd0e1, #00bcd4 50%, #80deea);
    background-image:    -moz-linear-gradient(#4dd0e1, #00bcd4 50%, #80deea);
    background-image:      -o-linear-gradient(#4dd0e1, #00bcd4 50%, #80deea);
    background-image:         linear-gradient(#4dd0e1, #00bcd4 50%, #80deea);
    background-repeat: no-repeat;
}

.bg-gradient-radial-cyan
{
    background-image: -webkit-radial-gradient(circle, #0097a7, #4dd0e1);
    background-image:    -moz-radial-gradient(circle, #0097a7, #4dd0e1);
    background-image:      -o-radial-gradient(circle, #0097a7, #4dd0e1);
    background-image:         radial-gradient(circle, #0097a7, #4dd0e1);
    background-repeat: no-repeat;
}

.bg-gradient-striped-cyan
{
    background-image: -webkit-linear-gradient(45deg, #80deea 25%, transparent 25%, transparent 50%, #80deea 50%, #80deea 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #80deea 25%, transparent 25%, transparent 50%, #80deea 50%, #80deea 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #80deea 25%, transparent 25%, transparent 50%, #80deea 50%, #80deea 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #80deea 25%, transparent 25%, transparent 50%, #80deea 50%, #80deea 75%, transparent 75%, transparent);
}

.bg-gradient-x-cyan .card-header,
.bg-gradient-x-cyan .card-footer,
.bg-gradient-y-cyan .card-header,
.bg-gradient-y-cyan .card-footer,
.bg-gradient-directional-cyan .card-header,
.bg-gradient-directional-cyan .card-footer,
.bg-gradient-radial-cyan .card-header,
.bg-gradient-radial-cyan .card-footer,
.bg-gradient-striped-cyan .card-header,
.bg-gradient-striped-cyan .card-footer,
.bg-gradient-x2-cyan .card-header,
.bg-gradient-x2-cyan .card-footer,
.bg-gradient-y2-cyan .card-header,
.bg-gradient-y2-cyan .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-teal
{
    background-image: -webkit-gradient(linear, left top, right top, from(#00796b), to(#4db6ac));
    background-image: -webkit-linear-gradient(left, #00796b 0%, #4db6ac 100%);
    background-image:    -moz-linear-gradient(left, #00796b 0%, #4db6ac 100%);
    background-image:      -o-linear-gradient(left, #00796b 0%, #4db6ac 100%);
    background-image:         linear-gradient(to right, #00796b 0%, #4db6ac 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-teal
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#00796b), to(#4db6ac));
    background-image: -webkit-linear-gradient(top, #00796b 0%, #4db6ac 100%);
    background-image:    -moz-linear-gradient(top, #00796b 0%, #4db6ac 100%);
    background-image:      -o-linear-gradient(top, #00796b 0%, #4db6ac 100%);
    background-image:         linear-gradient(to bottom, #00796b 0%, #4db6ac 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-teal
{
    background-image: -webkit-linear-gradient(45deg, #00796b, #4db6ac);
    background-image:    -moz-linear-gradient(45deg, #00796b, #4db6ac);
    background-image:      -o-linear-gradient(45deg, #00796b, #4db6ac);
    background-image:         linear-gradient(45deg, #00796b, #4db6ac);
    background-repeat: repeat-x;
}

.bg-gradient-x2-teal
{
    background-image: -webkit-gradient(linear, left top, right top, from(#4db6ac), color-stop(50%, #009688), to(#80cbc4));
    background-image: -webkit-linear-gradient(left, #4db6ac, #009688 50%, #80cbc4);
    background-image:    -moz-linear-gradient(left, #4db6ac, #009688 50%, #80cbc4);
    background-image:      -o-linear-gradient(left, #4db6ac, #009688 50%, #80cbc4);
    background-image:         linear-gradient(to right, #4db6ac, #009688 50%, #80cbc4);
    background-repeat: no-repeat;
}

.bg-gradient-y2-teal
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#4db6ac), color-stop(50%, #009688), to(#80cbc4));
    background-image: -webkit-linear-gradient(#4db6ac, #009688 50%, #80cbc4);
    background-image:    -moz-linear-gradient(#4db6ac, #009688 50%, #80cbc4);
    background-image:      -o-linear-gradient(#4db6ac, #009688 50%, #80cbc4);
    background-image:         linear-gradient(#4db6ac, #009688 50%, #80cbc4);
    background-repeat: no-repeat;
}

.bg-gradient-radial-teal
{
    background-image: -webkit-radial-gradient(circle, #00796b, #4db6ac);
    background-image:    -moz-radial-gradient(circle, #00796b, #4db6ac);
    background-image:      -o-radial-gradient(circle, #00796b, #4db6ac);
    background-image:         radial-gradient(circle, #00796b, #4db6ac);
    background-repeat: no-repeat;
}

.bg-gradient-striped-teal
{
    background-image: -webkit-linear-gradient(45deg, #80cbc4 25%, transparent 25%, transparent 50%, #80cbc4 50%, #80cbc4 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #80cbc4 25%, transparent 25%, transparent 50%, #80cbc4 50%, #80cbc4 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #80cbc4 25%, transparent 25%, transparent 50%, #80cbc4 50%, #80cbc4 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #80cbc4 25%, transparent 25%, transparent 50%, #80cbc4 50%, #80cbc4 75%, transparent 75%, transparent);
}

.bg-gradient-x-teal .card-header,
.bg-gradient-x-teal .card-footer,
.bg-gradient-y-teal .card-header,
.bg-gradient-y-teal .card-footer,
.bg-gradient-directional-teal .card-header,
.bg-gradient-directional-teal .card-footer,
.bg-gradient-radial-teal .card-header,
.bg-gradient-radial-teal .card-footer,
.bg-gradient-striped-teal .card-header,
.bg-gradient-striped-teal .card-footer,
.bg-gradient-x2-teal .card-header,
.bg-gradient-x2-teal .card-footer,
.bg-gradient-y2-teal .card-header,
.bg-gradient-y2-teal .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-green
{
    background-image: -webkit-gradient(linear, left top, right top, from(#388e3c), to(#81c784));
    background-image: -webkit-linear-gradient(left, #388e3c 0%, #81c784 100%);
    background-image:    -moz-linear-gradient(left, #388e3c 0%, #81c784 100%);
    background-image:      -o-linear-gradient(left, #388e3c 0%, #81c784 100%);
    background-image:         linear-gradient(to right, #388e3c 0%, #81c784 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-green
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#388e3c), to(#81c784));
    background-image: -webkit-linear-gradient(top, #388e3c 0%, #81c784 100%);
    background-image:    -moz-linear-gradient(top, #388e3c 0%, #81c784 100%);
    background-image:      -o-linear-gradient(top, #388e3c 0%, #81c784 100%);
    background-image:         linear-gradient(to bottom, #388e3c 0%, #81c784 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-green
{
    background-image: -webkit-linear-gradient(45deg, #388e3c, #81c784);
    background-image:    -moz-linear-gradient(45deg, #388e3c, #81c784);
    background-image:      -o-linear-gradient(45deg, #388e3c, #81c784);
    background-image:         linear-gradient(45deg, #388e3c, #81c784);
    background-repeat: repeat-x;
}

.bg-gradient-x2-green
{
    background-image: -webkit-gradient(linear, left top, right top, from(#81c784), color-stop(50%, #4caf50), to(#a5d6a7));
    background-image: -webkit-linear-gradient(left, #81c784, #4caf50 50%, #a5d6a7);
    background-image:    -moz-linear-gradient(left, #81c784, #4caf50 50%, #a5d6a7);
    background-image:      -o-linear-gradient(left, #81c784, #4caf50 50%, #a5d6a7);
    background-image:         linear-gradient(to right, #81c784, #4caf50 50%, #a5d6a7);
    background-repeat: no-repeat;
}

.bg-gradient-y2-green
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#81c784), color-stop(50%, #4caf50), to(#a5d6a7));
    background-image: -webkit-linear-gradient(#81c784, #4caf50 50%, #a5d6a7);
    background-image:    -moz-linear-gradient(#81c784, #4caf50 50%, #a5d6a7);
    background-image:      -o-linear-gradient(#81c784, #4caf50 50%, #a5d6a7);
    background-image:         linear-gradient(#81c784, #4caf50 50%, #a5d6a7);
    background-repeat: no-repeat;
}

.bg-gradient-radial-green
{
    background-image: -webkit-radial-gradient(circle, #388e3c, #81c784);
    background-image:    -moz-radial-gradient(circle, #388e3c, #81c784);
    background-image:      -o-radial-gradient(circle, #388e3c, #81c784);
    background-image:         radial-gradient(circle, #388e3c, #81c784);
    background-repeat: no-repeat;
}

.bg-gradient-striped-green
{
    background-image: -webkit-linear-gradient(45deg, #a5d6a7 25%, transparent 25%, transparent 50%, #a5d6a7 50%, #a5d6a7 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #a5d6a7 25%, transparent 25%, transparent 50%, #a5d6a7 50%, #a5d6a7 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #a5d6a7 25%, transparent 25%, transparent 50%, #a5d6a7 50%, #a5d6a7 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #a5d6a7 25%, transparent 25%, transparent 50%, #a5d6a7 50%, #a5d6a7 75%, transparent 75%, transparent);
}

.bg-gradient-x-green .card-header,
.bg-gradient-x-green .card-footer,
.bg-gradient-y-green .card-header,
.bg-gradient-y-green .card-footer,
.bg-gradient-directional-green .card-header,
.bg-gradient-directional-green .card-footer,
.bg-gradient-radial-green .card-header,
.bg-gradient-radial-green .card-footer,
.bg-gradient-striped-green .card-header,
.bg-gradient-striped-green .card-footer,
.bg-gradient-x2-green .card-header,
.bg-gradient-x2-green .card-footer,
.bg-gradient-y2-green .card-header,
.bg-gradient-y2-green .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-light-green
{
    background-image: -webkit-gradient(linear, left top, right top, from(#689f38), to(#aed581));
    background-image: -webkit-linear-gradient(left, #689f38 0%, #aed581 100%);
    background-image:    -moz-linear-gradient(left, #689f38 0%, #aed581 100%);
    background-image:      -o-linear-gradient(left, #689f38 0%, #aed581 100%);
    background-image:         linear-gradient(to right, #689f38 0%, #aed581 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-light-green
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#689f38), to(#aed581));
    background-image: -webkit-linear-gradient(top, #689f38 0%, #aed581 100%);
    background-image:    -moz-linear-gradient(top, #689f38 0%, #aed581 100%);
    background-image:      -o-linear-gradient(top, #689f38 0%, #aed581 100%);
    background-image:         linear-gradient(to bottom, #689f38 0%, #aed581 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-light-green
{
    background-image: -webkit-linear-gradient(45deg, #689f38, #aed581);
    background-image:    -moz-linear-gradient(45deg, #689f38, #aed581);
    background-image:      -o-linear-gradient(45deg, #689f38, #aed581);
    background-image:         linear-gradient(45deg, #689f38, #aed581);
    background-repeat: repeat-x;
}

.bg-gradient-x2-light-green
{
    background-image: -webkit-gradient(linear, left top, right top, from(#aed581), color-stop(50%, #8bc34a), to(#c5e1a5));
    background-image: -webkit-linear-gradient(left, #aed581, #8bc34a 50%, #c5e1a5);
    background-image:    -moz-linear-gradient(left, #aed581, #8bc34a 50%, #c5e1a5);
    background-image:      -o-linear-gradient(left, #aed581, #8bc34a 50%, #c5e1a5);
    background-image:         linear-gradient(to right, #aed581, #8bc34a 50%, #c5e1a5);
    background-repeat: no-repeat;
}

.bg-gradient-y2-light-green
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#aed581), color-stop(50%, #8bc34a), to(#c5e1a5));
    background-image: -webkit-linear-gradient(#aed581, #8bc34a 50%, #c5e1a5);
    background-image:    -moz-linear-gradient(#aed581, #8bc34a 50%, #c5e1a5);
    background-image:      -o-linear-gradient(#aed581, #8bc34a 50%, #c5e1a5);
    background-image:         linear-gradient(#aed581, #8bc34a 50%, #c5e1a5);
    background-repeat: no-repeat;
}

.bg-gradient-radial-light-green
{
    background-image: -webkit-radial-gradient(circle, #689f38, #aed581);
    background-image:    -moz-radial-gradient(circle, #689f38, #aed581);
    background-image:      -o-radial-gradient(circle, #689f38, #aed581);
    background-image:         radial-gradient(circle, #689f38, #aed581);
    background-repeat: no-repeat;
}

.bg-gradient-striped-light-green
{
    background-image: -webkit-linear-gradient(45deg, #c5e1a5 25%, transparent 25%, transparent 50%, #c5e1a5 50%, #c5e1a5 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #c5e1a5 25%, transparent 25%, transparent 50%, #c5e1a5 50%, #c5e1a5 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #c5e1a5 25%, transparent 25%, transparent 50%, #c5e1a5 50%, #c5e1a5 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #c5e1a5 25%, transparent 25%, transparent 50%, #c5e1a5 50%, #c5e1a5 75%, transparent 75%, transparent);
}

.bg-gradient-x-light-green .card-header,
.bg-gradient-x-light-green .card-footer,
.bg-gradient-y-light-green .card-header,
.bg-gradient-y-light-green .card-footer,
.bg-gradient-directional-light-green .card-header,
.bg-gradient-directional-light-green .card-footer,
.bg-gradient-radial-light-green .card-header,
.bg-gradient-radial-light-green .card-footer,
.bg-gradient-striped-light-green .card-header,
.bg-gradient-striped-light-green .card-footer,
.bg-gradient-x2-light-green .card-header,
.bg-gradient-x2-light-green .card-footer,
.bg-gradient-y2-light-green .card-header,
.bg-gradient-y2-light-green .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-lime
{
    background-image: -webkit-gradient(linear, left top, right top, from(#afb42b), to(#dce775));
    background-image: -webkit-linear-gradient(left, #afb42b 0%, #dce775 100%);
    background-image:    -moz-linear-gradient(left, #afb42b 0%, #dce775 100%);
    background-image:      -o-linear-gradient(left, #afb42b 0%, #dce775 100%);
    background-image:         linear-gradient(to right, #afb42b 0%, #dce775 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-lime
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#afb42b), to(#dce775));
    background-image: -webkit-linear-gradient(top, #afb42b 0%, #dce775 100%);
    background-image:    -moz-linear-gradient(top, #afb42b 0%, #dce775 100%);
    background-image:      -o-linear-gradient(top, #afb42b 0%, #dce775 100%);
    background-image:         linear-gradient(to bottom, #afb42b 0%, #dce775 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-lime
{
    background-image: -webkit-linear-gradient(45deg, #afb42b, #dce775);
    background-image:    -moz-linear-gradient(45deg, #afb42b, #dce775);
    background-image:      -o-linear-gradient(45deg, #afb42b, #dce775);
    background-image:         linear-gradient(45deg, #afb42b, #dce775);
    background-repeat: repeat-x;
}

.bg-gradient-x2-lime
{
    background-image: -webkit-gradient(linear, left top, right top, from(#dce775), color-stop(50%, #cddc39), to(#e6ee9c));
    background-image: -webkit-linear-gradient(left, #dce775, #cddc39 50%, #e6ee9c);
    background-image:    -moz-linear-gradient(left, #dce775, #cddc39 50%, #e6ee9c);
    background-image:      -o-linear-gradient(left, #dce775, #cddc39 50%, #e6ee9c);
    background-image:         linear-gradient(to right, #dce775, #cddc39 50%, #e6ee9c);
    background-repeat: no-repeat;
}

.bg-gradient-y2-lime
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#dce775), color-stop(50%, #cddc39), to(#e6ee9c));
    background-image: -webkit-linear-gradient(#dce775, #cddc39 50%, #e6ee9c);
    background-image:    -moz-linear-gradient(#dce775, #cddc39 50%, #e6ee9c);
    background-image:      -o-linear-gradient(#dce775, #cddc39 50%, #e6ee9c);
    background-image:         linear-gradient(#dce775, #cddc39 50%, #e6ee9c);
    background-repeat: no-repeat;
}

.bg-gradient-radial-lime
{
    background-image: -webkit-radial-gradient(circle, #afb42b, #dce775);
    background-image:    -moz-radial-gradient(circle, #afb42b, #dce775);
    background-image:      -o-radial-gradient(circle, #afb42b, #dce775);
    background-image:         radial-gradient(circle, #afb42b, #dce775);
    background-repeat: no-repeat;
}

.bg-gradient-striped-lime
{
    background-image: -webkit-linear-gradient(45deg, #e6ee9c 25%, transparent 25%, transparent 50%, #e6ee9c 50%, #e6ee9c 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #e6ee9c 25%, transparent 25%, transparent 50%, #e6ee9c 50%, #e6ee9c 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #e6ee9c 25%, transparent 25%, transparent 50%, #e6ee9c 50%, #e6ee9c 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #e6ee9c 25%, transparent 25%, transparent 50%, #e6ee9c 50%, #e6ee9c 75%, transparent 75%, transparent);
}

.bg-gradient-x-lime .card-header,
.bg-gradient-x-lime .card-footer,
.bg-gradient-y-lime .card-header,
.bg-gradient-y-lime .card-footer,
.bg-gradient-directional-lime .card-header,
.bg-gradient-directional-lime .card-footer,
.bg-gradient-radial-lime .card-header,
.bg-gradient-radial-lime .card-footer,
.bg-gradient-striped-lime .card-header,
.bg-gradient-striped-lime .card-footer,
.bg-gradient-x2-lime .card-header,
.bg-gradient-x2-lime .card-footer,
.bg-gradient-y2-lime .card-header,
.bg-gradient-y2-lime .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-yellow
{
    background-image: -webkit-gradient(linear, left top, right top, from(#fbc02d), to(#fff176));
    background-image: -webkit-linear-gradient(left, #fbc02d 0%, #fff176 100%);
    background-image:    -moz-linear-gradient(left, #fbc02d 0%, #fff176 100%);
    background-image:      -o-linear-gradient(left, #fbc02d 0%, #fff176 100%);
    background-image:         linear-gradient(to right, #fbc02d 0%, #fff176 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-yellow
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fbc02d), to(#fff176));
    background-image: -webkit-linear-gradient(top, #fbc02d 0%, #fff176 100%);
    background-image:    -moz-linear-gradient(top, #fbc02d 0%, #fff176 100%);
    background-image:      -o-linear-gradient(top, #fbc02d 0%, #fff176 100%);
    background-image:         linear-gradient(to bottom, #fbc02d 0%, #fff176 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-yellow
{
    background-image: -webkit-linear-gradient(45deg, #fbc02d, #fff176);
    background-image:    -moz-linear-gradient(45deg, #fbc02d, #fff176);
    background-image:      -o-linear-gradient(45deg, #fbc02d, #fff176);
    background-image:         linear-gradient(45deg, #fbc02d, #fff176);
    background-repeat: repeat-x;
}

.bg-gradient-x2-yellow
{
    background-image: -webkit-gradient(linear, left top, right top, from(#fff176), color-stop(50%, #ffeb3b), to(#fff59d));
    background-image: -webkit-linear-gradient(left, #fff176, #ffeb3b 50%, #fff59d);
    background-image:    -moz-linear-gradient(left, #fff176, #ffeb3b 50%, #fff59d);
    background-image:      -o-linear-gradient(left, #fff176, #ffeb3b 50%, #fff59d);
    background-image:         linear-gradient(to right, #fff176, #ffeb3b 50%, #fff59d);
    background-repeat: no-repeat;
}

.bg-gradient-y2-yellow
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff176), color-stop(50%, #ffeb3b), to(#fff59d));
    background-image: -webkit-linear-gradient(#fff176, #ffeb3b 50%, #fff59d);
    background-image:    -moz-linear-gradient(#fff176, #ffeb3b 50%, #fff59d);
    background-image:      -o-linear-gradient(#fff176, #ffeb3b 50%, #fff59d);
    background-image:         linear-gradient(#fff176, #ffeb3b 50%, #fff59d);
    background-repeat: no-repeat;
}

.bg-gradient-radial-yellow
{
    background-image: -webkit-radial-gradient(circle, #fbc02d, #fff176);
    background-image:    -moz-radial-gradient(circle, #fbc02d, #fff176);
    background-image:      -o-radial-gradient(circle, #fbc02d, #fff176);
    background-image:         radial-gradient(circle, #fbc02d, #fff176);
    background-repeat: no-repeat;
}

.bg-gradient-striped-yellow
{
    background-image: -webkit-linear-gradient(45deg, #fff59d 25%, transparent 25%, transparent 50%, #fff59d 50%, #fff59d 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #fff59d 25%, transparent 25%, transparent 50%, #fff59d 50%, #fff59d 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #fff59d 25%, transparent 25%, transparent 50%, #fff59d 50%, #fff59d 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #fff59d 25%, transparent 25%, transparent 50%, #fff59d 50%, #fff59d 75%, transparent 75%, transparent);
}

.bg-gradient-x-yellow .card-header,
.bg-gradient-x-yellow .card-footer,
.bg-gradient-y-yellow .card-header,
.bg-gradient-y-yellow .card-footer,
.bg-gradient-directional-yellow .card-header,
.bg-gradient-directional-yellow .card-footer,
.bg-gradient-radial-yellow .card-header,
.bg-gradient-radial-yellow .card-footer,
.bg-gradient-striped-yellow .card-header,
.bg-gradient-striped-yellow .card-footer,
.bg-gradient-x2-yellow .card-header,
.bg-gradient-x2-yellow .card-footer,
.bg-gradient-y2-yellow .card-header,
.bg-gradient-y2-yellow .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-amber
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ffa000), to(#ffd54f));
    background-image: -webkit-linear-gradient(left, #ffa000 0%, #ffd54f 100%);
    background-image:    -moz-linear-gradient(left, #ffa000 0%, #ffd54f 100%);
    background-image:      -o-linear-gradient(left, #ffa000 0%, #ffd54f 100%);
    background-image:         linear-gradient(to right, #ffa000 0%, #ffd54f 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-amber
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffa000), to(#ffd54f));
    background-image: -webkit-linear-gradient(top, #ffa000 0%, #ffd54f 100%);
    background-image:    -moz-linear-gradient(top, #ffa000 0%, #ffd54f 100%);
    background-image:      -o-linear-gradient(top, #ffa000 0%, #ffd54f 100%);
    background-image:         linear-gradient(to bottom, #ffa000 0%, #ffd54f 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-amber
{
    background-image: -webkit-linear-gradient(45deg, #ffa000, #ffd54f);
    background-image:    -moz-linear-gradient(45deg, #ffa000, #ffd54f);
    background-image:      -o-linear-gradient(45deg, #ffa000, #ffd54f);
    background-image:         linear-gradient(45deg, #ffa000, #ffd54f);
    background-repeat: repeat-x;
}

.bg-gradient-x2-amber
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ffd54f), color-stop(50%, #ffc107), to(#ffe082));
    background-image: -webkit-linear-gradient(left, #ffd54f, #ffc107 50%, #ffe082);
    background-image:    -moz-linear-gradient(left, #ffd54f, #ffc107 50%, #ffe082);
    background-image:      -o-linear-gradient(left, #ffd54f, #ffc107 50%, #ffe082);
    background-image:         linear-gradient(to right, #ffd54f, #ffc107 50%, #ffe082);
    background-repeat: no-repeat;
}

.bg-gradient-y2-amber
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffd54f), color-stop(50%, #ffc107), to(#ffe082));
    background-image: -webkit-linear-gradient(#ffd54f, #ffc107 50%, #ffe082);
    background-image:    -moz-linear-gradient(#ffd54f, #ffc107 50%, #ffe082);
    background-image:      -o-linear-gradient(#ffd54f, #ffc107 50%, #ffe082);
    background-image:         linear-gradient(#ffd54f, #ffc107 50%, #ffe082);
    background-repeat: no-repeat;
}

.bg-gradient-radial-amber
{
    background-image: -webkit-radial-gradient(circle, #ffa000, #ffd54f);
    background-image:    -moz-radial-gradient(circle, #ffa000, #ffd54f);
    background-image:      -o-radial-gradient(circle, #ffa000, #ffd54f);
    background-image:         radial-gradient(circle, #ffa000, #ffd54f);
    background-repeat: no-repeat;
}

.bg-gradient-striped-amber
{
    background-image: -webkit-linear-gradient(45deg, #ffe082 25%, transparent 25%, transparent 50%, #ffe082 50%, #ffe082 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #ffe082 25%, transparent 25%, transparent 50%, #ffe082 50%, #ffe082 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #ffe082 25%, transparent 25%, transparent 50%, #ffe082 50%, #ffe082 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #ffe082 25%, transparent 25%, transparent 50%, #ffe082 50%, #ffe082 75%, transparent 75%, transparent);
}

.bg-gradient-x-amber .card-header,
.bg-gradient-x-amber .card-footer,
.bg-gradient-y-amber .card-header,
.bg-gradient-y-amber .card-footer,
.bg-gradient-directional-amber .card-header,
.bg-gradient-directional-amber .card-footer,
.bg-gradient-radial-amber .card-header,
.bg-gradient-radial-amber .card-footer,
.bg-gradient-striped-amber .card-header,
.bg-gradient-striped-amber .card-footer,
.bg-gradient-x2-amber .card-header,
.bg-gradient-x2-amber .card-footer,
.bg-gradient-y2-amber .card-header,
.bg-gradient-y2-amber .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-orange
{
    background-image: -webkit-gradient(linear, left top, right top, from(#f57c00), to(#ffb74d));
    background-image: -webkit-linear-gradient(left, #f57c00 0%, #ffb74d 100%);
    background-image:    -moz-linear-gradient(left, #f57c00 0%, #ffb74d 100%);
    background-image:      -o-linear-gradient(left, #f57c00 0%, #ffb74d 100%);
    background-image:         linear-gradient(to right, #f57c00 0%, #ffb74d 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-orange
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f57c00), to(#ffb74d));
    background-image: -webkit-linear-gradient(top, #f57c00 0%, #ffb74d 100%);
    background-image:    -moz-linear-gradient(top, #f57c00 0%, #ffb74d 100%);
    background-image:      -o-linear-gradient(top, #f57c00 0%, #ffb74d 100%);
    background-image:         linear-gradient(to bottom, #f57c00 0%, #ffb74d 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-orange
{
    background-image: -webkit-linear-gradient(45deg, #f57c00, #ffb74d);
    background-image:    -moz-linear-gradient(45deg, #f57c00, #ffb74d);
    background-image:      -o-linear-gradient(45deg, #f57c00, #ffb74d);
    background-image:         linear-gradient(45deg, #f57c00, #ffb74d);
    background-repeat: repeat-x;
}

.bg-gradient-x2-orange
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ffb74d), color-stop(50%, #ff9800), to(#ffcc80));
    background-image: -webkit-linear-gradient(left, #ffb74d, #ff9800 50%, #ffcc80);
    background-image:    -moz-linear-gradient(left, #ffb74d, #ff9800 50%, #ffcc80);
    background-image:      -o-linear-gradient(left, #ffb74d, #ff9800 50%, #ffcc80);
    background-image:         linear-gradient(to right, #ffb74d, #ff9800 50%, #ffcc80);
    background-repeat: no-repeat;
}

.bg-gradient-y2-orange
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffb74d), color-stop(50%, #ff9800), to(#ffcc80));
    background-image: -webkit-linear-gradient(#ffb74d, #ff9800 50%, #ffcc80);
    background-image:    -moz-linear-gradient(#ffb74d, #ff9800 50%, #ffcc80);
    background-image:      -o-linear-gradient(#ffb74d, #ff9800 50%, #ffcc80);
    background-image:         linear-gradient(#ffb74d, #ff9800 50%, #ffcc80);
    background-repeat: no-repeat;
}

.bg-gradient-radial-orange
{
    background-image: -webkit-radial-gradient(circle, #f57c00, #ffb74d);
    background-image:    -moz-radial-gradient(circle, #f57c00, #ffb74d);
    background-image:      -o-radial-gradient(circle, #f57c00, #ffb74d);
    background-image:         radial-gradient(circle, #f57c00, #ffb74d);
    background-repeat: no-repeat;
}

.bg-gradient-striped-orange
{
    background-image: -webkit-linear-gradient(45deg, #ffcc80 25%, transparent 25%, transparent 50%, #ffcc80 50%, #ffcc80 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #ffcc80 25%, transparent 25%, transparent 50%, #ffcc80 50%, #ffcc80 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #ffcc80 25%, transparent 25%, transparent 50%, #ffcc80 50%, #ffcc80 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #ffcc80 25%, transparent 25%, transparent 50%, #ffcc80 50%, #ffcc80 75%, transparent 75%, transparent);
}

.bg-gradient-x-orange .card-header,
.bg-gradient-x-orange .card-footer,
.bg-gradient-y-orange .card-header,
.bg-gradient-y-orange .card-footer,
.bg-gradient-directional-orange .card-header,
.bg-gradient-directional-orange .card-footer,
.bg-gradient-radial-orange .card-header,
.bg-gradient-radial-orange .card-footer,
.bg-gradient-striped-orange .card-header,
.bg-gradient-striped-orange .card-footer,
.bg-gradient-x2-orange .card-header,
.bg-gradient-x2-orange .card-footer,
.bg-gradient-y2-orange .card-header,
.bg-gradient-y2-orange .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-deep-orange
{
    background-image: -webkit-gradient(linear, left top, right top, from(#e64a19), to(#ff8a65));
    background-image: -webkit-linear-gradient(left, #e64a19 0%, #ff8a65 100%);
    background-image:    -moz-linear-gradient(left, #e64a19 0%, #ff8a65 100%);
    background-image:      -o-linear-gradient(left, #e64a19 0%, #ff8a65 100%);
    background-image:         linear-gradient(to right, #e64a19 0%, #ff8a65 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-deep-orange
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e64a19), to(#ff8a65));
    background-image: -webkit-linear-gradient(top, #e64a19 0%, #ff8a65 100%);
    background-image:    -moz-linear-gradient(top, #e64a19 0%, #ff8a65 100%);
    background-image:      -o-linear-gradient(top, #e64a19 0%, #ff8a65 100%);
    background-image:         linear-gradient(to bottom, #e64a19 0%, #ff8a65 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-deep-orange
{
    background-image: -webkit-linear-gradient(45deg, #e64a19, #ff8a65);
    background-image:    -moz-linear-gradient(45deg, #e64a19, #ff8a65);
    background-image:      -o-linear-gradient(45deg, #e64a19, #ff8a65);
    background-image:         linear-gradient(45deg, #e64a19, #ff8a65);
    background-repeat: repeat-x;
}

.bg-gradient-x2-deep-orange
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ff8a65), color-stop(50%, #ff5722), to(#ffab91));
    background-image: -webkit-linear-gradient(left, #ff8a65, #ff5722 50%, #ffab91);
    background-image:    -moz-linear-gradient(left, #ff8a65, #ff5722 50%, #ffab91);
    background-image:      -o-linear-gradient(left, #ff8a65, #ff5722 50%, #ffab91);
    background-image:         linear-gradient(to right, #ff8a65, #ff5722 50%, #ffab91);
    background-repeat: no-repeat;
}

.bg-gradient-y2-deep-orange
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff8a65), color-stop(50%, #ff5722), to(#ffab91));
    background-image: -webkit-linear-gradient(#ff8a65, #ff5722 50%, #ffab91);
    background-image:    -moz-linear-gradient(#ff8a65, #ff5722 50%, #ffab91);
    background-image:      -o-linear-gradient(#ff8a65, #ff5722 50%, #ffab91);
    background-image:         linear-gradient(#ff8a65, #ff5722 50%, #ffab91);
    background-repeat: no-repeat;
}

.bg-gradient-radial-deep-orange
{
    background-image: -webkit-radial-gradient(circle, #e64a19, #ff8a65);
    background-image:    -moz-radial-gradient(circle, #e64a19, #ff8a65);
    background-image:      -o-radial-gradient(circle, #e64a19, #ff8a65);
    background-image:         radial-gradient(circle, #e64a19, #ff8a65);
    background-repeat: no-repeat;
}

.bg-gradient-striped-deep-orange
{
    background-image: -webkit-linear-gradient(45deg, #ffab91 25%, transparent 25%, transparent 50%, #ffab91 50%, #ffab91 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #ffab91 25%, transparent 25%, transparent 50%, #ffab91 50%, #ffab91 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #ffab91 25%, transparent 25%, transparent 50%, #ffab91 50%, #ffab91 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #ffab91 25%, transparent 25%, transparent 50%, #ffab91 50%, #ffab91 75%, transparent 75%, transparent);
}

.bg-gradient-x-deep-orange .card-header,
.bg-gradient-x-deep-orange .card-footer,
.bg-gradient-y-deep-orange .card-header,
.bg-gradient-y-deep-orange .card-footer,
.bg-gradient-directional-deep-orange .card-header,
.bg-gradient-directional-deep-orange .card-footer,
.bg-gradient-radial-deep-orange .card-header,
.bg-gradient-radial-deep-orange .card-footer,
.bg-gradient-striped-deep-orange .card-header,
.bg-gradient-striped-deep-orange .card-footer,
.bg-gradient-x2-deep-orange .card-header,
.bg-gradient-x2-deep-orange .card-footer,
.bg-gradient-y2-deep-orange .card-header,
.bg-gradient-y2-deep-orange .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-brown
{
    background-image: -webkit-gradient(linear, left top, right top, from(#5d4037), to(#a1887f));
    background-image: -webkit-linear-gradient(left, #5d4037 0%, #a1887f 100%);
    background-image:    -moz-linear-gradient(left, #5d4037 0%, #a1887f 100%);
    background-image:      -o-linear-gradient(left, #5d4037 0%, #a1887f 100%);
    background-image:         linear-gradient(to right, #5d4037 0%, #a1887f 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-brown
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#5d4037), to(#a1887f));
    background-image: -webkit-linear-gradient(top, #5d4037 0%, #a1887f 100%);
    background-image:    -moz-linear-gradient(top, #5d4037 0%, #a1887f 100%);
    background-image:      -o-linear-gradient(top, #5d4037 0%, #a1887f 100%);
    background-image:         linear-gradient(to bottom, #5d4037 0%, #a1887f 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-brown
{
    background-image: -webkit-linear-gradient(45deg, #5d4037, #a1887f);
    background-image:    -moz-linear-gradient(45deg, #5d4037, #a1887f);
    background-image:      -o-linear-gradient(45deg, #5d4037, #a1887f);
    background-image:         linear-gradient(45deg, #5d4037, #a1887f);
    background-repeat: repeat-x;
}

.bg-gradient-x2-brown
{
    background-image: -webkit-gradient(linear, left top, right top, from(#a1887f), color-stop(50%, #795548), to(#bcaaa4));
    background-image: -webkit-linear-gradient(left, #a1887f, #795548 50%, #bcaaa4);
    background-image:    -moz-linear-gradient(left, #a1887f, #795548 50%, #bcaaa4);
    background-image:      -o-linear-gradient(left, #a1887f, #795548 50%, #bcaaa4);
    background-image:         linear-gradient(to right, #a1887f, #795548 50%, #bcaaa4);
    background-repeat: no-repeat;
}

.bg-gradient-y2-brown
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#a1887f), color-stop(50%, #795548), to(#bcaaa4));
    background-image: -webkit-linear-gradient(#a1887f, #795548 50%, #bcaaa4);
    background-image:    -moz-linear-gradient(#a1887f, #795548 50%, #bcaaa4);
    background-image:      -o-linear-gradient(#a1887f, #795548 50%, #bcaaa4);
    background-image:         linear-gradient(#a1887f, #795548 50%, #bcaaa4);
    background-repeat: no-repeat;
}

.bg-gradient-radial-brown
{
    background-image: -webkit-radial-gradient(circle, #5d4037, #a1887f);
    background-image:    -moz-radial-gradient(circle, #5d4037, #a1887f);
    background-image:      -o-radial-gradient(circle, #5d4037, #a1887f);
    background-image:         radial-gradient(circle, #5d4037, #a1887f);
    background-repeat: no-repeat;
}

.bg-gradient-striped-brown
{
    background-image: -webkit-linear-gradient(45deg, #bcaaa4 25%, transparent 25%, transparent 50%, #bcaaa4 50%, #bcaaa4 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #bcaaa4 25%, transparent 25%, transparent 50%, #bcaaa4 50%, #bcaaa4 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #bcaaa4 25%, transparent 25%, transparent 50%, #bcaaa4 50%, #bcaaa4 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #bcaaa4 25%, transparent 25%, transparent 50%, #bcaaa4 50%, #bcaaa4 75%, transparent 75%, transparent);
}

.bg-gradient-x-brown .card-header,
.bg-gradient-x-brown .card-footer,
.bg-gradient-y-brown .card-header,
.bg-gradient-y-brown .card-footer,
.bg-gradient-directional-brown .card-header,
.bg-gradient-directional-brown .card-footer,
.bg-gradient-radial-brown .card-header,
.bg-gradient-radial-brown .card-footer,
.bg-gradient-striped-brown .card-header,
.bg-gradient-striped-brown .card-footer,
.bg-gradient-x2-brown .card-header,
.bg-gradient-x2-brown .card-footer,
.bg-gradient-y2-brown .card-header,
.bg-gradient-y2-brown .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-blue-grey
{
    background-image: -webkit-gradient(linear, left top, right top, from(#455a64), to(#90a4ae));
    background-image: -webkit-linear-gradient(left, #455a64 0%, #90a4ae 100%);
    background-image:    -moz-linear-gradient(left, #455a64 0%, #90a4ae 100%);
    background-image:      -o-linear-gradient(left, #455a64 0%, #90a4ae 100%);
    background-image:         linear-gradient(to right, #455a64 0%, #90a4ae 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-blue-grey
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#455a64), to(#90a4ae));
    background-image: -webkit-linear-gradient(top, #455a64 0%, #90a4ae 100%);
    background-image:    -moz-linear-gradient(top, #455a64 0%, #90a4ae 100%);
    background-image:      -o-linear-gradient(top, #455a64 0%, #90a4ae 100%);
    background-image:         linear-gradient(to bottom, #455a64 0%, #90a4ae 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-blue-grey
{
    background-image: -webkit-linear-gradient(45deg, #455a64, #90a4ae);
    background-image:    -moz-linear-gradient(45deg, #455a64, #90a4ae);
    background-image:      -o-linear-gradient(45deg, #455a64, #90a4ae);
    background-image:         linear-gradient(45deg, #455a64, #90a4ae);
    background-repeat: repeat-x;
}

.bg-gradient-x2-blue-grey
{
    background-image: -webkit-gradient(linear, left top, right top, from(#90a4ae), color-stop(50%, #607d8b), to(#b0bec5));
    background-image: -webkit-linear-gradient(left, #90a4ae, #607d8b 50%, #b0bec5);
    background-image:    -moz-linear-gradient(left, #90a4ae, #607d8b 50%, #b0bec5);
    background-image:      -o-linear-gradient(left, #90a4ae, #607d8b 50%, #b0bec5);
    background-image:         linear-gradient(to right, #90a4ae, #607d8b 50%, #b0bec5);
    background-repeat: no-repeat;
}

.bg-gradient-y2-blue-grey
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#90a4ae), color-stop(50%, #607d8b), to(#b0bec5));
    background-image: -webkit-linear-gradient(#90a4ae, #607d8b 50%, #b0bec5);
    background-image:    -moz-linear-gradient(#90a4ae, #607d8b 50%, #b0bec5);
    background-image:      -o-linear-gradient(#90a4ae, #607d8b 50%, #b0bec5);
    background-image:         linear-gradient(#90a4ae, #607d8b 50%, #b0bec5);
    background-repeat: no-repeat;
}

.bg-gradient-radial-blue-grey
{
    background-image: -webkit-radial-gradient(circle, #455a64, #90a4ae);
    background-image:    -moz-radial-gradient(circle, #455a64, #90a4ae);
    background-image:      -o-radial-gradient(circle, #455a64, #90a4ae);
    background-image:         radial-gradient(circle, #455a64, #90a4ae);
    background-repeat: no-repeat;
}

.bg-gradient-striped-blue-grey
{
    background-image: -webkit-linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
}

.bg-gradient-x-blue-grey .card-header,
.bg-gradient-x-blue-grey .card-footer,
.bg-gradient-y-blue-grey .card-header,
.bg-gradient-y-blue-grey .card-footer,
.bg-gradient-directional-blue-grey .card-header,
.bg-gradient-directional-blue-grey .card-footer,
.bg-gradient-radial-blue-grey .card-header,
.bg-gradient-radial-blue-grey .card-footer,
.bg-gradient-striped-blue-grey .card-header,
.bg-gradient-striped-blue-grey .card-footer,
.bg-gradient-x2-blue-grey .card-header,
.bg-gradient-x2-blue-grey .card-footer,
.bg-gradient-y2-blue-grey .card-header,
.bg-gradient-y2-blue-grey .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-grey
{
    background-image: -webkit-gradient(linear, left top, right top, from(#616161), to(#e0e0e0));
    background-image: -webkit-linear-gradient(left, #616161 0%, #e0e0e0 100%);
    background-image:    -moz-linear-gradient(left, #616161 0%, #e0e0e0 100%);
    background-image:      -o-linear-gradient(left, #616161 0%, #e0e0e0 100%);
    background-image:         linear-gradient(to right, #616161 0%, #e0e0e0 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-grey
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#616161), to(#e0e0e0));
    background-image: -webkit-linear-gradient(top, #616161 0%, #e0e0e0 100%);
    background-image:    -moz-linear-gradient(top, #616161 0%, #e0e0e0 100%);
    background-image:      -o-linear-gradient(top, #616161 0%, #e0e0e0 100%);
    background-image:         linear-gradient(to bottom, #616161 0%, #e0e0e0 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-grey
{
    background-image: -webkit-linear-gradient(45deg, #616161, #e0e0e0);
    background-image:    -moz-linear-gradient(45deg, #616161, #e0e0e0);
    background-image:      -o-linear-gradient(45deg, #616161, #e0e0e0);
    background-image:         linear-gradient(45deg, #616161, #e0e0e0);
    background-repeat: repeat-x;
}

.bg-gradient-x2-grey
{
    background-image: -webkit-gradient(linear, left top, right top, from(#e0e0e0), color-stop(50%, #9e9e9e), to(#eee));
    background-image: -webkit-linear-gradient(left, #e0e0e0, #9e9e9e 50%, #eee);
    background-image:    -moz-linear-gradient(left, #e0e0e0, #9e9e9e 50%, #eee);
    background-image:      -o-linear-gradient(left, #e0e0e0, #9e9e9e 50%, #eee);
    background-image:         linear-gradient(to right, #e0e0e0, #9e9e9e 50%, #eee);
    background-repeat: no-repeat;
}

.bg-gradient-y2-grey
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e0e0e0), color-stop(50%, #9e9e9e), to(#eee));
    background-image: -webkit-linear-gradient(#e0e0e0, #9e9e9e 50%, #eee);
    background-image:    -moz-linear-gradient(#e0e0e0, #9e9e9e 50%, #eee);
    background-image:      -o-linear-gradient(#e0e0e0, #9e9e9e 50%, #eee);
    background-image:         linear-gradient(#e0e0e0, #9e9e9e 50%, #eee);
    background-repeat: no-repeat;
}

.bg-gradient-radial-grey
{
    background-image: -webkit-radial-gradient(circle, #616161, #e0e0e0);
    background-image:    -moz-radial-gradient(circle, #616161, #e0e0e0);
    background-image:      -o-radial-gradient(circle, #616161, #e0e0e0);
    background-image:         radial-gradient(circle, #616161, #e0e0e0);
    background-repeat: no-repeat;
}

.bg-gradient-striped-grey
{
    background-image: -webkit-linear-gradient(45deg, #eee 25%, transparent 25%, transparent 50%, #eee 50%, #eee 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #eee 25%, transparent 25%, transparent 50%, #eee 50%, #eee 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #eee 25%, transparent 25%, transparent 50%, #eee 50%, #eee 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #eee 25%, transparent 25%, transparent 50%, #eee 50%, #eee 75%, transparent 75%, transparent);
}

.bg-gradient-x-grey .card-header,
.bg-gradient-x-grey .card-footer,
.bg-gradient-y-grey .card-header,
.bg-gradient-y-grey .card-footer,
.bg-gradient-directional-grey .card-header,
.bg-gradient-directional-grey .card-footer,
.bg-gradient-radial-grey .card-header,
.bg-gradient-radial-grey .card-footer,
.bg-gradient-striped-grey .card-header,
.bg-gradient-striped-grey .card-footer,
.bg-gradient-x2-grey .card-header,
.bg-gradient-x2-grey .card-footer,
.bg-gradient-y2-grey .card-header,
.bg-gradient-y2-grey .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-grey-blue
{
    background-image: -webkit-gradient(linear, left top, right top, from(#2c303b), to(#6f85ad));
    background-image: -webkit-linear-gradient(left, #2c303b 0%, #6f85ad 100%);
    background-image:    -moz-linear-gradient(left, #2c303b 0%, #6f85ad 100%);
    background-image:      -o-linear-gradient(left, #2c303b 0%, #6f85ad 100%);
    background-image:         linear-gradient(to right, #2c303b 0%, #6f85ad 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-grey-blue
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#2c303b), to(#6f85ad));
    background-image: -webkit-linear-gradient(top, #2c303b 0%, #6f85ad 100%);
    background-image:    -moz-linear-gradient(top, #2c303b 0%, #6f85ad 100%);
    background-image:      -o-linear-gradient(top, #2c303b 0%, #6f85ad 100%);
    background-image:         linear-gradient(to bottom, #2c303b 0%, #6f85ad 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-grey-blue
{
    background-image: -webkit-linear-gradient(45deg, #2c303b, #6f85ad);
    background-image:    -moz-linear-gradient(45deg, #2c303b, #6f85ad);
    background-image:      -o-linear-gradient(45deg, #2c303b, #6f85ad);
    background-image:         linear-gradient(45deg, #2c303b, #6f85ad);
    background-repeat: repeat-x;
}

.bg-gradient-x2-grey-blue
{
    background-image: -webkit-gradient(linear, left top, right top, from(#6f85ad), color-stop(50%, #1b2942), to(#b0bec5));
    background-image: -webkit-linear-gradient(left, #6f85ad, #1b2942 50%, #b0bec5);
    background-image:    -moz-linear-gradient(left, #6f85ad, #1b2942 50%, #b0bec5);
    background-image:      -o-linear-gradient(left, #6f85ad, #1b2942 50%, #b0bec5);
    background-image:         linear-gradient(to right, #6f85ad, #1b2942 50%, #b0bec5);
    background-repeat: no-repeat;
}

.bg-gradient-y2-grey-blue
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#6f85ad), color-stop(50%, #1b2942), to(#b0bec5));
    background-image: -webkit-linear-gradient(#6f85ad, #1b2942 50%, #b0bec5);
    background-image:    -moz-linear-gradient(#6f85ad, #1b2942 50%, #b0bec5);
    background-image:      -o-linear-gradient(#6f85ad, #1b2942 50%, #b0bec5);
    background-image:         linear-gradient(#6f85ad, #1b2942 50%, #b0bec5);
    background-repeat: no-repeat;
}

.bg-gradient-radial-grey-blue
{
    background-image: -webkit-radial-gradient(circle, #2c303b, #6f85ad);
    background-image:    -moz-radial-gradient(circle, #2c303b, #6f85ad);
    background-image:      -o-radial-gradient(circle, #2c303b, #6f85ad);
    background-image:         radial-gradient(circle, #2c303b, #6f85ad);
    background-repeat: no-repeat;
}

.bg-gradient-striped-grey-blue
{
    background-image: -webkit-linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
}

.bg-gradient-x-grey-blue .card-header,
.bg-gradient-x-grey-blue .card-footer,
.bg-gradient-y-grey-blue .card-header,
.bg-gradient-y-grey-blue .card-footer,
.bg-gradient-directional-grey-blue .card-header,
.bg-gradient-directional-grey-blue .card-footer,
.bg-gradient-radial-grey-blue .card-header,
.bg-gradient-radial-grey-blue .card-footer,
.bg-gradient-striped-grey-blue .card-header,
.bg-gradient-striped-grey-blue .card-footer,
.bg-gradient-x2-grey-blue .card-header,
.bg-gradient-x2-grey-blue .card-footer,
.bg-gradient-y2-grey-blue .card-header,
.bg-gradient-y2-grey-blue .card-footer
{
    background-color: transparent;
}
