.white
{
    color: #fff !important;
}

.bg-white
{
    background-color: #fff !important;
}
.bg-white .card-header,
.bg-white .card-footer
{
    background-color: transparent;
}
.bg-white.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
}

.selectboxit.selectboxit-btn.bg-white
{
    background-color: #fff !important;
}

.alert-white
{
    color: #a6a6a6 !important; 
    border-color: #fff !important;
    background-color: white !important;
}
.alert-white .alert-link
{
    color: #919191 !important;
}

.border-white
{
    border-color: #fff;
}

.overlay-white
{
    background: #fff;
    /* The Fallback */
    background: rgba(255, 255, 255, .8);
}

/* .card-outline-white{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-white
{
    color: #fff; 
    border-color: #fff !important;
    background-color: #fff !important;
}
.btn-white:hover
{
    color: #fff !important; 
    border-color: !important;
    background-color: !important;
}
.btn-white:focus,
.btn-white:active
{
    color: #fff !important; 
    border-color: !important;
    background-color: !important;
}
.btn-white.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
}

.btn-outline-white
{
    color: #fff; 
    border-color: #fff;
    background-color: transparent;
}
.btn-outline-white:hover
{
    color: #fff !important; 
    background-color: #fff;
}
.btn-outline-white.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
}

input[type='checkbox'].bg-white + .custom-control-label:before,
input[type='radio'].bg-white + .custom-control-label:before
{
    background-color: #fff !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-white
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff !important;
}

.border-white
{
    border: 1px solid #fff !important;
}

.border-top-white
{
    border-top: 1px solid #fff;
}

.border-bottom-white
{
    border-bottom: 1px solid #fff;
}

.border-left-white
{
    border-left: 1px solid #fff;
}

.border-right-white
{
    border-right: 1px solid #fff;
}

.black
{
    color: #000 !important;
}

.bg-black
{
    background-color: #000 !important;
}
.bg-black .card-header,
.bg-black .card-footer
{
    background-color: transparent;
}
.bg-black.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
}

.selectboxit.selectboxit-btn.bg-black
{
    background-color: #000 !important;
}

.alert-black
{
    color: black !important; 
    border-color: #000 !important;
    background-color: #242424 !important;
}
.alert-black .alert-link
{
    color: black !important;
}

.border-black
{
    border-color: #000;
}

.overlay-black
{
    background: #000;
    /* The Fallback */
    background: rgba(0, 0, 0, .8);
}

/* .card-outline-black{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-black
{
    color: #fff; 
    border-color: #000 !important;
    background-color: #000 !important;
}
.btn-black:hover
{
    color: #fff !important; 
    border-color: !important;
    background-color: !important;
}
.btn-black:focus,
.btn-black:active
{
    color: #fff !important; 
    border-color: !important;
    background-color: !important;
}
.btn-black.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
}

.btn-outline-black
{
    color: #000; 
    border-color: #000;
    background-color: transparent;
}
.btn-outline-black:hover
{
    color: #fff !important; 
    background-color: #000;
}
.btn-outline-black.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
}

input[type='checkbox'].bg-black + .custom-control-label:before,
input[type='radio'].bg-black + .custom-control-label:before
{
    background-color: #000 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-black
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #000 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #000 !important;
}

.border-black
{
    border: 1px solid #000 !important;
}

.border-top-black
{
    border-top: 1px solid #000;
}

.border-bottom-black
{
    border-bottom: 1px solid #000;
}

.border-left-black
{
    border-left: 1px solid #000;
}

.border-right-black
{
    border-right: 1px solid #000;
}

.primary.lighten-5
{
    color: #f1ebff !important;
}

.bg-primary.bg-lighten-5
{
    background-color: #f1ebff !important;
}
.bg-primary.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(241, 235, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(241, 235, 255, .6);
}

.btn-primary.btn-lighten-5
{
    border-color: #704acf !important;
    background-color: #f1ebff !important;
}
.btn-primary.btn-lighten-5:hover
{
    border-color: #704acf !important;
    background-color: #5e34c6 !important;
}
.btn-primary.btn-lighten-5:focus,
.btn-primary.btn-lighten-5:active
{
    border-color: #5e34c6 !important;
    background-color: #522eae !important;
}

.btn-outline-primary.btn-outline-lighten-5
{
    color: #f1ebff !important; 
    border-color: #f1ebff !important;
}
.btn-outline-primary.btn-outline-lighten-5:hover
{
    background-color: #f1ebff !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f1ebff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f1ebff !important;
}

.border-primary.border-lighten-5
{
    border: 1px solid #f1ebff !important;
}

.border-top-primary.border-top-lighten-5
{
    border-top: 1px solid #f1ebff !important;
}

.border-bottom-primary.border-bottom-lighten-5
{
    border-bottom: 1px solid #f1ebff !important;
}

.border-left-primary.border-left-lighten-5
{
    border-left: 1px solid #f1ebff !important;
}

.border-right-primary.border-right-lighten-5
{
    border-right: 1px solid #f1ebff !important;
}

.overlay-primary.overlay-lighten-5
{
    background: #f1ebff;
    /* The Fallback */
    background: rgba(241, 235, 255, .8);
}

.primary.lighten-4
{
    color: #e9e3f8 !important;
}

.bg-primary.bg-lighten-4
{
    background-color: #e9e3f8 !important;
}
.bg-primary.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(233, 227, 248, .6);
            box-shadow: 0 1px 20px 1px rgba(233, 227, 248, .6);
}

.btn-primary.btn-lighten-4
{
    border-color: #704acf !important;
    background-color: #e9e3f8 !important;
}
.btn-primary.btn-lighten-4:hover
{
    border-color: #704acf !important;
    background-color: #5e34c6 !important;
}
.btn-primary.btn-lighten-4:focus,
.btn-primary.btn-lighten-4:active
{
    border-color: #5e34c6 !important;
    background-color: #522eae !important;
}

.btn-outline-primary.btn-outline-lighten-4
{
    color: #e9e3f8 !important; 
    border-color: #e9e3f8 !important;
}
.btn-outline-primary.btn-outline-lighten-4:hover
{
    background-color: #e9e3f8 !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e9e3f8 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e9e3f8 !important;
}

.border-primary.border-lighten-4
{
    border: 1px solid #e9e3f8 !important;
}

.border-top-primary.border-top-lighten-4
{
    border-top: 1px solid #e9e3f8 !important;
}

.border-bottom-primary.border-bottom-lighten-4
{
    border-bottom: 1px solid #e9e3f8 !important;
}

.border-left-primary.border-left-lighten-4
{
    border-left: 1px solid #e9e3f8 !important;
}

.border-right-primary.border-right-lighten-4
{
    border-right: 1px solid #e9e3f8 !important;
}

.overlay-primary.overlay-lighten-4
{
    background: #e9e3f8;
    /* The Fallback */
    background: rgba(233, 227, 248, .8);
}

.primary.lighten-3
{
    color: #bcaae9 !important;
}

.bg-primary.bg-lighten-3
{
    background-color: #bcaae9 !important;
}
.bg-primary.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(188, 170, 233, .6);
            box-shadow: 0 1px 20px 1px rgba(188, 170, 233, .6);
}

.btn-primary.btn-lighten-3
{
    border-color: #704acf !important;
    background-color: #bcaae9 !important;
}
.btn-primary.btn-lighten-3:hover
{
    border-color: #704acf !important;
    background-color: #5e34c6 !important;
}
.btn-primary.btn-lighten-3:focus,
.btn-primary.btn-lighten-3:active
{
    border-color: #5e34c6 !important;
    background-color: #522eae !important;
}

.btn-outline-primary.btn-outline-lighten-3
{
    color: #bcaae9 !important; 
    border-color: #bcaae9 !important;
}
.btn-outline-primary.btn-outline-lighten-3:hover
{
    background-color: #bcaae9 !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bcaae9 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bcaae9 !important;
}

.border-primary.border-lighten-3
{
    border: 1px solid #bcaae9 !important;
}

.border-top-primary.border-top-lighten-3
{
    border-top: 1px solid #bcaae9 !important;
}

.border-bottom-primary.border-bottom-lighten-3
{
    border-bottom: 1px solid #bcaae9 !important;
}

.border-left-primary.border-left-lighten-3
{
    border-left: 1px solid #bcaae9 !important;
}

.border-right-primary.border-right-lighten-3
{
    border-right: 1px solid #bcaae9 !important;
}

.overlay-primary.overlay-lighten-3
{
    background: #bcaae9;
    /* The Fallback */
    background: rgba(188, 170, 233, .8);
}

.primary.lighten-2
{
    color: #ac92ec !important;
}

.bg-primary.bg-lighten-2
{
    background-color: #ac92ec !important;
}
.bg-primary.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(172, 146, 236, .6);
            box-shadow: 0 1px 20px 1px rgba(172, 146, 236, .6);
}

.btn-primary.btn-lighten-2
{
    border-color: #704acf !important;
    background-color: #ac92ec !important;
}
.btn-primary.btn-lighten-2:hover
{
    border-color: #704acf !important;
    background-color: #5e34c6 !important;
}
.btn-primary.btn-lighten-2:focus,
.btn-primary.btn-lighten-2:active
{
    border-color: #5e34c6 !important;
    background-color: #522eae !important;
}

.btn-outline-primary.btn-outline-lighten-2
{
    color: #ac92ec !important; 
    border-color: #ac92ec !important;
}
.btn-outline-primary.btn-outline-lighten-2:hover
{
    background-color: #ac92ec !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ac92ec !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ac92ec !important;
}

.border-primary.border-lighten-2
{
    border: 1px solid #ac92ec !important;
}

.border-top-primary.border-top-lighten-2
{
    border-top: 1px solid #ac92ec !important;
}

.border-bottom-primary.border-bottom-lighten-2
{
    border-bottom: 1px solid #ac92ec !important;
}

.border-left-primary.border-left-lighten-2
{
    border-left: 1px solid #ac92ec !important;
}

.border-right-primary.border-right-lighten-2
{
    border-right: 1px solid #ac92ec !important;
}

.overlay-primary.overlay-lighten-2
{
    background: #ac92ec;
    /* The Fallback */
    background: rgba(172, 146, 236, .8);
}

.primary.lighten-1
{
    color: #a992e2 !important;
}

.bg-primary.bg-lighten-1
{
    background-color: #a992e2 !important;
}
.bg-primary.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(169, 146, 226, .6);
            box-shadow: 0 1px 20px 1px rgba(169, 146, 226, .6);
}

.btn-primary.btn-lighten-1
{
    border-color: #704acf !important;
    background-color: #a992e2 !important;
}
.btn-primary.btn-lighten-1:hover
{
    border-color: #704acf !important;
    background-color: #5e34c6 !important;
}
.btn-primary.btn-lighten-1:focus,
.btn-primary.btn-lighten-1:active
{
    border-color: #5e34c6 !important;
    background-color: #522eae !important;
}

.btn-outline-primary.btn-outline-lighten-1
{
    color: #a992e2 !important; 
    border-color: #a992e2 !important;
}
.btn-outline-primary.btn-outline-lighten-1:hover
{
    background-color: #a992e2 !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #a992e2 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #a992e2 !important;
}

.border-primary.border-lighten-1
{
    border: 1px solid #a992e2 !important;
}

.border-top-primary.border-top-lighten-1
{
    border-top: 1px solid #a992e2 !important;
}

.border-bottom-primary.border-bottom-lighten-1
{
    border-bottom: 1px solid #a992e2 !important;
}

.border-left-primary.border-left-lighten-1
{
    border-left: 1px solid #a992e2 !important;
}

.border-right-primary.border-right-lighten-1
{
    border-right: 1px solid #a992e2 !important;
}

.overlay-primary.overlay-lighten-1
{
    background: #a992e2;
    /* The Fallback */
    background: rgba(169, 146, 226, .8);
}

.primary
{
    color: #967adc !important;
}

.bg-primary
{
    background-color: #967adc !important;
}
.bg-primary .card-header,
.bg-primary .card-footer
{
    background-color: transparent;
}
.bg-primary.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(150, 122, 220, .6);
            box-shadow: 0 1px 20px 1px rgba(150, 122, 220, .6);
}

.selectboxit.selectboxit-btn.bg-primary
{
    background-color: #967adc !important;
}

.alert-primary
{
    color: #3d2281 !important; 
    border-color: #967adc !important;
    background-color: #c3b3eb !important;
}
.alert-primary .alert-link
{
    color: #2e1a61 !important;
}

.border-primary
{
    border-color: #967adc;
}

.overlay-primary
{
    background: #967adc;
    /* The Fallback */
    background: rgba(150, 122, 220, .8);
}

/* .card-outline-primary{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-primary
{
    color: #fff; 
    border-color: #967adc !important;
    background-color: #967adc !important;
}
.btn-primary:hover
{
    color: #fff !important; 
    border-color: #704acf !important;
    background-color: #8362d6 !important;
}
.btn-primary:focus,
.btn-primary:active
{
    color: #fff !important; 
    border-color: #5e34c6 !important;
    background-color: #704acf !important;
}
.btn-primary.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(150, 122, 220, .6);
            box-shadow: 0 1px 20px 1px rgba(150, 122, 220, .6);
}

.btn-outline-primary
{
    color: #967adc; 
    border-color: #967adc;
    background-color: transparent;
}
.btn-outline-primary:hover
{
    color: #fff !important; 
    background-color: #967adc;
}
.btn-outline-primary.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(150, 122, 220, .6);
            box-shadow: 0 1px 20px 1px rgba(150, 122, 220, .6);
}

input[type='checkbox'].bg-primary + .custom-control-label:before,
input[type='radio'].bg-primary + .custom-control-label:before
{
    background-color: #967adc !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #967adc !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #967adc !important;
}

.border-primary
{
    border: 1px solid #967adc !important;
}

.border-top-primary
{
    border-top: 1px solid #967adc;
}

.border-bottom-primary
{
    border-bottom: 1px solid #967adc;
}

.border-left-primary
{
    border-left: 1px solid #967adc;
}

.border-right-primary
{
    border-right: 1px solid #967adc;
}

.primary.darken-1
{
    color: #8362d6 !important;
}

.bg-primary.bg-darken-1
{
    background-color: #8362d6 !important;
}
.bg-primary.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(131, 98, 214, .6);
            box-shadow: 0 1px 20px 1px rgba(131, 98, 214, .6);
}

.btn-primary.btn-darken-1
{
    border-color: #704acf !important;
    background-color: #8362d6 !important;
}
.btn-primary.btn-darken-1:hover
{
    border-color: #704acf !important;
    background-color: #5e34c6 !important;
}
.btn-primary.btn-darken-1:focus,
.btn-primary.btn-darken-1:active
{
    border-color: #5e34c6 !important;
    background-color: #522eae !important;
}

.btn-outline-primary.btn-outline-darken-1
{
    color: #8362d6 !important; 
    border-color: #8362d6 !important;
}
.btn-outline-primary.btn-outline-darken-1:hover
{
    background-color: #8362d6 !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #8362d6 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #8362d6 !important;
}

.border-primary.border-darken-1
{
    border: 1px solid #8362d6 !important;
}

.border-top-primary.border-top-darken-1
{
    border-top: 1px solid #8362d6 !important;
}

.border-bottom-primary.border-bottom-darken-1
{
    border-bottom: 1px solid #8362d6 !important;
}

.border-left-primary.border-left-darken-1
{
    border-left: 1px solid #8362d6 !important;
}

.border-right-primary.border-right-darken-1
{
    border-right: 1px solid #8362d6 !important;
}

.overlay-primary.overlay-darken-1
{
    background: #8362d6;
    /* The Fallback */
    background: rgba(131, 98, 214, .8);
}

.primary.darken-2
{
    color: #704acf !important;
}

.bg-primary.bg-darken-2
{
    background-color: #704acf !important;
}
.bg-primary.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(112, 74, 207, .6);
            box-shadow: 0 1px 20px 1px rgba(112, 74, 207, .6);
}

.btn-primary.btn-darken-2
{
    border-color: #704acf !important;
    background-color: #704acf !important;
}
.btn-primary.btn-darken-2:hover
{
    border-color: #704acf !important;
    background-color: #5e34c6 !important;
}
.btn-primary.btn-darken-2:focus,
.btn-primary.btn-darken-2:active
{
    border-color: #5e34c6 !important;
    background-color: #522eae !important;
}

.btn-outline-primary.btn-outline-darken-2
{
    color: #704acf !important; 
    border-color: #704acf !important;
}
.btn-outline-primary.btn-outline-darken-2:hover
{
    background-color: #704acf !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #704acf !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #704acf !important;
}

.border-primary.border-darken-2
{
    border: 1px solid #704acf !important;
}

.border-top-primary.border-top-darken-2
{
    border-top: 1px solid #704acf !important;
}

.border-bottom-primary.border-bottom-darken-2
{
    border-bottom: 1px solid #704acf !important;
}

.border-left-primary.border-left-darken-2
{
    border-left: 1px solid #704acf !important;
}

.border-right-primary.border-right-darken-2
{
    border-right: 1px solid #704acf !important;
}

.overlay-primary.overlay-darken-2
{
    background: #704acf;
    /* The Fallback */
    background: rgba(112, 74, 207, .8);
}

.primary.darken-3
{
    color: #5e34c6 !important;
}

.bg-primary.bg-darken-3
{
    background-color: #5e34c6 !important;
}
.bg-primary.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(94, 52, 198, .6);
            box-shadow: 0 1px 20px 1px rgba(94, 52, 198, .6);
}

.btn-primary.btn-darken-3
{
    border-color: #704acf !important;
    background-color: #5e34c6 !important;
}
.btn-primary.btn-darken-3:hover
{
    border-color: #704acf !important;
    background-color: #5e34c6 !important;
}
.btn-primary.btn-darken-3:focus,
.btn-primary.btn-darken-3:active
{
    border-color: #5e34c6 !important;
    background-color: #522eae !important;
}

.btn-outline-primary.btn-outline-darken-3
{
    color: #5e34c6 !important; 
    border-color: #5e34c6 !important;
}
.btn-outline-primary.btn-outline-darken-3:hover
{
    background-color: #5e34c6 !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #5e34c6 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #5e34c6 !important;
}

.border-primary.border-darken-3
{
    border: 1px solid #5e34c6 !important;
}

.border-top-primary.border-top-darken-3
{
    border-top: 1px solid #5e34c6 !important;
}

.border-bottom-primary.border-bottom-darken-3
{
    border-bottom: 1px solid #5e34c6 !important;
}

.border-left-primary.border-left-darken-3
{
    border-left: 1px solid #5e34c6 !important;
}

.border-right-primary.border-right-darken-3
{
    border-right: 1px solid #5e34c6 !important;
}

.overlay-primary.overlay-darken-3
{
    background: #5e34c6;
    /* The Fallback */
    background: rgba(94, 52, 198, .8);
}

.primary.darken-4
{
    color: #522eae !important;
}

.bg-primary.bg-darken-4
{
    background-color: #522eae !important;
}
.bg-primary.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(82, 46, 174, .6);
            box-shadow: 0 1px 20px 1px rgba(82, 46, 174, .6);
}

.btn-primary.btn-darken-4
{
    border-color: #704acf !important;
    background-color: #522eae !important;
}
.btn-primary.btn-darken-4:hover
{
    border-color: #704acf !important;
    background-color: #5e34c6 !important;
}
.btn-primary.btn-darken-4:focus,
.btn-primary.btn-darken-4:active
{
    border-color: #5e34c6 !important;
    background-color: #522eae !important;
}

.btn-outline-primary.btn-outline-darken-4
{
    color: #522eae !important; 
    border-color: #522eae !important;
}
.btn-outline-primary.btn-outline-darken-4:hover
{
    background-color: #522eae !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #522eae !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #522eae !important;
}

.border-primary.border-darken-4
{
    border: 1px solid #522eae !important;
}

.border-top-primary.border-top-darken-4
{
    border-top: 1px solid #522eae !important;
}

.border-bottom-primary.border-bottom-darken-4
{
    border-bottom: 1px solid #522eae !important;
}

.border-left-primary.border-left-darken-4
{
    border-left: 1px solid #522eae !important;
}

.border-right-primary.border-right-darken-4
{
    border-right: 1px solid #522eae !important;
}

.overlay-primary.overlay-darken-4
{
    background: #522eae;
    /* The Fallback */
    background: rgba(82, 46, 174, .8);
}

.primary.accent-1
{
    color: #9e77fd !important;
}

.bg-primary.bg-accent-1
{
    background-color: #9e77fd !important;
}
.bg-primary.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(158, 119, 253, .6);
            box-shadow: 0 1px 20px 1px rgba(158, 119, 253, .6);
}

.btn-primary.btn-accent-1
{
    border-color: #704acf !important;
    background-color: #9e77fd !important;
}
.btn-primary.btn-accent-1:hover
{
    border-color: #704acf !important;
    background-color: #5e34c6 !important;
}
.btn-primary.btn-accent-1:focus,
.btn-primary.btn-accent-1:active
{
    border-color: #5e34c6 !important;
    background-color: #522eae !important;
}

.btn-outline-primary.btn-outline-accent-1
{
    color: #9e77fd !important; 
    border-color: #9e77fd !important;
}
.btn-outline-primary.btn-outline-accent-1:hover
{
    background-color: #9e77fd !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #9e77fd !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #9e77fd !important;
}

.border-primary.border-accent-1
{
    border: 1px solid #9e77fd !important;
}

.border-top-primary.border-top-accent-1
{
    border-top: 1px solid #9e77fd !important;
}

.border-bottom-primary.border-bottom-accent-1
{
    border-bottom: 1px solid #9e77fd !important;
}

.border-left-primary.border-left-accent-1
{
    border-left: 1px solid #9e77fd !important;
}

.border-right-primary.border-right-accent-1
{
    border-right: 1px solid #9e77fd !important;
}

.overlay-primary.overlay-accent-1
{
    background: #9e77fd;
    /* The Fallback */
    background: rgba(158, 119, 253, .8);
}

.primary.accent-2
{
    color: #9067f5 !important;
}

.bg-primary.bg-accent-2
{
    background-color: #9067f5 !important;
}
.bg-primary.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(144, 103, 245, .6);
            box-shadow: 0 1px 20px 1px rgba(144, 103, 245, .6);
}

.btn-primary.btn-accent-2
{
    border-color: #704acf !important;
    background-color: #9067f5 !important;
}
.btn-primary.btn-accent-2:hover
{
    border-color: #704acf !important;
    background-color: #5e34c6 !important;
}
.btn-primary.btn-accent-2:focus,
.btn-primary.btn-accent-2:active
{
    border-color: #5e34c6 !important;
    background-color: #522eae !important;
}

.btn-outline-primary.btn-outline-accent-2
{
    color: #9067f5 !important; 
    border-color: #9067f5 !important;
}
.btn-outline-primary.btn-outline-accent-2:hover
{
    background-color: #9067f5 !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #9067f5 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #9067f5 !important;
}

.border-primary.border-accent-2
{
    border: 1px solid #9067f5 !important;
}

.border-top-primary.border-top-accent-2
{
    border-top: 1px solid #9067f5 !important;
}

.border-bottom-primary.border-bottom-accent-2
{
    border-bottom: 1px solid #9067f5 !important;
}

.border-left-primary.border-left-accent-2
{
    border-left: 1px solid #9067f5 !important;
}

.border-right-primary.border-right-accent-2
{
    border-right: 1px solid #9067f5 !important;
}

.overlay-primary.overlay-accent-2
{
    background: #9067f5;
    /* The Fallback */
    background: rgba(144, 103, 245, .8);
}

.primary.accent-3
{
    color: #845de2 !important;
}

.bg-primary.bg-accent-3
{
    background-color: #845de2 !important;
}
.bg-primary.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(132, 93, 226, .6);
            box-shadow: 0 1px 20px 1px rgba(132, 93, 226, .6);
}

.btn-primary.btn-accent-3
{
    border-color: #704acf !important;
    background-color: #845de2 !important;
}
.btn-primary.btn-accent-3:hover
{
    border-color: #704acf !important;
    background-color: #5e34c6 !important;
}
.btn-primary.btn-accent-3:focus,
.btn-primary.btn-accent-3:active
{
    border-color: #5e34c6 !important;
    background-color: #522eae !important;
}

.btn-outline-primary.btn-outline-accent-3
{
    color: #845de2 !important; 
    border-color: #845de2 !important;
}
.btn-outline-primary.btn-outline-accent-3:hover
{
    background-color: #845de2 !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #845de2 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #845de2 !important;
}

.border-primary.border-accent-3
{
    border: 1px solid #845de2 !important;
}

.border-top-primary.border-top-accent-3
{
    border-top: 1px solid #845de2 !important;
}

.border-bottom-primary.border-bottom-accent-3
{
    border-bottom: 1px solid #845de2 !important;
}

.border-left-primary.border-left-accent-3
{
    border-left: 1px solid #845de2 !important;
}

.border-right-primary.border-right-accent-3
{
    border-right: 1px solid #845de2 !important;
}

.overlay-primary.overlay-accent-3
{
    background: #845de2;
    /* The Fallback */
    background: rgba(132, 93, 226, .8);
}

.primary.accent-4
{
    color: #7452c7 !important;
}

.bg-primary.bg-accent-4
{
    background-color: #7452c7 !important;
}
.bg-primary.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(116, 82, 199, .6);
            box-shadow: 0 1px 20px 1px rgba(116, 82, 199, .6);
}

.btn-primary.btn-accent-4
{
    border-color: #704acf !important;
    background-color: #7452c7 !important;
}
.btn-primary.btn-accent-4:hover
{
    border-color: #704acf !important;
    background-color: #5e34c6 !important;
}
.btn-primary.btn-accent-4:focus,
.btn-primary.btn-accent-4:active
{
    border-color: #5e34c6 !important;
    background-color: #522eae !important;
}

.btn-outline-primary.btn-outline-accent-4
{
    color: #7452c7 !important; 
    border-color: #7452c7 !important;
}
.btn-outline-primary.btn-outline-accent-4:hover
{
    background-color: #7452c7 !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #7452c7 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #7452c7 !important;
}

.border-primary.border-accent-4
{
    border: 1px solid #7452c7 !important;
}

.border-top-primary.border-top-accent-4
{
    border-top: 1px solid #7452c7 !important;
}

.border-bottom-primary.border-bottom-accent-4
{
    border-bottom: 1px solid #7452c7 !important;
}

.border-left-primary.border-left-accent-4
{
    border-left: 1px solid #7452c7 !important;
}

.border-right-primary.border-right-accent-4
{
    border-right: 1px solid #7452c7 !important;
}

.overlay-primary.overlay-accent-4
{
    background: #7452c7;
    /* The Fallback */
    background: rgba(116, 82, 199, .8);
}

.success.lighten-5
{
    color: #c2ede3 !important;
}

.bg-success.bg-lighten-5
{
    background-color: #c2ede3 !important;
}
.bg-success.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(194, 237, 227, .6);
            box-shadow: 0 1px 20px 1px rgba(194, 237, 227, .6);
}

.btn-success.btn-lighten-5
{
    border-color: #298d74 !important;
    background-color: #c2ede3 !important;
}
.btn-success.btn-lighten-5:hover
{
    border-color: #298d74 !important;
    background-color: #227560 !important;
}
.btn-success.btn-lighten-5:focus,
.btn-success.btn-lighten-5:active
{
    border-color: #227560 !important;
    background-color: #1b5d4d !important;
}

.btn-outline-success.btn-outline-lighten-5
{
    color: #c2ede3 !important; 
    border-color: #c2ede3 !important;
}
.btn-outline-success.btn-outline-lighten-5:hover
{
    background-color: #c2ede3 !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c2ede3 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c2ede3 !important;
}

.border-success.border-lighten-5
{
    border: 1px solid #c2ede3 !important;
}

.border-top-success.border-top-lighten-5
{
    border-top: 1px solid #c2ede3 !important;
}

.border-bottom-success.border-bottom-lighten-5
{
    border-bottom: 1px solid #c2ede3 !important;
}

.border-left-success.border-left-lighten-5
{
    border-left: 1px solid #c2ede3 !important;
}

.border-right-success.border-right-lighten-5
{
    border-right: 1px solid #c2ede3 !important;
}

.overlay-success.overlay-lighten-5
{
    background: #c2ede3;
    /* The Fallback */
    background: rgba(194, 237, 227, .8);
}

.success.lighten-4
{
    color: #97e1ce !important;
}

.bg-success.bg-lighten-4
{
    background-color: #97e1ce !important;
}
.bg-success.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(151, 225, 206, .6);
            box-shadow: 0 1px 20px 1px rgba(151, 225, 206, .6);
}

.btn-success.btn-lighten-4
{
    border-color: #298d74 !important;
    background-color: #97e1ce !important;
}
.btn-success.btn-lighten-4:hover
{
    border-color: #298d74 !important;
    background-color: #227560 !important;
}
.btn-success.btn-lighten-4:focus,
.btn-success.btn-lighten-4:active
{
    border-color: #227560 !important;
    background-color: #1b5d4d !important;
}

.btn-outline-success.btn-outline-lighten-4
{
    color: #97e1ce !important; 
    border-color: #97e1ce !important;
}
.btn-outline-success.btn-outline-lighten-4:hover
{
    background-color: #97e1ce !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #97e1ce !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #97e1ce !important;
}

.border-success.border-lighten-4
{
    border: 1px solid #97e1ce !important;
}

.border-top-success.border-top-lighten-4
{
    border-top: 1px solid #97e1ce !important;
}

.border-bottom-success.border-bottom-lighten-4
{
    border-bottom: 1px solid #97e1ce !important;
}

.border-left-success.border-left-lighten-4
{
    border-left: 1px solid #97e1ce !important;
}

.border-right-success.border-right-lighten-4
{
    border-right: 1px solid #97e1ce !important;
}

.overlay-success.overlay-lighten-4
{
    background: #97e1ce;
    /* The Fallback */
    background: rgba(151, 225, 206, .8);
}

.success.lighten-3
{
    color: #60d0b4 !important;
}

.bg-success.bg-lighten-3
{
    background-color: #60d0b4 !important;
}
.bg-success.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(96, 208, 180, .6);
            box-shadow: 0 1px 20px 1px rgba(96, 208, 180, .6);
}

.btn-success.btn-lighten-3
{
    border-color: #298d74 !important;
    background-color: #60d0b4 !important;
}
.btn-success.btn-lighten-3:hover
{
    border-color: #298d74 !important;
    background-color: #227560 !important;
}
.btn-success.btn-lighten-3:focus,
.btn-success.btn-lighten-3:active
{
    border-color: #227560 !important;
    background-color: #1b5d4d !important;
}

.btn-outline-success.btn-outline-lighten-3
{
    color: #60d0b4 !important; 
    border-color: #60d0b4 !important;
}
.btn-outline-success.btn-outline-lighten-3:hover
{
    background-color: #60d0b4 !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #60d0b4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #60d0b4 !important;
}

.border-success.border-lighten-3
{
    border: 1px solid #60d0b4 !important;
}

.border-top-success.border-top-lighten-3
{
    border-top: 1px solid #60d0b4 !important;
}

.border-bottom-success.border-bottom-lighten-3
{
    border-bottom: 1px solid #60d0b4 !important;
}

.border-left-success.border-left-lighten-3
{
    border-left: 1px solid #60d0b4 !important;
}

.border-right-success.border-right-lighten-3
{
    border-right: 1px solid #60d0b4 !important;
}

.overlay-success.overlay-lighten-3
{
    background: #60d0b4;
    /* The Fallback */
    background: rgba(96, 208, 180, .8);
}

.success.lighten-2
{
    color: #48cfad !important;
}

.bg-success.bg-lighten-2
{
    background-color: #48cfad !important;
}
.bg-success.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(72, 207, 173, .6);
            box-shadow: 0 1px 20px 1px rgba(72, 207, 173, .6);
}

.btn-success.btn-lighten-2
{
    border-color: #298d74 !important;
    background-color: #48cfad !important;
}
.btn-success.btn-lighten-2:hover
{
    border-color: #298d74 !important;
    background-color: #227560 !important;
}
.btn-success.btn-lighten-2:focus,
.btn-success.btn-lighten-2:active
{
    border-color: #227560 !important;
    background-color: #1b5d4d !important;
}

.btn-outline-success.btn-outline-lighten-2
{
    color: #48cfad !important; 
    border-color: #48cfad !important;
}
.btn-outline-success.btn-outline-lighten-2:hover
{
    background-color: #48cfad !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #48cfad !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #48cfad !important;
}

.border-success.border-lighten-2
{
    border: 1px solid #48cfad !important;
}

.border-top-success.border-top-lighten-2
{
    border-top: 1px solid #48cfad !important;
}

.border-bottom-success.border-bottom-lighten-2
{
    border-bottom: 1px solid #48cfad !important;
}

.border-left-success.border-left-lighten-2
{
    border-left: 1px solid #48cfad !important;
}

.border-right-success.border-right-lighten-2
{
    border-right: 1px solid #48cfad !important;
}

.overlay-success.overlay-lighten-2
{
    background: #48cfad;
    /* The Fallback */
    background: rgba(72, 207, 173, .8);
}

.success.lighten-1
{
    color: #48c9a9 !important;
}

.bg-success.bg-lighten-1
{
    background-color: #48c9a9 !important;
}
.bg-success.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(72, 201, 169, .6);
            box-shadow: 0 1px 20px 1px rgba(72, 201, 169, .6);
}

.btn-success.btn-lighten-1
{
    border-color: #298d74 !important;
    background-color: #48c9a9 !important;
}
.btn-success.btn-lighten-1:hover
{
    border-color: #298d74 !important;
    background-color: #227560 !important;
}
.btn-success.btn-lighten-1:focus,
.btn-success.btn-lighten-1:active
{
    border-color: #227560 !important;
    background-color: #1b5d4d !important;
}

.btn-outline-success.btn-outline-lighten-1
{
    color: #48c9a9 !important; 
    border-color: #48c9a9 !important;
}
.btn-outline-success.btn-outline-lighten-1:hover
{
    background-color: #48c9a9 !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #48c9a9 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #48c9a9 !important;
}

.border-success.border-lighten-1
{
    border: 1px solid #48c9a9 !important;
}

.border-top-success.border-top-lighten-1
{
    border-top: 1px solid #48c9a9 !important;
}

.border-bottom-success.border-bottom-lighten-1
{
    border-bottom: 1px solid #48c9a9 !important;
}

.border-left-success.border-left-lighten-1
{
    border-left: 1px solid #48c9a9 !important;
}

.border-right-success.border-right-lighten-1
{
    border-right: 1px solid #48c9a9 !important;
}

.overlay-success.overlay-lighten-1
{
    background: #48c9a9;
    /* The Fallback */
    background: rgba(72, 201, 169, .8);
}

.success
{
    color: #37bc9b !important;
}

.bg-success
{
    background-color: #37bc9b !important;
}
.bg-success .card-header,
.bg-success .card-footer
{
    background-color: transparent;
}
.bg-success.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(55, 188, 155, .6);
            box-shadow: 0 1px 20px 1px rgba(55, 188, 155, .6);
}

.selectboxit.selectboxit-btn.bg-success
{
    background-color: #37bc9b !important;
}

.alert-success
{
    color: #0f3229 !important; 
    border-color: #37bc9b !important;
    background-color: #68d3b8 !important;
}
.alert-success .alert-link
{
    color: #05120f !important;
}

.border-success
{
    border-color: #37bc9b;
}

.overlay-success
{
    background: #37bc9b;
    /* The Fallback */
    background: rgba(55, 188, 155, .8);
}

/* .card-outline-success{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-success
{
    color: #fff; 
    border-color: #37bc9b !important;
    background-color: #37bc9b !important;
}
.btn-success:hover
{
    color: #fff !important; 
    border-color: #298d74 !important;
    background-color: #30a487 !important;
}
.btn-success:focus,
.btn-success:active
{
    color: #fff !important; 
    border-color: #227560 !important;
    background-color: #298d74 !important;
}
.btn-success.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(55, 188, 155, .6);
            box-shadow: 0 1px 20px 1px rgba(55, 188, 155, .6);
}

.btn-outline-success
{
    color: #37bc9b; 
    border-color: #37bc9b;
    background-color: transparent;
}
.btn-outline-success:hover
{
    color: #fff !important; 
    background-color: #37bc9b;
}
.btn-outline-success.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(55, 188, 155, .6);
            box-shadow: 0 1px 20px 1px rgba(55, 188, 155, .6);
}

input[type='checkbox'].bg-success + .custom-control-label:before,
input[type='radio'].bg-success + .custom-control-label:before
{
    background-color: #37bc9b !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #37bc9b !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #37bc9b !important;
}

.border-success
{
    border: 1px solid #37bc9b !important;
}

.border-top-success
{
    border-top: 1px solid #37bc9b;
}

.border-bottom-success
{
    border-bottom: 1px solid #37bc9b;
}

.border-left-success
{
    border-left: 1px solid #37bc9b;
}

.border-right-success
{
    border-right: 1px solid #37bc9b;
}

.success.darken-1
{
    color: #30a487 !important;
}

.bg-success.bg-darken-1
{
    background-color: #30a487 !important;
}
.bg-success.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(48, 164, 135, .6);
            box-shadow: 0 1px 20px 1px rgba(48, 164, 135, .6);
}

.btn-success.btn-darken-1
{
    border-color: #298d74 !important;
    background-color: #30a487 !important;
}
.btn-success.btn-darken-1:hover
{
    border-color: #298d74 !important;
    background-color: #227560 !important;
}
.btn-success.btn-darken-1:focus,
.btn-success.btn-darken-1:active
{
    border-color: #227560 !important;
    background-color: #1b5d4d !important;
}

.btn-outline-success.btn-outline-darken-1
{
    color: #30a487 !important; 
    border-color: #30a487 !important;
}
.btn-outline-success.btn-outline-darken-1:hover
{
    background-color: #30a487 !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #30a487 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #30a487 !important;
}

.border-success.border-darken-1
{
    border: 1px solid #30a487 !important;
}

.border-top-success.border-top-darken-1
{
    border-top: 1px solid #30a487 !important;
}

.border-bottom-success.border-bottom-darken-1
{
    border-bottom: 1px solid #30a487 !important;
}

.border-left-success.border-left-darken-1
{
    border-left: 1px solid #30a487 !important;
}

.border-right-success.border-right-darken-1
{
    border-right: 1px solid #30a487 !important;
}

.overlay-success.overlay-darken-1
{
    background: #30a487;
    /* The Fallback */
    background: rgba(48, 164, 135, .8);
}

.success.darken-2
{
    color: #298d74 !important;
}

.bg-success.bg-darken-2
{
    background-color: #298d74 !important;
}
.bg-success.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(41, 141, 116, .6);
            box-shadow: 0 1px 20px 1px rgba(41, 141, 116, .6);
}

.btn-success.btn-darken-2
{
    border-color: #298d74 !important;
    background-color: #298d74 !important;
}
.btn-success.btn-darken-2:hover
{
    border-color: #298d74 !important;
    background-color: #227560 !important;
}
.btn-success.btn-darken-2:focus,
.btn-success.btn-darken-2:active
{
    border-color: #227560 !important;
    background-color: #1b5d4d !important;
}

.btn-outline-success.btn-outline-darken-2
{
    color: #298d74 !important; 
    border-color: #298d74 !important;
}
.btn-outline-success.btn-outline-darken-2:hover
{
    background-color: #298d74 !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #298d74 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #298d74 !important;
}

.border-success.border-darken-2
{
    border: 1px solid #298d74 !important;
}

.border-top-success.border-top-darken-2
{
    border-top: 1px solid #298d74 !important;
}

.border-bottom-success.border-bottom-darken-2
{
    border-bottom: 1px solid #298d74 !important;
}

.border-left-success.border-left-darken-2
{
    border-left: 1px solid #298d74 !important;
}

.border-right-success.border-right-darken-2
{
    border-right: 1px solid #298d74 !important;
}

.overlay-success.overlay-darken-2
{
    background: #298d74;
    /* The Fallback */
    background: rgba(41, 141, 116, .8);
}

.success.darken-3
{
    color: #227560 !important;
}

.bg-success.bg-darken-3
{
    background-color: #227560 !important;
}
.bg-success.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(34, 117, 96, .6);
            box-shadow: 0 1px 20px 1px rgba(34, 117, 96, .6);
}

.btn-success.btn-darken-3
{
    border-color: #298d74 !important;
    background-color: #227560 !important;
}
.btn-success.btn-darken-3:hover
{
    border-color: #298d74 !important;
    background-color: #227560 !important;
}
.btn-success.btn-darken-3:focus,
.btn-success.btn-darken-3:active
{
    border-color: #227560 !important;
    background-color: #1b5d4d !important;
}

.btn-outline-success.btn-outline-darken-3
{
    color: #227560 !important; 
    border-color: #227560 !important;
}
.btn-outline-success.btn-outline-darken-3:hover
{
    background-color: #227560 !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #227560 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #227560 !important;
}

.border-success.border-darken-3
{
    border: 1px solid #227560 !important;
}

.border-top-success.border-top-darken-3
{
    border-top: 1px solid #227560 !important;
}

.border-bottom-success.border-bottom-darken-3
{
    border-bottom: 1px solid #227560 !important;
}

.border-left-success.border-left-darken-3
{
    border-left: 1px solid #227560 !important;
}

.border-right-success.border-right-darken-3
{
    border-right: 1px solid #227560 !important;
}

.overlay-success.overlay-darken-3
{
    background: #227560;
    /* The Fallback */
    background: rgba(34, 117, 96, .8);
}

.success.darken-4
{
    color: #1b5d4d !important;
}

.bg-success.bg-darken-4
{
    background-color: #1b5d4d !important;
}
.bg-success.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(27, 93, 77, .6);
            box-shadow: 0 1px 20px 1px rgba(27, 93, 77, .6);
}

.btn-success.btn-darken-4
{
    border-color: #298d74 !important;
    background-color: #1b5d4d !important;
}
.btn-success.btn-darken-4:hover
{
    border-color: #298d74 !important;
    background-color: #227560 !important;
}
.btn-success.btn-darken-4:focus,
.btn-success.btn-darken-4:active
{
    border-color: #227560 !important;
    background-color: #1b5d4d !important;
}

.btn-outline-success.btn-outline-darken-4
{
    color: #1b5d4d !important; 
    border-color: #1b5d4d !important;
}
.btn-outline-success.btn-outline-darken-4:hover
{
    background-color: #1b5d4d !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1b5d4d !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1b5d4d !important;
}

.border-success.border-darken-4
{
    border: 1px solid #1b5d4d !important;
}

.border-top-success.border-top-darken-4
{
    border-top: 1px solid #1b5d4d !important;
}

.border-bottom-success.border-bottom-darken-4
{
    border-bottom: 1px solid #1b5d4d !important;
}

.border-left-success.border-left-darken-4
{
    border-left: 1px solid #1b5d4d !important;
}

.border-right-success.border-right-darken-4
{
    border-right: 1px solid #1b5d4d !important;
}

.overlay-success.overlay-darken-4
{
    background: #1b5d4d;
    /* The Fallback */
    background: rgba(27, 93, 77, .8);
}

.success.accent-1
{
    color: #d8f7f0 !important;
}

.bg-success.bg-accent-1
{
    background-color: #d8f7f0 !important;
}
.bg-success.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(216, 247, 240, .6);
            box-shadow: 0 1px 20px 1px rgba(216, 247, 240, .6);
}

.btn-success.btn-accent-1
{
    border-color: #298d74 !important;
    background-color: #d8f7f0 !important;
}
.btn-success.btn-accent-1:hover
{
    border-color: #298d74 !important;
    background-color: #227560 !important;
}
.btn-success.btn-accent-1:focus,
.btn-success.btn-accent-1:active
{
    border-color: #227560 !important;
    background-color: #1b5d4d !important;
}

.btn-outline-success.btn-outline-accent-1
{
    color: #d8f7f0 !important; 
    border-color: #d8f7f0 !important;
}
.btn-outline-success.btn-outline-accent-1:hover
{
    background-color: #d8f7f0 !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d8f7f0 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d8f7f0 !important;
}

.border-success.border-accent-1
{
    border: 1px solid #d8f7f0 !important;
}

.border-top-success.border-top-accent-1
{
    border-top: 1px solid #d8f7f0 !important;
}

.border-bottom-success.border-bottom-accent-1
{
    border-bottom: 1px solid #d8f7f0 !important;
}

.border-left-success.border-left-accent-1
{
    border-left: 1px solid #d8f7f0 !important;
}

.border-right-success.border-right-accent-1
{
    border-right: 1px solid #d8f7f0 !important;
}

.overlay-success.overlay-accent-1
{
    background: #d8f7f0;
    /* The Fallback */
    background: rgba(216, 247, 240, .8);
}

.success.accent-2
{
    color: #c2ede3 !important;
}

.bg-success.bg-accent-2
{
    background-color: #c2ede3 !important;
}
.bg-success.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(194, 237, 227, .6);
            box-shadow: 0 1px 20px 1px rgba(194, 237, 227, .6);
}

.btn-success.btn-accent-2
{
    border-color: #298d74 !important;
    background-color: #c2ede3 !important;
}
.btn-success.btn-accent-2:hover
{
    border-color: #298d74 !important;
    background-color: #227560 !important;
}
.btn-success.btn-accent-2:focus,
.btn-success.btn-accent-2:active
{
    border-color: #227560 !important;
    background-color: #1b5d4d !important;
}

.btn-outline-success.btn-outline-accent-2
{
    color: #c2ede3 !important; 
    border-color: #c2ede3 !important;
}
.btn-outline-success.btn-outline-accent-2:hover
{
    background-color: #c2ede3 !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c2ede3 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c2ede3 !important;
}

.border-success.border-accent-2
{
    border: 1px solid #c2ede3 !important;
}

.border-top-success.border-top-accent-2
{
    border-top: 1px solid #c2ede3 !important;
}

.border-bottom-success.border-bottom-accent-2
{
    border-bottom: 1px solid #c2ede3 !important;
}

.border-left-success.border-left-accent-2
{
    border-left: 1px solid #c2ede3 !important;
}

.border-right-success.border-right-accent-2
{
    border-right: 1px solid #c2ede3 !important;
}

.overlay-success.overlay-accent-2
{
    background: #c2ede3;
    /* The Fallback */
    background: rgba(194, 237, 227, .8);
}

.success.accent-3
{
    color: #3ec3a2 !important;
}

.bg-success.bg-accent-3
{
    background-color: #3ec3a2 !important;
}
.bg-success.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(62, 195, 162, .6);
            box-shadow: 0 1px 20px 1px rgba(62, 195, 162, .6);
}

.btn-success.btn-accent-3
{
    border-color: #298d74 !important;
    background-color: #3ec3a2 !important;
}
.btn-success.btn-accent-3:hover
{
    border-color: #298d74 !important;
    background-color: #227560 !important;
}
.btn-success.btn-accent-3:focus,
.btn-success.btn-accent-3:active
{
    border-color: #227560 !important;
    background-color: #1b5d4d !important;
}

.btn-outline-success.btn-outline-accent-3
{
    color: #3ec3a2 !important; 
    border-color: #3ec3a2 !important;
}
.btn-outline-success.btn-outline-accent-3:hover
{
    background-color: #3ec3a2 !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #3ec3a2 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #3ec3a2 !important;
}

.border-success.border-accent-3
{
    border: 1px solid #3ec3a2 !important;
}

.border-top-success.border-top-accent-3
{
    border-top: 1px solid #3ec3a2 !important;
}

.border-bottom-success.border-bottom-accent-3
{
    border-bottom: 1px solid #3ec3a2 !important;
}

.border-left-success.border-left-accent-3
{
    border-left: 1px solid #3ec3a2 !important;
}

.border-right-success.border-right-accent-3
{
    border-right: 1px solid #3ec3a2 !important;
}

.overlay-success.overlay-accent-3
{
    background: #3ec3a2;
    /* The Fallback */
    background: rgba(62, 195, 162, .8);
}

.success.accent-4
{
    color: #298d74 !important;
}

.bg-success.bg-accent-4
{
    background-color: #298d74 !important;
}
.bg-success.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(41, 141, 116, .6);
            box-shadow: 0 1px 20px 1px rgba(41, 141, 116, .6);
}

.btn-success.btn-accent-4
{
    border-color: #298d74 !important;
    background-color: #298d74 !important;
}
.btn-success.btn-accent-4:hover
{
    border-color: #298d74 !important;
    background-color: #227560 !important;
}
.btn-success.btn-accent-4:focus,
.btn-success.btn-accent-4:active
{
    border-color: #227560 !important;
    background-color: #1b5d4d !important;
}

.btn-outline-success.btn-outline-accent-4
{
    color: #298d74 !important; 
    border-color: #298d74 !important;
}
.btn-outline-success.btn-outline-accent-4:hover
{
    background-color: #298d74 !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #298d74 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #298d74 !important;
}

.border-success.border-accent-4
{
    border: 1px solid #298d74 !important;
}

.border-top-success.border-top-accent-4
{
    border-top: 1px solid #298d74 !important;
}

.border-bottom-success.border-bottom-accent-4
{
    border-bottom: 1px solid #298d74 !important;
}

.border-left-success.border-left-accent-4
{
    border-left: 1px solid #298d74 !important;
}

.border-right-success.border-right-accent-4
{
    border-right: 1px solid #298d74 !important;
}

.overlay-success.overlay-accent-4
{
    background: #298d74;
    /* The Fallback */
    background: rgba(41, 141, 116, .8);
}

.info.lighten-5
{
    color: #daf0f8 !important;
}

.bg-info.bg-lighten-5
{
    background-color: #daf0f8 !important;
}
.bg-info.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(218, 240, 248, .6);
            box-shadow: 0 1px 20px 1px rgba(218, 240, 248, .6);
}

.btn-info.btn-lighten-5
{
    border-color: #228eb6 !important;
    background-color: #daf0f8 !important;
}
.btn-info.btn-lighten-5:hover
{
    border-color: #228eb6 !important;
    background-color: #1d7a9c !important;
}
.btn-info.btn-lighten-5:focus,
.btn-info.btn-lighten-5:active
{
    border-color: #1d7a9c !important;
    background-color: #196682 !important;
}

.btn-outline-info.btn-outline-lighten-5
{
    color: #daf0f8 !important; 
    border-color: #daf0f8 !important;
}
.btn-outline-info.btn-outline-lighten-5:hover
{
    background-color: #daf0f8 !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #daf0f8 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #daf0f8 !important;
}

.border-info.border-lighten-5
{
    border: 1px solid #daf0f8 !important;
}

.border-top-info.border-top-lighten-5
{
    border-top: 1px solid #daf0f8 !important;
}

.border-bottom-info.border-bottom-lighten-5
{
    border-bottom: 1px solid #daf0f8 !important;
}

.border-left-info.border-left-lighten-5
{
    border-left: 1px solid #daf0f8 !important;
}

.border-right-info.border-right-lighten-5
{
    border-right: 1px solid #daf0f8 !important;
}

.overlay-info.overlay-lighten-5
{
    background: #daf0f8;
    /* The Fallback */
    background: rgba(218, 240, 248, .8);
}

.info.lighten-4
{
    color: #abddef !important;
}

.bg-info.bg-lighten-4
{
    background-color: #abddef !important;
}
.bg-info.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(171, 221, 239, .6);
            box-shadow: 0 1px 20px 1px rgba(171, 221, 239, .6);
}

.btn-info.btn-lighten-4
{
    border-color: #228eb6 !important;
    background-color: #abddef !important;
}
.btn-info.btn-lighten-4:hover
{
    border-color: #228eb6 !important;
    background-color: #1d7a9c !important;
}
.btn-info.btn-lighten-4:focus,
.btn-info.btn-lighten-4:active
{
    border-color: #1d7a9c !important;
    background-color: #196682 !important;
}

.btn-outline-info.btn-outline-lighten-4
{
    color: #abddef !important; 
    border-color: #abddef !important;
}
.btn-outline-info.btn-outline-lighten-4:hover
{
    background-color: #abddef !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #abddef !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #abddef !important;
}

.border-info.border-lighten-4
{
    border: 1px solid #abddef !important;
}

.border-top-info.border-top-lighten-4
{
    border-top: 1px solid #abddef !important;
}

.border-bottom-info.border-bottom-lighten-4
{
    border-bottom: 1px solid #abddef !important;
}

.border-left-info.border-left-lighten-4
{
    border-left: 1px solid #abddef !important;
}

.border-right-info.border-right-lighten-4
{
    border-right: 1px solid #abddef !important;
}

.overlay-info.overlay-lighten-4
{
    background: #abddef;
    /* The Fallback */
    background: rgba(171, 221, 239, .8);
}

.info.lighten-3
{
    color: #6ec4e4 !important;
}

.bg-info.bg-lighten-3
{
    background-color: #6ec4e4 !important;
}
.bg-info.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(110, 196, 228, .6);
            box-shadow: 0 1px 20px 1px rgba(110, 196, 228, .6);
}

.btn-info.btn-lighten-3
{
    border-color: #228eb6 !important;
    background-color: #6ec4e4 !important;
}
.btn-info.btn-lighten-3:hover
{
    border-color: #228eb6 !important;
    background-color: #1d7a9c !important;
}
.btn-info.btn-lighten-3:focus,
.btn-info.btn-lighten-3:active
{
    border-color: #1d7a9c !important;
    background-color: #196682 !important;
}

.btn-outline-info.btn-outline-lighten-3
{
    color: #6ec4e4 !important; 
    border-color: #6ec4e4 !important;
}
.btn-outline-info.btn-outline-lighten-3:hover
{
    background-color: #6ec4e4 !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #6ec4e4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #6ec4e4 !important;
}

.border-info.border-lighten-3
{
    border: 1px solid #6ec4e4 !important;
}

.border-top-info.border-top-lighten-3
{
    border-top: 1px solid #6ec4e4 !important;
}

.border-bottom-info.border-bottom-lighten-3
{
    border-bottom: 1px solid #6ec4e4 !important;
}

.border-left-info.border-left-lighten-3
{
    border-left: 1px solid #6ec4e4 !important;
}

.border-right-info.border-right-lighten-3
{
    border-right: 1px solid #6ec4e4 !important;
}

.overlay-info.overlay-lighten-3
{
    background: #6ec4e4;
    /* The Fallback */
    background: rgba(110, 196, 228, .8);
}

.info.lighten-2
{
    color: #4fc1e9 !important;
}

.bg-info.bg-lighten-2
{
    background-color: #4fc1e9 !important;
}
.bg-info.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(79, 193, 233, .6);
            box-shadow: 0 1px 20px 1px rgba(79, 193, 233, .6);
}

.btn-info.btn-lighten-2
{
    border-color: #228eb6 !important;
    background-color: #4fc1e9 !important;
}
.btn-info.btn-lighten-2:hover
{
    border-color: #228eb6 !important;
    background-color: #1d7a9c !important;
}
.btn-info.btn-lighten-2:focus,
.btn-info.btn-lighten-2:active
{
    border-color: #1d7a9c !important;
    background-color: #196682 !important;
}

.btn-outline-info.btn-outline-lighten-2
{
    color: #4fc1e9 !important; 
    border-color: #4fc1e9 !important;
}
.btn-outline-info.btn-outline-lighten-2:hover
{
    background-color: #4fc1e9 !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4fc1e9 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4fc1e9 !important;
}

.border-info.border-lighten-2
{
    border: 1px solid #4fc1e9 !important;
}

.border-top-info.border-top-lighten-2
{
    border-top: 1px solid #4fc1e9 !important;
}

.border-bottom-info.border-bottom-lighten-2
{
    border-bottom: 1px solid #4fc1e9 !important;
}

.border-left-info.border-left-lighten-2
{
    border-left: 1px solid #4fc1e9 !important;
}

.border-right-info.border-right-lighten-2
{
    border-right: 1px solid #4fc1e9 !important;
}

.overlay-info.overlay-lighten-2
{
    background: #4fc1e9;
    /* The Fallback */
    background: rgba(79, 193, 233, .8);
}

.info.lighten-1
{
    color: #55b9df !important;
}

.bg-info.bg-lighten-1
{
    background-color: #55b9df !important;
}
.bg-info.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(85, 185, 223, .6);
            box-shadow: 0 1px 20px 1px rgba(85, 185, 223, .6);
}

.btn-info.btn-lighten-1
{
    border-color: #228eb6 !important;
    background-color: #55b9df !important;
}
.btn-info.btn-lighten-1:hover
{
    border-color: #228eb6 !important;
    background-color: #1d7a9c !important;
}
.btn-info.btn-lighten-1:focus,
.btn-info.btn-lighten-1:active
{
    border-color: #1d7a9c !important;
    background-color: #196682 !important;
}

.btn-outline-info.btn-outline-lighten-1
{
    color: #55b9df !important; 
    border-color: #55b9df !important;
}
.btn-outline-info.btn-outline-lighten-1:hover
{
    background-color: #55b9df !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #55b9df !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #55b9df !important;
}

.border-info.border-lighten-1
{
    border: 1px solid #55b9df !important;
}

.border-top-info.border-top-lighten-1
{
    border-top: 1px solid #55b9df !important;
}

.border-bottom-info.border-bottom-lighten-1
{
    border-bottom: 1px solid #55b9df !important;
}

.border-left-info.border-left-lighten-1
{
    border-left: 1px solid #55b9df !important;
}

.border-right-info.border-right-lighten-1
{
    border-right: 1px solid #55b9df !important;
}

.overlay-info.overlay-lighten-1
{
    background: #55b9df;
    /* The Fallback */
    background: rgba(85, 185, 223, .8);
}

.info
{
    color: #3bafda !important;
}

.bg-info
{
    background-color: #3bafda !important;
}
.bg-info .card-header,
.bg-info .card-footer
{
    background-color: transparent;
}
.bg-info.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(59, 175, 218, .6);
            box-shadow: 0 1px 20px 1px rgba(59, 175, 218, .6);
}

.selectboxit.selectboxit-btn.bg-info
{
    background-color: #3bafda !important;
}

.alert-info
{
    color: #104153 !important; 
    border-color: #3bafda !important;
    background-color: #77c8e5 !important;
}
.alert-info .alert-link
{
    color: #092631 !important;
}

.border-info
{
    border-color: #3bafda;
}

.overlay-info
{
    background: #3bafda;
    /* The Fallback */
    background: rgba(59, 175, 218, .8);
}

/* .card-outline-info{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-info
{
    color: #fff; 
    border-color: #3bafda !important;
    background-color: #3bafda !important;
}
.btn-info:hover
{
    color: #fff !important; 
    border-color: #228eb6 !important;
    background-color: #27a2cf !important;
}
.btn-info:focus,
.btn-info:active
{
    color: #fff !important; 
    border-color: #1d7a9c !important;
    background-color: #228eb6 !important;
}
.btn-info.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(59, 175, 218, .6);
            box-shadow: 0 1px 20px 1px rgba(59, 175, 218, .6);
}

.btn-outline-info
{
    color: #3bafda; 
    border-color: #3bafda;
    background-color: transparent;
}
.btn-outline-info:hover
{
    color: #fff !important; 
    background-color: #3bafda;
}
.btn-outline-info.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(59, 175, 218, .6);
            box-shadow: 0 1px 20px 1px rgba(59, 175, 218, .6);
}

input[type='checkbox'].bg-info + .custom-control-label:before,
input[type='radio'].bg-info + .custom-control-label:before
{
    background-color: #3bafda !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #3bafda !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #3bafda !important;
}

.border-info
{
    border: 1px solid #3bafda !important;
}

.border-top-info
{
    border-top: 1px solid #3bafda;
}

.border-bottom-info
{
    border-bottom: 1px solid #3bafda;
}

.border-left-info
{
    border-left: 1px solid #3bafda;
}

.border-right-info
{
    border-right: 1px solid #3bafda;
}

.info.darken-1
{
    color: #27a2cf !important;
}

.bg-info.bg-darken-1
{
    background-color: #27a2cf !important;
}
.bg-info.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(39, 162, 207, .6);
            box-shadow: 0 1px 20px 1px rgba(39, 162, 207, .6);
}

.btn-info.btn-darken-1
{
    border-color: #228eb6 !important;
    background-color: #27a2cf !important;
}
.btn-info.btn-darken-1:hover
{
    border-color: #228eb6 !important;
    background-color: #1d7a9c !important;
}
.btn-info.btn-darken-1:focus,
.btn-info.btn-darken-1:active
{
    border-color: #1d7a9c !important;
    background-color: #196682 !important;
}

.btn-outline-info.btn-outline-darken-1
{
    color: #27a2cf !important; 
    border-color: #27a2cf !important;
}
.btn-outline-info.btn-outline-darken-1:hover
{
    background-color: #27a2cf !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #27a2cf !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #27a2cf !important;
}

.border-info.border-darken-1
{
    border: 1px solid #27a2cf !important;
}

.border-top-info.border-top-darken-1
{
    border-top: 1px solid #27a2cf !important;
}

.border-bottom-info.border-bottom-darken-1
{
    border-bottom: 1px solid #27a2cf !important;
}

.border-left-info.border-left-darken-1
{
    border-left: 1px solid #27a2cf !important;
}

.border-right-info.border-right-darken-1
{
    border-right: 1px solid #27a2cf !important;
}

.overlay-info.overlay-darken-1
{
    background: #27a2cf;
    /* The Fallback */
    background: rgba(39, 162, 207, .8);
}

.info.darken-2
{
    color: #228eb6 !important;
}

.bg-info.bg-darken-2
{
    background-color: #228eb6 !important;
}
.bg-info.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(34, 142, 182, .6);
            box-shadow: 0 1px 20px 1px rgba(34, 142, 182, .6);
}

.btn-info.btn-darken-2
{
    border-color: #228eb6 !important;
    background-color: #228eb6 !important;
}
.btn-info.btn-darken-2:hover
{
    border-color: #228eb6 !important;
    background-color: #1d7a9c !important;
}
.btn-info.btn-darken-2:focus,
.btn-info.btn-darken-2:active
{
    border-color: #1d7a9c !important;
    background-color: #196682 !important;
}

.btn-outline-info.btn-outline-darken-2
{
    color: #228eb6 !important; 
    border-color: #228eb6 !important;
}
.btn-outline-info.btn-outline-darken-2:hover
{
    background-color: #228eb6 !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #228eb6 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #228eb6 !important;
}

.border-info.border-darken-2
{
    border: 1px solid #228eb6 !important;
}

.border-top-info.border-top-darken-2
{
    border-top: 1px solid #228eb6 !important;
}

.border-bottom-info.border-bottom-darken-2
{
    border-bottom: 1px solid #228eb6 !important;
}

.border-left-info.border-left-darken-2
{
    border-left: 1px solid #228eb6 !important;
}

.border-right-info.border-right-darken-2
{
    border-right: 1px solid #228eb6 !important;
}

.overlay-info.overlay-darken-2
{
    background: #228eb6;
    /* The Fallback */
    background: rgba(34, 142, 182, .8);
}

.info.darken-3
{
    color: #1d7a9c !important;
}

.bg-info.bg-darken-3
{
    background-color: #1d7a9c !important;
}
.bg-info.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(29, 122, 156, .6);
            box-shadow: 0 1px 20px 1px rgba(29, 122, 156, .6);
}

.btn-info.btn-darken-3
{
    border-color: #228eb6 !important;
    background-color: #1d7a9c !important;
}
.btn-info.btn-darken-3:hover
{
    border-color: #228eb6 !important;
    background-color: #1d7a9c !important;
}
.btn-info.btn-darken-3:focus,
.btn-info.btn-darken-3:active
{
    border-color: #1d7a9c !important;
    background-color: #196682 !important;
}

.btn-outline-info.btn-outline-darken-3
{
    color: #1d7a9c !important; 
    border-color: #1d7a9c !important;
}
.btn-outline-info.btn-outline-darken-3:hover
{
    background-color: #1d7a9c !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1d7a9c !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1d7a9c !important;
}

.border-info.border-darken-3
{
    border: 1px solid #1d7a9c !important;
}

.border-top-info.border-top-darken-3
{
    border-top: 1px solid #1d7a9c !important;
}

.border-bottom-info.border-bottom-darken-3
{
    border-bottom: 1px solid #1d7a9c !important;
}

.border-left-info.border-left-darken-3
{
    border-left: 1px solid #1d7a9c !important;
}

.border-right-info.border-right-darken-3
{
    border-right: 1px solid #1d7a9c !important;
}

.overlay-info.overlay-darken-3
{
    background: #1d7a9c;
    /* The Fallback */
    background: rgba(29, 122, 156, .8);
}

.info.darken-4
{
    color: #196682 !important;
}

.bg-info.bg-darken-4
{
    background-color: #196682 !important;
}
.bg-info.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(25, 102, 130, .6);
            box-shadow: 0 1px 20px 1px rgba(25, 102, 130, .6);
}

.btn-info.btn-darken-4
{
    border-color: #228eb6 !important;
    background-color: #196682 !important;
}
.btn-info.btn-darken-4:hover
{
    border-color: #228eb6 !important;
    background-color: #1d7a9c !important;
}
.btn-info.btn-darken-4:focus,
.btn-info.btn-darken-4:active
{
    border-color: #1d7a9c !important;
    background-color: #196682 !important;
}

.btn-outline-info.btn-outline-darken-4
{
    color: #196682 !important; 
    border-color: #196682 !important;
}
.btn-outline-info.btn-outline-darken-4:hover
{
    background-color: #196682 !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #196682 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #196682 !important;
}

.border-info.border-darken-4
{
    border: 1px solid #196682 !important;
}

.border-top-info.border-top-darken-4
{
    border-top: 1px solid #196682 !important;
}

.border-bottom-info.border-bottom-darken-4
{
    border-bottom: 1px solid #196682 !important;
}

.border-left-info.border-left-darken-4
{
    border-left: 1px solid #196682 !important;
}

.border-right-info.border-right-darken-4
{
    border-right: 1px solid #196682 !important;
}

.overlay-info.overlay-darken-4
{
    background: #196682;
    /* The Fallback */
    background: rgba(25, 102, 130, .8);
}

.info.accent-1
{
    color: #80d2ef !important;
}

.bg-info.bg-accent-1
{
    background-color: #80d2ef !important;
}
.bg-info.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(128, 210, 239, .6);
            box-shadow: 0 1px 20px 1px rgba(128, 210, 239, .6);
}

.btn-info.btn-accent-1
{
    border-color: #228eb6 !important;
    background-color: #80d2ef !important;
}
.btn-info.btn-accent-1:hover
{
    border-color: #228eb6 !important;
    background-color: #1d7a9c !important;
}
.btn-info.btn-accent-1:focus,
.btn-info.btn-accent-1:active
{
    border-color: #1d7a9c !important;
    background-color: #196682 !important;
}

.btn-outline-info.btn-outline-accent-1
{
    color: #80d2ef !important; 
    border-color: #80d2ef !important;
}
.btn-outline-info.btn-outline-accent-1:hover
{
    background-color: #80d2ef !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #80d2ef !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #80d2ef !important;
}

.border-info.border-accent-1
{
    border: 1px solid #80d2ef !important;
}

.border-top-info.border-top-accent-1
{
    border-top: 1px solid #80d2ef !important;
}

.border-bottom-info.border-bottom-accent-1
{
    border-bottom: 1px solid #80d2ef !important;
}

.border-left-info.border-left-accent-1
{
    border-left: 1px solid #80d2ef !important;
}

.border-right-info.border-right-accent-1
{
    border-right: 1px solid #80d2ef !important;
}

.overlay-info.overlay-accent-1
{
    background: #80d2ef;
    /* The Fallback */
    background: rgba(128, 210, 239, .8);
}

.info.accent-2
{
    color: #25b1e4 !important;
}

.bg-info.bg-accent-2
{
    background-color: #25b1e4 !important;
}
.bg-info.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(37, 177, 228, .6);
            box-shadow: 0 1px 20px 1px rgba(37, 177, 228, .6);
}

.btn-info.btn-accent-2
{
    border-color: #228eb6 !important;
    background-color: #25b1e4 !important;
}
.btn-info.btn-accent-2:hover
{
    border-color: #228eb6 !important;
    background-color: #1d7a9c !important;
}
.btn-info.btn-accent-2:focus,
.btn-info.btn-accent-2:active
{
    border-color: #1d7a9c !important;
    background-color: #196682 !important;
}

.btn-outline-info.btn-outline-accent-2
{
    color: #25b1e4 !important; 
    border-color: #25b1e4 !important;
}
.btn-outline-info.btn-outline-accent-2:hover
{
    background-color: #25b1e4 !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #25b1e4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #25b1e4 !important;
}

.border-info.border-accent-2
{
    border: 1px solid #25b1e4 !important;
}

.border-top-info.border-top-accent-2
{
    border-top: 1px solid #25b1e4 !important;
}

.border-bottom-info.border-bottom-accent-2
{
    border-bottom: 1px solid #25b1e4 !important;
}

.border-left-info.border-left-accent-2
{
    border-left: 1px solid #25b1e4 !important;
}

.border-right-info.border-right-accent-2
{
    border-right: 1px solid #25b1e4 !important;
}

.overlay-info.overlay-accent-2
{
    background: #25b1e4;
    /* The Fallback */
    background: rgba(37, 177, 228, .8);
}

.info.accent-3
{
    color: #168bb6 !important;
}

.bg-info.bg-accent-3
{
    background-color: #168bb6 !important;
}
.bg-info.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(22, 139, 182, .6);
            box-shadow: 0 1px 20px 1px rgba(22, 139, 182, .6);
}

.btn-info.btn-accent-3
{
    border-color: #228eb6 !important;
    background-color: #168bb6 !important;
}
.btn-info.btn-accent-3:hover
{
    border-color: #228eb6 !important;
    background-color: #1d7a9c !important;
}
.btn-info.btn-accent-3:focus,
.btn-info.btn-accent-3:active
{
    border-color: #1d7a9c !important;
    background-color: #196682 !important;
}

.btn-outline-info.btn-outline-accent-3
{
    color: #168bb6 !important; 
    border-color: #168bb6 !important;
}
.btn-outline-info.btn-outline-accent-3:hover
{
    background-color: #168bb6 !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #168bb6 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #168bb6 !important;
}

.border-info.border-accent-3
{
    border: 1px solid #168bb6 !important;
}

.border-top-info.border-top-accent-3
{
    border-top: 1px solid #168bb6 !important;
}

.border-bottom-info.border-bottom-accent-3
{
    border-bottom: 1px solid #168bb6 !important;
}

.border-left-info.border-left-accent-3
{
    border-left: 1px solid #168bb6 !important;
}

.border-right-info.border-right-accent-3
{
    border-right: 1px solid #168bb6 !important;
}

.overlay-info.overlay-accent-3
{
    background: #168bb6;
    /* The Fallback */
    background: rgba(22, 139, 182, .8);
}

.info.accent-4
{
    color: #126f91 !important;
}

.bg-info.bg-accent-4
{
    background-color: #126f91 !important;
}
.bg-info.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(18, 111, 145, .6);
            box-shadow: 0 1px 20px 1px rgba(18, 111, 145, .6);
}

.btn-info.btn-accent-4
{
    border-color: #228eb6 !important;
    background-color: #126f91 !important;
}
.btn-info.btn-accent-4:hover
{
    border-color: #228eb6 !important;
    background-color: #1d7a9c !important;
}
.btn-info.btn-accent-4:focus,
.btn-info.btn-accent-4:active
{
    border-color: #1d7a9c !important;
    background-color: #196682 !important;
}

.btn-outline-info.btn-outline-accent-4
{
    color: #126f91 !important; 
    border-color: #126f91 !important;
}
.btn-outline-info.btn-outline-accent-4:hover
{
    background-color: #126f91 !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #126f91 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #126f91 !important;
}

.border-info.border-accent-4
{
    border: 1px solid #126f91 !important;
}

.border-top-info.border-top-accent-4
{
    border-top: 1px solid #126f91 !important;
}

.border-bottom-info.border-bottom-accent-4
{
    border-bottom: 1px solid #126f91 !important;
}

.border-left-info.border-left-accent-4
{
    border-left: 1px solid #126f91 !important;
}

.border-right-info.border-right-accent-4
{
    border-right: 1px solid #126f91 !important;
}

.overlay-info.overlay-accent-4
{
    background: #126f91;
    /* The Fallback */
    background: rgba(18, 111, 145, .8);
}

.warning.lighten-5
{
    color: #fffcf6 !important;
}

.bg-warning.bg-lighten-5
{
    background-color: #fffcf6 !important;
}
.bg-warning.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 252, 246, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 252, 246, .6);
}

.btn-warning.btn-lighten-5
{
    border-color: #efa50b !important;
    background-color: #fffcf6 !important;
}
.btn-warning.btn-lighten-5:hover
{
    border-color: #efa50b !important;
    background-color: #d2910a !important;
}
.btn-warning.btn-lighten-5:focus,
.btn-warning.btn-lighten-5:active
{
    border-color: #d2910a !important;
    background-color: #b57c09 !important;
}

.btn-outline-warning.btn-outline-lighten-5
{
    color: #fffcf6 !important; 
    border-color: #fffcf6 !important;
}
.btn-outline-warning.btn-outline-lighten-5:hover
{
    background-color: #fffcf6 !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fffcf6 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fffcf6 !important;
}

.border-warning.border-lighten-5
{
    border: 1px solid #fffcf6 !important;
}

.border-top-warning.border-top-lighten-5
{
    border-top: 1px solid #fffcf6 !important;
}

.border-bottom-warning.border-bottom-lighten-5
{
    border-bottom: 1px solid #fffcf6 !important;
}

.border-left-warning.border-left-lighten-5
{
    border-left: 1px solid #fffcf6 !important;
}

.border-right-warning.border-right-lighten-5
{
    border-right: 1px solid #fffcf6 !important;
}

.overlay-warning.overlay-lighten-5
{
    background: #fffcf6;
    /* The Fallback */
    background: rgba(255, 252, 246, .8);
}

.warning.lighten-4
{
    color: #fce9c1 !important;
}

.bg-warning.bg-lighten-4
{
    background-color: #fce9c1 !important;
}
.bg-warning.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(252, 233, 193, .6);
            box-shadow: 0 1px 20px 1px rgba(252, 233, 193, .6);
}

.btn-warning.btn-lighten-4
{
    border-color: #efa50b !important;
    background-color: #fce9c1 !important;
}
.btn-warning.btn-lighten-4:hover
{
    border-color: #efa50b !important;
    background-color: #d2910a !important;
}
.btn-warning.btn-lighten-4:focus,
.btn-warning.btn-lighten-4:active
{
    border-color: #d2910a !important;
    background-color: #b57c09 !important;
}

.btn-outline-warning.btn-outline-lighten-4
{
    color: #fce9c1 !important; 
    border-color: #fce9c1 !important;
}
.btn-outline-warning.btn-outline-lighten-4:hover
{
    background-color: #fce9c1 !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fce9c1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fce9c1 !important;
}

.border-warning.border-lighten-4
{
    border: 1px solid #fce9c1 !important;
}

.border-top-warning.border-top-lighten-4
{
    border-top: 1px solid #fce9c1 !important;
}

.border-bottom-warning.border-bottom-lighten-4
{
    border-bottom: 1px solid #fce9c1 !important;
}

.border-left-warning.border-left-lighten-4
{
    border-left: 1px solid #fce9c1 !important;
}

.border-right-warning.border-right-lighten-4
{
    border-right: 1px solid #fce9c1 !important;
}

.overlay-warning.overlay-lighten-4
{
    background: #fce9c1;
    /* The Fallback */
    background: rgba(252, 233, 193, .8);
}

.warning.lighten-3
{
    color: #f9d07c !important;
}

.bg-warning.bg-lighten-3
{
    background-color: #f9d07c !important;
}
.bg-warning.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(249, 208, 124, .6);
            box-shadow: 0 1px 20px 1px rgba(249, 208, 124, .6);
}

.btn-warning.btn-lighten-3
{
    border-color: #efa50b !important;
    background-color: #f9d07c !important;
}
.btn-warning.btn-lighten-3:hover
{
    border-color: #efa50b !important;
    background-color: #d2910a !important;
}
.btn-warning.btn-lighten-3:focus,
.btn-warning.btn-lighten-3:active
{
    border-color: #d2910a !important;
    background-color: #b57c09 !important;
}

.btn-outline-warning.btn-outline-lighten-3
{
    color: #f9d07c !important; 
    border-color: #f9d07c !important;
}
.btn-outline-warning.btn-outline-lighten-3:hover
{
    background-color: #f9d07c !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f9d07c !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f9d07c !important;
}

.border-warning.border-lighten-3
{
    border: 1px solid #f9d07c !important;
}

.border-top-warning.border-top-lighten-3
{
    border-top: 1px solid #f9d07c !important;
}

.border-bottom-warning.border-bottom-lighten-3
{
    border-bottom: 1px solid #f9d07c !important;
}

.border-left-warning.border-left-lighten-3
{
    border-left: 1px solid #f9d07c !important;
}

.border-right-warning.border-right-lighten-3
{
    border-right: 1px solid #f9d07c !important;
}

.overlay-warning.overlay-lighten-3
{
    background: #f9d07c;
    /* The Fallback */
    background: rgba(249, 208, 124, .8);
}

.warning.lighten-2
{
    color: #ffce54 !important;
}

.bg-warning.bg-lighten-2
{
    background-color: #ffce54 !important;
}
.bg-warning.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 206, 84, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 206, 84, .6);
}

.btn-warning.btn-lighten-2
{
    border-color: #efa50b !important;
    background-color: #ffce54 !important;
}
.btn-warning.btn-lighten-2:hover
{
    border-color: #efa50b !important;
    background-color: #d2910a !important;
}
.btn-warning.btn-lighten-2:focus,
.btn-warning.btn-lighten-2:active
{
    border-color: #d2910a !important;
    background-color: #b57c09 !important;
}

.btn-outline-warning.btn-outline-lighten-2
{
    color: #ffce54 !important; 
    border-color: #ffce54 !important;
}
.btn-outline-warning.btn-outline-lighten-2:hover
{
    background-color: #ffce54 !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffce54 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffce54 !important;
}

.border-warning.border-lighten-2
{
    border: 1px solid #ffce54 !important;
}

.border-top-warning.border-top-lighten-2
{
    border-top: 1px solid #ffce54 !important;
}

.border-bottom-warning.border-bottom-lighten-2
{
    border-bottom: 1px solid #ffce54 !important;
}

.border-left-warning.border-left-lighten-2
{
    border-left: 1px solid #ffce54 !important;
}

.border-right-warning.border-right-lighten-2
{
    border-right: 1px solid #ffce54 !important;
}

.overlay-warning.overlay-lighten-2
{
    background: #ffce54;
    /* The Fallback */
    background: rgba(255, 206, 84, .8);
}

.warning.lighten-1
{
    color: #f7c55f !important;
}

.bg-warning.bg-lighten-1
{
    background-color: #f7c55f !important;
}
.bg-warning.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(247, 197, 95, .6);
            box-shadow: 0 1px 20px 1px rgba(247, 197, 95, .6);
}

.btn-warning.btn-lighten-1
{
    border-color: #efa50b !important;
    background-color: #f7c55f !important;
}
.btn-warning.btn-lighten-1:hover
{
    border-color: #efa50b !important;
    background-color: #d2910a !important;
}
.btn-warning.btn-lighten-1:focus,
.btn-warning.btn-lighten-1:active
{
    border-color: #d2910a !important;
    background-color: #b57c09 !important;
}

.btn-outline-warning.btn-outline-lighten-1
{
    color: #f7c55f !important; 
    border-color: #f7c55f !important;
}
.btn-outline-warning.btn-outline-lighten-1:hover
{
    background-color: #f7c55f !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f7c55f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f7c55f !important;
}

.border-warning.border-lighten-1
{
    border: 1px solid #f7c55f !important;
}

.border-top-warning.border-top-lighten-1
{
    border-top: 1px solid #f7c55f !important;
}

.border-bottom-warning.border-bottom-lighten-1
{
    border-bottom: 1px solid #f7c55f !important;
}

.border-left-warning.border-left-lighten-1
{
    border-left: 1px solid #f7c55f !important;
}

.border-right-warning.border-right-lighten-1
{
    border-right: 1px solid #f7c55f !important;
}

.overlay-warning.overlay-lighten-1
{
    background: #f7c55f;
    /* The Fallback */
    background: rgba(247, 197, 95, .8);
}

.warning
{
    color: #f6bb42 !important;
}

.bg-warning
{
    background-color: #f6bb42 !important;
}
.bg-warning .card-header,
.bg-warning .card-footer
{
    background-color: transparent;
}
.bg-warning.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(246, 187, 66, .6);
            box-shadow: 0 1px 20px 1px rgba(246, 187, 66, .6);
}

.selectboxit.selectboxit-btn.bg-warning
{
    background-color: #f6bb42 !important;
}

.alert-warning
{
    color: #7f5806 !important; 
    border-color: #f6bb42 !important;
    background-color: #f9d486 !important;
}
.alert-warning .alert-link
{
    color: #583d04 !important;
}

.border-warning
{
    border-color: #f6bb42;
}

.overlay-warning
{
    background: #f6bb42;
    /* The Fallback */
    background: rgba(246, 187, 66, .8);
}

/* .card-outline-warning{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-warning
{
    color: #fff; 
    border-color: #f6bb42 !important;
    background-color: #f6bb42 !important;
}
.btn-warning:hover
{
    color: #fff !important; 
    border-color: #efa50b !important;
    background-color: #f5b025 !important;
}
.btn-warning:focus,
.btn-warning:active
{
    color: #fff !important; 
    border-color: #d2910a !important;
    background-color: #efa50b !important;
}
.btn-warning.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(246, 187, 66, .6);
            box-shadow: 0 1px 20px 1px rgba(246, 187, 66, .6);
}

.btn-outline-warning
{
    color: #f6bb42; 
    border-color: #f6bb42;
    background-color: transparent;
}
.btn-outline-warning:hover
{
    color: #fff !important; 
    background-color: #f6bb42;
}
.btn-outline-warning.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(246, 187, 66, .6);
            box-shadow: 0 1px 20px 1px rgba(246, 187, 66, .6);
}

input[type='checkbox'].bg-warning + .custom-control-label:before,
input[type='radio'].bg-warning + .custom-control-label:before
{
    background-color: #f6bb42 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f6bb42 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f6bb42 !important;
}

.border-warning
{
    border: 1px solid #f6bb42 !important;
}

.border-top-warning
{
    border-top: 1px solid #f6bb42;
}

.border-bottom-warning
{
    border-bottom: 1px solid #f6bb42;
}

.border-left-warning
{
    border-left: 1px solid #f6bb42;
}

.border-right-warning
{
    border-right: 1px solid #f6bb42;
}

.warning.darken-1
{
    color: #f5b025 !important;
}

.bg-warning.bg-darken-1
{
    background-color: #f5b025 !important;
}
.bg-warning.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(245, 176, 37, .6);
            box-shadow: 0 1px 20px 1px rgba(245, 176, 37, .6);
}

.btn-warning.btn-darken-1
{
    border-color: #efa50b !important;
    background-color: #f5b025 !important;
}
.btn-warning.btn-darken-1:hover
{
    border-color: #efa50b !important;
    background-color: #d2910a !important;
}
.btn-warning.btn-darken-1:focus,
.btn-warning.btn-darken-1:active
{
    border-color: #d2910a !important;
    background-color: #b57c09 !important;
}

.btn-outline-warning.btn-outline-darken-1
{
    color: #f5b025 !important; 
    border-color: #f5b025 !important;
}
.btn-outline-warning.btn-outline-darken-1:hover
{
    background-color: #f5b025 !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f5b025 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f5b025 !important;
}

.border-warning.border-darken-1
{
    border: 1px solid #f5b025 !important;
}

.border-top-warning.border-top-darken-1
{
    border-top: 1px solid #f5b025 !important;
}

.border-bottom-warning.border-bottom-darken-1
{
    border-bottom: 1px solid #f5b025 !important;
}

.border-left-warning.border-left-darken-1
{
    border-left: 1px solid #f5b025 !important;
}

.border-right-warning.border-right-darken-1
{
    border-right: 1px solid #f5b025 !important;
}

.overlay-warning.overlay-darken-1
{
    background: #f5b025;
    /* The Fallback */
    background: rgba(245, 176, 37, .8);
}

.warning.darken-2
{
    color: #efa50b !important;
}

.bg-warning.bg-darken-2
{
    background-color: #efa50b !important;
}
.bg-warning.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(239, 165, 11, .6);
            box-shadow: 0 1px 20px 1px rgba(239, 165, 11, .6);
}

.btn-warning.btn-darken-2
{
    border-color: #efa50b !important;
    background-color: #efa50b !important;
}
.btn-warning.btn-darken-2:hover
{
    border-color: #efa50b !important;
    background-color: #d2910a !important;
}
.btn-warning.btn-darken-2:focus,
.btn-warning.btn-darken-2:active
{
    border-color: #d2910a !important;
    background-color: #b57c09 !important;
}

.btn-outline-warning.btn-outline-darken-2
{
    color: #efa50b !important; 
    border-color: #efa50b !important;
}
.btn-outline-warning.btn-outline-darken-2:hover
{
    background-color: #efa50b !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #efa50b !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #efa50b !important;
}

.border-warning.border-darken-2
{
    border: 1px solid #efa50b !important;
}

.border-top-warning.border-top-darken-2
{
    border-top: 1px solid #efa50b !important;
}

.border-bottom-warning.border-bottom-darken-2
{
    border-bottom: 1px solid #efa50b !important;
}

.border-left-warning.border-left-darken-2
{
    border-left: 1px solid #efa50b !important;
}

.border-right-warning.border-right-darken-2
{
    border-right: 1px solid #efa50b !important;
}

.overlay-warning.overlay-darken-2
{
    background: #efa50b;
    /* The Fallback */
    background: rgba(239, 165, 11, .8);
}

.warning.darken-3
{
    color: #d2910a !important;
}

.bg-warning.bg-darken-3
{
    background-color: #d2910a !important;
}
.bg-warning.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(210, 145, 10, .6);
            box-shadow: 0 1px 20px 1px rgba(210, 145, 10, .6);
}

.btn-warning.btn-darken-3
{
    border-color: #efa50b !important;
    background-color: #d2910a !important;
}
.btn-warning.btn-darken-3:hover
{
    border-color: #efa50b !important;
    background-color: #d2910a !important;
}
.btn-warning.btn-darken-3:focus,
.btn-warning.btn-darken-3:active
{
    border-color: #d2910a !important;
    background-color: #b57c09 !important;
}

.btn-outline-warning.btn-outline-darken-3
{
    color: #d2910a !important; 
    border-color: #d2910a !important;
}
.btn-outline-warning.btn-outline-darken-3:hover
{
    background-color: #d2910a !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d2910a !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d2910a !important;
}

.border-warning.border-darken-3
{
    border: 1px solid #d2910a !important;
}

.border-top-warning.border-top-darken-3
{
    border-top: 1px solid #d2910a !important;
}

.border-bottom-warning.border-bottom-darken-3
{
    border-bottom: 1px solid #d2910a !important;
}

.border-left-warning.border-left-darken-3
{
    border-left: 1px solid #d2910a !important;
}

.border-right-warning.border-right-darken-3
{
    border-right: 1px solid #d2910a !important;
}

.overlay-warning.overlay-darken-3
{
    background: #d2910a;
    /* The Fallback */
    background: rgba(210, 145, 10, .8);
}

.warning.darken-4
{
    color: #b57c09 !important;
}

.bg-warning.bg-darken-4
{
    background-color: #b57c09 !important;
}
.bg-warning.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(181, 124, 9, .6);
            box-shadow: 0 1px 20px 1px rgba(181, 124, 9, .6);
}

.btn-warning.btn-darken-4
{
    border-color: #efa50b !important;
    background-color: #b57c09 !important;
}
.btn-warning.btn-darken-4:hover
{
    border-color: #efa50b !important;
    background-color: #d2910a !important;
}
.btn-warning.btn-darken-4:focus,
.btn-warning.btn-darken-4:active
{
    border-color: #d2910a !important;
    background-color: #b57c09 !important;
}

.btn-outline-warning.btn-outline-darken-4
{
    color: #b57c09 !important; 
    border-color: #b57c09 !important;
}
.btn-outline-warning.btn-outline-darken-4:hover
{
    background-color: #b57c09 !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b57c09 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b57c09 !important;
}

.border-warning.border-darken-4
{
    border: 1px solid #b57c09 !important;
}

.border-top-warning.border-top-darken-4
{
    border-top: 1px solid #b57c09 !important;
}

.border-bottom-warning.border-bottom-darken-4
{
    border-bottom: 1px solid #b57c09 !important;
}

.border-left-warning.border-left-darken-4
{
    border-left: 1px solid #b57c09 !important;
}

.border-right-warning.border-right-darken-4
{
    border-right: 1px solid #b57c09 !important;
}

.overlay-warning.overlay-darken-4
{
    background: #b57c09;
    /* The Fallback */
    background: rgba(181, 124, 9, .8);
}

.warning.accent-1
{
    color: #fddea1 !important;
}

.bg-warning.bg-accent-1
{
    background-color: #fddea1 !important;
}
.bg-warning.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(253, 222, 161, .6);
            box-shadow: 0 1px 20px 1px rgba(253, 222, 161, .6);
}

.btn-warning.btn-accent-1
{
    border-color: #efa50b !important;
    background-color: #fddea1 !important;
}
.btn-warning.btn-accent-1:hover
{
    border-color: #efa50b !important;
    background-color: #d2910a !important;
}
.btn-warning.btn-accent-1:focus,
.btn-warning.btn-accent-1:active
{
    border-color: #d2910a !important;
    background-color: #b57c09 !important;
}

.btn-outline-warning.btn-outline-accent-1
{
    color: #fddea1 !important; 
    border-color: #fddea1 !important;
}
.btn-outline-warning.btn-outline-accent-1:hover
{
    background-color: #fddea1 !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fddea1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fddea1 !important;
}

.border-warning.border-accent-1
{
    border: 1px solid #fddea1 !important;
}

.border-top-warning.border-top-accent-1
{
    border-top: 1px solid #fddea1 !important;
}

.border-bottom-warning.border-bottom-accent-1
{
    border-bottom: 1px solid #fddea1 !important;
}

.border-left-warning.border-left-accent-1
{
    border-left: 1px solid #fddea1 !important;
}

.border-right-warning.border-right-accent-1
{
    border-right: 1px solid #fddea1 !important;
}

.overlay-warning.overlay-accent-1
{
    background: #fddea1;
    /* The Fallback */
    background: rgba(253, 222, 161, .8);
}

.warning.accent-2
{
    color: #f9d07d !important;
}

.bg-warning.bg-accent-2
{
    background-color: #f9d07d !important;
}
.bg-warning.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(249, 208, 125, .6);
            box-shadow: 0 1px 20px 1px rgba(249, 208, 125, .6);
}

.btn-warning.btn-accent-2
{
    border-color: #efa50b !important;
    background-color: #f9d07d !important;
}
.btn-warning.btn-accent-2:hover
{
    border-color: #efa50b !important;
    background-color: #d2910a !important;
}
.btn-warning.btn-accent-2:focus,
.btn-warning.btn-accent-2:active
{
    border-color: #d2910a !important;
    background-color: #b57c09 !important;
}

.btn-outline-warning.btn-outline-accent-2
{
    color: #f9d07d !important; 
    border-color: #f9d07d !important;
}
.btn-outline-warning.btn-outline-accent-2:hover
{
    background-color: #f9d07d !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f9d07d !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f9d07d !important;
}

.border-warning.border-accent-2
{
    border: 1px solid #f9d07d !important;
}

.border-top-warning.border-top-accent-2
{
    border-top: 1px solid #f9d07d !important;
}

.border-bottom-warning.border-bottom-accent-2
{
    border-bottom: 1px solid #f9d07d !important;
}

.border-left-warning.border-left-accent-2
{
    border-left: 1px solid #f9d07d !important;
}

.border-right-warning.border-right-accent-2
{
    border-right: 1px solid #f9d07d !important;
}

.overlay-warning.overlay-accent-2
{
    background: #f9d07d;
    /* The Fallback */
    background: rgba(249, 208, 125, .8);
}

.warning.accent-3
{
    color: #fda85c !important;
}

.bg-warning.bg-accent-3
{
    background-color: #fda85c !important;
}
.bg-warning.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(253, 168, 92, .6);
            box-shadow: 0 1px 20px 1px rgba(253, 168, 92, .6);
}

.btn-warning.btn-accent-3
{
    border-color: #efa50b !important;
    background-color: #fda85c !important;
}
.btn-warning.btn-accent-3:hover
{
    border-color: #efa50b !important;
    background-color: #d2910a !important;
}
.btn-warning.btn-accent-3:focus,
.btn-warning.btn-accent-3:active
{
    border-color: #d2910a !important;
    background-color: #b57c09 !important;
}

.btn-outline-warning.btn-outline-accent-3
{
    color: #fda85c !important; 
    border-color: #fda85c !important;
}
.btn-outline-warning.btn-outline-accent-3:hover
{
    background-color: #fda85c !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fda85c !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fda85c !important;
}

.border-warning.border-accent-3
{
    border: 1px solid #fda85c !important;
}

.border-top-warning.border-top-accent-3
{
    border-top: 1px solid #fda85c !important;
}

.border-bottom-warning.border-bottom-accent-3
{
    border-bottom: 1px solid #fda85c !important;
}

.border-left-warning.border-left-accent-3
{
    border-left: 1px solid #fda85c !important;
}

.border-right-warning.border-right-accent-3
{
    border-right: 1px solid #fda85c !important;
}

.overlay-warning.overlay-accent-3
{
    background: #fda85c;
    /* The Fallback */
    background: rgba(253, 168, 92, .8);
}

.warning.accent-4
{
    color: #f5a90b !important;
}

.bg-warning.bg-accent-4
{
    background-color: #f5a90b !important;
}
.bg-warning.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(245, 169, 11, .6);
            box-shadow: 0 1px 20px 1px rgba(245, 169, 11, .6);
}

.btn-warning.btn-accent-4
{
    border-color: #efa50b !important;
    background-color: #f5a90b !important;
}
.btn-warning.btn-accent-4:hover
{
    border-color: #efa50b !important;
    background-color: #d2910a !important;
}
.btn-warning.btn-accent-4:focus,
.btn-warning.btn-accent-4:active
{
    border-color: #d2910a !important;
    background-color: #b57c09 !important;
}

.btn-outline-warning.btn-outline-accent-4
{
    color: #f5a90b !important; 
    border-color: #f5a90b !important;
}
.btn-outline-warning.btn-outline-accent-4:hover
{
    background-color: #f5a90b !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f5a90b !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f5a90b !important;
}

.border-warning.border-accent-4
{
    border: 1px solid #f5a90b !important;
}

.border-top-warning.border-top-accent-4
{
    border-top: 1px solid #f5a90b !important;
}

.border-bottom-warning.border-bottom-accent-4
{
    border-bottom: 1px solid #f5a90b !important;
}

.border-left-warning.border-left-accent-4
{
    border-left: 1px solid #f5a90b !important;
}

.border-right-warning.border-right-accent-4
{
    border-right: 1px solid #f5a90b !important;
}

.overlay-warning.overlay-accent-4
{
    background: #f5a90b;
    /* The Fallback */
    background: rgba(245, 169, 11, .8);
}

.danger.lighten-5
{
    color: #f9e1e4 !important;
}

.bg-danger.bg-lighten-5
{
    background-color: #f9e1e4 !important;
}
.bg-danger.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(249, 225, 228, .6);
            box-shadow: 0 1px 20px 1px rgba(249, 225, 228, .6);
}

.btn-danger.btn-lighten-5
{
    border-color: #bc2534 !important;
    background-color: #f9e1e4 !important;
}
.btn-danger.btn-lighten-5:hover
{
    border-color: #bc2534 !important;
    background-color: #a2202d !important;
}
.btn-danger.btn-lighten-5:focus,
.btn-danger.btn-lighten-5:active
{
    border-color: #a2202d !important;
    background-color: #891b26 !important;
}

.btn-outline-danger.btn-outline-lighten-5
{
    color: #f9e1e4 !important; 
    border-color: #f9e1e4 !important;
}
.btn-outline-danger.btn-outline-lighten-5:hover
{
    background-color: #f9e1e4 !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f9e1e4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f9e1e4 !important;
}

.border-danger.border-lighten-5
{
    border: 1px solid #f9e1e4 !important;
}

.border-top-danger.border-top-lighten-5
{
    border-top: 1px solid #f9e1e4 !important;
}

.border-bottom-danger.border-bottom-lighten-5
{
    border-bottom: 1px solid #f9e1e4 !important;
}

.border-left-danger.border-left-lighten-5
{
    border-left: 1px solid #f9e1e4 !important;
}

.border-right-danger.border-right-lighten-5
{
    border-right: 1px solid #f9e1e4 !important;
}

.overlay-danger.overlay-lighten-5
{
    background: #f9e1e4;
    /* The Fallback */
    background: rgba(249, 225, 228, .8);
}

.danger.lighten-4
{
    color: #f0b3b9 !important;
}

.bg-danger.bg-lighten-4
{
    background-color: #f0b3b9 !important;
}
.bg-danger.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(240, 179, 185, .6);
            box-shadow: 0 1px 20px 1px rgba(240, 179, 185, .6);
}

.btn-danger.btn-lighten-4
{
    border-color: #bc2534 !important;
    background-color: #f0b3b9 !important;
}
.btn-danger.btn-lighten-4:hover
{
    border-color: #bc2534 !important;
    background-color: #a2202d !important;
}
.btn-danger.btn-lighten-4:focus,
.btn-danger.btn-lighten-4:active
{
    border-color: #a2202d !important;
    background-color: #891b26 !important;
}

.btn-outline-danger.btn-outline-lighten-4
{
    color: #f0b3b9 !important; 
    border-color: #f0b3b9 !important;
}
.btn-outline-danger.btn-outline-lighten-4:hover
{
    background-color: #f0b3b9 !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f0b3b9 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f0b3b9 !important;
}

.border-danger.border-lighten-4
{
    border: 1px solid #f0b3b9 !important;
}

.border-top-danger.border-top-lighten-4
{
    border-top: 1px solid #f0b3b9 !important;
}

.border-bottom-danger.border-bottom-lighten-4
{
    border-bottom: 1px solid #f0b3b9 !important;
}

.border-left-danger.border-left-lighten-4
{
    border-left: 1px solid #f0b3b9 !important;
}

.border-right-danger.border-right-lighten-4
{
    border-right: 1px solid #f0b3b9 !important;
}

.overlay-danger.overlay-lighten-4
{
    background: #f0b3b9;
    /* The Fallback */
    background: rgba(240, 179, 185, .8);
}

.danger.lighten-3
{
    color: #e47782 !important;
}

.bg-danger.bg-lighten-3
{
    background-color: #e47782 !important;
}
.bg-danger.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(228, 119, 130, .6);
            box-shadow: 0 1px 20px 1px rgba(228, 119, 130, .6);
}

.btn-danger.btn-lighten-3
{
    border-color: #bc2534 !important;
    background-color: #e47782 !important;
}
.btn-danger.btn-lighten-3:hover
{
    border-color: #bc2534 !important;
    background-color: #a2202d !important;
}
.btn-danger.btn-lighten-3:focus,
.btn-danger.btn-lighten-3:active
{
    border-color: #a2202d !important;
    background-color: #891b26 !important;
}

.btn-outline-danger.btn-outline-lighten-3
{
    color: #e47782 !important; 
    border-color: #e47782 !important;
}
.btn-outline-danger.btn-outline-lighten-3:hover
{
    background-color: #e47782 !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e47782 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e47782 !important;
}

.border-danger.border-lighten-3
{
    border: 1px solid #e47782 !important;
}

.border-top-danger.border-top-lighten-3
{
    border-top: 1px solid #e47782 !important;
}

.border-bottom-danger.border-bottom-lighten-3
{
    border-bottom: 1px solid #e47782 !important;
}

.border-left-danger.border-left-lighten-3
{
    border-left: 1px solid #e47782 !important;
}

.border-right-danger.border-right-lighten-3
{
    border-right: 1px solid #e47782 !important;
}

.overlay-danger.overlay-lighten-3
{
    background: #e47782;
    /* The Fallback */
    background: rgba(228, 119, 130, .8);
}

.danger.lighten-2
{
    color: #ed5565 !important;
}

.bg-danger.bg-lighten-2
{
    background-color: #ed5565 !important;
}
.bg-danger.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(237, 85, 101, .6);
            box-shadow: 0 1px 20px 1px rgba(237, 85, 101, .6);
}

.btn-danger.btn-lighten-2
{
    border-color: #bc2534 !important;
    background-color: #ed5565 !important;
}
.btn-danger.btn-lighten-2:hover
{
    border-color: #bc2534 !important;
    background-color: #a2202d !important;
}
.btn-danger.btn-lighten-2:focus,
.btn-danger.btn-lighten-2:active
{
    border-color: #a2202d !important;
    background-color: #891b26 !important;
}

.btn-outline-danger.btn-outline-lighten-2
{
    color: #ed5565 !important; 
    border-color: #ed5565 !important;
}
.btn-outline-danger.btn-outline-lighten-2:hover
{
    background-color: #ed5565 !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ed5565 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ed5565 !important;
}

.border-danger.border-lighten-2
{
    border: 1px solid #ed5565 !important;
}

.border-top-danger.border-top-lighten-2
{
    border-top: 1px solid #ed5565 !important;
}

.border-bottom-danger.border-bottom-lighten-2
{
    border-bottom: 1px solid #ed5565 !important;
}

.border-left-danger.border-left-lighten-2
{
    border-left: 1px solid #ed5565 !important;
}

.border-right-danger.border-right-lighten-2
{
    border-right: 1px solid #ed5565 !important;
}

.overlay-danger.overlay-lighten-2
{
    background: #ed5565;
    /* The Fallback */
    background: rgba(237, 85, 101, .8);
}

.danger.lighten-1
{
    color: #df5e6a !important;
}

.bg-danger.bg-lighten-1
{
    background-color: #df5e6a !important;
}
.bg-danger.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(223, 94, 106, .6);
            box-shadow: 0 1px 20px 1px rgba(223, 94, 106, .6);
}

.btn-danger.btn-lighten-1
{
    border-color: #bc2534 !important;
    background-color: #df5e6a !important;
}
.btn-danger.btn-lighten-1:hover
{
    border-color: #bc2534 !important;
    background-color: #a2202d !important;
}
.btn-danger.btn-lighten-1:focus,
.btn-danger.btn-lighten-1:active
{
    border-color: #a2202d !important;
    background-color: #891b26 !important;
}

.btn-outline-danger.btn-outline-lighten-1
{
    color: #df5e6a !important; 
    border-color: #df5e6a !important;
}
.btn-outline-danger.btn-outline-lighten-1:hover
{
    background-color: #df5e6a !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #df5e6a !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #df5e6a !important;
}

.border-danger.border-lighten-1
{
    border: 1px solid #df5e6a !important;
}

.border-top-danger.border-top-lighten-1
{
    border-top: 1px solid #df5e6a !important;
}

.border-bottom-danger.border-bottom-lighten-1
{
    border-bottom: 1px solid #df5e6a !important;
}

.border-left-danger.border-left-lighten-1
{
    border-left: 1px solid #df5e6a !important;
}

.border-right-danger.border-right-lighten-1
{
    border-right: 1px solid #df5e6a !important;
}

.overlay-danger.overlay-lighten-1
{
    background: #df5e6a;
    /* The Fallback */
    background: rgba(223, 94, 106, .8);
}

.danger
{
    color: #da4453 !important;
}

.bg-danger
{
    background-color: #da4453 !important;
}
.bg-danger .card-header,
.bg-danger .card-footer
{
    background-color: transparent;
}
.bg-danger.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(218, 68, 83, .6);
            box-shadow: 0 1px 20px 1px rgba(218, 68, 83, .6);
}

.selectboxit.selectboxit-btn.bg-danger
{
    background-color: #da4453 !important;
}

.alert-danger
{
    color: #5a1219 !important; 
    border-color: #da4453 !important;
    background-color: #e6808a !important;
}
.alert-danger .alert-link
{
    color: #380b0f !important;
}

.border-danger
{
    border-color: #da4453;
}

.overlay-danger
{
    background: #da4453;
    /* The Fallback */
    background: rgba(218, 68, 83, .8);
}

/* .card-outline-danger{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-danger
{
    color: #fff; 
    border-color: #da4453 !important;
    background-color: #da4453 !important;
}
.btn-danger:hover
{
    color: #fff !important; 
    border-color: #bc2534 !important;
    background-color: #d52a3b !important;
}
.btn-danger:focus,
.btn-danger:active
{
    color: #fff !important; 
    border-color: #a2202d !important;
    background-color: #bc2534 !important;
}
.btn-danger.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(218, 68, 83, .6);
            box-shadow: 0 1px 20px 1px rgba(218, 68, 83, .6);
}

.btn-outline-danger
{
    color: #da4453; 
    border-color: #da4453;
    background-color: transparent;
}
.btn-outline-danger:hover
{
    color: #fff !important; 
    background-color: #da4453;
}
.btn-outline-danger.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(218, 68, 83, .6);
            box-shadow: 0 1px 20px 1px rgba(218, 68, 83, .6);
}

input[type='checkbox'].bg-danger + .custom-control-label:before,
input[type='radio'].bg-danger + .custom-control-label:before
{
    background-color: #da4453 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #da4453 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #da4453 !important;
}

.border-danger
{
    border: 1px solid #da4453 !important;
}

.border-top-danger
{
    border-top: 1px solid #da4453;
}

.border-bottom-danger
{
    border-bottom: 1px solid #da4453;
}

.border-left-danger
{
    border-left: 1px solid #da4453;
}

.border-right-danger
{
    border-right: 1px solid #da4453;
}

.danger.darken-1
{
    color: #d52a3b !important;
}

.bg-danger.bg-darken-1
{
    background-color: #d52a3b !important;
}
.bg-danger.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(213, 42, 59, .6);
            box-shadow: 0 1px 20px 1px rgba(213, 42, 59, .6);
}

.btn-danger.btn-darken-1
{
    border-color: #bc2534 !important;
    background-color: #d52a3b !important;
}
.btn-danger.btn-darken-1:hover
{
    border-color: #bc2534 !important;
    background-color: #a2202d !important;
}
.btn-danger.btn-darken-1:focus,
.btn-danger.btn-darken-1:active
{
    border-color: #a2202d !important;
    background-color: #891b26 !important;
}

.btn-outline-danger.btn-outline-darken-1
{
    color: #d52a3b !important; 
    border-color: #d52a3b !important;
}
.btn-outline-danger.btn-outline-darken-1:hover
{
    background-color: #d52a3b !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d52a3b !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d52a3b !important;
}

.border-danger.border-darken-1
{
    border: 1px solid #d52a3b !important;
}

.border-top-danger.border-top-darken-1
{
    border-top: 1px solid #d52a3b !important;
}

.border-bottom-danger.border-bottom-darken-1
{
    border-bottom: 1px solid #d52a3b !important;
}

.border-left-danger.border-left-darken-1
{
    border-left: 1px solid #d52a3b !important;
}

.border-right-danger.border-right-darken-1
{
    border-right: 1px solid #d52a3b !important;
}

.overlay-danger.overlay-darken-1
{
    background: #d52a3b;
    /* The Fallback */
    background: rgba(213, 42, 59, .8);
}

.danger.darken-2
{
    color: #bc2534 !important;
}

.bg-danger.bg-darken-2
{
    background-color: #bc2534 !important;
}
.bg-danger.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(188, 37, 52, .6);
            box-shadow: 0 1px 20px 1px rgba(188, 37, 52, .6);
}

.btn-danger.btn-darken-2
{
    border-color: #bc2534 !important;
    background-color: #bc2534 !important;
}
.btn-danger.btn-darken-2:hover
{
    border-color: #bc2534 !important;
    background-color: #a2202d !important;
}
.btn-danger.btn-darken-2:focus,
.btn-danger.btn-darken-2:active
{
    border-color: #a2202d !important;
    background-color: #891b26 !important;
}

.btn-outline-danger.btn-outline-darken-2
{
    color: #bc2534 !important; 
    border-color: #bc2534 !important;
}
.btn-outline-danger.btn-outline-darken-2:hover
{
    background-color: #bc2534 !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bc2534 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bc2534 !important;
}

.border-danger.border-darken-2
{
    border: 1px solid #bc2534 !important;
}

.border-top-danger.border-top-darken-2
{
    border-top: 1px solid #bc2534 !important;
}

.border-bottom-danger.border-bottom-darken-2
{
    border-bottom: 1px solid #bc2534 !important;
}

.border-left-danger.border-left-darken-2
{
    border-left: 1px solid #bc2534 !important;
}

.border-right-danger.border-right-darken-2
{
    border-right: 1px solid #bc2534 !important;
}

.overlay-danger.overlay-darken-2
{
    background: #bc2534;
    /* The Fallback */
    background: rgba(188, 37, 52, .8);
}

.danger.darken-3
{
    color: #a2202d !important;
}

.bg-danger.bg-darken-3
{
    background-color: #a2202d !important;
}
.bg-danger.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(162, 32, 45, .6);
            box-shadow: 0 1px 20px 1px rgba(162, 32, 45, .6);
}

.btn-danger.btn-darken-3
{
    border-color: #bc2534 !important;
    background-color: #a2202d !important;
}
.btn-danger.btn-darken-3:hover
{
    border-color: #bc2534 !important;
    background-color: #a2202d !important;
}
.btn-danger.btn-darken-3:focus,
.btn-danger.btn-darken-3:active
{
    border-color: #a2202d !important;
    background-color: #891b26 !important;
}

.btn-outline-danger.btn-outline-darken-3
{
    color: #a2202d !important; 
    border-color: #a2202d !important;
}
.btn-outline-danger.btn-outline-darken-3:hover
{
    background-color: #a2202d !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #a2202d !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #a2202d !important;
}

.border-danger.border-darken-3
{
    border: 1px solid #a2202d !important;
}

.border-top-danger.border-top-darken-3
{
    border-top: 1px solid #a2202d !important;
}

.border-bottom-danger.border-bottom-darken-3
{
    border-bottom: 1px solid #a2202d !important;
}

.border-left-danger.border-left-darken-3
{
    border-left: 1px solid #a2202d !important;
}

.border-right-danger.border-right-darken-3
{
    border-right: 1px solid #a2202d !important;
}

.overlay-danger.overlay-darken-3
{
    background: #a2202d;
    /* The Fallback */
    background: rgba(162, 32, 45, .8);
}

.danger.darken-4
{
    color: #891b26 !important;
}

.bg-danger.bg-darken-4
{
    background-color: #891b26 !important;
}
.bg-danger.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(137, 27, 38, .6);
            box-shadow: 0 1px 20px 1px rgba(137, 27, 38, .6);
}

.btn-danger.btn-darken-4
{
    border-color: #bc2534 !important;
    background-color: #891b26 !important;
}
.btn-danger.btn-darken-4:hover
{
    border-color: #bc2534 !important;
    background-color: #a2202d !important;
}
.btn-danger.btn-darken-4:focus,
.btn-danger.btn-darken-4:active
{
    border-color: #a2202d !important;
    background-color: #891b26 !important;
}

.btn-outline-danger.btn-outline-darken-4
{
    color: #891b26 !important; 
    border-color: #891b26 !important;
}
.btn-outline-danger.btn-outline-darken-4:hover
{
    background-color: #891b26 !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #891b26 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #891b26 !important;
}

.border-danger.border-darken-4
{
    border: 1px solid #891b26 !important;
}

.border-top-danger.border-top-darken-4
{
    border-top: 1px solid #891b26 !important;
}

.border-bottom-danger.border-bottom-darken-4
{
    border-bottom: 1px solid #891b26 !important;
}

.border-left-danger.border-left-darken-4
{
    border-left: 1px solid #891b26 !important;
}

.border-right-danger.border-right-darken-4
{
    border-right: 1px solid #891b26 !important;
}

.overlay-danger.overlay-darken-4
{
    background: #891b26;
    /* The Fallback */
    background: rgba(137, 27, 38, .8);
}

.danger.accent-1
{
    color: #f9e1e4 !important;
}

.bg-danger.bg-accent-1
{
    background-color: #f9e1e4 !important;
}
.bg-danger.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(249, 225, 228, .6);
            box-shadow: 0 1px 20px 1px rgba(249, 225, 228, .6);
}

.btn-danger.btn-accent-1
{
    border-color: #bc2534 !important;
    background-color: #f9e1e4 !important;
}
.btn-danger.btn-accent-1:hover
{
    border-color: #bc2534 !important;
    background-color: #a2202d !important;
}
.btn-danger.btn-accent-1:focus,
.btn-danger.btn-accent-1:active
{
    border-color: #a2202d !important;
    background-color: #891b26 !important;
}

.btn-outline-danger.btn-outline-accent-1
{
    color: #f9e1e4 !important; 
    border-color: #f9e1e4 !important;
}
.btn-outline-danger.btn-outline-accent-1:hover
{
    background-color: #f9e1e4 !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f9e1e4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f9e1e4 !important;
}

.border-danger.border-accent-1
{
    border: 1px solid #f9e1e4 !important;
}

.border-top-danger.border-top-accent-1
{
    border-top: 1px solid #f9e1e4 !important;
}

.border-bottom-danger.border-bottom-accent-1
{
    border-bottom: 1px solid #f9e1e4 !important;
}

.border-left-danger.border-left-accent-1
{
    border-left: 1px solid #f9e1e4 !important;
}

.border-right-danger.border-right-accent-1
{
    border-right: 1px solid #f9e1e4 !important;
}

.overlay-danger.overlay-accent-1
{
    background: #f9e1e4;
    /* The Fallback */
    background: rgba(249, 225, 228, .8);
}

.danger.accent-2
{
    color: #ea7983 !important;
}

.bg-danger.bg-accent-2
{
    background-color: #ea7983 !important;
}
.bg-danger.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(234, 121, 131, .6);
            box-shadow: 0 1px 20px 1px rgba(234, 121, 131, .6);
}

.btn-danger.btn-accent-2
{
    border-color: #bc2534 !important;
    background-color: #ea7983 !important;
}
.btn-danger.btn-accent-2:hover
{
    border-color: #bc2534 !important;
    background-color: #a2202d !important;
}
.btn-danger.btn-accent-2:focus,
.btn-danger.btn-accent-2:active
{
    border-color: #a2202d !important;
    background-color: #891b26 !important;
}

.btn-outline-danger.btn-outline-accent-2
{
    color: #ea7983 !important; 
    border-color: #ea7983 !important;
}
.btn-outline-danger.btn-outline-accent-2:hover
{
    background-color: #ea7983 !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ea7983 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ea7983 !important;
}

.border-danger.border-accent-2
{
    border: 1px solid #ea7983 !important;
}

.border-top-danger.border-top-accent-2
{
    border-top: 1px solid #ea7983 !important;
}

.border-bottom-danger.border-bottom-accent-2
{
    border-bottom: 1px solid #ea7983 !important;
}

.border-left-danger.border-left-accent-2
{
    border-left: 1px solid #ea7983 !important;
}

.border-right-danger.border-right-accent-2
{
    border-right: 1px solid #ea7983 !important;
}

.overlay-danger.overlay-accent-2
{
    background: #ea7983;
    /* The Fallback */
    background: rgba(234, 121, 131, .8);
}

.danger.accent-3
{
    color: #df5e6a !important;
}

.bg-danger.bg-accent-3
{
    background-color: #df5e6a !important;
}
.bg-danger.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(223, 94, 106, .6);
            box-shadow: 0 1px 20px 1px rgba(223, 94, 106, .6);
}

.btn-danger.btn-accent-3
{
    border-color: #bc2534 !important;
    background-color: #df5e6a !important;
}
.btn-danger.btn-accent-3:hover
{
    border-color: #bc2534 !important;
    background-color: #a2202d !important;
}
.btn-danger.btn-accent-3:focus,
.btn-danger.btn-accent-3:active
{
    border-color: #a2202d !important;
    background-color: #891b26 !important;
}

.btn-outline-danger.btn-outline-accent-3
{
    color: #df5e6a !important; 
    border-color: #df5e6a !important;
}
.btn-outline-danger.btn-outline-accent-3:hover
{
    background-color: #df5e6a !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #df5e6a !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #df5e6a !important;
}

.border-danger.border-accent-3
{
    border: 1px solid #df5e6a !important;
}

.border-top-danger.border-top-accent-3
{
    border-top: 1px solid #df5e6a !important;
}

.border-bottom-danger.border-bottom-accent-3
{
    border-bottom: 1px solid #df5e6a !important;
}

.border-left-danger.border-left-accent-3
{
    border-left: 1px solid #df5e6a !important;
}

.border-right-danger.border-right-accent-3
{
    border-right: 1px solid #df5e6a !important;
}

.overlay-danger.overlay-accent-3
{
    background: #df5e6a;
    /* The Fallback */
    background: rgba(223, 94, 106, .8);
}

.danger.accent-4
{
    color: #cc2f3e !important;
}

.bg-danger.bg-accent-4
{
    background-color: #cc2f3e !important;
}
.bg-danger.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(204, 47, 62, .6);
            box-shadow: 0 1px 20px 1px rgba(204, 47, 62, .6);
}

.btn-danger.btn-accent-4
{
    border-color: #bc2534 !important;
    background-color: #cc2f3e !important;
}
.btn-danger.btn-accent-4:hover
{
    border-color: #bc2534 !important;
    background-color: #a2202d !important;
}
.btn-danger.btn-accent-4:focus,
.btn-danger.btn-accent-4:active
{
    border-color: #a2202d !important;
    background-color: #891b26 !important;
}

.btn-outline-danger.btn-outline-accent-4
{
    color: #cc2f3e !important; 
    border-color: #cc2f3e !important;
}
.btn-outline-danger.btn-outline-accent-4:hover
{
    background-color: #cc2f3e !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #cc2f3e !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #cc2f3e !important;
}

.border-danger.border-accent-4
{
    border: 1px solid #cc2f3e !important;
}

.border-top-danger.border-top-accent-4
{
    border-top: 1px solid #cc2f3e !important;
}

.border-bottom-danger.border-bottom-accent-4
{
    border-bottom: 1px solid #cc2f3e !important;
}

.border-left-danger.border-left-accent-4
{
    border-left: 1px solid #cc2f3e !important;
}

.border-right-danger.border-right-accent-4
{
    border-right: 1px solid #cc2f3e !important;
}

.overlay-danger.overlay-accent-4
{
    background: #cc2f3e;
    /* The Fallback */
    background: rgba(204, 47, 62, .8);
}

.red.lighten-5
{
    color: #ffebee !important;
}

.bg-red.bg-lighten-5
{
    background-color: #ffebee !important;
}
.bg-red.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 235, 238, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 235, 238, .6);
}

.btn-red.btn-lighten-5
{
    border-color: #d32f2f !important;
    background-color: #ffebee !important;
}
.btn-red.btn-lighten-5:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-lighten-5:focus,
.btn-red.btn-lighten-5:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-lighten-5
{
    color: #ffebee !important; 
    border-color: #ffebee !important;
}
.btn-outline-red.btn-outline-lighten-5:hover
{
    background-color: #ffebee !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffebee !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffebee !important;
}

.border-red.border-lighten-5
{
    border: 1px solid #ffebee !important;
}

.border-top-red.border-top-lighten-5
{
    border-top: 1px solid #ffebee !important;
}

.border-bottom-red.border-bottom-lighten-5
{
    border-bottom: 1px solid #ffebee !important;
}

.border-left-red.border-left-lighten-5
{
    border-left: 1px solid #ffebee !important;
}

.border-right-red.border-right-lighten-5
{
    border-right: 1px solid #ffebee !important;
}

.overlay-red.overlay-lighten-5
{
    background: #ffebee;
    /* The Fallback */
    background: rgba(255, 235, 238, .8);
}

.red.lighten-4
{
    color: #ffcdd2 !important;
}

.bg-red.bg-lighten-4
{
    background-color: #ffcdd2 !important;
}
.bg-red.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 205, 210, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 205, 210, .6);
}

.btn-red.btn-lighten-4
{
    border-color: #d32f2f !important;
    background-color: #ffcdd2 !important;
}
.btn-red.btn-lighten-4:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-lighten-4:focus,
.btn-red.btn-lighten-4:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-lighten-4
{
    color: #ffcdd2 !important; 
    border-color: #ffcdd2 !important;
}
.btn-outline-red.btn-outline-lighten-4:hover
{
    background-color: #ffcdd2 !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffcdd2 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffcdd2 !important;
}

.border-red.border-lighten-4
{
    border: 1px solid #ffcdd2 !important;
}

.border-top-red.border-top-lighten-4
{
    border-top: 1px solid #ffcdd2 !important;
}

.border-bottom-red.border-bottom-lighten-4
{
    border-bottom: 1px solid #ffcdd2 !important;
}

.border-left-red.border-left-lighten-4
{
    border-left: 1px solid #ffcdd2 !important;
}

.border-right-red.border-right-lighten-4
{
    border-right: 1px solid #ffcdd2 !important;
}

.overlay-red.overlay-lighten-4
{
    background: #ffcdd2;
    /* The Fallback */
    background: rgba(255, 205, 210, .8);
}

.red.lighten-3
{
    color: #ef9a9a !important;
}

.bg-red.bg-lighten-3
{
    background-color: #ef9a9a !important;
}
.bg-red.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(239, 154, 154, .6);
            box-shadow: 0 1px 20px 1px rgba(239, 154, 154, .6);
}

.btn-red.btn-lighten-3
{
    border-color: #d32f2f !important;
    background-color: #ef9a9a !important;
}
.btn-red.btn-lighten-3:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-lighten-3:focus,
.btn-red.btn-lighten-3:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-lighten-3
{
    color: #ef9a9a !important; 
    border-color: #ef9a9a !important;
}
.btn-outline-red.btn-outline-lighten-3:hover
{
    background-color: #ef9a9a !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ef9a9a !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ef9a9a !important;
}

.border-red.border-lighten-3
{
    border: 1px solid #ef9a9a !important;
}

.border-top-red.border-top-lighten-3
{
    border-top: 1px solid #ef9a9a !important;
}

.border-bottom-red.border-bottom-lighten-3
{
    border-bottom: 1px solid #ef9a9a !important;
}

.border-left-red.border-left-lighten-3
{
    border-left: 1px solid #ef9a9a !important;
}

.border-right-red.border-right-lighten-3
{
    border-right: 1px solid #ef9a9a !important;
}

.overlay-red.overlay-lighten-3
{
    background: #ef9a9a;
    /* The Fallback */
    background: rgba(239, 154, 154, .8);
}

.red.lighten-2
{
    color: #e57373 !important;
}

.bg-red.bg-lighten-2
{
    background-color: #e57373 !important;
}
.bg-red.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(229, 115, 115, .6);
            box-shadow: 0 1px 20px 1px rgba(229, 115, 115, .6);
}

.btn-red.btn-lighten-2
{
    border-color: #d32f2f !important;
    background-color: #e57373 !important;
}
.btn-red.btn-lighten-2:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-lighten-2:focus,
.btn-red.btn-lighten-2:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-lighten-2
{
    color: #e57373 !important; 
    border-color: #e57373 !important;
}
.btn-outline-red.btn-outline-lighten-2:hover
{
    background-color: #e57373 !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e57373 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e57373 !important;
}

.border-red.border-lighten-2
{
    border: 1px solid #e57373 !important;
}

.border-top-red.border-top-lighten-2
{
    border-top: 1px solid #e57373 !important;
}

.border-bottom-red.border-bottom-lighten-2
{
    border-bottom: 1px solid #e57373 !important;
}

.border-left-red.border-left-lighten-2
{
    border-left: 1px solid #e57373 !important;
}

.border-right-red.border-right-lighten-2
{
    border-right: 1px solid #e57373 !important;
}

.overlay-red.overlay-lighten-2
{
    background: #e57373;
    /* The Fallback */
    background: rgba(229, 115, 115, .8);
}

.red.lighten-1
{
    color: #ef5350 !important;
}

.bg-red.bg-lighten-1
{
    background-color: #ef5350 !important;
}
.bg-red.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(239, 83, 80, .6);
            box-shadow: 0 1px 20px 1px rgba(239, 83, 80, .6);
}

.btn-red.btn-lighten-1
{
    border-color: #d32f2f !important;
    background-color: #ef5350 !important;
}
.btn-red.btn-lighten-1:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-lighten-1:focus,
.btn-red.btn-lighten-1:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-lighten-1
{
    color: #ef5350 !important; 
    border-color: #ef5350 !important;
}
.btn-outline-red.btn-outline-lighten-1:hover
{
    background-color: #ef5350 !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ef5350 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ef5350 !important;
}

.border-red.border-lighten-1
{
    border: 1px solid #ef5350 !important;
}

.border-top-red.border-top-lighten-1
{
    border-top: 1px solid #ef5350 !important;
}

.border-bottom-red.border-bottom-lighten-1
{
    border-bottom: 1px solid #ef5350 !important;
}

.border-left-red.border-left-lighten-1
{
    border-left: 1px solid #ef5350 !important;
}

.border-right-red.border-right-lighten-1
{
    border-right: 1px solid #ef5350 !important;
}

.overlay-red.overlay-lighten-1
{
    background: #ef5350;
    /* The Fallback */
    background: rgba(239, 83, 80, .8);
}

.red
{
    color: #f44336 !important;
}

.bg-red
{
    background-color: #f44336 !important;
}
.bg-red .card-header,
.bg-red .card-footer
{
    background-color: transparent;
}
.bg-red.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(244, 67, 54, .6);
            box-shadow: 0 1px 20px 1px rgba(244, 67, 54, .6);
}

.selectboxit.selectboxit-btn.bg-red
{
    background-color: #f44336 !important;
}

.alert-red
{
    color: #710e06 !important; 
    border-color: #f44336 !important;
    background-color: #f8827a !important;
}
.alert-red .alert-link
{
    color: #4b0904 !important;
}

.border-red
{
    border-color: #f44336;
}

.overlay-red
{
    background: #f44336;
    /* The Fallback */
    background: rgba(244, 67, 54, .8);
}

/* .card-outline-red{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-red
{
    color: #fff; 
    border-color: #f44336 !important;
    background-color: #f44336 !important;
}
.btn-red:hover
{
    color: #fff !important; 
    border-color: #d32f2f !important;
    background-color: #e53935 !important;
}
.btn-red:focus,
.btn-red:active
{
    color: #fff !important; 
    border-color: #c62828 !important;
    background-color: #d32f2f !important;
}
.btn-red.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(244, 67, 54, .6);
            box-shadow: 0 1px 20px 1px rgba(244, 67, 54, .6);
}

.btn-outline-red
{
    color: #f44336; 
    border-color: #f44336;
    background-color: transparent;
}
.btn-outline-red:hover
{
    color: #fff !important; 
    background-color: #f44336;
}
.btn-outline-red.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(244, 67, 54, .6);
            box-shadow: 0 1px 20px 1px rgba(244, 67, 54, .6);
}

input[type='checkbox'].bg-red + .custom-control-label:before,
input[type='radio'].bg-red + .custom-control-label:before
{
    background-color: #f44336 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f44336 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f44336 !important;
}

.border-red
{
    border: 1px solid #f44336 !important;
}

.border-top-red
{
    border-top: 1px solid #f44336;
}

.border-bottom-red
{
    border-bottom: 1px solid #f44336;
}

.border-left-red
{
    border-left: 1px solid #f44336;
}

.border-right-red
{
    border-right: 1px solid #f44336;
}

.red.darken-1
{
    color: #e53935 !important;
}

.bg-red.bg-darken-1
{
    background-color: #e53935 !important;
}
.bg-red.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(229, 57, 53, .6);
            box-shadow: 0 1px 20px 1px rgba(229, 57, 53, .6);
}

.btn-red.btn-darken-1
{
    border-color: #d32f2f !important;
    background-color: #e53935 !important;
}
.btn-red.btn-darken-1:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-darken-1:focus,
.btn-red.btn-darken-1:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-darken-1
{
    color: #e53935 !important; 
    border-color: #e53935 !important;
}
.btn-outline-red.btn-outline-darken-1:hover
{
    background-color: #e53935 !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e53935 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e53935 !important;
}

.border-red.border-darken-1
{
    border: 1px solid #e53935 !important;
}

.border-top-red.border-top-darken-1
{
    border-top: 1px solid #e53935 !important;
}

.border-bottom-red.border-bottom-darken-1
{
    border-bottom: 1px solid #e53935 !important;
}

.border-left-red.border-left-darken-1
{
    border-left: 1px solid #e53935 !important;
}

.border-right-red.border-right-darken-1
{
    border-right: 1px solid #e53935 !important;
}

.overlay-red.overlay-darken-1
{
    background: #e53935;
    /* The Fallback */
    background: rgba(229, 57, 53, .8);
}

.red.darken-2
{
    color: #d32f2f !important;
}

.bg-red.bg-darken-2
{
    background-color: #d32f2f !important;
}
.bg-red.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(211, 47, 47, .6);
            box-shadow: 0 1px 20px 1px rgba(211, 47, 47, .6);
}

.btn-red.btn-darken-2
{
    border-color: #d32f2f !important;
    background-color: #d32f2f !important;
}
.btn-red.btn-darken-2:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-darken-2:focus,
.btn-red.btn-darken-2:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-darken-2
{
    color: #d32f2f !important; 
    border-color: #d32f2f !important;
}
.btn-outline-red.btn-outline-darken-2:hover
{
    background-color: #d32f2f !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d32f2f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d32f2f !important;
}

.border-red.border-darken-2
{
    border: 1px solid #d32f2f !important;
}

.border-top-red.border-top-darken-2
{
    border-top: 1px solid #d32f2f !important;
}

.border-bottom-red.border-bottom-darken-2
{
    border-bottom: 1px solid #d32f2f !important;
}

.border-left-red.border-left-darken-2
{
    border-left: 1px solid #d32f2f !important;
}

.border-right-red.border-right-darken-2
{
    border-right: 1px solid #d32f2f !important;
}

.overlay-red.overlay-darken-2
{
    background: #d32f2f;
    /* The Fallback */
    background: rgba(211, 47, 47, .8);
}

.red.darken-3
{
    color: #c62828 !important;
}

.bg-red.bg-darken-3
{
    background-color: #c62828 !important;
}
.bg-red.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(198, 40, 40, .6);
            box-shadow: 0 1px 20px 1px rgba(198, 40, 40, .6);
}

.btn-red.btn-darken-3
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-darken-3:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-darken-3:focus,
.btn-red.btn-darken-3:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-darken-3
{
    color: #c62828 !important; 
    border-color: #c62828 !important;
}
.btn-outline-red.btn-outline-darken-3:hover
{
    background-color: #c62828 !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c62828 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c62828 !important;
}

.border-red.border-darken-3
{
    border: 1px solid #c62828 !important;
}

.border-top-red.border-top-darken-3
{
    border-top: 1px solid #c62828 !important;
}

.border-bottom-red.border-bottom-darken-3
{
    border-bottom: 1px solid #c62828 !important;
}

.border-left-red.border-left-darken-3
{
    border-left: 1px solid #c62828 !important;
}

.border-right-red.border-right-darken-3
{
    border-right: 1px solid #c62828 !important;
}

.overlay-red.overlay-darken-3
{
    background: #c62828;
    /* The Fallback */
    background: rgba(198, 40, 40, .8);
}

.red.darken-4
{
    color: #b71c1c !important;
}

.bg-red.bg-darken-4
{
    background-color: #b71c1c !important;
}
.bg-red.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(183, 28, 28, .6);
            box-shadow: 0 1px 20px 1px rgba(183, 28, 28, .6);
}

.btn-red.btn-darken-4
{
    border-color: #d32f2f !important;
    background-color: #b71c1c !important;
}
.btn-red.btn-darken-4:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-darken-4:focus,
.btn-red.btn-darken-4:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-darken-4
{
    color: #b71c1c !important; 
    border-color: #b71c1c !important;
}
.btn-outline-red.btn-outline-darken-4:hover
{
    background-color: #b71c1c !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b71c1c !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b71c1c !important;
}

.border-red.border-darken-4
{
    border: 1px solid #b71c1c !important;
}

.border-top-red.border-top-darken-4
{
    border-top: 1px solid #b71c1c !important;
}

.border-bottom-red.border-bottom-darken-4
{
    border-bottom: 1px solid #b71c1c !important;
}

.border-left-red.border-left-darken-4
{
    border-left: 1px solid #b71c1c !important;
}

.border-right-red.border-right-darken-4
{
    border-right: 1px solid #b71c1c !important;
}

.overlay-red.overlay-darken-4
{
    background: #b71c1c;
    /* The Fallback */
    background: rgba(183, 28, 28, .8);
}

.red.accent-1
{
    color: #ff8a80 !important;
}

.bg-red.bg-accent-1
{
    background-color: #ff8a80 !important;
}
.bg-red.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 138, 128, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 138, 128, .6);
}

.btn-red.btn-accent-1
{
    border-color: #d32f2f !important;
    background-color: #ff8a80 !important;
}
.btn-red.btn-accent-1:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-accent-1:focus,
.btn-red.btn-accent-1:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-accent-1
{
    color: #ff8a80 !important; 
    border-color: #ff8a80 !important;
}
.btn-outline-red.btn-outline-accent-1:hover
{
    background-color: #ff8a80 !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff8a80 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff8a80 !important;
}

.border-red.border-accent-1
{
    border: 1px solid #ff8a80 !important;
}

.border-top-red.border-top-accent-1
{
    border-top: 1px solid #ff8a80 !important;
}

.border-bottom-red.border-bottom-accent-1
{
    border-bottom: 1px solid #ff8a80 !important;
}

.border-left-red.border-left-accent-1
{
    border-left: 1px solid #ff8a80 !important;
}

.border-right-red.border-right-accent-1
{
    border-right: 1px solid #ff8a80 !important;
}

.overlay-red.overlay-accent-1
{
    background: #ff8a80;
    /* The Fallback */
    background: rgba(255, 138, 128, .8);
}

.red.accent-2
{
    color: #ff5252 !important;
}

.bg-red.bg-accent-2
{
    background-color: #ff5252 !important;
}
.bg-red.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 82, 82, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 82, 82, .6);
}

.btn-red.btn-accent-2
{
    border-color: #d32f2f !important;
    background-color: #ff5252 !important;
}
.btn-red.btn-accent-2:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-accent-2:focus,
.btn-red.btn-accent-2:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-accent-2
{
    color: #ff5252 !important; 
    border-color: #ff5252 !important;
}
.btn-outline-red.btn-outline-accent-2:hover
{
    background-color: #ff5252 !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff5252 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff5252 !important;
}

.border-red.border-accent-2
{
    border: 1px solid #ff5252 !important;
}

.border-top-red.border-top-accent-2
{
    border-top: 1px solid #ff5252 !important;
}

.border-bottom-red.border-bottom-accent-2
{
    border-bottom: 1px solid #ff5252 !important;
}

.border-left-red.border-left-accent-2
{
    border-left: 1px solid #ff5252 !important;
}

.border-right-red.border-right-accent-2
{
    border-right: 1px solid #ff5252 !important;
}

.overlay-red.overlay-accent-2
{
    background: #ff5252;
    /* The Fallback */
    background: rgba(255, 82, 82, .8);
}

.red.accent-3
{
    color: #ff1744 !important;
}

.bg-red.bg-accent-3
{
    background-color: #ff1744 !important;
}
.bg-red.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 23, 68, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 23, 68, .6);
}

.btn-red.btn-accent-3
{
    border-color: #d32f2f !important;
    background-color: #ff1744 !important;
}
.btn-red.btn-accent-3:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-accent-3:focus,
.btn-red.btn-accent-3:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-accent-3
{
    color: #ff1744 !important; 
    border-color: #ff1744 !important;
}
.btn-outline-red.btn-outline-accent-3:hover
{
    background-color: #ff1744 !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff1744 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff1744 !important;
}

.border-red.border-accent-3
{
    border: 1px solid #ff1744 !important;
}

.border-top-red.border-top-accent-3
{
    border-top: 1px solid #ff1744 !important;
}

.border-bottom-red.border-bottom-accent-3
{
    border-bottom: 1px solid #ff1744 !important;
}

.border-left-red.border-left-accent-3
{
    border-left: 1px solid #ff1744 !important;
}

.border-right-red.border-right-accent-3
{
    border-right: 1px solid #ff1744 !important;
}

.overlay-red.overlay-accent-3
{
    background: #ff1744;
    /* The Fallback */
    background: rgba(255, 23, 68, .8);
}

.red.accent-4
{
    color: #d50000 !important;
}

.bg-red.bg-accent-4
{
    background-color: #d50000 !important;
}
.bg-red.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(213, 0, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(213, 0, 0, .6);
}

.btn-red.btn-accent-4
{
    border-color: #d32f2f !important;
    background-color: #d50000 !important;
}
.btn-red.btn-accent-4:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-accent-4:focus,
.btn-red.btn-accent-4:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-accent-4
{
    color: #d50000 !important; 
    border-color: #d50000 !important;
}
.btn-outline-red.btn-outline-accent-4:hover
{
    background-color: #d50000 !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d50000 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d50000 !important;
}

.border-red.border-accent-4
{
    border: 1px solid #d50000 !important;
}

.border-top-red.border-top-accent-4
{
    border-top: 1px solid #d50000 !important;
}

.border-bottom-red.border-bottom-accent-4
{
    border-bottom: 1px solid #d50000 !important;
}

.border-left-red.border-left-accent-4
{
    border-left: 1px solid #d50000 !important;
}

.border-right-red.border-right-accent-4
{
    border-right: 1px solid #d50000 !important;
}

.overlay-red.overlay-accent-4
{
    background: #d50000;
    /* The Fallback */
    background: rgba(213, 0, 0, .8);
}

.pink.lighten-5
{
    color: #fce4ec !important;
}

.bg-pink.bg-lighten-5
{
    background-color: #fce4ec !important;
}
.bg-pink.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(252, 228, 236, .6);
            box-shadow: 0 1px 20px 1px rgba(252, 228, 236, .6);
}

.btn-pink.btn-lighten-5
{
    border-color: #c2185b !important;
    background-color: #fce4ec !important;
}
.btn-pink.btn-lighten-5:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-lighten-5:focus,
.btn-pink.btn-lighten-5:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-lighten-5
{
    color: #fce4ec !important; 
    border-color: #fce4ec !important;
}
.btn-outline-pink.btn-outline-lighten-5:hover
{
    background-color: #fce4ec !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fce4ec !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fce4ec !important;
}

.border-pink.border-lighten-5
{
    border: 1px solid #fce4ec !important;
}

.border-top-pink.border-top-lighten-5
{
    border-top: 1px solid #fce4ec !important;
}

.border-bottom-pink.border-bottom-lighten-5
{
    border-bottom: 1px solid #fce4ec !important;
}

.border-left-pink.border-left-lighten-5
{
    border-left: 1px solid #fce4ec !important;
}

.border-right-pink.border-right-lighten-5
{
    border-right: 1px solid #fce4ec !important;
}

.overlay-pink.overlay-lighten-5
{
    background: #fce4ec;
    /* The Fallback */
    background: rgba(252, 228, 236, .8);
}

.pink.lighten-4
{
    color: #f8bbd0 !important;
}

.bg-pink.bg-lighten-4
{
    background-color: #f8bbd0 !important;
}
.bg-pink.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(248, 187, 208, .6);
            box-shadow: 0 1px 20px 1px rgba(248, 187, 208, .6);
}

.btn-pink.btn-lighten-4
{
    border-color: #c2185b !important;
    background-color: #f8bbd0 !important;
}
.btn-pink.btn-lighten-4:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-lighten-4:focus,
.btn-pink.btn-lighten-4:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-lighten-4
{
    color: #f8bbd0 !important; 
    border-color: #f8bbd0 !important;
}
.btn-outline-pink.btn-outline-lighten-4:hover
{
    background-color: #f8bbd0 !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f8bbd0 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f8bbd0 !important;
}

.border-pink.border-lighten-4
{
    border: 1px solid #f8bbd0 !important;
}

.border-top-pink.border-top-lighten-4
{
    border-top: 1px solid #f8bbd0 !important;
}

.border-bottom-pink.border-bottom-lighten-4
{
    border-bottom: 1px solid #f8bbd0 !important;
}

.border-left-pink.border-left-lighten-4
{
    border-left: 1px solid #f8bbd0 !important;
}

.border-right-pink.border-right-lighten-4
{
    border-right: 1px solid #f8bbd0 !important;
}

.overlay-pink.overlay-lighten-4
{
    background: #f8bbd0;
    /* The Fallback */
    background: rgba(248, 187, 208, .8);
}

.pink.lighten-3
{
    color: #f48fb1 !important;
}

.bg-pink.bg-lighten-3
{
    background-color: #f48fb1 !important;
}
.bg-pink.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(244, 143, 177, .6);
            box-shadow: 0 1px 20px 1px rgba(244, 143, 177, .6);
}

.btn-pink.btn-lighten-3
{
    border-color: #c2185b !important;
    background-color: #f48fb1 !important;
}
.btn-pink.btn-lighten-3:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-lighten-3:focus,
.btn-pink.btn-lighten-3:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-lighten-3
{
    color: #f48fb1 !important; 
    border-color: #f48fb1 !important;
}
.btn-outline-pink.btn-outline-lighten-3:hover
{
    background-color: #f48fb1 !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f48fb1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f48fb1 !important;
}

.border-pink.border-lighten-3
{
    border: 1px solid #f48fb1 !important;
}

.border-top-pink.border-top-lighten-3
{
    border-top: 1px solid #f48fb1 !important;
}

.border-bottom-pink.border-bottom-lighten-3
{
    border-bottom: 1px solid #f48fb1 !important;
}

.border-left-pink.border-left-lighten-3
{
    border-left: 1px solid #f48fb1 !important;
}

.border-right-pink.border-right-lighten-3
{
    border-right: 1px solid #f48fb1 !important;
}

.overlay-pink.overlay-lighten-3
{
    background: #f48fb1;
    /* The Fallback */
    background: rgba(244, 143, 177, .8);
}

.pink.lighten-2
{
    color: #f06292 !important;
}

.bg-pink.bg-lighten-2
{
    background-color: #f06292 !important;
}
.bg-pink.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(240, 98, 146, .6);
            box-shadow: 0 1px 20px 1px rgba(240, 98, 146, .6);
}

.btn-pink.btn-lighten-2
{
    border-color: #c2185b !important;
    background-color: #f06292 !important;
}
.btn-pink.btn-lighten-2:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-lighten-2:focus,
.btn-pink.btn-lighten-2:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-lighten-2
{
    color: #f06292 !important; 
    border-color: #f06292 !important;
}
.btn-outline-pink.btn-outline-lighten-2:hover
{
    background-color: #f06292 !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f06292 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f06292 !important;
}

.border-pink.border-lighten-2
{
    border: 1px solid #f06292 !important;
}

.border-top-pink.border-top-lighten-2
{
    border-top: 1px solid #f06292 !important;
}

.border-bottom-pink.border-bottom-lighten-2
{
    border-bottom: 1px solid #f06292 !important;
}

.border-left-pink.border-left-lighten-2
{
    border-left: 1px solid #f06292 !important;
}

.border-right-pink.border-right-lighten-2
{
    border-right: 1px solid #f06292 !important;
}

.overlay-pink.overlay-lighten-2
{
    background: #f06292;
    /* The Fallback */
    background: rgba(240, 98, 146, .8);
}

.pink.lighten-1
{
    color: #ec407a !important;
}

.bg-pink.bg-lighten-1
{
    background-color: #ec407a !important;
}
.bg-pink.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(236, 64, 122, .6);
            box-shadow: 0 1px 20px 1px rgba(236, 64, 122, .6);
}

.btn-pink.btn-lighten-1
{
    border-color: #c2185b !important;
    background-color: #ec407a !important;
}
.btn-pink.btn-lighten-1:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-lighten-1:focus,
.btn-pink.btn-lighten-1:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-lighten-1
{
    color: #ec407a !important; 
    border-color: #ec407a !important;
}
.btn-outline-pink.btn-outline-lighten-1:hover
{
    background-color: #ec407a !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ec407a !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ec407a !important;
}

.border-pink.border-lighten-1
{
    border: 1px solid #ec407a !important;
}

.border-top-pink.border-top-lighten-1
{
    border-top: 1px solid #ec407a !important;
}

.border-bottom-pink.border-bottom-lighten-1
{
    border-bottom: 1px solid #ec407a !important;
}

.border-left-pink.border-left-lighten-1
{
    border-left: 1px solid #ec407a !important;
}

.border-right-pink.border-right-lighten-1
{
    border-right: 1px solid #ec407a !important;
}

.overlay-pink.overlay-lighten-1
{
    background: #ec407a;
    /* The Fallback */
    background: rgba(236, 64, 122, .8);
}

.pink
{
    color: #e91e63 !important;
}

.bg-pink
{
    background-color: #e91e63 !important;
}
.bg-pink .card-header,
.bg-pink .card-footer
{
    background-color: transparent;
}
.bg-pink.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(233, 30, 99, .6);
            box-shadow: 0 1px 20px 1px rgba(233, 30, 99, .6);
}

.selectboxit.selectboxit-btn.bg-pink
{
    background-color: #e91e63 !important;
}

.alert-pink
{
    color: #4d081f !important; 
    border-color: #e91e63 !important;
    background-color: #ef5f90 !important;
}
.alert-pink .alert-link
{
    color: #280410 !important;
}

.border-pink
{
    border-color: #e91e63;
}

.overlay-pink
{
    background: #e91e63;
    /* The Fallback */
    background: rgba(233, 30, 99, .8);
}

/* .card-outline-pink{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-pink
{
    color: #fff; 
    border-color: #e91e63 !important;
    background-color: #e91e63 !important;
}
.btn-pink:hover
{
    color: #fff !important; 
    border-color: #c2185b !important;
    background-color: #d81b60 !important;
}
.btn-pink:focus,
.btn-pink:active
{
    color: #fff !important; 
    border-color: #ad1457 !important;
    background-color: #c2185b !important;
}
.btn-pink.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(233, 30, 99, .6);
            box-shadow: 0 1px 20px 1px rgba(233, 30, 99, .6);
}

.btn-outline-pink
{
    color: #e91e63; 
    border-color: #e91e63;
    background-color: transparent;
}
.btn-outline-pink:hover
{
    color: #fff !important; 
    background-color: #e91e63;
}
.btn-outline-pink.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(233, 30, 99, .6);
            box-shadow: 0 1px 20px 1px rgba(233, 30, 99, .6);
}

input[type='checkbox'].bg-pink + .custom-control-label:before,
input[type='radio'].bg-pink + .custom-control-label:before
{
    background-color: #e91e63 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e91e63 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e91e63 !important;
}

.border-pink
{
    border: 1px solid #e91e63 !important;
}

.border-top-pink
{
    border-top: 1px solid #e91e63;
}

.border-bottom-pink
{
    border-bottom: 1px solid #e91e63;
}

.border-left-pink
{
    border-left: 1px solid #e91e63;
}

.border-right-pink
{
    border-right: 1px solid #e91e63;
}

.pink.darken-1
{
    color: #d81b60 !important;
}

.bg-pink.bg-darken-1
{
    background-color: #d81b60 !important;
}
.bg-pink.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(216, 27, 96, .6);
            box-shadow: 0 1px 20px 1px rgba(216, 27, 96, .6);
}

.btn-pink.btn-darken-1
{
    border-color: #c2185b !important;
    background-color: #d81b60 !important;
}
.btn-pink.btn-darken-1:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-darken-1:focus,
.btn-pink.btn-darken-1:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-darken-1
{
    color: #d81b60 !important; 
    border-color: #d81b60 !important;
}
.btn-outline-pink.btn-outline-darken-1:hover
{
    background-color: #d81b60 !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d81b60 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d81b60 !important;
}

.border-pink.border-darken-1
{
    border: 1px solid #d81b60 !important;
}

.border-top-pink.border-top-darken-1
{
    border-top: 1px solid #d81b60 !important;
}

.border-bottom-pink.border-bottom-darken-1
{
    border-bottom: 1px solid #d81b60 !important;
}

.border-left-pink.border-left-darken-1
{
    border-left: 1px solid #d81b60 !important;
}

.border-right-pink.border-right-darken-1
{
    border-right: 1px solid #d81b60 !important;
}

.overlay-pink.overlay-darken-1
{
    background: #d81b60;
    /* The Fallback */
    background: rgba(216, 27, 96, .8);
}

.pink.darken-2
{
    color: #c2185b !important;
}

.bg-pink.bg-darken-2
{
    background-color: #c2185b !important;
}
.bg-pink.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(194, 24, 91, .6);
            box-shadow: 0 1px 20px 1px rgba(194, 24, 91, .6);
}

.btn-pink.btn-darken-2
{
    border-color: #c2185b !important;
    background-color: #c2185b !important;
}
.btn-pink.btn-darken-2:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-darken-2:focus,
.btn-pink.btn-darken-2:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-darken-2
{
    color: #c2185b !important; 
    border-color: #c2185b !important;
}
.btn-outline-pink.btn-outline-darken-2:hover
{
    background-color: #c2185b !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c2185b !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c2185b !important;
}

.border-pink.border-darken-2
{
    border: 1px solid #c2185b !important;
}

.border-top-pink.border-top-darken-2
{
    border-top: 1px solid #c2185b !important;
}

.border-bottom-pink.border-bottom-darken-2
{
    border-bottom: 1px solid #c2185b !important;
}

.border-left-pink.border-left-darken-2
{
    border-left: 1px solid #c2185b !important;
}

.border-right-pink.border-right-darken-2
{
    border-right: 1px solid #c2185b !important;
}

.overlay-pink.overlay-darken-2
{
    background: #c2185b;
    /* The Fallback */
    background: rgba(194, 24, 91, .8);
}

.pink.darken-3
{
    color: #ad1457 !important;
}

.bg-pink.bg-darken-3
{
    background-color: #ad1457 !important;
}
.bg-pink.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(173, 20, 87, .6);
            box-shadow: 0 1px 20px 1px rgba(173, 20, 87, .6);
}

.btn-pink.btn-darken-3
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-darken-3:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-darken-3:focus,
.btn-pink.btn-darken-3:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-darken-3
{
    color: #ad1457 !important; 
    border-color: #ad1457 !important;
}
.btn-outline-pink.btn-outline-darken-3:hover
{
    background-color: #ad1457 !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ad1457 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ad1457 !important;
}

.border-pink.border-darken-3
{
    border: 1px solid #ad1457 !important;
}

.border-top-pink.border-top-darken-3
{
    border-top: 1px solid #ad1457 !important;
}

.border-bottom-pink.border-bottom-darken-3
{
    border-bottom: 1px solid #ad1457 !important;
}

.border-left-pink.border-left-darken-3
{
    border-left: 1px solid #ad1457 !important;
}

.border-right-pink.border-right-darken-3
{
    border-right: 1px solid #ad1457 !important;
}

.overlay-pink.overlay-darken-3
{
    background: #ad1457;
    /* The Fallback */
    background: rgba(173, 20, 87, .8);
}

.pink.darken-4
{
    color: #880e4f !important;
}

.bg-pink.bg-darken-4
{
    background-color: #880e4f !important;
}
.bg-pink.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(136, 14, 79, .6);
            box-shadow: 0 1px 20px 1px rgba(136, 14, 79, .6);
}

.btn-pink.btn-darken-4
{
    border-color: #c2185b !important;
    background-color: #880e4f !important;
}
.btn-pink.btn-darken-4:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-darken-4:focus,
.btn-pink.btn-darken-4:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-darken-4
{
    color: #880e4f !important; 
    border-color: #880e4f !important;
}
.btn-outline-pink.btn-outline-darken-4:hover
{
    background-color: #880e4f !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #880e4f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #880e4f !important;
}

.border-pink.border-darken-4
{
    border: 1px solid #880e4f !important;
}

.border-top-pink.border-top-darken-4
{
    border-top: 1px solid #880e4f !important;
}

.border-bottom-pink.border-bottom-darken-4
{
    border-bottom: 1px solid #880e4f !important;
}

.border-left-pink.border-left-darken-4
{
    border-left: 1px solid #880e4f !important;
}

.border-right-pink.border-right-darken-4
{
    border-right: 1px solid #880e4f !important;
}

.overlay-pink.overlay-darken-4
{
    background: #880e4f;
    /* The Fallback */
    background: rgba(136, 14, 79, .8);
}

.pink.accent-1
{
    color: #ff80ab !important;
}

.bg-pink.bg-accent-1
{
    background-color: #ff80ab !important;
}
.bg-pink.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 128, 171, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 128, 171, .6);
}

.btn-pink.btn-accent-1
{
    border-color: #c2185b !important;
    background-color: #ff80ab !important;
}
.btn-pink.btn-accent-1:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-accent-1:focus,
.btn-pink.btn-accent-1:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-accent-1
{
    color: #ff80ab !important; 
    border-color: #ff80ab !important;
}
.btn-outline-pink.btn-outline-accent-1:hover
{
    background-color: #ff80ab !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff80ab !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff80ab !important;
}

.border-pink.border-accent-1
{
    border: 1px solid #ff80ab !important;
}

.border-top-pink.border-top-accent-1
{
    border-top: 1px solid #ff80ab !important;
}

.border-bottom-pink.border-bottom-accent-1
{
    border-bottom: 1px solid #ff80ab !important;
}

.border-left-pink.border-left-accent-1
{
    border-left: 1px solid #ff80ab !important;
}

.border-right-pink.border-right-accent-1
{
    border-right: 1px solid #ff80ab !important;
}

.overlay-pink.overlay-accent-1
{
    background: #ff80ab;
    /* The Fallback */
    background: rgba(255, 128, 171, .8);
}

.pink.accent-2
{
    color: #ff4081 !important;
}

.bg-pink.bg-accent-2
{
    background-color: #ff4081 !important;
}
.bg-pink.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 64, 129, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 64, 129, .6);
}

.btn-pink.btn-accent-2
{
    border-color: #c2185b !important;
    background-color: #ff4081 !important;
}
.btn-pink.btn-accent-2:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-accent-2:focus,
.btn-pink.btn-accent-2:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-accent-2
{
    color: #ff4081 !important; 
    border-color: #ff4081 !important;
}
.btn-outline-pink.btn-outline-accent-2:hover
{
    background-color: #ff4081 !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff4081 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff4081 !important;
}

.border-pink.border-accent-2
{
    border: 1px solid #ff4081 !important;
}

.border-top-pink.border-top-accent-2
{
    border-top: 1px solid #ff4081 !important;
}

.border-bottom-pink.border-bottom-accent-2
{
    border-bottom: 1px solid #ff4081 !important;
}

.border-left-pink.border-left-accent-2
{
    border-left: 1px solid #ff4081 !important;
}

.border-right-pink.border-right-accent-2
{
    border-right: 1px solid #ff4081 !important;
}

.overlay-pink.overlay-accent-2
{
    background: #ff4081;
    /* The Fallback */
    background: rgba(255, 64, 129, .8);
}

.pink.accent-3
{
    color: #f50057 !important;
}

.bg-pink.bg-accent-3
{
    background-color: #f50057 !important;
}
.bg-pink.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(245, 0, 87, .6);
            box-shadow: 0 1px 20px 1px rgba(245, 0, 87, .6);
}

.btn-pink.btn-accent-3
{
    border-color: #c2185b !important;
    background-color: #f50057 !important;
}
.btn-pink.btn-accent-3:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-accent-3:focus,
.btn-pink.btn-accent-3:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-accent-3
{
    color: #f50057 !important; 
    border-color: #f50057 !important;
}
.btn-outline-pink.btn-outline-accent-3:hover
{
    background-color: #f50057 !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f50057 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f50057 !important;
}

.border-pink.border-accent-3
{
    border: 1px solid #f50057 !important;
}

.border-top-pink.border-top-accent-3
{
    border-top: 1px solid #f50057 !important;
}

.border-bottom-pink.border-bottom-accent-3
{
    border-bottom: 1px solid #f50057 !important;
}

.border-left-pink.border-left-accent-3
{
    border-left: 1px solid #f50057 !important;
}

.border-right-pink.border-right-accent-3
{
    border-right: 1px solid #f50057 !important;
}

.overlay-pink.overlay-accent-3
{
    background: #f50057;
    /* The Fallback */
    background: rgba(245, 0, 87, .8);
}

.pink.accent-4
{
    color: #c51162 !important;
}

.bg-pink.bg-accent-4
{
    background-color: #c51162 !important;
}
.bg-pink.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(197, 17, 98, .6);
            box-shadow: 0 1px 20px 1px rgba(197, 17, 98, .6);
}

.btn-pink.btn-accent-4
{
    border-color: #c2185b !important;
    background-color: #c51162 !important;
}
.btn-pink.btn-accent-4:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-accent-4:focus,
.btn-pink.btn-accent-4:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-accent-4
{
    color: #c51162 !important; 
    border-color: #c51162 !important;
}
.btn-outline-pink.btn-outline-accent-4:hover
{
    background-color: #c51162 !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c51162 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c51162 !important;
}

.border-pink.border-accent-4
{
    border: 1px solid #c51162 !important;
}

.border-top-pink.border-top-accent-4
{
    border-top: 1px solid #c51162 !important;
}

.border-bottom-pink.border-bottom-accent-4
{
    border-bottom: 1px solid #c51162 !important;
}

.border-left-pink.border-left-accent-4
{
    border-left: 1px solid #c51162 !important;
}

.border-right-pink.border-right-accent-4
{
    border-right: 1px solid #c51162 !important;
}

.overlay-pink.overlay-accent-4
{
    background: #c51162;
    /* The Fallback */
    background: rgba(197, 17, 98, .8);
}

.purple.lighten-5
{
    color: #f3e5f5 !important;
}

.bg-purple.bg-lighten-5
{
    background-color: #f3e5f5 !important;
}
.bg-purple.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(243, 229, 245, .6);
            box-shadow: 0 1px 20px 1px rgba(243, 229, 245, .6);
}

.btn-purple.btn-lighten-5
{
    border-color: #7b1fa2 !important;
    background-color: #f3e5f5 !important;
}
.btn-purple.btn-lighten-5:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-lighten-5:focus,
.btn-purple.btn-lighten-5:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-lighten-5
{
    color: #f3e5f5 !important; 
    border-color: #f3e5f5 !important;
}
.btn-outline-purple.btn-outline-lighten-5:hover
{
    background-color: #f3e5f5 !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f3e5f5 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f3e5f5 !important;
}

.border-purple.border-lighten-5
{
    border: 1px solid #f3e5f5 !important;
}

.border-top-purple.border-top-lighten-5
{
    border-top: 1px solid #f3e5f5 !important;
}

.border-bottom-purple.border-bottom-lighten-5
{
    border-bottom: 1px solid #f3e5f5 !important;
}

.border-left-purple.border-left-lighten-5
{
    border-left: 1px solid #f3e5f5 !important;
}

.border-right-purple.border-right-lighten-5
{
    border-right: 1px solid #f3e5f5 !important;
}

.overlay-purple.overlay-lighten-5
{
    background: #f3e5f5;
    /* The Fallback */
    background: rgba(243, 229, 245, .8);
}

.purple.lighten-4
{
    color: #e1bee7 !important;
}

.bg-purple.bg-lighten-4
{
    background-color: #e1bee7 !important;
}
.bg-purple.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(225, 190, 231, .6);
            box-shadow: 0 1px 20px 1px rgba(225, 190, 231, .6);
}

.btn-purple.btn-lighten-4
{
    border-color: #7b1fa2 !important;
    background-color: #e1bee7 !important;
}
.btn-purple.btn-lighten-4:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-lighten-4:focus,
.btn-purple.btn-lighten-4:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-lighten-4
{
    color: #e1bee7 !important; 
    border-color: #e1bee7 !important;
}
.btn-outline-purple.btn-outline-lighten-4:hover
{
    background-color: #e1bee7 !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e1bee7 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e1bee7 !important;
}

.border-purple.border-lighten-4
{
    border: 1px solid #e1bee7 !important;
}

.border-top-purple.border-top-lighten-4
{
    border-top: 1px solid #e1bee7 !important;
}

.border-bottom-purple.border-bottom-lighten-4
{
    border-bottom: 1px solid #e1bee7 !important;
}

.border-left-purple.border-left-lighten-4
{
    border-left: 1px solid #e1bee7 !important;
}

.border-right-purple.border-right-lighten-4
{
    border-right: 1px solid #e1bee7 !important;
}

.overlay-purple.overlay-lighten-4
{
    background: #e1bee7;
    /* The Fallback */
    background: rgba(225, 190, 231, .8);
}

.purple.lighten-3
{
    color: #ce93d8 !important;
}

.bg-purple.bg-lighten-3
{
    background-color: #ce93d8 !important;
}
.bg-purple.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(206, 147, 216, .6);
            box-shadow: 0 1px 20px 1px rgba(206, 147, 216, .6);
}

.btn-purple.btn-lighten-3
{
    border-color: #7b1fa2 !important;
    background-color: #ce93d8 !important;
}
.btn-purple.btn-lighten-3:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-lighten-3:focus,
.btn-purple.btn-lighten-3:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-lighten-3
{
    color: #ce93d8 !important; 
    border-color: #ce93d8 !important;
}
.btn-outline-purple.btn-outline-lighten-3:hover
{
    background-color: #ce93d8 !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ce93d8 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ce93d8 !important;
}

.border-purple.border-lighten-3
{
    border: 1px solid #ce93d8 !important;
}

.border-top-purple.border-top-lighten-3
{
    border-top: 1px solid #ce93d8 !important;
}

.border-bottom-purple.border-bottom-lighten-3
{
    border-bottom: 1px solid #ce93d8 !important;
}

.border-left-purple.border-left-lighten-3
{
    border-left: 1px solid #ce93d8 !important;
}

.border-right-purple.border-right-lighten-3
{
    border-right: 1px solid #ce93d8 !important;
}

.overlay-purple.overlay-lighten-3
{
    background: #ce93d8;
    /* The Fallback */
    background: rgba(206, 147, 216, .8);
}

.purple.lighten-2
{
    color: #ba68c8 !important;
}

.bg-purple.bg-lighten-2
{
    background-color: #ba68c8 !important;
}
.bg-purple.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(186, 104, 200, .6);
            box-shadow: 0 1px 20px 1px rgba(186, 104, 200, .6);
}

.btn-purple.btn-lighten-2
{
    border-color: #7b1fa2 !important;
    background-color: #ba68c8 !important;
}
.btn-purple.btn-lighten-2:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-lighten-2:focus,
.btn-purple.btn-lighten-2:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-lighten-2
{
    color: #ba68c8 !important; 
    border-color: #ba68c8 !important;
}
.btn-outline-purple.btn-outline-lighten-2:hover
{
    background-color: #ba68c8 !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ba68c8 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ba68c8 !important;
}

.border-purple.border-lighten-2
{
    border: 1px solid #ba68c8 !important;
}

.border-top-purple.border-top-lighten-2
{
    border-top: 1px solid #ba68c8 !important;
}

.border-bottom-purple.border-bottom-lighten-2
{
    border-bottom: 1px solid #ba68c8 !important;
}

.border-left-purple.border-left-lighten-2
{
    border-left: 1px solid #ba68c8 !important;
}

.border-right-purple.border-right-lighten-2
{
    border-right: 1px solid #ba68c8 !important;
}

.overlay-purple.overlay-lighten-2
{
    background: #ba68c8;
    /* The Fallback */
    background: rgba(186, 104, 200, .8);
}

.purple.lighten-1
{
    color: #ab47bc !important;
}

.bg-purple.bg-lighten-1
{
    background-color: #ab47bc !important;
}
.bg-purple.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(171, 71, 188, .6);
            box-shadow: 0 1px 20px 1px rgba(171, 71, 188, .6);
}

.btn-purple.btn-lighten-1
{
    border-color: #7b1fa2 !important;
    background-color: #ab47bc !important;
}
.btn-purple.btn-lighten-1:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-lighten-1:focus,
.btn-purple.btn-lighten-1:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-lighten-1
{
    color: #ab47bc !important; 
    border-color: #ab47bc !important;
}
.btn-outline-purple.btn-outline-lighten-1:hover
{
    background-color: #ab47bc !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ab47bc !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ab47bc !important;
}

.border-purple.border-lighten-1
{
    border: 1px solid #ab47bc !important;
}

.border-top-purple.border-top-lighten-1
{
    border-top: 1px solid #ab47bc !important;
}

.border-bottom-purple.border-bottom-lighten-1
{
    border-bottom: 1px solid #ab47bc !important;
}

.border-left-purple.border-left-lighten-1
{
    border-left: 1px solid #ab47bc !important;
}

.border-right-purple.border-right-lighten-1
{
    border-right: 1px solid #ab47bc !important;
}

.overlay-purple.overlay-lighten-1
{
    background: #ab47bc;
    /* The Fallback */
    background: rgba(171, 71, 188, .8);
}

.purple
{
    color: #9c27b0 !important;
}

.bg-purple
{
    background-color: #9c27b0 !important;
}
.bg-purple .card-header,
.bg-purple .card-footer
{
    background-color: transparent;
}
.bg-purple.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(156, 39, 176, .6);
            box-shadow: 0 1px 20px 1px rgba(156, 39, 176, .6);
}

.selectboxit.selectboxit-btn.bg-purple
{
    background-color: #9c27b0 !important;
}

.alert-purple
{
    color: #1a071e !important; 
    border-color: #9c27b0 !important;
    background-color: #c248d6 !important;
}
.alert-purple .alert-link
{
    color: black !important;
}

.border-purple
{
    border-color: #9c27b0;
}

.overlay-purple
{
    background: #9c27b0;
    /* The Fallback */
    background: rgba(156, 39, 176, .8);
}

/* .card-outline-purple{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-purple
{
    color: #fff; 
    border-color: #9c27b0 !important;
    background-color: #9c27b0 !important;
}
.btn-purple:hover
{
    color: #fff !important; 
    border-color: #7b1fa2 !important;
    background-color: #8e24aa !important;
}
.btn-purple:focus,
.btn-purple:active
{
    color: #fff !important; 
    border-color: #6a1b9a !important;
    background-color: #7b1fa2 !important;
}
.btn-purple.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(156, 39, 176, .6);
            box-shadow: 0 1px 20px 1px rgba(156, 39, 176, .6);
}

.btn-outline-purple
{
    color: #9c27b0; 
    border-color: #9c27b0;
    background-color: transparent;
}
.btn-outline-purple:hover
{
    color: #fff !important; 
    background-color: #9c27b0;
}
.btn-outline-purple.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(156, 39, 176, .6);
            box-shadow: 0 1px 20px 1px rgba(156, 39, 176, .6);
}

input[type='checkbox'].bg-purple + .custom-control-label:before,
input[type='radio'].bg-purple + .custom-control-label:before
{
    background-color: #9c27b0 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #9c27b0 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #9c27b0 !important;
}

.border-purple
{
    border: 1px solid #9c27b0 !important;
}

.border-top-purple
{
    border-top: 1px solid #9c27b0;
}

.border-bottom-purple
{
    border-bottom: 1px solid #9c27b0;
}

.border-left-purple
{
    border-left: 1px solid #9c27b0;
}

.border-right-purple
{
    border-right: 1px solid #9c27b0;
}

.purple.darken-1
{
    color: #8e24aa !important;
}

.bg-purple.bg-darken-1
{
    background-color: #8e24aa !important;
}
.bg-purple.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(142, 36, 170, .6);
            box-shadow: 0 1px 20px 1px rgba(142, 36, 170, .6);
}

.btn-purple.btn-darken-1
{
    border-color: #7b1fa2 !important;
    background-color: #8e24aa !important;
}
.btn-purple.btn-darken-1:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-darken-1:focus,
.btn-purple.btn-darken-1:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-darken-1
{
    color: #8e24aa !important; 
    border-color: #8e24aa !important;
}
.btn-outline-purple.btn-outline-darken-1:hover
{
    background-color: #8e24aa !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #8e24aa !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #8e24aa !important;
}

.border-purple.border-darken-1
{
    border: 1px solid #8e24aa !important;
}

.border-top-purple.border-top-darken-1
{
    border-top: 1px solid #8e24aa !important;
}

.border-bottom-purple.border-bottom-darken-1
{
    border-bottom: 1px solid #8e24aa !important;
}

.border-left-purple.border-left-darken-1
{
    border-left: 1px solid #8e24aa !important;
}

.border-right-purple.border-right-darken-1
{
    border-right: 1px solid #8e24aa !important;
}

.overlay-purple.overlay-darken-1
{
    background: #8e24aa;
    /* The Fallback */
    background: rgba(142, 36, 170, .8);
}

.purple.darken-2
{
    color: #7b1fa2 !important;
}

.bg-purple.bg-darken-2
{
    background-color: #7b1fa2 !important;
}
.bg-purple.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(123, 31, 162, .6);
            box-shadow: 0 1px 20px 1px rgba(123, 31, 162, .6);
}

.btn-purple.btn-darken-2
{
    border-color: #7b1fa2 !important;
    background-color: #7b1fa2 !important;
}
.btn-purple.btn-darken-2:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-darken-2:focus,
.btn-purple.btn-darken-2:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-darken-2
{
    color: #7b1fa2 !important; 
    border-color: #7b1fa2 !important;
}
.btn-outline-purple.btn-outline-darken-2:hover
{
    background-color: #7b1fa2 !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #7b1fa2 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #7b1fa2 !important;
}

.border-purple.border-darken-2
{
    border: 1px solid #7b1fa2 !important;
}

.border-top-purple.border-top-darken-2
{
    border-top: 1px solid #7b1fa2 !important;
}

.border-bottom-purple.border-bottom-darken-2
{
    border-bottom: 1px solid #7b1fa2 !important;
}

.border-left-purple.border-left-darken-2
{
    border-left: 1px solid #7b1fa2 !important;
}

.border-right-purple.border-right-darken-2
{
    border-right: 1px solid #7b1fa2 !important;
}

.overlay-purple.overlay-darken-2
{
    background: #7b1fa2;
    /* The Fallback */
    background: rgba(123, 31, 162, .8);
}

.purple.darken-3
{
    color: #6a1b9a !important;
}

.bg-purple.bg-darken-3
{
    background-color: #6a1b9a !important;
}
.bg-purple.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(106, 27, 154, .6);
            box-shadow: 0 1px 20px 1px rgba(106, 27, 154, .6);
}

.btn-purple.btn-darken-3
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-darken-3:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-darken-3:focus,
.btn-purple.btn-darken-3:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-darken-3
{
    color: #6a1b9a !important; 
    border-color: #6a1b9a !important;
}
.btn-outline-purple.btn-outline-darken-3:hover
{
    background-color: #6a1b9a !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #6a1b9a !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #6a1b9a !important;
}

.border-purple.border-darken-3
{
    border: 1px solid #6a1b9a !important;
}

.border-top-purple.border-top-darken-3
{
    border-top: 1px solid #6a1b9a !important;
}

.border-bottom-purple.border-bottom-darken-3
{
    border-bottom: 1px solid #6a1b9a !important;
}

.border-left-purple.border-left-darken-3
{
    border-left: 1px solid #6a1b9a !important;
}

.border-right-purple.border-right-darken-3
{
    border-right: 1px solid #6a1b9a !important;
}

.overlay-purple.overlay-darken-3
{
    background: #6a1b9a;
    /* The Fallback */
    background: rgba(106, 27, 154, .8);
}

.purple.darken-4
{
    color: #4a148c !important;
}

.bg-purple.bg-darken-4
{
    background-color: #4a148c !important;
}
.bg-purple.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(74, 20, 140, .6);
            box-shadow: 0 1px 20px 1px rgba(74, 20, 140, .6);
}

.btn-purple.btn-darken-4
{
    border-color: #7b1fa2 !important;
    background-color: #4a148c !important;
}
.btn-purple.btn-darken-4:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-darken-4:focus,
.btn-purple.btn-darken-4:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-darken-4
{
    color: #4a148c !important; 
    border-color: #4a148c !important;
}
.btn-outline-purple.btn-outline-darken-4:hover
{
    background-color: #4a148c !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4a148c !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4a148c !important;
}

.border-purple.border-darken-4
{
    border: 1px solid #4a148c !important;
}

.border-top-purple.border-top-darken-4
{
    border-top: 1px solid #4a148c !important;
}

.border-bottom-purple.border-bottom-darken-4
{
    border-bottom: 1px solid #4a148c !important;
}

.border-left-purple.border-left-darken-4
{
    border-left: 1px solid #4a148c !important;
}

.border-right-purple.border-right-darken-4
{
    border-right: 1px solid #4a148c !important;
}

.overlay-purple.overlay-darken-4
{
    background: #4a148c;
    /* The Fallback */
    background: rgba(74, 20, 140, .8);
}

.purple.accent-1
{
    color: #ea80fc !important;
}

.bg-purple.bg-accent-1
{
    background-color: #ea80fc !important;
}
.bg-purple.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(234, 128, 252, .6);
            box-shadow: 0 1px 20px 1px rgba(234, 128, 252, .6);
}

.btn-purple.btn-accent-1
{
    border-color: #7b1fa2 !important;
    background-color: #ea80fc !important;
}
.btn-purple.btn-accent-1:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-accent-1:focus,
.btn-purple.btn-accent-1:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-accent-1
{
    color: #ea80fc !important; 
    border-color: #ea80fc !important;
}
.btn-outline-purple.btn-outline-accent-1:hover
{
    background-color: #ea80fc !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ea80fc !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ea80fc !important;
}

.border-purple.border-accent-1
{
    border: 1px solid #ea80fc !important;
}

.border-top-purple.border-top-accent-1
{
    border-top: 1px solid #ea80fc !important;
}

.border-bottom-purple.border-bottom-accent-1
{
    border-bottom: 1px solid #ea80fc !important;
}

.border-left-purple.border-left-accent-1
{
    border-left: 1px solid #ea80fc !important;
}

.border-right-purple.border-right-accent-1
{
    border-right: 1px solid #ea80fc !important;
}

.overlay-purple.overlay-accent-1
{
    background: #ea80fc;
    /* The Fallback */
    background: rgba(234, 128, 252, .8);
}

.purple.accent-2
{
    color: #e040fb !important;
}

.bg-purple.bg-accent-2
{
    background-color: #e040fb !important;
}
.bg-purple.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(224, 64, 251, .6);
            box-shadow: 0 1px 20px 1px rgba(224, 64, 251, .6);
}

.btn-purple.btn-accent-2
{
    border-color: #7b1fa2 !important;
    background-color: #e040fb !important;
}
.btn-purple.btn-accent-2:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-accent-2:focus,
.btn-purple.btn-accent-2:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-accent-2
{
    color: #e040fb !important; 
    border-color: #e040fb !important;
}
.btn-outline-purple.btn-outline-accent-2:hover
{
    background-color: #e040fb !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e040fb !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e040fb !important;
}

.border-purple.border-accent-2
{
    border: 1px solid #e040fb !important;
}

.border-top-purple.border-top-accent-2
{
    border-top: 1px solid #e040fb !important;
}

.border-bottom-purple.border-bottom-accent-2
{
    border-bottom: 1px solid #e040fb !important;
}

.border-left-purple.border-left-accent-2
{
    border-left: 1px solid #e040fb !important;
}

.border-right-purple.border-right-accent-2
{
    border-right: 1px solid #e040fb !important;
}

.overlay-purple.overlay-accent-2
{
    background: #e040fb;
    /* The Fallback */
    background: rgba(224, 64, 251, .8);
}

.purple.accent-3
{
    color: #d500f9 !important;
}

.bg-purple.bg-accent-3
{
    background-color: #d500f9 !important;
}
.bg-purple.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(213, 0, 249, .6);
            box-shadow: 0 1px 20px 1px rgba(213, 0, 249, .6);
}

.btn-purple.btn-accent-3
{
    border-color: #7b1fa2 !important;
    background-color: #d500f9 !important;
}
.btn-purple.btn-accent-3:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-accent-3:focus,
.btn-purple.btn-accent-3:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-accent-3
{
    color: #d500f9 !important; 
    border-color: #d500f9 !important;
}
.btn-outline-purple.btn-outline-accent-3:hover
{
    background-color: #d500f9 !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d500f9 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d500f9 !important;
}

.border-purple.border-accent-3
{
    border: 1px solid #d500f9 !important;
}

.border-top-purple.border-top-accent-3
{
    border-top: 1px solid #d500f9 !important;
}

.border-bottom-purple.border-bottom-accent-3
{
    border-bottom: 1px solid #d500f9 !important;
}

.border-left-purple.border-left-accent-3
{
    border-left: 1px solid #d500f9 !important;
}

.border-right-purple.border-right-accent-3
{
    border-right: 1px solid #d500f9 !important;
}

.overlay-purple.overlay-accent-3
{
    background: #d500f9;
    /* The Fallback */
    background: rgba(213, 0, 249, .8);
}

.purple.accent-4
{
    color: #d0f !important;
}

.bg-purple.bg-accent-4
{
    background-color: #d0f !important;
}
.bg-purple.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(221, 0, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(221, 0, 255, .6);
}

.btn-purple.btn-accent-4
{
    border-color: #7b1fa2 !important;
    background-color: #d0f !important;
}
.btn-purple.btn-accent-4:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-accent-4:focus,
.btn-purple.btn-accent-4:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-accent-4
{
    color: #d0f !important; 
    border-color: #d0f !important;
}
.btn-outline-purple.btn-outline-accent-4:hover
{
    background-color: #d0f !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d0f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d0f !important;
}

.border-purple.border-accent-4
{
    border: 1px solid #d0f !important;
}

.border-top-purple.border-top-accent-4
{
    border-top: 1px solid #d0f !important;
}

.border-bottom-purple.border-bottom-accent-4
{
    border-bottom: 1px solid #d0f !important;
}

.border-left-purple.border-left-accent-4
{
    border-left: 1px solid #d0f !important;
}

.border-right-purple.border-right-accent-4
{
    border-right: 1px solid #d0f !important;
}

.overlay-purple.overlay-accent-4
{
    background: #d0f;
    /* The Fallback */
    background: rgba(221, 0, 255, .8);
}

.deep-purple.lighten-5
{
    color: #ede7f6 !important;
}

.bg-deep-purple.bg-lighten-5
{
    background-color: #ede7f6 !important;
}
.bg-deep-purple.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(237, 231, 246, .6);
            box-shadow: 0 1px 20px 1px rgba(237, 231, 246, .6);
}

.btn-deep-purple.btn-lighten-5
{
    border-color: #512da8 !important;
    background-color: #ede7f6 !important;
}
.btn-deep-purple.btn-lighten-5:hover
{
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-lighten-5:focus,
.btn-deep-purple.btn-lighten-5:active
{
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}

.btn-outline-deep-purple.btn-outline-lighten-5
{
    color: #ede7f6 !important; 
    border-color: #ede7f6 !important;
}
.btn-outline-deep-purple.btn-outline-lighten-5:hover
{
    background-color: #ede7f6 !important;
}

input:focus ~ .bg-deep-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ede7f6 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ede7f6 !important;
}

.border-deep-purple.border-lighten-5
{
    border: 1px solid #ede7f6 !important;
}

.border-top-deep-purple.border-top-lighten-5
{
    border-top: 1px solid #ede7f6 !important;
}

.border-bottom-deep-purple.border-bottom-lighten-5
{
    border-bottom: 1px solid #ede7f6 !important;
}

.border-left-deep-purple.border-left-lighten-5
{
    border-left: 1px solid #ede7f6 !important;
}

.border-right-deep-purple.border-right-lighten-5
{
    border-right: 1px solid #ede7f6 !important;
}

.overlay-deep-purple.overlay-lighten-5
{
    background: #ede7f6;
    /* The Fallback */
    background: rgba(237, 231, 246, .8);
}

.deep-purple.lighten-4
{
    color: #d1c4e9 !important;
}

.bg-deep-purple.bg-lighten-4
{
    background-color: #d1c4e9 !important;
}
.bg-deep-purple.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(209, 196, 233, .6);
            box-shadow: 0 1px 20px 1px rgba(209, 196, 233, .6);
}

.btn-deep-purple.btn-lighten-4
{
    border-color: #512da8 !important;
    background-color: #d1c4e9 !important;
}
.btn-deep-purple.btn-lighten-4:hover
{
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-lighten-4:focus,
.btn-deep-purple.btn-lighten-4:active
{
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}

.btn-outline-deep-purple.btn-outline-lighten-4
{
    color: #d1c4e9 !important; 
    border-color: #d1c4e9 !important;
}
.btn-outline-deep-purple.btn-outline-lighten-4:hover
{
    background-color: #d1c4e9 !important;
}

input:focus ~ .bg-deep-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d1c4e9 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d1c4e9 !important;
}

.border-deep-purple.border-lighten-4
{
    border: 1px solid #d1c4e9 !important;
}

.border-top-deep-purple.border-top-lighten-4
{
    border-top: 1px solid #d1c4e9 !important;
}

.border-bottom-deep-purple.border-bottom-lighten-4
{
    border-bottom: 1px solid #d1c4e9 !important;
}

.border-left-deep-purple.border-left-lighten-4
{
    border-left: 1px solid #d1c4e9 !important;
}

.border-right-deep-purple.border-right-lighten-4
{
    border-right: 1px solid #d1c4e9 !important;
}

.overlay-deep-purple.overlay-lighten-4
{
    background: #d1c4e9;
    /* The Fallback */
    background: rgba(209, 196, 233, .8);
}

.deep-purple.lighten-3
{
    color: #b39ddb !important;
}

.bg-deep-purple.bg-lighten-3
{
    background-color: #b39ddb !important;
}
.bg-deep-purple.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(179, 157, 219, .6);
            box-shadow: 0 1px 20px 1px rgba(179, 157, 219, .6);
}

.btn-deep-purple.btn-lighten-3
{
    border-color: #512da8 !important;
    background-color: #b39ddb !important;
}
.btn-deep-purple.btn-lighten-3:hover
{
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-lighten-3:focus,
.btn-deep-purple.btn-lighten-3:active
{
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}

.btn-outline-deep-purple.btn-outline-lighten-3
{
    color: #b39ddb !important; 
    border-color: #b39ddb !important;
}
.btn-outline-deep-purple.btn-outline-lighten-3:hover
{
    background-color: #b39ddb !important;
}

input:focus ~ .bg-deep-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b39ddb !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b39ddb !important;
}

.border-deep-purple.border-lighten-3
{
    border: 1px solid #b39ddb !important;
}

.border-top-deep-purple.border-top-lighten-3
{
    border-top: 1px solid #b39ddb !important;
}

.border-bottom-deep-purple.border-bottom-lighten-3
{
    border-bottom: 1px solid #b39ddb !important;
}

.border-left-deep-purple.border-left-lighten-3
{
    border-left: 1px solid #b39ddb !important;
}

.border-right-deep-purple.border-right-lighten-3
{
    border-right: 1px solid #b39ddb !important;
}

.overlay-deep-purple.overlay-lighten-3
{
    background: #b39ddb;
    /* The Fallback */
    background: rgba(179, 157, 219, .8);
}

.deep-purple.lighten-2
{
    color: #9575cd !important;
}

.bg-deep-purple.bg-lighten-2
{
    background-color: #9575cd !important;
}
.bg-deep-purple.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(149, 117, 205, .6);
            box-shadow: 0 1px 20px 1px rgba(149, 117, 205, .6);
}

.btn-deep-purple.btn-lighten-2
{
    border-color: #512da8 !important;
    background-color: #9575cd !important;
}
.btn-deep-purple.btn-lighten-2:hover
{
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-lighten-2:focus,
.btn-deep-purple.btn-lighten-2:active
{
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}

.btn-outline-deep-purple.btn-outline-lighten-2
{
    color: #9575cd !important; 
    border-color: #9575cd !important;
}
.btn-outline-deep-purple.btn-outline-lighten-2:hover
{
    background-color: #9575cd !important;
}

input:focus ~ .bg-deep-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #9575cd !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #9575cd !important;
}

.border-deep-purple.border-lighten-2
{
    border: 1px solid #9575cd !important;
}

.border-top-deep-purple.border-top-lighten-2
{
    border-top: 1px solid #9575cd !important;
}

.border-bottom-deep-purple.border-bottom-lighten-2
{
    border-bottom: 1px solid #9575cd !important;
}

.border-left-deep-purple.border-left-lighten-2
{
    border-left: 1px solid #9575cd !important;
}

.border-right-deep-purple.border-right-lighten-2
{
    border-right: 1px solid #9575cd !important;
}

.overlay-deep-purple.overlay-lighten-2
{
    background: #9575cd;
    /* The Fallback */
    background: rgba(149, 117, 205, .8);
}

.deep-purple.lighten-1
{
    color: #7e57c2 !important;
}

.bg-deep-purple.bg-lighten-1
{
    background-color: #7e57c2 !important;
}
.bg-deep-purple.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(126, 87, 194, .6);
            box-shadow: 0 1px 20px 1px rgba(126, 87, 194, .6);
}

.btn-deep-purple.btn-lighten-1
{
    border-color: #512da8 !important;
    background-color: #7e57c2 !important;
}
.btn-deep-purple.btn-lighten-1:hover
{
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-lighten-1:focus,
.btn-deep-purple.btn-lighten-1:active
{
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}

.btn-outline-deep-purple.btn-outline-lighten-1
{
    color: #7e57c2 !important; 
    border-color: #7e57c2 !important;
}
.btn-outline-deep-purple.btn-outline-lighten-1:hover
{
    background-color: #7e57c2 !important;
}

input:focus ~ .bg-deep-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #7e57c2 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #7e57c2 !important;
}

.border-deep-purple.border-lighten-1
{
    border: 1px solid #7e57c2 !important;
}

.border-top-deep-purple.border-top-lighten-1
{
    border-top: 1px solid #7e57c2 !important;
}

.border-bottom-deep-purple.border-bottom-lighten-1
{
    border-bottom: 1px solid #7e57c2 !important;
}

.border-left-deep-purple.border-left-lighten-1
{
    border-left: 1px solid #7e57c2 !important;
}

.border-right-deep-purple.border-right-lighten-1
{
    border-right: 1px solid #7e57c2 !important;
}

.overlay-deep-purple.overlay-lighten-1
{
    background: #7e57c2;
    /* The Fallback */
    background: rgba(126, 87, 194, .8);
}

.deep-purple
{
    color: #673ab7 !important;
}

.bg-deep-purple
{
    background-color: #673ab7 !important;
}
.bg-deep-purple .card-header,
.bg-deep-purple .card-footer
{
    background-color: transparent;
}
.bg-deep-purple.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(103, 58, 183, .6);
            box-shadow: 0 1px 20px 1px rgba(103, 58, 183, .6);
}

.selectboxit.selectboxit-btn.bg-deep-purple
{
    background-color: #673ab7 !important;
}

.alert-deep-purple
{
    color: #1b0f2f !important; 
    border-color: #673ab7 !important;
    background-color: #8e69cf !important;
}
.alert-deep-purple .alert-link
{
    color: #090510 !important;
}

.border-deep-purple
{
    border-color: #673ab7;
}

.overlay-deep-purple
{
    background: #673ab7;
    /* The Fallback */
    background: rgba(103, 58, 183, .8);
}

/* .card-outline-deep-purple{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-deep-purple
{
    color: #fff; 
    border-color: #673ab7 !important;
    background-color: #673ab7 !important;
}
.btn-deep-purple:hover
{
    color: #fff !important; 
    border-color: #512da8 !important;
    background-color: #5e35b1 !important;
}
.btn-deep-purple:focus,
.btn-deep-purple:active
{
    color: #fff !important; 
    border-color: #4527a0 !important;
    background-color: #512da8 !important;
}
.btn-deep-purple.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(103, 58, 183, .6);
            box-shadow: 0 1px 20px 1px rgba(103, 58, 183, .6);
}

.btn-outline-deep-purple
{
    color: #673ab7; 
    border-color: #673ab7;
    background-color: transparent;
}
.btn-outline-deep-purple:hover
{
    color: #fff !important; 
    background-color: #673ab7;
}
.btn-outline-deep-purple.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(103, 58, 183, .6);
            box-shadow: 0 1px 20px 1px rgba(103, 58, 183, .6);
}

input[type='checkbox'].bg-deep-purple + .custom-control-label:before,
input[type='radio'].bg-deep-purple + .custom-control-label:before
{
    background-color: #673ab7 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-deep-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #673ab7 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #673ab7 !important;
}

.border-deep-purple
{
    border: 1px solid #673ab7 !important;
}

.border-top-deep-purple
{
    border-top: 1px solid #673ab7;
}

.border-bottom-deep-purple
{
    border-bottom: 1px solid #673ab7;
}

.border-left-deep-purple
{
    border-left: 1px solid #673ab7;
}

.border-right-deep-purple
{
    border-right: 1px solid #673ab7;
}

.deep-purple.darken-1
{
    color: #5e35b1 !important;
}

.bg-deep-purple.bg-darken-1
{
    background-color: #5e35b1 !important;
}
.bg-deep-purple.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(94, 53, 177, .6);
            box-shadow: 0 1px 20px 1px rgba(94, 53, 177, .6);
}

.btn-deep-purple.btn-darken-1
{
    border-color: #512da8 !important;
    background-color: #5e35b1 !important;
}
.btn-deep-purple.btn-darken-1:hover
{
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-darken-1:focus,
.btn-deep-purple.btn-darken-1:active
{
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}

.btn-outline-deep-purple.btn-outline-darken-1
{
    color: #5e35b1 !important; 
    border-color: #5e35b1 !important;
}
.btn-outline-deep-purple.btn-outline-darken-1:hover
{
    background-color: #5e35b1 !important;
}

input:focus ~ .bg-deep-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #5e35b1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #5e35b1 !important;
}

.border-deep-purple.border-darken-1
{
    border: 1px solid #5e35b1 !important;
}

.border-top-deep-purple.border-top-darken-1
{
    border-top: 1px solid #5e35b1 !important;
}

.border-bottom-deep-purple.border-bottom-darken-1
{
    border-bottom: 1px solid #5e35b1 !important;
}

.border-left-deep-purple.border-left-darken-1
{
    border-left: 1px solid #5e35b1 !important;
}

.border-right-deep-purple.border-right-darken-1
{
    border-right: 1px solid #5e35b1 !important;
}

.overlay-deep-purple.overlay-darken-1
{
    background: #5e35b1;
    /* The Fallback */
    background: rgba(94, 53, 177, .8);
}

.deep-purple.darken-2
{
    color: #512da8 !important;
}

.bg-deep-purple.bg-darken-2
{
    background-color: #512da8 !important;
}
.bg-deep-purple.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(81, 45, 168, .6);
            box-shadow: 0 1px 20px 1px rgba(81, 45, 168, .6);
}

.btn-deep-purple.btn-darken-2
{
    border-color: #512da8 !important;
    background-color: #512da8 !important;
}
.btn-deep-purple.btn-darken-2:hover
{
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-darken-2:focus,
.btn-deep-purple.btn-darken-2:active
{
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}

.btn-outline-deep-purple.btn-outline-darken-2
{
    color: #512da8 !important; 
    border-color: #512da8 !important;
}
.btn-outline-deep-purple.btn-outline-darken-2:hover
{
    background-color: #512da8 !important;
}

input:focus ~ .bg-deep-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #512da8 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #512da8 !important;
}

.border-deep-purple.border-darken-2
{
    border: 1px solid #512da8 !important;
}

.border-top-deep-purple.border-top-darken-2
{
    border-top: 1px solid #512da8 !important;
}

.border-bottom-deep-purple.border-bottom-darken-2
{
    border-bottom: 1px solid #512da8 !important;
}

.border-left-deep-purple.border-left-darken-2
{
    border-left: 1px solid #512da8 !important;
}

.border-right-deep-purple.border-right-darken-2
{
    border-right: 1px solid #512da8 !important;
}

.overlay-deep-purple.overlay-darken-2
{
    background: #512da8;
    /* The Fallback */
    background: rgba(81, 45, 168, .8);
}

.deep-purple.darken-3
{
    color: #4527a0 !important;
}

.bg-deep-purple.bg-darken-3
{
    background-color: #4527a0 !important;
}
.bg-deep-purple.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(69, 39, 160, .6);
            box-shadow: 0 1px 20px 1px rgba(69, 39, 160, .6);
}

.btn-deep-purple.btn-darken-3
{
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-darken-3:hover
{
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-darken-3:focus,
.btn-deep-purple.btn-darken-3:active
{
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}

.btn-outline-deep-purple.btn-outline-darken-3
{
    color: #4527a0 !important; 
    border-color: #4527a0 !important;
}
.btn-outline-deep-purple.btn-outline-darken-3:hover
{
    background-color: #4527a0 !important;
}

input:focus ~ .bg-deep-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4527a0 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4527a0 !important;
}

.border-deep-purple.border-darken-3
{
    border: 1px solid #4527a0 !important;
}

.border-top-deep-purple.border-top-darken-3
{
    border-top: 1px solid #4527a0 !important;
}

.border-bottom-deep-purple.border-bottom-darken-3
{
    border-bottom: 1px solid #4527a0 !important;
}

.border-left-deep-purple.border-left-darken-3
{
    border-left: 1px solid #4527a0 !important;
}

.border-right-deep-purple.border-right-darken-3
{
    border-right: 1px solid #4527a0 !important;
}

.overlay-deep-purple.overlay-darken-3
{
    background: #4527a0;
    /* The Fallback */
    background: rgba(69, 39, 160, .8);
}

.deep-purple.darken-4
{
    color: #311b92 !important;
}

.bg-deep-purple.bg-darken-4
{
    background-color: #311b92 !important;
}
.bg-deep-purple.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(49, 27, 146, .6);
            box-shadow: 0 1px 20px 1px rgba(49, 27, 146, .6);
}

.btn-deep-purple.btn-darken-4
{
    border-color: #512da8 !important;
    background-color: #311b92 !important;
}
.btn-deep-purple.btn-darken-4:hover
{
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-darken-4:focus,
.btn-deep-purple.btn-darken-4:active
{
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}

.btn-outline-deep-purple.btn-outline-darken-4
{
    color: #311b92 !important; 
    border-color: #311b92 !important;
}
.btn-outline-deep-purple.btn-outline-darken-4:hover
{
    background-color: #311b92 !important;
}

input:focus ~ .bg-deep-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #311b92 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #311b92 !important;
}

.border-deep-purple.border-darken-4
{
    border: 1px solid #311b92 !important;
}

.border-top-deep-purple.border-top-darken-4
{
    border-top: 1px solid #311b92 !important;
}

.border-bottom-deep-purple.border-bottom-darken-4
{
    border-bottom: 1px solid #311b92 !important;
}

.border-left-deep-purple.border-left-darken-4
{
    border-left: 1px solid #311b92 !important;
}

.border-right-deep-purple.border-right-darken-4
{
    border-right: 1px solid #311b92 !important;
}

.overlay-deep-purple.overlay-darken-4
{
    background: #311b92;
    /* The Fallback */
    background: rgba(49, 27, 146, .8);
}

.deep-purple.accent-1
{
    color: #b388ff !important;
}

.bg-deep-purple.bg-accent-1
{
    background-color: #b388ff !important;
}
.bg-deep-purple.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(179, 136, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(179, 136, 255, .6);
}

.btn-deep-purple.btn-accent-1
{
    border-color: #512da8 !important;
    background-color: #b388ff !important;
}
.btn-deep-purple.btn-accent-1:hover
{
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-accent-1:focus,
.btn-deep-purple.btn-accent-1:active
{
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}

.btn-outline-deep-purple.btn-outline-accent-1
{
    color: #b388ff !important; 
    border-color: #b388ff !important;
}
.btn-outline-deep-purple.btn-outline-accent-1:hover
{
    background-color: #b388ff !important;
}

input:focus ~ .bg-deep-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b388ff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b388ff !important;
}

.border-deep-purple.border-accent-1
{
    border: 1px solid #b388ff !important;
}

.border-top-deep-purple.border-top-accent-1
{
    border-top: 1px solid #b388ff !important;
}

.border-bottom-deep-purple.border-bottom-accent-1
{
    border-bottom: 1px solid #b388ff !important;
}

.border-left-deep-purple.border-left-accent-1
{
    border-left: 1px solid #b388ff !important;
}

.border-right-deep-purple.border-right-accent-1
{
    border-right: 1px solid #b388ff !important;
}

.overlay-deep-purple.overlay-accent-1
{
    background: #b388ff;
    /* The Fallback */
    background: rgba(179, 136, 255, .8);
}

.deep-purple.accent-2
{
    color: #7c4dff !important;
}

.bg-deep-purple.bg-accent-2
{
    background-color: #7c4dff !important;
}
.bg-deep-purple.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(124, 77, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(124, 77, 255, .6);
}

.btn-deep-purple.btn-accent-2
{
    border-color: #512da8 !important;
    background-color: #7c4dff !important;
}
.btn-deep-purple.btn-accent-2:hover
{
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-accent-2:focus,
.btn-deep-purple.btn-accent-2:active
{
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}

.btn-outline-deep-purple.btn-outline-accent-2
{
    color: #7c4dff !important; 
    border-color: #7c4dff !important;
}
.btn-outline-deep-purple.btn-outline-accent-2:hover
{
    background-color: #7c4dff !important;
}

input:focus ~ .bg-deep-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #7c4dff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #7c4dff !important;
}

.border-deep-purple.border-accent-2
{
    border: 1px solid #7c4dff !important;
}

.border-top-deep-purple.border-top-accent-2
{
    border-top: 1px solid #7c4dff !important;
}

.border-bottom-deep-purple.border-bottom-accent-2
{
    border-bottom: 1px solid #7c4dff !important;
}

.border-left-deep-purple.border-left-accent-2
{
    border-left: 1px solid #7c4dff !important;
}

.border-right-deep-purple.border-right-accent-2
{
    border-right: 1px solid #7c4dff !important;
}

.overlay-deep-purple.overlay-accent-2
{
    background: #7c4dff;
    /* The Fallback */
    background: rgba(124, 77, 255, .8);
}

.deep-purple.accent-3
{
    color: #651fff !important;
}

.bg-deep-purple.bg-accent-3
{
    background-color: #651fff !important;
}
.bg-deep-purple.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(101, 31, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(101, 31, 255, .6);
}

.btn-deep-purple.btn-accent-3
{
    border-color: #512da8 !important;
    background-color: #651fff !important;
}
.btn-deep-purple.btn-accent-3:hover
{
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-accent-3:focus,
.btn-deep-purple.btn-accent-3:active
{
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}

.btn-outline-deep-purple.btn-outline-accent-3
{
    color: #651fff !important; 
    border-color: #651fff !important;
}
.btn-outline-deep-purple.btn-outline-accent-3:hover
{
    background-color: #651fff !important;
}

input:focus ~ .bg-deep-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #651fff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #651fff !important;
}

.border-deep-purple.border-accent-3
{
    border: 1px solid #651fff !important;
}

.border-top-deep-purple.border-top-accent-3
{
    border-top: 1px solid #651fff !important;
}

.border-bottom-deep-purple.border-bottom-accent-3
{
    border-bottom: 1px solid #651fff !important;
}

.border-left-deep-purple.border-left-accent-3
{
    border-left: 1px solid #651fff !important;
}

.border-right-deep-purple.border-right-accent-3
{
    border-right: 1px solid #651fff !important;
}

.overlay-deep-purple.overlay-accent-3
{
    background: #651fff;
    /* The Fallback */
    background: rgba(101, 31, 255, .8);
}

.deep-purple.accent-4
{
    color: #6200ea !important;
}

.bg-deep-purple.bg-accent-4
{
    background-color: #6200ea !important;
}
.bg-deep-purple.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(98, 0, 234, .6);
            box-shadow: 0 1px 20px 1px rgba(98, 0, 234, .6);
}

.btn-deep-purple.btn-accent-4
{
    border-color: #512da8 !important;
    background-color: #6200ea !important;
}
.btn-deep-purple.btn-accent-4:hover
{
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-accent-4:focus,
.btn-deep-purple.btn-accent-4:active
{
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}

.btn-outline-deep-purple.btn-outline-accent-4
{
    color: #6200ea !important; 
    border-color: #6200ea !important;
}
.btn-outline-deep-purple.btn-outline-accent-4:hover
{
    background-color: #6200ea !important;
}

input:focus ~ .bg-deep-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #6200ea !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #6200ea !important;
}

.border-deep-purple.border-accent-4
{
    border: 1px solid #6200ea !important;
}

.border-top-deep-purple.border-top-accent-4
{
    border-top: 1px solid #6200ea !important;
}

.border-bottom-deep-purple.border-bottom-accent-4
{
    border-bottom: 1px solid #6200ea !important;
}

.border-left-deep-purple.border-left-accent-4
{
    border-left: 1px solid #6200ea !important;
}

.border-right-deep-purple.border-right-accent-4
{
    border-right: 1px solid #6200ea !important;
}

.overlay-deep-purple.overlay-accent-4
{
    background: #6200ea;
    /* The Fallback */
    background: rgba(98, 0, 234, .8);
}

.indigo.lighten-5
{
    color: #e8eaf6 !important;
}

.bg-indigo.bg-lighten-5
{
    background-color: #e8eaf6 !important;
}
.bg-indigo.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(232, 234, 246, .6);
            box-shadow: 0 1px 20px 1px rgba(232, 234, 246, .6);
}

.btn-indigo.btn-lighten-5
{
    border-color: #303f9f !important;
    background-color: #e8eaf6 !important;
}
.btn-indigo.btn-lighten-5:hover
{
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-lighten-5:focus,
.btn-indigo.btn-lighten-5:active
{
    border-color: #283593 !important;
    background-color: #1a237e !important;
}

.btn-outline-indigo.btn-outline-lighten-5
{
    color: #e8eaf6 !important; 
    border-color: #e8eaf6 !important;
}
.btn-outline-indigo.btn-outline-lighten-5:hover
{
    background-color: #e8eaf6 !important;
}

input:focus ~ .bg-indigo
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e8eaf6 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e8eaf6 !important;
}

.border-indigo.border-lighten-5
{
    border: 1px solid #e8eaf6 !important;
}

.border-top-indigo.border-top-lighten-5
{
    border-top: 1px solid #e8eaf6 !important;
}

.border-bottom-indigo.border-bottom-lighten-5
{
    border-bottom: 1px solid #e8eaf6 !important;
}

.border-left-indigo.border-left-lighten-5
{
    border-left: 1px solid #e8eaf6 !important;
}

.border-right-indigo.border-right-lighten-5
{
    border-right: 1px solid #e8eaf6 !important;
}

.overlay-indigo.overlay-lighten-5
{
    background: #e8eaf6;
    /* The Fallback */
    background: rgba(232, 234, 246, .8);
}

.indigo.lighten-4
{
    color: #c5cae9 !important;
}

.bg-indigo.bg-lighten-4
{
    background-color: #c5cae9 !important;
}
.bg-indigo.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(197, 202, 233, .6);
            box-shadow: 0 1px 20px 1px rgba(197, 202, 233, .6);
}

.btn-indigo.btn-lighten-4
{
    border-color: #303f9f !important;
    background-color: #c5cae9 !important;
}
.btn-indigo.btn-lighten-4:hover
{
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-lighten-4:focus,
.btn-indigo.btn-lighten-4:active
{
    border-color: #283593 !important;
    background-color: #1a237e !important;
}

.btn-outline-indigo.btn-outline-lighten-4
{
    color: #c5cae9 !important; 
    border-color: #c5cae9 !important;
}
.btn-outline-indigo.btn-outline-lighten-4:hover
{
    background-color: #c5cae9 !important;
}

input:focus ~ .bg-indigo
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c5cae9 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c5cae9 !important;
}

.border-indigo.border-lighten-4
{
    border: 1px solid #c5cae9 !important;
}

.border-top-indigo.border-top-lighten-4
{
    border-top: 1px solid #c5cae9 !important;
}

.border-bottom-indigo.border-bottom-lighten-4
{
    border-bottom: 1px solid #c5cae9 !important;
}

.border-left-indigo.border-left-lighten-4
{
    border-left: 1px solid #c5cae9 !important;
}

.border-right-indigo.border-right-lighten-4
{
    border-right: 1px solid #c5cae9 !important;
}

.overlay-indigo.overlay-lighten-4
{
    background: #c5cae9;
    /* The Fallback */
    background: rgba(197, 202, 233, .8);
}

.indigo.lighten-3
{
    color: #9fa8da !important;
}

.bg-indigo.bg-lighten-3
{
    background-color: #9fa8da !important;
}
.bg-indigo.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(159, 168, 218, .6);
            box-shadow: 0 1px 20px 1px rgba(159, 168, 218, .6);
}

.btn-indigo.btn-lighten-3
{
    border-color: #303f9f !important;
    background-color: #9fa8da !important;
}
.btn-indigo.btn-lighten-3:hover
{
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-lighten-3:focus,
.btn-indigo.btn-lighten-3:active
{
    border-color: #283593 !important;
    background-color: #1a237e !important;
}

.btn-outline-indigo.btn-outline-lighten-3
{
    color: #9fa8da !important; 
    border-color: #9fa8da !important;
}
.btn-outline-indigo.btn-outline-lighten-3:hover
{
    background-color: #9fa8da !important;
}

input:focus ~ .bg-indigo
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #9fa8da !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #9fa8da !important;
}

.border-indigo.border-lighten-3
{
    border: 1px solid #9fa8da !important;
}

.border-top-indigo.border-top-lighten-3
{
    border-top: 1px solid #9fa8da !important;
}

.border-bottom-indigo.border-bottom-lighten-3
{
    border-bottom: 1px solid #9fa8da !important;
}

.border-left-indigo.border-left-lighten-3
{
    border-left: 1px solid #9fa8da !important;
}

.border-right-indigo.border-right-lighten-3
{
    border-right: 1px solid #9fa8da !important;
}

.overlay-indigo.overlay-lighten-3
{
    background: #9fa8da;
    /* The Fallback */
    background: rgba(159, 168, 218, .8);
}

.indigo.lighten-2
{
    color: #7986cb !important;
}

.bg-indigo.bg-lighten-2
{
    background-color: #7986cb !important;
}
.bg-indigo.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(121, 134, 203, .6);
            box-shadow: 0 1px 20px 1px rgba(121, 134, 203, .6);
}

.btn-indigo.btn-lighten-2
{
    border-color: #303f9f !important;
    background-color: #7986cb !important;
}
.btn-indigo.btn-lighten-2:hover
{
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-lighten-2:focus,
.btn-indigo.btn-lighten-2:active
{
    border-color: #283593 !important;
    background-color: #1a237e !important;
}

.btn-outline-indigo.btn-outline-lighten-2
{
    color: #7986cb !important; 
    border-color: #7986cb !important;
}
.btn-outline-indigo.btn-outline-lighten-2:hover
{
    background-color: #7986cb !important;
}

input:focus ~ .bg-indigo
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #7986cb !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #7986cb !important;
}

.border-indigo.border-lighten-2
{
    border: 1px solid #7986cb !important;
}

.border-top-indigo.border-top-lighten-2
{
    border-top: 1px solid #7986cb !important;
}

.border-bottom-indigo.border-bottom-lighten-2
{
    border-bottom: 1px solid #7986cb !important;
}

.border-left-indigo.border-left-lighten-2
{
    border-left: 1px solid #7986cb !important;
}

.border-right-indigo.border-right-lighten-2
{
    border-right: 1px solid #7986cb !important;
}

.overlay-indigo.overlay-lighten-2
{
    background: #7986cb;
    /* The Fallback */
    background: rgba(121, 134, 203, .8);
}

.indigo.lighten-1
{
    color: #5c6bc0 !important;
}

.bg-indigo.bg-lighten-1
{
    background-color: #5c6bc0 !important;
}
.bg-indigo.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(92, 107, 192, .6);
            box-shadow: 0 1px 20px 1px rgba(92, 107, 192, .6);
}

.btn-indigo.btn-lighten-1
{
    border-color: #303f9f !important;
    background-color: #5c6bc0 !important;
}
.btn-indigo.btn-lighten-1:hover
{
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-lighten-1:focus,
.btn-indigo.btn-lighten-1:active
{
    border-color: #283593 !important;
    background-color: #1a237e !important;
}

.btn-outline-indigo.btn-outline-lighten-1
{
    color: #5c6bc0 !important; 
    border-color: #5c6bc0 !important;
}
.btn-outline-indigo.btn-outline-lighten-1:hover
{
    background-color: #5c6bc0 !important;
}

input:focus ~ .bg-indigo
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #5c6bc0 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #5c6bc0 !important;
}

.border-indigo.border-lighten-1
{
    border: 1px solid #5c6bc0 !important;
}

.border-top-indigo.border-top-lighten-1
{
    border-top: 1px solid #5c6bc0 !important;
}

.border-bottom-indigo.border-bottom-lighten-1
{
    border-bottom: 1px solid #5c6bc0 !important;
}

.border-left-indigo.border-left-lighten-1
{
    border-left: 1px solid #5c6bc0 !important;
}

.border-right-indigo.border-right-lighten-1
{
    border-right: 1px solid #5c6bc0 !important;
}

.overlay-indigo.overlay-lighten-1
{
    background: #5c6bc0;
    /* The Fallback */
    background: rgba(92, 107, 192, .8);
}

.indigo
{
    color: #3f51b5 !important;
}

.bg-indigo
{
    background-color: #3f51b5 !important;
}
.bg-indigo .card-header,
.bg-indigo .card-footer
{
    background-color: transparent;
}
.bg-indigo.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(63, 81, 181, .6);
            box-shadow: 0 1px 20px 1px rgba(63, 81, 181, .6);
}

.selectboxit.selectboxit-btn.bg-indigo
{
    background-color: #3f51b5 !important;
}

.alert-indigo
{
    color: #111631 !important; 
    border-color: #3f51b5 !important;
    background-color: #6f7dcd !important;
}
.alert-indigo .alert-link
{
    color: #060812 !important;
}

.border-indigo
{
    border-color: #3f51b5;
}

.overlay-indigo
{
    background: #3f51b5;
    /* The Fallback */
    background: rgba(63, 81, 181, .8);
}

/* .card-outline-indigo{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-indigo
{
    color: #fff; 
    border-color: #3f51b5 !important;
    background-color: #3f51b5 !important;
}
.btn-indigo:hover
{
    color: #fff !important; 
    border-color: #303f9f !important;
    background-color: #3949ab !important;
}
.btn-indigo:focus,
.btn-indigo:active
{
    color: #fff !important; 
    border-color: #283593 !important;
    background-color: #303f9f !important;
}
.btn-indigo.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(63, 81, 181, .6);
            box-shadow: 0 1px 20px 1px rgba(63, 81, 181, .6);
}

.btn-outline-indigo
{
    color: #3f51b5; 
    border-color: #3f51b5;
    background-color: transparent;
}
.btn-outline-indigo:hover
{
    color: #fff !important; 
    background-color: #3f51b5;
}
.btn-outline-indigo.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(63, 81, 181, .6);
            box-shadow: 0 1px 20px 1px rgba(63, 81, 181, .6);
}

input[type='checkbox'].bg-indigo + .custom-control-label:before,
input[type='radio'].bg-indigo + .custom-control-label:before
{
    background-color: #3f51b5 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-indigo
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #3f51b5 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #3f51b5 !important;
}

.border-indigo
{
    border: 1px solid #3f51b5 !important;
}

.border-top-indigo
{
    border-top: 1px solid #3f51b5;
}

.border-bottom-indigo
{
    border-bottom: 1px solid #3f51b5;
}

.border-left-indigo
{
    border-left: 1px solid #3f51b5;
}

.border-right-indigo
{
    border-right: 1px solid #3f51b5;
}

.indigo.darken-1
{
    color: #3949ab !important;
}

.bg-indigo.bg-darken-1
{
    background-color: #3949ab !important;
}
.bg-indigo.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(57, 73, 171, .6);
            box-shadow: 0 1px 20px 1px rgba(57, 73, 171, .6);
}

.btn-indigo.btn-darken-1
{
    border-color: #303f9f !important;
    background-color: #3949ab !important;
}
.btn-indigo.btn-darken-1:hover
{
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-darken-1:focus,
.btn-indigo.btn-darken-1:active
{
    border-color: #283593 !important;
    background-color: #1a237e !important;
}

.btn-outline-indigo.btn-outline-darken-1
{
    color: #3949ab !important; 
    border-color: #3949ab !important;
}
.btn-outline-indigo.btn-outline-darken-1:hover
{
    background-color: #3949ab !important;
}

input:focus ~ .bg-indigo
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #3949ab !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #3949ab !important;
}

.border-indigo.border-darken-1
{
    border: 1px solid #3949ab !important;
}

.border-top-indigo.border-top-darken-1
{
    border-top: 1px solid #3949ab !important;
}

.border-bottom-indigo.border-bottom-darken-1
{
    border-bottom: 1px solid #3949ab !important;
}

.border-left-indigo.border-left-darken-1
{
    border-left: 1px solid #3949ab !important;
}

.border-right-indigo.border-right-darken-1
{
    border-right: 1px solid #3949ab !important;
}

.overlay-indigo.overlay-darken-1
{
    background: #3949ab;
    /* The Fallback */
    background: rgba(57, 73, 171, .8);
}

.indigo.darken-2
{
    color: #303f9f !important;
}

.bg-indigo.bg-darken-2
{
    background-color: #303f9f !important;
}
.bg-indigo.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(48, 63, 159, .6);
            box-shadow: 0 1px 20px 1px rgba(48, 63, 159, .6);
}

.btn-indigo.btn-darken-2
{
    border-color: #303f9f !important;
    background-color: #303f9f !important;
}
.btn-indigo.btn-darken-2:hover
{
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-darken-2:focus,
.btn-indigo.btn-darken-2:active
{
    border-color: #283593 !important;
    background-color: #1a237e !important;
}

.btn-outline-indigo.btn-outline-darken-2
{
    color: #303f9f !important; 
    border-color: #303f9f !important;
}
.btn-outline-indigo.btn-outline-darken-2:hover
{
    background-color: #303f9f !important;
}

input:focus ~ .bg-indigo
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #303f9f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #303f9f !important;
}

.border-indigo.border-darken-2
{
    border: 1px solid #303f9f !important;
}

.border-top-indigo.border-top-darken-2
{
    border-top: 1px solid #303f9f !important;
}

.border-bottom-indigo.border-bottom-darken-2
{
    border-bottom: 1px solid #303f9f !important;
}

.border-left-indigo.border-left-darken-2
{
    border-left: 1px solid #303f9f !important;
}

.border-right-indigo.border-right-darken-2
{
    border-right: 1px solid #303f9f !important;
}

.overlay-indigo.overlay-darken-2
{
    background: #303f9f;
    /* The Fallback */
    background: rgba(48, 63, 159, .8);
}

.indigo.darken-3
{
    color: #283593 !important;
}

.bg-indigo.bg-darken-3
{
    background-color: #283593 !important;
}
.bg-indigo.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(40, 53, 147, .6);
            box-shadow: 0 1px 20px 1px rgba(40, 53, 147, .6);
}

.btn-indigo.btn-darken-3
{
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-darken-3:hover
{
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-darken-3:focus,
.btn-indigo.btn-darken-3:active
{
    border-color: #283593 !important;
    background-color: #1a237e !important;
}

.btn-outline-indigo.btn-outline-darken-3
{
    color: #283593 !important; 
    border-color: #283593 !important;
}
.btn-outline-indigo.btn-outline-darken-3:hover
{
    background-color: #283593 !important;
}

input:focus ~ .bg-indigo
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #283593 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #283593 !important;
}

.border-indigo.border-darken-3
{
    border: 1px solid #283593 !important;
}

.border-top-indigo.border-top-darken-3
{
    border-top: 1px solid #283593 !important;
}

.border-bottom-indigo.border-bottom-darken-3
{
    border-bottom: 1px solid #283593 !important;
}

.border-left-indigo.border-left-darken-3
{
    border-left: 1px solid #283593 !important;
}

.border-right-indigo.border-right-darken-3
{
    border-right: 1px solid #283593 !important;
}

.overlay-indigo.overlay-darken-3
{
    background: #283593;
    /* The Fallback */
    background: rgba(40, 53, 147, .8);
}

.indigo.darken-4
{
    color: #1a237e !important;
}

.bg-indigo.bg-darken-4
{
    background-color: #1a237e !important;
}
.bg-indigo.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(26, 35, 126, .6);
            box-shadow: 0 1px 20px 1px rgba(26, 35, 126, .6);
}

.btn-indigo.btn-darken-4
{
    border-color: #303f9f !important;
    background-color: #1a237e !important;
}
.btn-indigo.btn-darken-4:hover
{
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-darken-4:focus,
.btn-indigo.btn-darken-4:active
{
    border-color: #283593 !important;
    background-color: #1a237e !important;
}

.btn-outline-indigo.btn-outline-darken-4
{
    color: #1a237e !important; 
    border-color: #1a237e !important;
}
.btn-outline-indigo.btn-outline-darken-4:hover
{
    background-color: #1a237e !important;
}

input:focus ~ .bg-indigo
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1a237e !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1a237e !important;
}

.border-indigo.border-darken-4
{
    border: 1px solid #1a237e !important;
}

.border-top-indigo.border-top-darken-4
{
    border-top: 1px solid #1a237e !important;
}

.border-bottom-indigo.border-bottom-darken-4
{
    border-bottom: 1px solid #1a237e !important;
}

.border-left-indigo.border-left-darken-4
{
    border-left: 1px solid #1a237e !important;
}

.border-right-indigo.border-right-darken-4
{
    border-right: 1px solid #1a237e !important;
}

.overlay-indigo.overlay-darken-4
{
    background: #1a237e;
    /* The Fallback */
    background: rgba(26, 35, 126, .8);
}

.indigo.accent-1
{
    color: #8c9eff !important;
}

.bg-indigo.bg-accent-1
{
    background-color: #8c9eff !important;
}
.bg-indigo.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(140, 158, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(140, 158, 255, .6);
}

.btn-indigo.btn-accent-1
{
    border-color: #303f9f !important;
    background-color: #8c9eff !important;
}
.btn-indigo.btn-accent-1:hover
{
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-accent-1:focus,
.btn-indigo.btn-accent-1:active
{
    border-color: #283593 !important;
    background-color: #1a237e !important;
}

.btn-outline-indigo.btn-outline-accent-1
{
    color: #8c9eff !important; 
    border-color: #8c9eff !important;
}
.btn-outline-indigo.btn-outline-accent-1:hover
{
    background-color: #8c9eff !important;
}

input:focus ~ .bg-indigo
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #8c9eff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #8c9eff !important;
}

.border-indigo.border-accent-1
{
    border: 1px solid #8c9eff !important;
}

.border-top-indigo.border-top-accent-1
{
    border-top: 1px solid #8c9eff !important;
}

.border-bottom-indigo.border-bottom-accent-1
{
    border-bottom: 1px solid #8c9eff !important;
}

.border-left-indigo.border-left-accent-1
{
    border-left: 1px solid #8c9eff !important;
}

.border-right-indigo.border-right-accent-1
{
    border-right: 1px solid #8c9eff !important;
}

.overlay-indigo.overlay-accent-1
{
    background: #8c9eff;
    /* The Fallback */
    background: rgba(140, 158, 255, .8);
}

.indigo.accent-2
{
    color: #536dfe !important;
}

.bg-indigo.bg-accent-2
{
    background-color: #536dfe !important;
}
.bg-indigo.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(83, 109, 254, .6);
            box-shadow: 0 1px 20px 1px rgba(83, 109, 254, .6);
}

.btn-indigo.btn-accent-2
{
    border-color: #303f9f !important;
    background-color: #536dfe !important;
}
.btn-indigo.btn-accent-2:hover
{
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-accent-2:focus,
.btn-indigo.btn-accent-2:active
{
    border-color: #283593 !important;
    background-color: #1a237e !important;
}

.btn-outline-indigo.btn-outline-accent-2
{
    color: #536dfe !important; 
    border-color: #536dfe !important;
}
.btn-outline-indigo.btn-outline-accent-2:hover
{
    background-color: #536dfe !important;
}

input:focus ~ .bg-indigo
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #536dfe !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #536dfe !important;
}

.border-indigo.border-accent-2
{
    border: 1px solid #536dfe !important;
}

.border-top-indigo.border-top-accent-2
{
    border-top: 1px solid #536dfe !important;
}

.border-bottom-indigo.border-bottom-accent-2
{
    border-bottom: 1px solid #536dfe !important;
}

.border-left-indigo.border-left-accent-2
{
    border-left: 1px solid #536dfe !important;
}

.border-right-indigo.border-right-accent-2
{
    border-right: 1px solid #536dfe !important;
}

.overlay-indigo.overlay-accent-2
{
    background: #536dfe;
    /* The Fallback */
    background: rgba(83, 109, 254, .8);
}

.indigo.accent-3
{
    color: #3d5afe !important;
}

.bg-indigo.bg-accent-3
{
    background-color: #3d5afe !important;
}
.bg-indigo.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(61, 90, 254, .6);
            box-shadow: 0 1px 20px 1px rgba(61, 90, 254, .6);
}

.btn-indigo.btn-accent-3
{
    border-color: #303f9f !important;
    background-color: #3d5afe !important;
}
.btn-indigo.btn-accent-3:hover
{
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-accent-3:focus,
.btn-indigo.btn-accent-3:active
{
    border-color: #283593 !important;
    background-color: #1a237e !important;
}

.btn-outline-indigo.btn-outline-accent-3
{
    color: #3d5afe !important; 
    border-color: #3d5afe !important;
}
.btn-outline-indigo.btn-outline-accent-3:hover
{
    background-color: #3d5afe !important;
}

input:focus ~ .bg-indigo
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #3d5afe !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #3d5afe !important;
}

.border-indigo.border-accent-3
{
    border: 1px solid #3d5afe !important;
}

.border-top-indigo.border-top-accent-3
{
    border-top: 1px solid #3d5afe !important;
}

.border-bottom-indigo.border-bottom-accent-3
{
    border-bottom: 1px solid #3d5afe !important;
}

.border-left-indigo.border-left-accent-3
{
    border-left: 1px solid #3d5afe !important;
}

.border-right-indigo.border-right-accent-3
{
    border-right: 1px solid #3d5afe !important;
}

.overlay-indigo.overlay-accent-3
{
    background: #3d5afe;
    /* The Fallback */
    background: rgba(61, 90, 254, .8);
}

.indigo.accent-4
{
    color: #304ffe !important;
}

.bg-indigo.bg-accent-4
{
    background-color: #304ffe !important;
}
.bg-indigo.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(48, 79, 254, .6);
            box-shadow: 0 1px 20px 1px rgba(48, 79, 254, .6);
}

.btn-indigo.btn-accent-4
{
    border-color: #303f9f !important;
    background-color: #304ffe !important;
}
.btn-indigo.btn-accent-4:hover
{
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-accent-4:focus,
.btn-indigo.btn-accent-4:active
{
    border-color: #283593 !important;
    background-color: #1a237e !important;
}

.btn-outline-indigo.btn-outline-accent-4
{
    color: #304ffe !important; 
    border-color: #304ffe !important;
}
.btn-outline-indigo.btn-outline-accent-4:hover
{
    background-color: #304ffe !important;
}

input:focus ~ .bg-indigo
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #304ffe !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #304ffe !important;
}

.border-indigo.border-accent-4
{
    border: 1px solid #304ffe !important;
}

.border-top-indigo.border-top-accent-4
{
    border-top: 1px solid #304ffe !important;
}

.border-bottom-indigo.border-bottom-accent-4
{
    border-bottom: 1px solid #304ffe !important;
}

.border-left-indigo.border-left-accent-4
{
    border-left: 1px solid #304ffe !important;
}

.border-right-indigo.border-right-accent-4
{
    border-right: 1px solid #304ffe !important;
}

.overlay-indigo.overlay-accent-4
{
    background: #304ffe;
    /* The Fallback */
    background: rgba(48, 79, 254, .8);
}

.blue.lighten-5
{
    color: #e3f2fd !important;
}

.bg-blue.bg-lighten-5
{
    background-color: #e3f2fd !important;
}
.bg-blue.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(227, 242, 253, .6);
            box-shadow: 0 1px 20px 1px rgba(227, 242, 253, .6);
}

.btn-blue.btn-lighten-5
{
    border-color: #1976d2 !important;
    background-color: #e3f2fd !important;
}
.btn-blue.btn-lighten-5:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-lighten-5:focus,
.btn-blue.btn-lighten-5:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-lighten-5
{
    color: #e3f2fd !important; 
    border-color: #e3f2fd !important;
}
.btn-outline-blue.btn-outline-lighten-5:hover
{
    background-color: #e3f2fd !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e3f2fd !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e3f2fd !important;
}

.border-blue.border-lighten-5
{
    border: 1px solid #e3f2fd !important;
}

.border-top-blue.border-top-lighten-5
{
    border-top: 1px solid #e3f2fd !important;
}

.border-bottom-blue.border-bottom-lighten-5
{
    border-bottom: 1px solid #e3f2fd !important;
}

.border-left-blue.border-left-lighten-5
{
    border-left: 1px solid #e3f2fd !important;
}

.border-right-blue.border-right-lighten-5
{
    border-right: 1px solid #e3f2fd !important;
}

.overlay-blue.overlay-lighten-5
{
    background: #e3f2fd;
    /* The Fallback */
    background: rgba(227, 242, 253, .8);
}

.blue.lighten-4
{
    color: #bbdefb !important;
}

.bg-blue.bg-lighten-4
{
    background-color: #bbdefb !important;
}
.bg-blue.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(187, 222, 251, .6);
            box-shadow: 0 1px 20px 1px rgba(187, 222, 251, .6);
}

.btn-blue.btn-lighten-4
{
    border-color: #1976d2 !important;
    background-color: #bbdefb !important;
}
.btn-blue.btn-lighten-4:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-lighten-4:focus,
.btn-blue.btn-lighten-4:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-lighten-4
{
    color: #bbdefb !important; 
    border-color: #bbdefb !important;
}
.btn-outline-blue.btn-outline-lighten-4:hover
{
    background-color: #bbdefb !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bbdefb !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bbdefb !important;
}

.border-blue.border-lighten-4
{
    border: 1px solid #bbdefb !important;
}

.border-top-blue.border-top-lighten-4
{
    border-top: 1px solid #bbdefb !important;
}

.border-bottom-blue.border-bottom-lighten-4
{
    border-bottom: 1px solid #bbdefb !important;
}

.border-left-blue.border-left-lighten-4
{
    border-left: 1px solid #bbdefb !important;
}

.border-right-blue.border-right-lighten-4
{
    border-right: 1px solid #bbdefb !important;
}

.overlay-blue.overlay-lighten-4
{
    background: #bbdefb;
    /* The Fallback */
    background: rgba(187, 222, 251, .8);
}

.blue.lighten-3
{
    color: #90caf9 !important;
}

.bg-blue.bg-lighten-3
{
    background-color: #90caf9 !important;
}
.bg-blue.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(144, 202, 249, .6);
            box-shadow: 0 1px 20px 1px rgba(144, 202, 249, .6);
}

.btn-blue.btn-lighten-3
{
    border-color: #1976d2 !important;
    background-color: #90caf9 !important;
}
.btn-blue.btn-lighten-3:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-lighten-3:focus,
.btn-blue.btn-lighten-3:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-lighten-3
{
    color: #90caf9 !important; 
    border-color: #90caf9 !important;
}
.btn-outline-blue.btn-outline-lighten-3:hover
{
    background-color: #90caf9 !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #90caf9 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #90caf9 !important;
}

.border-blue.border-lighten-3
{
    border: 1px solid #90caf9 !important;
}

.border-top-blue.border-top-lighten-3
{
    border-top: 1px solid #90caf9 !important;
}

.border-bottom-blue.border-bottom-lighten-3
{
    border-bottom: 1px solid #90caf9 !important;
}

.border-left-blue.border-left-lighten-3
{
    border-left: 1px solid #90caf9 !important;
}

.border-right-blue.border-right-lighten-3
{
    border-right: 1px solid #90caf9 !important;
}

.overlay-blue.overlay-lighten-3
{
    background: #90caf9;
    /* The Fallback */
    background: rgba(144, 202, 249, .8);
}

.blue.lighten-2
{
    color: #64b5f6 !important;
}

.bg-blue.bg-lighten-2
{
    background-color: #64b5f6 !important;
}
.bg-blue.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(100, 181, 246, .6);
            box-shadow: 0 1px 20px 1px rgba(100, 181, 246, .6);
}

.btn-blue.btn-lighten-2
{
    border-color: #1976d2 !important;
    background-color: #64b5f6 !important;
}
.btn-blue.btn-lighten-2:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-lighten-2:focus,
.btn-blue.btn-lighten-2:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-lighten-2
{
    color: #64b5f6 !important; 
    border-color: #64b5f6 !important;
}
.btn-outline-blue.btn-outline-lighten-2:hover
{
    background-color: #64b5f6 !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #64b5f6 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #64b5f6 !important;
}

.border-blue.border-lighten-2
{
    border: 1px solid #64b5f6 !important;
}

.border-top-blue.border-top-lighten-2
{
    border-top: 1px solid #64b5f6 !important;
}

.border-bottom-blue.border-bottom-lighten-2
{
    border-bottom: 1px solid #64b5f6 !important;
}

.border-left-blue.border-left-lighten-2
{
    border-left: 1px solid #64b5f6 !important;
}

.border-right-blue.border-right-lighten-2
{
    border-right: 1px solid #64b5f6 !important;
}

.overlay-blue.overlay-lighten-2
{
    background: #64b5f6;
    /* The Fallback */
    background: rgba(100, 181, 246, .8);
}

.blue.lighten-1
{
    color: #42a5f5 !important;
}

.bg-blue.bg-lighten-1
{
    background-color: #42a5f5 !important;
}
.bg-blue.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(66, 165, 245, .6);
            box-shadow: 0 1px 20px 1px rgba(66, 165, 245, .6);
}

.btn-blue.btn-lighten-1
{
    border-color: #1976d2 !important;
    background-color: #42a5f5 !important;
}
.btn-blue.btn-lighten-1:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-lighten-1:focus,
.btn-blue.btn-lighten-1:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-lighten-1
{
    color: #42a5f5 !important; 
    border-color: #42a5f5 !important;
}
.btn-outline-blue.btn-outline-lighten-1:hover
{
    background-color: #42a5f5 !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #42a5f5 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #42a5f5 !important;
}

.border-blue.border-lighten-1
{
    border: 1px solid #42a5f5 !important;
}

.border-top-blue.border-top-lighten-1
{
    border-top: 1px solid #42a5f5 !important;
}

.border-bottom-blue.border-bottom-lighten-1
{
    border-bottom: 1px solid #42a5f5 !important;
}

.border-left-blue.border-left-lighten-1
{
    border-left: 1px solid #42a5f5 !important;
}

.border-right-blue.border-right-lighten-1
{
    border-right: 1px solid #42a5f5 !important;
}

.overlay-blue.overlay-lighten-1
{
    background: #42a5f5;
    /* The Fallback */
    background: rgba(66, 165, 245, .8);
}

.blue
{
    color: #2196f3 !important;
}

.bg-blue
{
    background-color: #2196f3 !important;
}
.bg-blue .card-header,
.bg-blue .card-footer
{
    background-color: transparent;
}
.bg-blue.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(33, 150, 243, .6);
            box-shadow: 0 1px 20px 1px rgba(33, 150, 243, .6);
}

.selectboxit.selectboxit-btn.bg-blue
{
    background-color: #2196f3 !important;
}

.alert-blue
{
    color: #05365d !important; 
    border-color: #2196f3 !important;
    background-color: #65b6f7 !important;
}
.alert-blue .alert-link
{
    color: #031f36 !important;
}

.border-blue
{
    border-color: #2196f3;
}

.overlay-blue
{
    background: #2196f3;
    /* The Fallback */
    background: rgba(33, 150, 243, .8);
}

/* .card-outline-blue{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-blue
{
    color: #fff; 
    border-color: #2196f3 !important;
    background-color: #2196f3 !important;
}
.btn-blue:hover
{
    color: #fff !important; 
    border-color: #1976d2 !important;
    background-color: #1e88e5 !important;
}
.btn-blue:focus,
.btn-blue:active
{
    color: #fff !important; 
    border-color: #1565c0 !important;
    background-color: #1976d2 !important;
}
.btn-blue.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(33, 150, 243, .6);
            box-shadow: 0 1px 20px 1px rgba(33, 150, 243, .6);
}

.btn-outline-blue
{
    color: #2196f3; 
    border-color: #2196f3;
    background-color: transparent;
}
.btn-outline-blue:hover
{
    color: #fff !important; 
    background-color: #2196f3;
}
.btn-outline-blue.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(33, 150, 243, .6);
            box-shadow: 0 1px 20px 1px rgba(33, 150, 243, .6);
}

input[type='checkbox'].bg-blue + .custom-control-label:before,
input[type='radio'].bg-blue + .custom-control-label:before
{
    background-color: #2196f3 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #2196f3 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #2196f3 !important;
}

.border-blue
{
    border: 1px solid #2196f3 !important;
}

.border-top-blue
{
    border-top: 1px solid #2196f3;
}

.border-bottom-blue
{
    border-bottom: 1px solid #2196f3;
}

.border-left-blue
{
    border-left: 1px solid #2196f3;
}

.border-right-blue
{
    border-right: 1px solid #2196f3;
}

.blue.darken-1
{
    color: #1e88e5 !important;
}

.bg-blue.bg-darken-1
{
    background-color: #1e88e5 !important;
}
.bg-blue.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(30, 136, 229, .6);
            box-shadow: 0 1px 20px 1px rgba(30, 136, 229, .6);
}

.btn-blue.btn-darken-1
{
    border-color: #1976d2 !important;
    background-color: #1e88e5 !important;
}
.btn-blue.btn-darken-1:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-darken-1:focus,
.btn-blue.btn-darken-1:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-darken-1
{
    color: #1e88e5 !important; 
    border-color: #1e88e5 !important;
}
.btn-outline-blue.btn-outline-darken-1:hover
{
    background-color: #1e88e5 !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1e88e5 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1e88e5 !important;
}

.border-blue.border-darken-1
{
    border: 1px solid #1e88e5 !important;
}

.border-top-blue.border-top-darken-1
{
    border-top: 1px solid #1e88e5 !important;
}

.border-bottom-blue.border-bottom-darken-1
{
    border-bottom: 1px solid #1e88e5 !important;
}

.border-left-blue.border-left-darken-1
{
    border-left: 1px solid #1e88e5 !important;
}

.border-right-blue.border-right-darken-1
{
    border-right: 1px solid #1e88e5 !important;
}

.overlay-blue.overlay-darken-1
{
    background: #1e88e5;
    /* The Fallback */
    background: rgba(30, 136, 229, .8);
}

.blue.darken-2
{
    color: #1976d2 !important;
}

.bg-blue.bg-darken-2
{
    background-color: #1976d2 !important;
}
.bg-blue.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(25, 118, 210, .6);
            box-shadow: 0 1px 20px 1px rgba(25, 118, 210, .6);
}

.btn-blue.btn-darken-2
{
    border-color: #1976d2 !important;
    background-color: #1976d2 !important;
}
.btn-blue.btn-darken-2:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-darken-2:focus,
.btn-blue.btn-darken-2:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-darken-2
{
    color: #1976d2 !important; 
    border-color: #1976d2 !important;
}
.btn-outline-blue.btn-outline-darken-2:hover
{
    background-color: #1976d2 !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1976d2 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1976d2 !important;
}

.border-blue.border-darken-2
{
    border: 1px solid #1976d2 !important;
}

.border-top-blue.border-top-darken-2
{
    border-top: 1px solid #1976d2 !important;
}

.border-bottom-blue.border-bottom-darken-2
{
    border-bottom: 1px solid #1976d2 !important;
}

.border-left-blue.border-left-darken-2
{
    border-left: 1px solid #1976d2 !important;
}

.border-right-blue.border-right-darken-2
{
    border-right: 1px solid #1976d2 !important;
}

.overlay-blue.overlay-darken-2
{
    background: #1976d2;
    /* The Fallback */
    background: rgba(25, 118, 210, .8);
}

.blue.darken-3
{
    color: #1565c0 !important;
}

.bg-blue.bg-darken-3
{
    background-color: #1565c0 !important;
}
.bg-blue.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(21, 101, 192, .6);
            box-shadow: 0 1px 20px 1px rgba(21, 101, 192, .6);
}

.btn-blue.btn-darken-3
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-darken-3:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-darken-3:focus,
.btn-blue.btn-darken-3:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-darken-3
{
    color: #1565c0 !important; 
    border-color: #1565c0 !important;
}
.btn-outline-blue.btn-outline-darken-3:hover
{
    background-color: #1565c0 !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1565c0 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1565c0 !important;
}

.border-blue.border-darken-3
{
    border: 1px solid #1565c0 !important;
}

.border-top-blue.border-top-darken-3
{
    border-top: 1px solid #1565c0 !important;
}

.border-bottom-blue.border-bottom-darken-3
{
    border-bottom: 1px solid #1565c0 !important;
}

.border-left-blue.border-left-darken-3
{
    border-left: 1px solid #1565c0 !important;
}

.border-right-blue.border-right-darken-3
{
    border-right: 1px solid #1565c0 !important;
}

.overlay-blue.overlay-darken-3
{
    background: #1565c0;
    /* The Fallback */
    background: rgba(21, 101, 192, .8);
}

.blue.darken-4
{
    color: #0d47a1 !important;
}

.bg-blue.bg-darken-4
{
    background-color: #0d47a1 !important;
}
.bg-blue.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(13, 71, 161, .6);
            box-shadow: 0 1px 20px 1px rgba(13, 71, 161, .6);
}

.btn-blue.btn-darken-4
{
    border-color: #1976d2 !important;
    background-color: #0d47a1 !important;
}
.btn-blue.btn-darken-4:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-darken-4:focus,
.btn-blue.btn-darken-4:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-darken-4
{
    color: #0d47a1 !important; 
    border-color: #0d47a1 !important;
}
.btn-outline-blue.btn-outline-darken-4:hover
{
    background-color: #0d47a1 !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0d47a1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0d47a1 !important;
}

.border-blue.border-darken-4
{
    border: 1px solid #0d47a1 !important;
}

.border-top-blue.border-top-darken-4
{
    border-top: 1px solid #0d47a1 !important;
}

.border-bottom-blue.border-bottom-darken-4
{
    border-bottom: 1px solid #0d47a1 !important;
}

.border-left-blue.border-left-darken-4
{
    border-left: 1px solid #0d47a1 !important;
}

.border-right-blue.border-right-darken-4
{
    border-right: 1px solid #0d47a1 !important;
}

.overlay-blue.overlay-darken-4
{
    background: #0d47a1;
    /* The Fallback */
    background: rgba(13, 71, 161, .8);
}

.blue.accent-1
{
    color: #82b1ff !important;
}

.bg-blue.bg-accent-1
{
    background-color: #82b1ff !important;
}
.bg-blue.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(130, 177, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(130, 177, 255, .6);
}

.btn-blue.btn-accent-1
{
    border-color: #1976d2 !important;
    background-color: #82b1ff !important;
}
.btn-blue.btn-accent-1:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-accent-1:focus,
.btn-blue.btn-accent-1:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-accent-1
{
    color: #82b1ff !important; 
    border-color: #82b1ff !important;
}
.btn-outline-blue.btn-outline-accent-1:hover
{
    background-color: #82b1ff !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #82b1ff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #82b1ff !important;
}

.border-blue.border-accent-1
{
    border: 1px solid #82b1ff !important;
}

.border-top-blue.border-top-accent-1
{
    border-top: 1px solid #82b1ff !important;
}

.border-bottom-blue.border-bottom-accent-1
{
    border-bottom: 1px solid #82b1ff !important;
}

.border-left-blue.border-left-accent-1
{
    border-left: 1px solid #82b1ff !important;
}

.border-right-blue.border-right-accent-1
{
    border-right: 1px solid #82b1ff !important;
}

.overlay-blue.overlay-accent-1
{
    background: #82b1ff;
    /* The Fallback */
    background: rgba(130, 177, 255, .8);
}

.blue.accent-2
{
    color: #448aff !important;
}

.bg-blue.bg-accent-2
{
    background-color: #448aff !important;
}
.bg-blue.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(68, 138, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(68, 138, 255, .6);
}

.btn-blue.btn-accent-2
{
    border-color: #1976d2 !important;
    background-color: #448aff !important;
}
.btn-blue.btn-accent-2:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-accent-2:focus,
.btn-blue.btn-accent-2:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-accent-2
{
    color: #448aff !important; 
    border-color: #448aff !important;
}
.btn-outline-blue.btn-outline-accent-2:hover
{
    background-color: #448aff !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #448aff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #448aff !important;
}

.border-blue.border-accent-2
{
    border: 1px solid #448aff !important;
}

.border-top-blue.border-top-accent-2
{
    border-top: 1px solid #448aff !important;
}

.border-bottom-blue.border-bottom-accent-2
{
    border-bottom: 1px solid #448aff !important;
}

.border-left-blue.border-left-accent-2
{
    border-left: 1px solid #448aff !important;
}

.border-right-blue.border-right-accent-2
{
    border-right: 1px solid #448aff !important;
}

.overlay-blue.overlay-accent-2
{
    background: #448aff;
    /* The Fallback */
    background: rgba(68, 138, 255, .8);
}

.blue.accent-3
{
    color: #2979ff !important;
}

.bg-blue.bg-accent-3
{
    background-color: #2979ff !important;
}
.bg-blue.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(41, 121, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(41, 121, 255, .6);
}

.btn-blue.btn-accent-3
{
    border-color: #1976d2 !important;
    background-color: #2979ff !important;
}
.btn-blue.btn-accent-3:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-accent-3:focus,
.btn-blue.btn-accent-3:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-accent-3
{
    color: #2979ff !important; 
    border-color: #2979ff !important;
}
.btn-outline-blue.btn-outline-accent-3:hover
{
    background-color: #2979ff !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #2979ff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #2979ff !important;
}

.border-blue.border-accent-3
{
    border: 1px solid #2979ff !important;
}

.border-top-blue.border-top-accent-3
{
    border-top: 1px solid #2979ff !important;
}

.border-bottom-blue.border-bottom-accent-3
{
    border-bottom: 1px solid #2979ff !important;
}

.border-left-blue.border-left-accent-3
{
    border-left: 1px solid #2979ff !important;
}

.border-right-blue.border-right-accent-3
{
    border-right: 1px solid #2979ff !important;
}

.overlay-blue.overlay-accent-3
{
    background: #2979ff;
    /* The Fallback */
    background: rgba(41, 121, 255, .8);
}

.blue.accent-4
{
    color: #2962ff !important;
}

.bg-blue.bg-accent-4
{
    background-color: #2962ff !important;
}
.bg-blue.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(41, 98, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(41, 98, 255, .6);
}

.btn-blue.btn-accent-4
{
    border-color: #1976d2 !important;
    background-color: #2962ff !important;
}
.btn-blue.btn-accent-4:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-accent-4:focus,
.btn-blue.btn-accent-4:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-accent-4
{
    color: #2962ff !important; 
    border-color: #2962ff !important;
}
.btn-outline-blue.btn-outline-accent-4:hover
{
    background-color: #2962ff !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #2962ff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #2962ff !important;
}

.border-blue.border-accent-4
{
    border: 1px solid #2962ff !important;
}

.border-top-blue.border-top-accent-4
{
    border-top: 1px solid #2962ff !important;
}

.border-bottom-blue.border-bottom-accent-4
{
    border-bottom: 1px solid #2962ff !important;
}

.border-left-blue.border-left-accent-4
{
    border-left: 1px solid #2962ff !important;
}

.border-right-blue.border-right-accent-4
{
    border-right: 1px solid #2962ff !important;
}

.overlay-blue.overlay-accent-4
{
    background: #2962ff;
    /* The Fallback */
    background: rgba(41, 98, 255, .8);
}

.light-blue.lighten-5
{
    color: #e1f5fe !important;
}

.bg-light-blue.bg-lighten-5
{
    background-color: #e1f5fe !important;
}
.bg-light-blue.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(225, 245, 254, .6);
            box-shadow: 0 1px 20px 1px rgba(225, 245, 254, .6);
}

.btn-light-blue.btn-lighten-5
{
    border-color: #0288d1 !important;
    background-color: #e1f5fe !important;
}
.btn-light-blue.btn-lighten-5:hover
{
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-lighten-5:focus,
.btn-light-blue.btn-lighten-5:active
{
    border-color: #0277bd !important;
    background-color: #01579b !important;
}

.btn-outline-light-blue.btn-outline-lighten-5
{
    color: #e1f5fe !important; 
    border-color: #e1f5fe !important;
}
.btn-outline-light-blue.btn-outline-lighten-5:hover
{
    background-color: #e1f5fe !important;
}

input:focus ~ .bg-light-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e1f5fe !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e1f5fe !important;
}

.border-light-blue.border-lighten-5
{
    border: 1px solid #e1f5fe !important;
}

.border-top-light-blue.border-top-lighten-5
{
    border-top: 1px solid #e1f5fe !important;
}

.border-bottom-light-blue.border-bottom-lighten-5
{
    border-bottom: 1px solid #e1f5fe !important;
}

.border-left-light-blue.border-left-lighten-5
{
    border-left: 1px solid #e1f5fe !important;
}

.border-right-light-blue.border-right-lighten-5
{
    border-right: 1px solid #e1f5fe !important;
}

.overlay-light-blue.overlay-lighten-5
{
    background: #e1f5fe;
    /* The Fallback */
    background: rgba(225, 245, 254, .8);
}

.light-blue.lighten-4
{
    color: #b3e5fc !important;
}

.bg-light-blue.bg-lighten-4
{
    background-color: #b3e5fc !important;
}
.bg-light-blue.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(179, 229, 252, .6);
            box-shadow: 0 1px 20px 1px rgba(179, 229, 252, .6);
}

.btn-light-blue.btn-lighten-4
{
    border-color: #0288d1 !important;
    background-color: #b3e5fc !important;
}
.btn-light-blue.btn-lighten-4:hover
{
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-lighten-4:focus,
.btn-light-blue.btn-lighten-4:active
{
    border-color: #0277bd !important;
    background-color: #01579b !important;
}

.btn-outline-light-blue.btn-outline-lighten-4
{
    color: #b3e5fc !important; 
    border-color: #b3e5fc !important;
}
.btn-outline-light-blue.btn-outline-lighten-4:hover
{
    background-color: #b3e5fc !important;
}

input:focus ~ .bg-light-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b3e5fc !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b3e5fc !important;
}

.border-light-blue.border-lighten-4
{
    border: 1px solid #b3e5fc !important;
}

.border-top-light-blue.border-top-lighten-4
{
    border-top: 1px solid #b3e5fc !important;
}

.border-bottom-light-blue.border-bottom-lighten-4
{
    border-bottom: 1px solid #b3e5fc !important;
}

.border-left-light-blue.border-left-lighten-4
{
    border-left: 1px solid #b3e5fc !important;
}

.border-right-light-blue.border-right-lighten-4
{
    border-right: 1px solid #b3e5fc !important;
}

.overlay-light-blue.overlay-lighten-4
{
    background: #b3e5fc;
    /* The Fallback */
    background: rgba(179, 229, 252, .8);
}

.light-blue.lighten-3
{
    color: #81d4fa !important;
}

.bg-light-blue.bg-lighten-3
{
    background-color: #81d4fa !important;
}
.bg-light-blue.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(129, 212, 250, .6);
            box-shadow: 0 1px 20px 1px rgba(129, 212, 250, .6);
}

.btn-light-blue.btn-lighten-3
{
    border-color: #0288d1 !important;
    background-color: #81d4fa !important;
}
.btn-light-blue.btn-lighten-3:hover
{
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-lighten-3:focus,
.btn-light-blue.btn-lighten-3:active
{
    border-color: #0277bd !important;
    background-color: #01579b !important;
}

.btn-outline-light-blue.btn-outline-lighten-3
{
    color: #81d4fa !important; 
    border-color: #81d4fa !important;
}
.btn-outline-light-blue.btn-outline-lighten-3:hover
{
    background-color: #81d4fa !important;
}

input:focus ~ .bg-light-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #81d4fa !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #81d4fa !important;
}

.border-light-blue.border-lighten-3
{
    border: 1px solid #81d4fa !important;
}

.border-top-light-blue.border-top-lighten-3
{
    border-top: 1px solid #81d4fa !important;
}

.border-bottom-light-blue.border-bottom-lighten-3
{
    border-bottom: 1px solid #81d4fa !important;
}

.border-left-light-blue.border-left-lighten-3
{
    border-left: 1px solid #81d4fa !important;
}

.border-right-light-blue.border-right-lighten-3
{
    border-right: 1px solid #81d4fa !important;
}

.overlay-light-blue.overlay-lighten-3
{
    background: #81d4fa;
    /* The Fallback */
    background: rgba(129, 212, 250, .8);
}

.light-blue.lighten-2
{
    color: #4fc3f7 !important;
}

.bg-light-blue.bg-lighten-2
{
    background-color: #4fc3f7 !important;
}
.bg-light-blue.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(79, 195, 247, .6);
            box-shadow: 0 1px 20px 1px rgba(79, 195, 247, .6);
}

.btn-light-blue.btn-lighten-2
{
    border-color: #0288d1 !important;
    background-color: #4fc3f7 !important;
}
.btn-light-blue.btn-lighten-2:hover
{
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-lighten-2:focus,
.btn-light-blue.btn-lighten-2:active
{
    border-color: #0277bd !important;
    background-color: #01579b !important;
}

.btn-outline-light-blue.btn-outline-lighten-2
{
    color: #4fc3f7 !important; 
    border-color: #4fc3f7 !important;
}
.btn-outline-light-blue.btn-outline-lighten-2:hover
{
    background-color: #4fc3f7 !important;
}

input:focus ~ .bg-light-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4fc3f7 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4fc3f7 !important;
}

.border-light-blue.border-lighten-2
{
    border: 1px solid #4fc3f7 !important;
}

.border-top-light-blue.border-top-lighten-2
{
    border-top: 1px solid #4fc3f7 !important;
}

.border-bottom-light-blue.border-bottom-lighten-2
{
    border-bottom: 1px solid #4fc3f7 !important;
}

.border-left-light-blue.border-left-lighten-2
{
    border-left: 1px solid #4fc3f7 !important;
}

.border-right-light-blue.border-right-lighten-2
{
    border-right: 1px solid #4fc3f7 !important;
}

.overlay-light-blue.overlay-lighten-2
{
    background: #4fc3f7;
    /* The Fallback */
    background: rgba(79, 195, 247, .8);
}

.light-blue.lighten-1
{
    color: #29b6f6 !important;
}

.bg-light-blue.bg-lighten-1
{
    background-color: #29b6f6 !important;
}
.bg-light-blue.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(41, 182, 246, .6);
            box-shadow: 0 1px 20px 1px rgba(41, 182, 246, .6);
}

.btn-light-blue.btn-lighten-1
{
    border-color: #0288d1 !important;
    background-color: #29b6f6 !important;
}
.btn-light-blue.btn-lighten-1:hover
{
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-lighten-1:focus,
.btn-light-blue.btn-lighten-1:active
{
    border-color: #0277bd !important;
    background-color: #01579b !important;
}

.btn-outline-light-blue.btn-outline-lighten-1
{
    color: #29b6f6 !important; 
    border-color: #29b6f6 !important;
}
.btn-outline-light-blue.btn-outline-lighten-1:hover
{
    background-color: #29b6f6 !important;
}

input:focus ~ .bg-light-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #29b6f6 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #29b6f6 !important;
}

.border-light-blue.border-lighten-1
{
    border: 1px solid #29b6f6 !important;
}

.border-top-light-blue.border-top-lighten-1
{
    border-top: 1px solid #29b6f6 !important;
}

.border-bottom-light-blue.border-bottom-lighten-1
{
    border-bottom: 1px solid #29b6f6 !important;
}

.border-left-light-blue.border-left-lighten-1
{
    border-left: 1px solid #29b6f6 !important;
}

.border-right-light-blue.border-right-lighten-1
{
    border-right: 1px solid #29b6f6 !important;
}

.overlay-light-blue.overlay-lighten-1
{
    background: #29b6f6;
    /* The Fallback */
    background: rgba(41, 182, 246, .8);
}

.light-blue
{
    color: #03a9f4 !important;
}

.bg-light-blue
{
    background-color: #03a9f4 !important;
}
.bg-light-blue .card-header,
.bg-light-blue .card-footer
{
    background-color: transparent;
}
.bg-light-blue.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(3, 169, 244, .6);
            box-shadow: 0 1px 20px 1px rgba(3, 169, 244, .6);
}

.selectboxit.selectboxit-btn.bg-light-blue
{
    background-color: #03a9f4 !important;
}

.alert-light-blue
{
    color: #012f44 !important; 
    border-color: #03a9f4 !important;
    background-color: #42c2fd !important;
}
.alert-light-blue .alert-link
{
    color: #00131b !important;
}

.border-light-blue
{
    border-color: #03a9f4;
}

.overlay-light-blue
{
    background: #03a9f4;
    /* The Fallback */
    background: rgba(3, 169, 244, .8);
}

/* .card-outline-light-blue{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-light-blue
{
    color: #fff; 
    border-color: #03a9f4 !important;
    background-color: #03a9f4 !important;
}
.btn-light-blue:hover
{
    color: #fff !important; 
    border-color: #0288d1 !important;
    background-color: #039be5 !important;
}
.btn-light-blue:focus,
.btn-light-blue:active
{
    color: #fff !important; 
    border-color: #0277bd !important;
    background-color: #0288d1 !important;
}
.btn-light-blue.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(3, 169, 244, .6);
            box-shadow: 0 1px 20px 1px rgba(3, 169, 244, .6);
}

.btn-outline-light-blue
{
    color: #03a9f4; 
    border-color: #03a9f4;
    background-color: transparent;
}
.btn-outline-light-blue:hover
{
    color: #fff !important; 
    background-color: #03a9f4;
}
.btn-outline-light-blue.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(3, 169, 244, .6);
            box-shadow: 0 1px 20px 1px rgba(3, 169, 244, .6);
}

input[type='checkbox'].bg-light-blue + .custom-control-label:before,
input[type='radio'].bg-light-blue + .custom-control-label:before
{
    background-color: #03a9f4 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-light-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #03a9f4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #03a9f4 !important;
}

.border-light-blue
{
    border: 1px solid #03a9f4 !important;
}

.border-top-light-blue
{
    border-top: 1px solid #03a9f4;
}

.border-bottom-light-blue
{
    border-bottom: 1px solid #03a9f4;
}

.border-left-light-blue
{
    border-left: 1px solid #03a9f4;
}

.border-right-light-blue
{
    border-right: 1px solid #03a9f4;
}

.light-blue.darken-1
{
    color: #039be5 !important;
}

.bg-light-blue.bg-darken-1
{
    background-color: #039be5 !important;
}
.bg-light-blue.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(3, 155, 229, .6);
            box-shadow: 0 1px 20px 1px rgba(3, 155, 229, .6);
}

.btn-light-blue.btn-darken-1
{
    border-color: #0288d1 !important;
    background-color: #039be5 !important;
}
.btn-light-blue.btn-darken-1:hover
{
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-darken-1:focus,
.btn-light-blue.btn-darken-1:active
{
    border-color: #0277bd !important;
    background-color: #01579b !important;
}

.btn-outline-light-blue.btn-outline-darken-1
{
    color: #039be5 !important; 
    border-color: #039be5 !important;
}
.btn-outline-light-blue.btn-outline-darken-1:hover
{
    background-color: #039be5 !important;
}

input:focus ~ .bg-light-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #039be5 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #039be5 !important;
}

.border-light-blue.border-darken-1
{
    border: 1px solid #039be5 !important;
}

.border-top-light-blue.border-top-darken-1
{
    border-top: 1px solid #039be5 !important;
}

.border-bottom-light-blue.border-bottom-darken-1
{
    border-bottom: 1px solid #039be5 !important;
}

.border-left-light-blue.border-left-darken-1
{
    border-left: 1px solid #039be5 !important;
}

.border-right-light-blue.border-right-darken-1
{
    border-right: 1px solid #039be5 !important;
}

.overlay-light-blue.overlay-darken-1
{
    background: #039be5;
    /* The Fallback */
    background: rgba(3, 155, 229, .8);
}

.light-blue.darken-2
{
    color: #0288d1 !important;
}

.bg-light-blue.bg-darken-2
{
    background-color: #0288d1 !important;
}
.bg-light-blue.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(2, 136, 209, .6);
            box-shadow: 0 1px 20px 1px rgba(2, 136, 209, .6);
}

.btn-light-blue.btn-darken-2
{
    border-color: #0288d1 !important;
    background-color: #0288d1 !important;
}
.btn-light-blue.btn-darken-2:hover
{
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-darken-2:focus,
.btn-light-blue.btn-darken-2:active
{
    border-color: #0277bd !important;
    background-color: #01579b !important;
}

.btn-outline-light-blue.btn-outline-darken-2
{
    color: #0288d1 !important; 
    border-color: #0288d1 !important;
}
.btn-outline-light-blue.btn-outline-darken-2:hover
{
    background-color: #0288d1 !important;
}

input:focus ~ .bg-light-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0288d1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0288d1 !important;
}

.border-light-blue.border-darken-2
{
    border: 1px solid #0288d1 !important;
}

.border-top-light-blue.border-top-darken-2
{
    border-top: 1px solid #0288d1 !important;
}

.border-bottom-light-blue.border-bottom-darken-2
{
    border-bottom: 1px solid #0288d1 !important;
}

.border-left-light-blue.border-left-darken-2
{
    border-left: 1px solid #0288d1 !important;
}

.border-right-light-blue.border-right-darken-2
{
    border-right: 1px solid #0288d1 !important;
}

.overlay-light-blue.overlay-darken-2
{
    background: #0288d1;
    /* The Fallback */
    background: rgba(2, 136, 209, .8);
}

.light-blue.darken-3
{
    color: #0277bd !important;
}

.bg-light-blue.bg-darken-3
{
    background-color: #0277bd !important;
}
.bg-light-blue.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(2, 119, 189, .6);
            box-shadow: 0 1px 20px 1px rgba(2, 119, 189, .6);
}

.btn-light-blue.btn-darken-3
{
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-darken-3:hover
{
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-darken-3:focus,
.btn-light-blue.btn-darken-3:active
{
    border-color: #0277bd !important;
    background-color: #01579b !important;
}

.btn-outline-light-blue.btn-outline-darken-3
{
    color: #0277bd !important; 
    border-color: #0277bd !important;
}
.btn-outline-light-blue.btn-outline-darken-3:hover
{
    background-color: #0277bd !important;
}

input:focus ~ .bg-light-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0277bd !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0277bd !important;
}

.border-light-blue.border-darken-3
{
    border: 1px solid #0277bd !important;
}

.border-top-light-blue.border-top-darken-3
{
    border-top: 1px solid #0277bd !important;
}

.border-bottom-light-blue.border-bottom-darken-3
{
    border-bottom: 1px solid #0277bd !important;
}

.border-left-light-blue.border-left-darken-3
{
    border-left: 1px solid #0277bd !important;
}

.border-right-light-blue.border-right-darken-3
{
    border-right: 1px solid #0277bd !important;
}

.overlay-light-blue.overlay-darken-3
{
    background: #0277bd;
    /* The Fallback */
    background: rgba(2, 119, 189, .8);
}

.light-blue.darken-4
{
    color: #01579b !important;
}

.bg-light-blue.bg-darken-4
{
    background-color: #01579b !important;
}
.bg-light-blue.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(1, 87, 155, .6);
            box-shadow: 0 1px 20px 1px rgba(1, 87, 155, .6);
}

.btn-light-blue.btn-darken-4
{
    border-color: #0288d1 !important;
    background-color: #01579b !important;
}
.btn-light-blue.btn-darken-4:hover
{
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-darken-4:focus,
.btn-light-blue.btn-darken-4:active
{
    border-color: #0277bd !important;
    background-color: #01579b !important;
}

.btn-outline-light-blue.btn-outline-darken-4
{
    color: #01579b !important; 
    border-color: #01579b !important;
}
.btn-outline-light-blue.btn-outline-darken-4:hover
{
    background-color: #01579b !important;
}

input:focus ~ .bg-light-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #01579b !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #01579b !important;
}

.border-light-blue.border-darken-4
{
    border: 1px solid #01579b !important;
}

.border-top-light-blue.border-top-darken-4
{
    border-top: 1px solid #01579b !important;
}

.border-bottom-light-blue.border-bottom-darken-4
{
    border-bottom: 1px solid #01579b !important;
}

.border-left-light-blue.border-left-darken-4
{
    border-left: 1px solid #01579b !important;
}

.border-right-light-blue.border-right-darken-4
{
    border-right: 1px solid #01579b !important;
}

.overlay-light-blue.overlay-darken-4
{
    background: #01579b;
    /* The Fallback */
    background: rgba(1, 87, 155, .8);
}

.light-blue.accent-1
{
    color: #80d8ff !important;
}

.bg-light-blue.bg-accent-1
{
    background-color: #80d8ff !important;
}
.bg-light-blue.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(128, 216, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(128, 216, 255, .6);
}

.btn-light-blue.btn-accent-1
{
    border-color: #0288d1 !important;
    background-color: #80d8ff !important;
}
.btn-light-blue.btn-accent-1:hover
{
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-accent-1:focus,
.btn-light-blue.btn-accent-1:active
{
    border-color: #0277bd !important;
    background-color: #01579b !important;
}

.btn-outline-light-blue.btn-outline-accent-1
{
    color: #80d8ff !important; 
    border-color: #80d8ff !important;
}
.btn-outline-light-blue.btn-outline-accent-1:hover
{
    background-color: #80d8ff !important;
}

input:focus ~ .bg-light-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #80d8ff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #80d8ff !important;
}

.border-light-blue.border-accent-1
{
    border: 1px solid #80d8ff !important;
}

.border-top-light-blue.border-top-accent-1
{
    border-top: 1px solid #80d8ff !important;
}

.border-bottom-light-blue.border-bottom-accent-1
{
    border-bottom: 1px solid #80d8ff !important;
}

.border-left-light-blue.border-left-accent-1
{
    border-left: 1px solid #80d8ff !important;
}

.border-right-light-blue.border-right-accent-1
{
    border-right: 1px solid #80d8ff !important;
}

.overlay-light-blue.overlay-accent-1
{
    background: #80d8ff;
    /* The Fallback */
    background: rgba(128, 216, 255, .8);
}

.light-blue.accent-2
{
    color: #40c4ff !important;
}

.bg-light-blue.bg-accent-2
{
    background-color: #40c4ff !important;
}
.bg-light-blue.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(64, 196, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(64, 196, 255, .6);
}

.btn-light-blue.btn-accent-2
{
    border-color: #0288d1 !important;
    background-color: #40c4ff !important;
}
.btn-light-blue.btn-accent-2:hover
{
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-accent-2:focus,
.btn-light-blue.btn-accent-2:active
{
    border-color: #0277bd !important;
    background-color: #01579b !important;
}

.btn-outline-light-blue.btn-outline-accent-2
{
    color: #40c4ff !important; 
    border-color: #40c4ff !important;
}
.btn-outline-light-blue.btn-outline-accent-2:hover
{
    background-color: #40c4ff !important;
}

input:focus ~ .bg-light-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #40c4ff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #40c4ff !important;
}

.border-light-blue.border-accent-2
{
    border: 1px solid #40c4ff !important;
}

.border-top-light-blue.border-top-accent-2
{
    border-top: 1px solid #40c4ff !important;
}

.border-bottom-light-blue.border-bottom-accent-2
{
    border-bottom: 1px solid #40c4ff !important;
}

.border-left-light-blue.border-left-accent-2
{
    border-left: 1px solid #40c4ff !important;
}

.border-right-light-blue.border-right-accent-2
{
    border-right: 1px solid #40c4ff !important;
}

.overlay-light-blue.overlay-accent-2
{
    background: #40c4ff;
    /* The Fallback */
    background: rgba(64, 196, 255, .8);
}

.light-blue.accent-3
{
    color: #00b0ff !important;
}

.bg-light-blue.bg-accent-3
{
    background-color: #00b0ff !important;
}
.bg-light-blue.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 176, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 176, 255, .6);
}

.btn-light-blue.btn-accent-3
{
    border-color: #0288d1 !important;
    background-color: #00b0ff !important;
}
.btn-light-blue.btn-accent-3:hover
{
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-accent-3:focus,
.btn-light-blue.btn-accent-3:active
{
    border-color: #0277bd !important;
    background-color: #01579b !important;
}

.btn-outline-light-blue.btn-outline-accent-3
{
    color: #00b0ff !important; 
    border-color: #00b0ff !important;
}
.btn-outline-light-blue.btn-outline-accent-3:hover
{
    background-color: #00b0ff !important;
}

input:focus ~ .bg-light-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00b0ff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00b0ff !important;
}

.border-light-blue.border-accent-3
{
    border: 1px solid #00b0ff !important;
}

.border-top-light-blue.border-top-accent-3
{
    border-top: 1px solid #00b0ff !important;
}

.border-bottom-light-blue.border-bottom-accent-3
{
    border-bottom: 1px solid #00b0ff !important;
}

.border-left-light-blue.border-left-accent-3
{
    border-left: 1px solid #00b0ff !important;
}

.border-right-light-blue.border-right-accent-3
{
    border-right: 1px solid #00b0ff !important;
}

.overlay-light-blue.overlay-accent-3
{
    background: #00b0ff;
    /* The Fallback */
    background: rgba(0, 176, 255, .8);
}

.light-blue.accent-4
{
    color: #0091ea !important;
}

.bg-light-blue.bg-accent-4
{
    background-color: #0091ea !important;
}
.bg-light-blue.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 145, 234, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 145, 234, .6);
}

.btn-light-blue.btn-accent-4
{
    border-color: #0288d1 !important;
    background-color: #0091ea !important;
}
.btn-light-blue.btn-accent-4:hover
{
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-accent-4:focus,
.btn-light-blue.btn-accent-4:active
{
    border-color: #0277bd !important;
    background-color: #01579b !important;
}

.btn-outline-light-blue.btn-outline-accent-4
{
    color: #0091ea !important; 
    border-color: #0091ea !important;
}
.btn-outline-light-blue.btn-outline-accent-4:hover
{
    background-color: #0091ea !important;
}

input:focus ~ .bg-light-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0091ea !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0091ea !important;
}

.border-light-blue.border-accent-4
{
    border: 1px solid #0091ea !important;
}

.border-top-light-blue.border-top-accent-4
{
    border-top: 1px solid #0091ea !important;
}

.border-bottom-light-blue.border-bottom-accent-4
{
    border-bottom: 1px solid #0091ea !important;
}

.border-left-light-blue.border-left-accent-4
{
    border-left: 1px solid #0091ea !important;
}

.border-right-light-blue.border-right-accent-4
{
    border-right: 1px solid #0091ea !important;
}

.overlay-light-blue.overlay-accent-4
{
    background: #0091ea;
    /* The Fallback */
    background: rgba(0, 145, 234, .8);
}

.cyan.lighten-5
{
    color: #e0f7fa !important;
}

.bg-cyan.bg-lighten-5
{
    background-color: #e0f7fa !important;
}
.bg-cyan.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(224, 247, 250, .6);
            box-shadow: 0 1px 20px 1px rgba(224, 247, 250, .6);
}

.btn-cyan.btn-lighten-5
{
    border-color: #0097a7 !important;
    background-color: #e0f7fa !important;
}
.btn-cyan.btn-lighten-5:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-lighten-5:focus,
.btn-cyan.btn-lighten-5:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-lighten-5
{
    color: #e0f7fa !important; 
    border-color: #e0f7fa !important;
}
.btn-outline-cyan.btn-outline-lighten-5:hover
{
    background-color: #e0f7fa !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e0f7fa !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e0f7fa !important;
}

.border-cyan.border-lighten-5
{
    border: 1px solid #e0f7fa !important;
}

.border-top-cyan.border-top-lighten-5
{
    border-top: 1px solid #e0f7fa !important;
}

.border-bottom-cyan.border-bottom-lighten-5
{
    border-bottom: 1px solid #e0f7fa !important;
}

.border-left-cyan.border-left-lighten-5
{
    border-left: 1px solid #e0f7fa !important;
}

.border-right-cyan.border-right-lighten-5
{
    border-right: 1px solid #e0f7fa !important;
}

.overlay-cyan.overlay-lighten-5
{
    background: #e0f7fa;
    /* The Fallback */
    background: rgba(224, 247, 250, .8);
}

.cyan.lighten-4
{
    color: #b2ebf2 !important;
}

.bg-cyan.bg-lighten-4
{
    background-color: #b2ebf2 !important;
}
.bg-cyan.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(178, 235, 242, .6);
            box-shadow: 0 1px 20px 1px rgba(178, 235, 242, .6);
}

.btn-cyan.btn-lighten-4
{
    border-color: #0097a7 !important;
    background-color: #b2ebf2 !important;
}
.btn-cyan.btn-lighten-4:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-lighten-4:focus,
.btn-cyan.btn-lighten-4:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-lighten-4
{
    color: #b2ebf2 !important; 
    border-color: #b2ebf2 !important;
}
.btn-outline-cyan.btn-outline-lighten-4:hover
{
    background-color: #b2ebf2 !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b2ebf2 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b2ebf2 !important;
}

.border-cyan.border-lighten-4
{
    border: 1px solid #b2ebf2 !important;
}

.border-top-cyan.border-top-lighten-4
{
    border-top: 1px solid #b2ebf2 !important;
}

.border-bottom-cyan.border-bottom-lighten-4
{
    border-bottom: 1px solid #b2ebf2 !important;
}

.border-left-cyan.border-left-lighten-4
{
    border-left: 1px solid #b2ebf2 !important;
}

.border-right-cyan.border-right-lighten-4
{
    border-right: 1px solid #b2ebf2 !important;
}

.overlay-cyan.overlay-lighten-4
{
    background: #b2ebf2;
    /* The Fallback */
    background: rgba(178, 235, 242, .8);
}

.cyan.lighten-3
{
    color: #80deea !important;
}

.bg-cyan.bg-lighten-3
{
    background-color: #80deea !important;
}
.bg-cyan.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(128, 222, 234, .6);
            box-shadow: 0 1px 20px 1px rgba(128, 222, 234, .6);
}

.btn-cyan.btn-lighten-3
{
    border-color: #0097a7 !important;
    background-color: #80deea !important;
}
.btn-cyan.btn-lighten-3:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-lighten-3:focus,
.btn-cyan.btn-lighten-3:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-lighten-3
{
    color: #80deea !important; 
    border-color: #80deea !important;
}
.btn-outline-cyan.btn-outline-lighten-3:hover
{
    background-color: #80deea !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #80deea !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #80deea !important;
}

.border-cyan.border-lighten-3
{
    border: 1px solid #80deea !important;
}

.border-top-cyan.border-top-lighten-3
{
    border-top: 1px solid #80deea !important;
}

.border-bottom-cyan.border-bottom-lighten-3
{
    border-bottom: 1px solid #80deea !important;
}

.border-left-cyan.border-left-lighten-3
{
    border-left: 1px solid #80deea !important;
}

.border-right-cyan.border-right-lighten-3
{
    border-right: 1px solid #80deea !important;
}

.overlay-cyan.overlay-lighten-3
{
    background: #80deea;
    /* The Fallback */
    background: rgba(128, 222, 234, .8);
}

.cyan.lighten-2
{
    color: #4dd0e1 !important;
}

.bg-cyan.bg-lighten-2
{
    background-color: #4dd0e1 !important;
}
.bg-cyan.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(77, 208, 225, .6);
            box-shadow: 0 1px 20px 1px rgba(77, 208, 225, .6);
}

.btn-cyan.btn-lighten-2
{
    border-color: #0097a7 !important;
    background-color: #4dd0e1 !important;
}
.btn-cyan.btn-lighten-2:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-lighten-2:focus,
.btn-cyan.btn-lighten-2:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-lighten-2
{
    color: #4dd0e1 !important; 
    border-color: #4dd0e1 !important;
}
.btn-outline-cyan.btn-outline-lighten-2:hover
{
    background-color: #4dd0e1 !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4dd0e1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4dd0e1 !important;
}

.border-cyan.border-lighten-2
{
    border: 1px solid #4dd0e1 !important;
}

.border-top-cyan.border-top-lighten-2
{
    border-top: 1px solid #4dd0e1 !important;
}

.border-bottom-cyan.border-bottom-lighten-2
{
    border-bottom: 1px solid #4dd0e1 !important;
}

.border-left-cyan.border-left-lighten-2
{
    border-left: 1px solid #4dd0e1 !important;
}

.border-right-cyan.border-right-lighten-2
{
    border-right: 1px solid #4dd0e1 !important;
}

.overlay-cyan.overlay-lighten-2
{
    background: #4dd0e1;
    /* The Fallback */
    background: rgba(77, 208, 225, .8);
}

.cyan.lighten-1
{
    color: #26c6da !important;
}

.bg-cyan.bg-lighten-1
{
    background-color: #26c6da !important;
}
.bg-cyan.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(38, 198, 218, .6);
            box-shadow: 0 1px 20px 1px rgba(38, 198, 218, .6);
}

.btn-cyan.btn-lighten-1
{
    border-color: #0097a7 !important;
    background-color: #26c6da !important;
}
.btn-cyan.btn-lighten-1:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-lighten-1:focus,
.btn-cyan.btn-lighten-1:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-lighten-1
{
    color: #26c6da !important; 
    border-color: #26c6da !important;
}
.btn-outline-cyan.btn-outline-lighten-1:hover
{
    background-color: #26c6da !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #26c6da !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #26c6da !important;
}

.border-cyan.border-lighten-1
{
    border: 1px solid #26c6da !important;
}

.border-top-cyan.border-top-lighten-1
{
    border-top: 1px solid #26c6da !important;
}

.border-bottom-cyan.border-bottom-lighten-1
{
    border-bottom: 1px solid #26c6da !important;
}

.border-left-cyan.border-left-lighten-1
{
    border-left: 1px solid #26c6da !important;
}

.border-right-cyan.border-right-lighten-1
{
    border-right: 1px solid #26c6da !important;
}

.overlay-cyan.overlay-lighten-1
{
    background: #26c6da;
    /* The Fallback */
    background: rgba(38, 198, 218, .8);
}

.cyan
{
    color: #00bcd4 !important;
}

.bg-cyan
{
    background-color: #00bcd4 !important;
}
.bg-cyan .card-header,
.bg-cyan .card-footer
{
    background-color: transparent;
}
.bg-cyan.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 188, 212, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 188, 212, .6);
}

.selectboxit.selectboxit-btn.bg-cyan
{
    background-color: #00bcd4 !important;
}

.alert-cyan
{
    color: #001e22 !important; 
    border-color: #00bcd4 !important;
    background-color: #1ce5ff !important;
}
.alert-cyan .alert-link
{
    color: black !important;
}

.border-cyan
{
    border-color: #00bcd4;
}

.overlay-cyan
{
    background: #00bcd4;
    /* The Fallback */
    background: rgba(0, 188, 212, .8);
}

/* .card-outline-cyan{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-cyan
{
    color: #fff; 
    border-color: #00bcd4 !important;
    background-color: #00bcd4 !important;
}
.btn-cyan:hover
{
    color: #fff !important; 
    border-color: #0097a7 !important;
    background-color: #00acc1 !important;
}
.btn-cyan:focus,
.btn-cyan:active
{
    color: #fff !important; 
    border-color: #00838f !important;
    background-color: #0097a7 !important;
}
.btn-cyan.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 188, 212, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 188, 212, .6);
}

.btn-outline-cyan
{
    color: #00bcd4; 
    border-color: #00bcd4;
    background-color: transparent;
}
.btn-outline-cyan:hover
{
    color: #fff !important; 
    background-color: #00bcd4;
}
.btn-outline-cyan.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 188, 212, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 188, 212, .6);
}

input[type='checkbox'].bg-cyan + .custom-control-label:before,
input[type='radio'].bg-cyan + .custom-control-label:before
{
    background-color: #00bcd4 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00bcd4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00bcd4 !important;
}

.border-cyan
{
    border: 1px solid #00bcd4 !important;
}

.border-top-cyan
{
    border-top: 1px solid #00bcd4;
}

.border-bottom-cyan
{
    border-bottom: 1px solid #00bcd4;
}

.border-left-cyan
{
    border-left: 1px solid #00bcd4;
}

.border-right-cyan
{
    border-right: 1px solid #00bcd4;
}

.cyan.darken-1
{
    color: #00acc1 !important;
}

.bg-cyan.bg-darken-1
{
    background-color: #00acc1 !important;
}
.bg-cyan.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 172, 193, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 172, 193, .6);
}

.btn-cyan.btn-darken-1
{
    border-color: #0097a7 !important;
    background-color: #00acc1 !important;
}
.btn-cyan.btn-darken-1:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-darken-1:focus,
.btn-cyan.btn-darken-1:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-darken-1
{
    color: #00acc1 !important; 
    border-color: #00acc1 !important;
}
.btn-outline-cyan.btn-outline-darken-1:hover
{
    background-color: #00acc1 !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00acc1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00acc1 !important;
}

.border-cyan.border-darken-1
{
    border: 1px solid #00acc1 !important;
}

.border-top-cyan.border-top-darken-1
{
    border-top: 1px solid #00acc1 !important;
}

.border-bottom-cyan.border-bottom-darken-1
{
    border-bottom: 1px solid #00acc1 !important;
}

.border-left-cyan.border-left-darken-1
{
    border-left: 1px solid #00acc1 !important;
}

.border-right-cyan.border-right-darken-1
{
    border-right: 1px solid #00acc1 !important;
}

.overlay-cyan.overlay-darken-1
{
    background: #00acc1;
    /* The Fallback */
    background: rgba(0, 172, 193, .8);
}

.cyan.darken-2
{
    color: #0097a7 !important;
}

.bg-cyan.bg-darken-2
{
    background-color: #0097a7 !important;
}
.bg-cyan.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 151, 167, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 151, 167, .6);
}

.btn-cyan.btn-darken-2
{
    border-color: #0097a7 !important;
    background-color: #0097a7 !important;
}
.btn-cyan.btn-darken-2:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-darken-2:focus,
.btn-cyan.btn-darken-2:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-darken-2
{
    color: #0097a7 !important; 
    border-color: #0097a7 !important;
}
.btn-outline-cyan.btn-outline-darken-2:hover
{
    background-color: #0097a7 !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0097a7 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0097a7 !important;
}

.border-cyan.border-darken-2
{
    border: 1px solid #0097a7 !important;
}

.border-top-cyan.border-top-darken-2
{
    border-top: 1px solid #0097a7 !important;
}

.border-bottom-cyan.border-bottom-darken-2
{
    border-bottom: 1px solid #0097a7 !important;
}

.border-left-cyan.border-left-darken-2
{
    border-left: 1px solid #0097a7 !important;
}

.border-right-cyan.border-right-darken-2
{
    border-right: 1px solid #0097a7 !important;
}

.overlay-cyan.overlay-darken-2
{
    background: #0097a7;
    /* The Fallback */
    background: rgba(0, 151, 167, .8);
}

.cyan.darken-3
{
    color: #00838f !important;
}

.bg-cyan.bg-darken-3
{
    background-color: #00838f !important;
}
.bg-cyan.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 131, 143, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 131, 143, .6);
}

.btn-cyan.btn-darken-3
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-darken-3:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-darken-3:focus,
.btn-cyan.btn-darken-3:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-darken-3
{
    color: #00838f !important; 
    border-color: #00838f !important;
}
.btn-outline-cyan.btn-outline-darken-3:hover
{
    background-color: #00838f !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00838f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00838f !important;
}

.border-cyan.border-darken-3
{
    border: 1px solid #00838f !important;
}

.border-top-cyan.border-top-darken-3
{
    border-top: 1px solid #00838f !important;
}

.border-bottom-cyan.border-bottom-darken-3
{
    border-bottom: 1px solid #00838f !important;
}

.border-left-cyan.border-left-darken-3
{
    border-left: 1px solid #00838f !important;
}

.border-right-cyan.border-right-darken-3
{
    border-right: 1px solid #00838f !important;
}

.overlay-cyan.overlay-darken-3
{
    background: #00838f;
    /* The Fallback */
    background: rgba(0, 131, 143, .8);
}

.cyan.darken-4
{
    color: #006064 !important;
}

.bg-cyan.bg-darken-4
{
    background-color: #006064 !important;
}
.bg-cyan.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 96, 100, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 96, 100, .6);
}

.btn-cyan.btn-darken-4
{
    border-color: #0097a7 !important;
    background-color: #006064 !important;
}
.btn-cyan.btn-darken-4:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-darken-4:focus,
.btn-cyan.btn-darken-4:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-darken-4
{
    color: #006064 !important; 
    border-color: #006064 !important;
}
.btn-outline-cyan.btn-outline-darken-4:hover
{
    background-color: #006064 !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #006064 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #006064 !important;
}

.border-cyan.border-darken-4
{
    border: 1px solid #006064 !important;
}

.border-top-cyan.border-top-darken-4
{
    border-top: 1px solid #006064 !important;
}

.border-bottom-cyan.border-bottom-darken-4
{
    border-bottom: 1px solid #006064 !important;
}

.border-left-cyan.border-left-darken-4
{
    border-left: 1px solid #006064 !important;
}

.border-right-cyan.border-right-darken-4
{
    border-right: 1px solid #006064 !important;
}

.overlay-cyan.overlay-darken-4
{
    background: #006064;
    /* The Fallback */
    background: rgba(0, 96, 100, .8);
}

.cyan.accent-1
{
    color: #84ffff !important;
}

.bg-cyan.bg-accent-1
{
    background-color: #84ffff !important;
}
.bg-cyan.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(132, 255, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(132, 255, 255, .6);
}

.btn-cyan.btn-accent-1
{
    border-color: #0097a7 !important;
    background-color: #84ffff !important;
}
.btn-cyan.btn-accent-1:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-accent-1:focus,
.btn-cyan.btn-accent-1:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-accent-1
{
    color: #84ffff !important; 
    border-color: #84ffff !important;
}
.btn-outline-cyan.btn-outline-accent-1:hover
{
    background-color: #84ffff !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #84ffff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #84ffff !important;
}

.border-cyan.border-accent-1
{
    border: 1px solid #84ffff !important;
}

.border-top-cyan.border-top-accent-1
{
    border-top: 1px solid #84ffff !important;
}

.border-bottom-cyan.border-bottom-accent-1
{
    border-bottom: 1px solid #84ffff !important;
}

.border-left-cyan.border-left-accent-1
{
    border-left: 1px solid #84ffff !important;
}

.border-right-cyan.border-right-accent-1
{
    border-right: 1px solid #84ffff !important;
}

.overlay-cyan.overlay-accent-1
{
    background: #84ffff;
    /* The Fallback */
    background: rgba(132, 255, 255, .8);
}

.cyan.accent-2
{
    color: #18ffff !important;
}

.bg-cyan.bg-accent-2
{
    background-color: #18ffff !important;
}
.bg-cyan.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(24, 255, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(24, 255, 255, .6);
}

.btn-cyan.btn-accent-2
{
    border-color: #0097a7 !important;
    background-color: #18ffff !important;
}
.btn-cyan.btn-accent-2:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-accent-2:focus,
.btn-cyan.btn-accent-2:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-accent-2
{
    color: #18ffff !important; 
    border-color: #18ffff !important;
}
.btn-outline-cyan.btn-outline-accent-2:hover
{
    background-color: #18ffff !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #18ffff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #18ffff !important;
}

.border-cyan.border-accent-2
{
    border: 1px solid #18ffff !important;
}

.border-top-cyan.border-top-accent-2
{
    border-top: 1px solid #18ffff !important;
}

.border-bottom-cyan.border-bottom-accent-2
{
    border-bottom: 1px solid #18ffff !important;
}

.border-left-cyan.border-left-accent-2
{
    border-left: 1px solid #18ffff !important;
}

.border-right-cyan.border-right-accent-2
{
    border-right: 1px solid #18ffff !important;
}

.overlay-cyan.overlay-accent-2
{
    background: #18ffff;
    /* The Fallback */
    background: rgba(24, 255, 255, .8);
}

.cyan.accent-3
{
    color: #00e5ff !important;
}

.bg-cyan.bg-accent-3
{
    background-color: #00e5ff !important;
}
.bg-cyan.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 229, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 229, 255, .6);
}

.btn-cyan.btn-accent-3
{
    border-color: #0097a7 !important;
    background-color: #00e5ff !important;
}
.btn-cyan.btn-accent-3:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-accent-3:focus,
.btn-cyan.btn-accent-3:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-accent-3
{
    color: #00e5ff !important; 
    border-color: #00e5ff !important;
}
.btn-outline-cyan.btn-outline-accent-3:hover
{
    background-color: #00e5ff !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00e5ff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00e5ff !important;
}

.border-cyan.border-accent-3
{
    border: 1px solid #00e5ff !important;
}

.border-top-cyan.border-top-accent-3
{
    border-top: 1px solid #00e5ff !important;
}

.border-bottom-cyan.border-bottom-accent-3
{
    border-bottom: 1px solid #00e5ff !important;
}

.border-left-cyan.border-left-accent-3
{
    border-left: 1px solid #00e5ff !important;
}

.border-right-cyan.border-right-accent-3
{
    border-right: 1px solid #00e5ff !important;
}

.overlay-cyan.overlay-accent-3
{
    background: #00e5ff;
    /* The Fallback */
    background: rgba(0, 229, 255, .8);
}

.cyan.accent-4
{
    color: #00b8d4 !important;
}

.bg-cyan.bg-accent-4
{
    background-color: #00b8d4 !important;
}
.bg-cyan.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 184, 212, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 184, 212, .6);
}

.btn-cyan.btn-accent-4
{
    border-color: #0097a7 !important;
    background-color: #00b8d4 !important;
}
.btn-cyan.btn-accent-4:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-accent-4:focus,
.btn-cyan.btn-accent-4:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-accent-4
{
    color: #00b8d4 !important; 
    border-color: #00b8d4 !important;
}
.btn-outline-cyan.btn-outline-accent-4:hover
{
    background-color: #00b8d4 !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00b8d4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00b8d4 !important;
}

.border-cyan.border-accent-4
{
    border: 1px solid #00b8d4 !important;
}

.border-top-cyan.border-top-accent-4
{
    border-top: 1px solid #00b8d4 !important;
}

.border-bottom-cyan.border-bottom-accent-4
{
    border-bottom: 1px solid #00b8d4 !important;
}

.border-left-cyan.border-left-accent-4
{
    border-left: 1px solid #00b8d4 !important;
}

.border-right-cyan.border-right-accent-4
{
    border-right: 1px solid #00b8d4 !important;
}

.overlay-cyan.overlay-accent-4
{
    background: #00b8d4;
    /* The Fallback */
    background: rgba(0, 184, 212, .8);
}

.teal.lighten-5
{
    color: #e0f2f1 !important;
}

.bg-teal.bg-lighten-5
{
    background-color: #e0f2f1 !important;
}
.bg-teal.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(224, 242, 241, .6);
            box-shadow: 0 1px 20px 1px rgba(224, 242, 241, .6);
}

.btn-teal.btn-lighten-5
{
    border-color: #00796b !important;
    background-color: #e0f2f1 !important;
}
.btn-teal.btn-lighten-5:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-lighten-5:focus,
.btn-teal.btn-lighten-5:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-lighten-5
{
    color: #e0f2f1 !important; 
    border-color: #e0f2f1 !important;
}
.btn-outline-teal.btn-outline-lighten-5:hover
{
    background-color: #e0f2f1 !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e0f2f1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e0f2f1 !important;
}

.border-teal.border-lighten-5
{
    border: 1px solid #e0f2f1 !important;
}

.border-top-teal.border-top-lighten-5
{
    border-top: 1px solid #e0f2f1 !important;
}

.border-bottom-teal.border-bottom-lighten-5
{
    border-bottom: 1px solid #e0f2f1 !important;
}

.border-left-teal.border-left-lighten-5
{
    border-left: 1px solid #e0f2f1 !important;
}

.border-right-teal.border-right-lighten-5
{
    border-right: 1px solid #e0f2f1 !important;
}

.overlay-teal.overlay-lighten-5
{
    background: #e0f2f1;
    /* The Fallback */
    background: rgba(224, 242, 241, .8);
}

.teal.lighten-4
{
    color: #b2dfdb !important;
}

.bg-teal.bg-lighten-4
{
    background-color: #b2dfdb !important;
}
.bg-teal.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(178, 223, 219, .6);
            box-shadow: 0 1px 20px 1px rgba(178, 223, 219, .6);
}

.btn-teal.btn-lighten-4
{
    border-color: #00796b !important;
    background-color: #b2dfdb !important;
}
.btn-teal.btn-lighten-4:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-lighten-4:focus,
.btn-teal.btn-lighten-4:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-lighten-4
{
    color: #b2dfdb !important; 
    border-color: #b2dfdb !important;
}
.btn-outline-teal.btn-outline-lighten-4:hover
{
    background-color: #b2dfdb !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b2dfdb !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b2dfdb !important;
}

.border-teal.border-lighten-4
{
    border: 1px solid #b2dfdb !important;
}

.border-top-teal.border-top-lighten-4
{
    border-top: 1px solid #b2dfdb !important;
}

.border-bottom-teal.border-bottom-lighten-4
{
    border-bottom: 1px solid #b2dfdb !important;
}

.border-left-teal.border-left-lighten-4
{
    border-left: 1px solid #b2dfdb !important;
}

.border-right-teal.border-right-lighten-4
{
    border-right: 1px solid #b2dfdb !important;
}

.overlay-teal.overlay-lighten-4
{
    background: #b2dfdb;
    /* The Fallback */
    background: rgba(178, 223, 219, .8);
}

.teal.lighten-3
{
    color: #80cbc4 !important;
}

.bg-teal.bg-lighten-3
{
    background-color: #80cbc4 !important;
}
.bg-teal.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(128, 203, 196, .6);
            box-shadow: 0 1px 20px 1px rgba(128, 203, 196, .6);
}

.btn-teal.btn-lighten-3
{
    border-color: #00796b !important;
    background-color: #80cbc4 !important;
}
.btn-teal.btn-lighten-3:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-lighten-3:focus,
.btn-teal.btn-lighten-3:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-lighten-3
{
    color: #80cbc4 !important; 
    border-color: #80cbc4 !important;
}
.btn-outline-teal.btn-outline-lighten-3:hover
{
    background-color: #80cbc4 !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #80cbc4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #80cbc4 !important;
}

.border-teal.border-lighten-3
{
    border: 1px solid #80cbc4 !important;
}

.border-top-teal.border-top-lighten-3
{
    border-top: 1px solid #80cbc4 !important;
}

.border-bottom-teal.border-bottom-lighten-3
{
    border-bottom: 1px solid #80cbc4 !important;
}

.border-left-teal.border-left-lighten-3
{
    border-left: 1px solid #80cbc4 !important;
}

.border-right-teal.border-right-lighten-3
{
    border-right: 1px solid #80cbc4 !important;
}

.overlay-teal.overlay-lighten-3
{
    background: #80cbc4;
    /* The Fallback */
    background: rgba(128, 203, 196, .8);
}

.teal.lighten-2
{
    color: #4db6ac !important;
}

.bg-teal.bg-lighten-2
{
    background-color: #4db6ac !important;
}
.bg-teal.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(77, 182, 172, .6);
            box-shadow: 0 1px 20px 1px rgba(77, 182, 172, .6);
}

.btn-teal.btn-lighten-2
{
    border-color: #00796b !important;
    background-color: #4db6ac !important;
}
.btn-teal.btn-lighten-2:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-lighten-2:focus,
.btn-teal.btn-lighten-2:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-lighten-2
{
    color: #4db6ac !important; 
    border-color: #4db6ac !important;
}
.btn-outline-teal.btn-outline-lighten-2:hover
{
    background-color: #4db6ac !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4db6ac !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4db6ac !important;
}

.border-teal.border-lighten-2
{
    border: 1px solid #4db6ac !important;
}

.border-top-teal.border-top-lighten-2
{
    border-top: 1px solid #4db6ac !important;
}

.border-bottom-teal.border-bottom-lighten-2
{
    border-bottom: 1px solid #4db6ac !important;
}

.border-left-teal.border-left-lighten-2
{
    border-left: 1px solid #4db6ac !important;
}

.border-right-teal.border-right-lighten-2
{
    border-right: 1px solid #4db6ac !important;
}

.overlay-teal.overlay-lighten-2
{
    background: #4db6ac;
    /* The Fallback */
    background: rgba(77, 182, 172, .8);
}

.teal.lighten-1
{
    color: #26a69a !important;
}

.bg-teal.bg-lighten-1
{
    background-color: #26a69a !important;
}
.bg-teal.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(38, 166, 154, .6);
            box-shadow: 0 1px 20px 1px rgba(38, 166, 154, .6);
}

.btn-teal.btn-lighten-1
{
    border-color: #00796b !important;
    background-color: #26a69a !important;
}
.btn-teal.btn-lighten-1:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-lighten-1:focus,
.btn-teal.btn-lighten-1:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-lighten-1
{
    color: #26a69a !important; 
    border-color: #26a69a !important;
}
.btn-outline-teal.btn-outline-lighten-1:hover
{
    background-color: #26a69a !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #26a69a !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #26a69a !important;
}

.border-teal.border-lighten-1
{
    border: 1px solid #26a69a !important;
}

.border-top-teal.border-top-lighten-1
{
    border-top: 1px solid #26a69a !important;
}

.border-bottom-teal.border-bottom-lighten-1
{
    border-bottom: 1px solid #26a69a !important;
}

.border-left-teal.border-left-lighten-1
{
    border-left: 1px solid #26a69a !important;
}

.border-right-teal.border-right-lighten-1
{
    border-right: 1px solid #26a69a !important;
}

.overlay-teal.overlay-lighten-1
{
    background: #26a69a;
    /* The Fallback */
    background: rgba(38, 166, 154, .8);
}

.teal
{
    color: #009688 !important;
}

.bg-teal
{
    background-color: #009688 !important;
}
.bg-teal .card-header,
.bg-teal .card-footer
{
    background-color: transparent;
}
.bg-teal.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 150, 136, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 150, 136, .6);
}

.selectboxit.selectboxit-btn.bg-teal
{
    background-color: #009688 !important;
}

.alert-teal
{
    color: black !important; 
    border-color: #009688 !important;
    background-color: #00ddc9 !important;
}
.alert-teal .alert-link
{
    color: black !important;
}

.border-teal
{
    border-color: #009688;
}

.overlay-teal
{
    background: #009688;
    /* The Fallback */
    background: rgba(0, 150, 136, .8);
}

/* .card-outline-teal{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-teal
{
    color: #fff; 
    border-color: #009688 !important;
    background-color: #009688 !important;
}
.btn-teal:hover
{
    color: #fff !important; 
    border-color: #00796b !important;
    background-color: #00897b !important;
}
.btn-teal:focus,
.btn-teal:active
{
    color: #fff !important; 
    border-color: #00695c !important;
    background-color: #00796b !important;
}
.btn-teal.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 150, 136, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 150, 136, .6);
}

.btn-outline-teal
{
    color: #009688; 
    border-color: #009688;
    background-color: transparent;
}
.btn-outline-teal:hover
{
    color: #fff !important; 
    background-color: #009688;
}
.btn-outline-teal.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 150, 136, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 150, 136, .6);
}

input[type='checkbox'].bg-teal + .custom-control-label:before,
input[type='radio'].bg-teal + .custom-control-label:before
{
    background-color: #009688 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #009688 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #009688 !important;
}

.border-teal
{
    border: 1px solid #009688 !important;
}

.border-top-teal
{
    border-top: 1px solid #009688;
}

.border-bottom-teal
{
    border-bottom: 1px solid #009688;
}

.border-left-teal
{
    border-left: 1px solid #009688;
}

.border-right-teal
{
    border-right: 1px solid #009688;
}

.teal.darken-1
{
    color: #00897b !important;
}

.bg-teal.bg-darken-1
{
    background-color: #00897b !important;
}
.bg-teal.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 137, 123, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 137, 123, .6);
}

.btn-teal.btn-darken-1
{
    border-color: #00796b !important;
    background-color: #00897b !important;
}
.btn-teal.btn-darken-1:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-darken-1:focus,
.btn-teal.btn-darken-1:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-darken-1
{
    color: #00897b !important; 
    border-color: #00897b !important;
}
.btn-outline-teal.btn-outline-darken-1:hover
{
    background-color: #00897b !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00897b !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00897b !important;
}

.border-teal.border-darken-1
{
    border: 1px solid #00897b !important;
}

.border-top-teal.border-top-darken-1
{
    border-top: 1px solid #00897b !important;
}

.border-bottom-teal.border-bottom-darken-1
{
    border-bottom: 1px solid #00897b !important;
}

.border-left-teal.border-left-darken-1
{
    border-left: 1px solid #00897b !important;
}

.border-right-teal.border-right-darken-1
{
    border-right: 1px solid #00897b !important;
}

.overlay-teal.overlay-darken-1
{
    background: #00897b;
    /* The Fallback */
    background: rgba(0, 137, 123, .8);
}

.teal.darken-2
{
    color: #00796b !important;
}

.bg-teal.bg-darken-2
{
    background-color: #00796b !important;
}
.bg-teal.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 121, 107, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 121, 107, .6);
}

.btn-teal.btn-darken-2
{
    border-color: #00796b !important;
    background-color: #00796b !important;
}
.btn-teal.btn-darken-2:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-darken-2:focus,
.btn-teal.btn-darken-2:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-darken-2
{
    color: #00796b !important; 
    border-color: #00796b !important;
}
.btn-outline-teal.btn-outline-darken-2:hover
{
    background-color: #00796b !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00796b !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00796b !important;
}

.border-teal.border-darken-2
{
    border: 1px solid #00796b !important;
}

.border-top-teal.border-top-darken-2
{
    border-top: 1px solid #00796b !important;
}

.border-bottom-teal.border-bottom-darken-2
{
    border-bottom: 1px solid #00796b !important;
}

.border-left-teal.border-left-darken-2
{
    border-left: 1px solid #00796b !important;
}

.border-right-teal.border-right-darken-2
{
    border-right: 1px solid #00796b !important;
}

.overlay-teal.overlay-darken-2
{
    background: #00796b;
    /* The Fallback */
    background: rgba(0, 121, 107, .8);
}

.teal.darken-3
{
    color: #00695c !important;
}

.bg-teal.bg-darken-3
{
    background-color: #00695c !important;
}
.bg-teal.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 105, 92, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 105, 92, .6);
}

.btn-teal.btn-darken-3
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-darken-3:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-darken-3:focus,
.btn-teal.btn-darken-3:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-darken-3
{
    color: #00695c !important; 
    border-color: #00695c !important;
}
.btn-outline-teal.btn-outline-darken-3:hover
{
    background-color: #00695c !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00695c !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00695c !important;
}

.border-teal.border-darken-3
{
    border: 1px solid #00695c !important;
}

.border-top-teal.border-top-darken-3
{
    border-top: 1px solid #00695c !important;
}

.border-bottom-teal.border-bottom-darken-3
{
    border-bottom: 1px solid #00695c !important;
}

.border-left-teal.border-left-darken-3
{
    border-left: 1px solid #00695c !important;
}

.border-right-teal.border-right-darken-3
{
    border-right: 1px solid #00695c !important;
}

.overlay-teal.overlay-darken-3
{
    background: #00695c;
    /* The Fallback */
    background: rgba(0, 105, 92, .8);
}

.teal.darken-4
{
    color: #004d40 !important;
}

.bg-teal.bg-darken-4
{
    background-color: #004d40 !important;
}
.bg-teal.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 77, 64, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 77, 64, .6);
}

.btn-teal.btn-darken-4
{
    border-color: #00796b !important;
    background-color: #004d40 !important;
}
.btn-teal.btn-darken-4:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-darken-4:focus,
.btn-teal.btn-darken-4:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-darken-4
{
    color: #004d40 !important; 
    border-color: #004d40 !important;
}
.btn-outline-teal.btn-outline-darken-4:hover
{
    background-color: #004d40 !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #004d40 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #004d40 !important;
}

.border-teal.border-darken-4
{
    border: 1px solid #004d40 !important;
}

.border-top-teal.border-top-darken-4
{
    border-top: 1px solid #004d40 !important;
}

.border-bottom-teal.border-bottom-darken-4
{
    border-bottom: 1px solid #004d40 !important;
}

.border-left-teal.border-left-darken-4
{
    border-left: 1px solid #004d40 !important;
}

.border-right-teal.border-right-darken-4
{
    border-right: 1px solid #004d40 !important;
}

.overlay-teal.overlay-darken-4
{
    background: #004d40;
    /* The Fallback */
    background: rgba(0, 77, 64, .8);
}

.teal.accent-1
{
    color: #a7ffeb !important;
}

.bg-teal.bg-accent-1
{
    background-color: #a7ffeb !important;
}
.bg-teal.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(167, 255, 235, .6);
            box-shadow: 0 1px 20px 1px rgba(167, 255, 235, .6);
}

.btn-teal.btn-accent-1
{
    border-color: #00796b !important;
    background-color: #a7ffeb !important;
}
.btn-teal.btn-accent-1:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-accent-1:focus,
.btn-teal.btn-accent-1:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-accent-1
{
    color: #a7ffeb !important; 
    border-color: #a7ffeb !important;
}
.btn-outline-teal.btn-outline-accent-1:hover
{
    background-color: #a7ffeb !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #a7ffeb !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #a7ffeb !important;
}

.border-teal.border-accent-1
{
    border: 1px solid #a7ffeb !important;
}

.border-top-teal.border-top-accent-1
{
    border-top: 1px solid #a7ffeb !important;
}

.border-bottom-teal.border-bottom-accent-1
{
    border-bottom: 1px solid #a7ffeb !important;
}

.border-left-teal.border-left-accent-1
{
    border-left: 1px solid #a7ffeb !important;
}

.border-right-teal.border-right-accent-1
{
    border-right: 1px solid #a7ffeb !important;
}

.overlay-teal.overlay-accent-1
{
    background: #a7ffeb;
    /* The Fallback */
    background: rgba(167, 255, 235, .8);
}

.teal.accent-2
{
    color: #64ffda !important;
}

.bg-teal.bg-accent-2
{
    background-color: #64ffda !important;
}
.bg-teal.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(100, 255, 218, .6);
            box-shadow: 0 1px 20px 1px rgba(100, 255, 218, .6);
}

.btn-teal.btn-accent-2
{
    border-color: #00796b !important;
    background-color: #64ffda !important;
}
.btn-teal.btn-accent-2:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-accent-2:focus,
.btn-teal.btn-accent-2:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-accent-2
{
    color: #64ffda !important; 
    border-color: #64ffda !important;
}
.btn-outline-teal.btn-outline-accent-2:hover
{
    background-color: #64ffda !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #64ffda !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #64ffda !important;
}

.border-teal.border-accent-2
{
    border: 1px solid #64ffda !important;
}

.border-top-teal.border-top-accent-2
{
    border-top: 1px solid #64ffda !important;
}

.border-bottom-teal.border-bottom-accent-2
{
    border-bottom: 1px solid #64ffda !important;
}

.border-left-teal.border-left-accent-2
{
    border-left: 1px solid #64ffda !important;
}

.border-right-teal.border-right-accent-2
{
    border-right: 1px solid #64ffda !important;
}

.overlay-teal.overlay-accent-2
{
    background: #64ffda;
    /* The Fallback */
    background: rgba(100, 255, 218, .8);
}

.teal.accent-3
{
    color: #1de9b6 !important;
}

.bg-teal.bg-accent-3
{
    background-color: #1de9b6 !important;
}
.bg-teal.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(29, 233, 182, .6);
            box-shadow: 0 1px 20px 1px rgba(29, 233, 182, .6);
}

.btn-teal.btn-accent-3
{
    border-color: #00796b !important;
    background-color: #1de9b6 !important;
}
.btn-teal.btn-accent-3:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-accent-3:focus,
.btn-teal.btn-accent-3:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-accent-3
{
    color: #1de9b6 !important; 
    border-color: #1de9b6 !important;
}
.btn-outline-teal.btn-outline-accent-3:hover
{
    background-color: #1de9b6 !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1de9b6 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1de9b6 !important;
}

.border-teal.border-accent-3
{
    border: 1px solid #1de9b6 !important;
}

.border-top-teal.border-top-accent-3
{
    border-top: 1px solid #1de9b6 !important;
}

.border-bottom-teal.border-bottom-accent-3
{
    border-bottom: 1px solid #1de9b6 !important;
}

.border-left-teal.border-left-accent-3
{
    border-left: 1px solid #1de9b6 !important;
}

.border-right-teal.border-right-accent-3
{
    border-right: 1px solid #1de9b6 !important;
}

.overlay-teal.overlay-accent-3
{
    background: #1de9b6;
    /* The Fallback */
    background: rgba(29, 233, 182, .8);
}

.teal.accent-4
{
    color: #00bfa5 !important;
}

.bg-teal.bg-accent-4
{
    background-color: #00bfa5 !important;
}
.bg-teal.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 191, 165, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 191, 165, .6);
}

.btn-teal.btn-accent-4
{
    border-color: #00796b !important;
    background-color: #00bfa5 !important;
}
.btn-teal.btn-accent-4:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-accent-4:focus,
.btn-teal.btn-accent-4:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-accent-4
{
    color: #00bfa5 !important; 
    border-color: #00bfa5 !important;
}
.btn-outline-teal.btn-outline-accent-4:hover
{
    background-color: #00bfa5 !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00bfa5 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00bfa5 !important;
}

.border-teal.border-accent-4
{
    border: 1px solid #00bfa5 !important;
}

.border-top-teal.border-top-accent-4
{
    border-top: 1px solid #00bfa5 !important;
}

.border-bottom-teal.border-bottom-accent-4
{
    border-bottom: 1px solid #00bfa5 !important;
}

.border-left-teal.border-left-accent-4
{
    border-left: 1px solid #00bfa5 !important;
}

.border-right-teal.border-right-accent-4
{
    border-right: 1px solid #00bfa5 !important;
}

.overlay-teal.overlay-accent-4
{
    background: #00bfa5;
    /* The Fallback */
    background: rgba(0, 191, 165, .8);
}

.green.lighten-5
{
    color: #e8f5e9 !important;
}

.bg-green.bg-lighten-5
{
    background-color: #e8f5e9 !important;
}
.bg-green.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(232, 245, 233, .6);
            box-shadow: 0 1px 20px 1px rgba(232, 245, 233, .6);
}

.btn-green.btn-lighten-5
{
    border-color: #388e3c !important;
    background-color: #e8f5e9 !important;
}
.btn-green.btn-lighten-5:hover
{
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-lighten-5:focus,
.btn-green.btn-lighten-5:active
{
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}

.btn-outline-green.btn-outline-lighten-5
{
    color: #e8f5e9 !important; 
    border-color: #e8f5e9 !important;
}
.btn-outline-green.btn-outline-lighten-5:hover
{
    background-color: #e8f5e9 !important;
}

input:focus ~ .bg-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e8f5e9 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e8f5e9 !important;
}

.border-green.border-lighten-5
{
    border: 1px solid #e8f5e9 !important;
}

.border-top-green.border-top-lighten-5
{
    border-top: 1px solid #e8f5e9 !important;
}

.border-bottom-green.border-bottom-lighten-5
{
    border-bottom: 1px solid #e8f5e9 !important;
}

.border-left-green.border-left-lighten-5
{
    border-left: 1px solid #e8f5e9 !important;
}

.border-right-green.border-right-lighten-5
{
    border-right: 1px solid #e8f5e9 !important;
}

.overlay-green.overlay-lighten-5
{
    background: #e8f5e9;
    /* The Fallback */
    background: rgba(232, 245, 233, .8);
}

.green.lighten-4
{
    color: #c8e6c9 !important;
}

.bg-green.bg-lighten-4
{
    background-color: #c8e6c9 !important;
}
.bg-green.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(200, 230, 201, .6);
            box-shadow: 0 1px 20px 1px rgba(200, 230, 201, .6);
}

.btn-green.btn-lighten-4
{
    border-color: #388e3c !important;
    background-color: #c8e6c9 !important;
}
.btn-green.btn-lighten-4:hover
{
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-lighten-4:focus,
.btn-green.btn-lighten-4:active
{
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}

.btn-outline-green.btn-outline-lighten-4
{
    color: #c8e6c9 !important; 
    border-color: #c8e6c9 !important;
}
.btn-outline-green.btn-outline-lighten-4:hover
{
    background-color: #c8e6c9 !important;
}

input:focus ~ .bg-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c8e6c9 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c8e6c9 !important;
}

.border-green.border-lighten-4
{
    border: 1px solid #c8e6c9 !important;
}

.border-top-green.border-top-lighten-4
{
    border-top: 1px solid #c8e6c9 !important;
}

.border-bottom-green.border-bottom-lighten-4
{
    border-bottom: 1px solid #c8e6c9 !important;
}

.border-left-green.border-left-lighten-4
{
    border-left: 1px solid #c8e6c9 !important;
}

.border-right-green.border-right-lighten-4
{
    border-right: 1px solid #c8e6c9 !important;
}

.overlay-green.overlay-lighten-4
{
    background: #c8e6c9;
    /* The Fallback */
    background: rgba(200, 230, 201, .8);
}

.green.lighten-3
{
    color: #a5d6a7 !important;
}

.bg-green.bg-lighten-3
{
    background-color: #a5d6a7 !important;
}
.bg-green.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(165, 214, 167, .6);
            box-shadow: 0 1px 20px 1px rgba(165, 214, 167, .6);
}

.btn-green.btn-lighten-3
{
    border-color: #388e3c !important;
    background-color: #a5d6a7 !important;
}
.btn-green.btn-lighten-3:hover
{
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-lighten-3:focus,
.btn-green.btn-lighten-3:active
{
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}

.btn-outline-green.btn-outline-lighten-3
{
    color: #a5d6a7 !important; 
    border-color: #a5d6a7 !important;
}
.btn-outline-green.btn-outline-lighten-3:hover
{
    background-color: #a5d6a7 !important;
}

input:focus ~ .bg-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #a5d6a7 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #a5d6a7 !important;
}

.border-green.border-lighten-3
{
    border: 1px solid #a5d6a7 !important;
}

.border-top-green.border-top-lighten-3
{
    border-top: 1px solid #a5d6a7 !important;
}

.border-bottom-green.border-bottom-lighten-3
{
    border-bottom: 1px solid #a5d6a7 !important;
}

.border-left-green.border-left-lighten-3
{
    border-left: 1px solid #a5d6a7 !important;
}

.border-right-green.border-right-lighten-3
{
    border-right: 1px solid #a5d6a7 !important;
}

.overlay-green.overlay-lighten-3
{
    background: #a5d6a7;
    /* The Fallback */
    background: rgba(165, 214, 167, .8);
}

.green.lighten-2
{
    color: #81c784 !important;
}

.bg-green.bg-lighten-2
{
    background-color: #81c784 !important;
}
.bg-green.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(129, 199, 132, .6);
            box-shadow: 0 1px 20px 1px rgba(129, 199, 132, .6);
}

.btn-green.btn-lighten-2
{
    border-color: #388e3c !important;
    background-color: #81c784 !important;
}
.btn-green.btn-lighten-2:hover
{
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-lighten-2:focus,
.btn-green.btn-lighten-2:active
{
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}

.btn-outline-green.btn-outline-lighten-2
{
    color: #81c784 !important; 
    border-color: #81c784 !important;
}
.btn-outline-green.btn-outline-lighten-2:hover
{
    background-color: #81c784 !important;
}

input:focus ~ .bg-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #81c784 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #81c784 !important;
}

.border-green.border-lighten-2
{
    border: 1px solid #81c784 !important;
}

.border-top-green.border-top-lighten-2
{
    border-top: 1px solid #81c784 !important;
}

.border-bottom-green.border-bottom-lighten-2
{
    border-bottom: 1px solid #81c784 !important;
}

.border-left-green.border-left-lighten-2
{
    border-left: 1px solid #81c784 !important;
}

.border-right-green.border-right-lighten-2
{
    border-right: 1px solid #81c784 !important;
}

.overlay-green.overlay-lighten-2
{
    background: #81c784;
    /* The Fallback */
    background: rgba(129, 199, 132, .8);
}

.green.lighten-1
{
    color: #66bb6a !important;
}

.bg-green.bg-lighten-1
{
    background-color: #66bb6a !important;
}
.bg-green.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(102, 187, 106, .6);
            box-shadow: 0 1px 20px 1px rgba(102, 187, 106, .6);
}

.btn-green.btn-lighten-1
{
    border-color: #388e3c !important;
    background-color: #66bb6a !important;
}
.btn-green.btn-lighten-1:hover
{
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-lighten-1:focus,
.btn-green.btn-lighten-1:active
{
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}

.btn-outline-green.btn-outline-lighten-1
{
    color: #66bb6a !important; 
    border-color: #66bb6a !important;
}
.btn-outline-green.btn-outline-lighten-1:hover
{
    background-color: #66bb6a !important;
}

input:focus ~ .bg-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #66bb6a !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #66bb6a !important;
}

.border-green.border-lighten-1
{
    border: 1px solid #66bb6a !important;
}

.border-top-green.border-top-lighten-1
{
    border-top: 1px solid #66bb6a !important;
}

.border-bottom-green.border-bottom-lighten-1
{
    border-bottom: 1px solid #66bb6a !important;
}

.border-left-green.border-left-lighten-1
{
    border-left: 1px solid #66bb6a !important;
}

.border-right-green.border-right-lighten-1
{
    border-right: 1px solid #66bb6a !important;
}

.overlay-green.overlay-lighten-1
{
    background: #66bb6a;
    /* The Fallback */
    background: rgba(102, 187, 106, .8);
}

.green
{
    color: #4caf50 !important;
}

.bg-green
{
    background-color: #4caf50 !important;
}
.bg-green .card-header,
.bg-green .card-footer
{
    background-color: transparent;
}
.bg-green.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(76, 175, 80, .6);
            box-shadow: 0 1px 20px 1px rgba(76, 175, 80, .6);
}

.selectboxit.selectboxit-btn.bg-green
{
    background-color: #4caf50 !important;
}

.alert-green
{
    color: #163317 !important; 
    border-color: #4caf50 !important;
    background-color: #7cc67f !important;
}
.alert-green .alert-link
{
    color: #0a160a !important;
}

.border-green
{
    border-color: #4caf50;
}

.overlay-green
{
    background: #4caf50;
    /* The Fallback */
    background: rgba(76, 175, 80, .8);
}

/* .card-outline-green{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-green
{
    color: #fff; 
    border-color: #4caf50 !important;
    background-color: #4caf50 !important;
}
.btn-green:hover
{
    color: #fff !important; 
    border-color: #388e3c !important;
    background-color: #43a047 !important;
}
.btn-green:focus,
.btn-green:active
{
    color: #fff !important; 
    border-color: #2e7d32 !important;
    background-color: #388e3c !important;
}
.btn-green.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(76, 175, 80, .6);
            box-shadow: 0 1px 20px 1px rgba(76, 175, 80, .6);
}

.btn-outline-green
{
    color: #4caf50; 
    border-color: #4caf50;
    background-color: transparent;
}
.btn-outline-green:hover
{
    color: #fff !important; 
    background-color: #4caf50;
}
.btn-outline-green.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(76, 175, 80, .6);
            box-shadow: 0 1px 20px 1px rgba(76, 175, 80, .6);
}

input[type='checkbox'].bg-green + .custom-control-label:before,
input[type='radio'].bg-green + .custom-control-label:before
{
    background-color: #4caf50 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4caf50 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4caf50 !important;
}

.border-green
{
    border: 1px solid #4caf50 !important;
}

.border-top-green
{
    border-top: 1px solid #4caf50;
}

.border-bottom-green
{
    border-bottom: 1px solid #4caf50;
}

.border-left-green
{
    border-left: 1px solid #4caf50;
}

.border-right-green
{
    border-right: 1px solid #4caf50;
}

.green.darken-1
{
    color: #43a047 !important;
}

.bg-green.bg-darken-1
{
    background-color: #43a047 !important;
}
.bg-green.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(67, 160, 71, .6);
            box-shadow: 0 1px 20px 1px rgba(67, 160, 71, .6);
}

.btn-green.btn-darken-1
{
    border-color: #388e3c !important;
    background-color: #43a047 !important;
}
.btn-green.btn-darken-1:hover
{
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-darken-1:focus,
.btn-green.btn-darken-1:active
{
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}

.btn-outline-green.btn-outline-darken-1
{
    color: #43a047 !important; 
    border-color: #43a047 !important;
}
.btn-outline-green.btn-outline-darken-1:hover
{
    background-color: #43a047 !important;
}

input:focus ~ .bg-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #43a047 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #43a047 !important;
}

.border-green.border-darken-1
{
    border: 1px solid #43a047 !important;
}

.border-top-green.border-top-darken-1
{
    border-top: 1px solid #43a047 !important;
}

.border-bottom-green.border-bottom-darken-1
{
    border-bottom: 1px solid #43a047 !important;
}

.border-left-green.border-left-darken-1
{
    border-left: 1px solid #43a047 !important;
}

.border-right-green.border-right-darken-1
{
    border-right: 1px solid #43a047 !important;
}

.overlay-green.overlay-darken-1
{
    background: #43a047;
    /* The Fallback */
    background: rgba(67, 160, 71, .8);
}

.green.darken-2
{
    color: #388e3c !important;
}

.bg-green.bg-darken-2
{
    background-color: #388e3c !important;
}
.bg-green.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(56, 142, 60, .6);
            box-shadow: 0 1px 20px 1px rgba(56, 142, 60, .6);
}

.btn-green.btn-darken-2
{
    border-color: #388e3c !important;
    background-color: #388e3c !important;
}
.btn-green.btn-darken-2:hover
{
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-darken-2:focus,
.btn-green.btn-darken-2:active
{
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}

.btn-outline-green.btn-outline-darken-2
{
    color: #388e3c !important; 
    border-color: #388e3c !important;
}
.btn-outline-green.btn-outline-darken-2:hover
{
    background-color: #388e3c !important;
}

input:focus ~ .bg-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #388e3c !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #388e3c !important;
}

.border-green.border-darken-2
{
    border: 1px solid #388e3c !important;
}

.border-top-green.border-top-darken-2
{
    border-top: 1px solid #388e3c !important;
}

.border-bottom-green.border-bottom-darken-2
{
    border-bottom: 1px solid #388e3c !important;
}

.border-left-green.border-left-darken-2
{
    border-left: 1px solid #388e3c !important;
}

.border-right-green.border-right-darken-2
{
    border-right: 1px solid #388e3c !important;
}

.overlay-green.overlay-darken-2
{
    background: #388e3c;
    /* The Fallback */
    background: rgba(56, 142, 60, .8);
}

.green.darken-3
{
    color: #2e7d32 !important;
}

.bg-green.bg-darken-3
{
    background-color: #2e7d32 !important;
}
.bg-green.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(46, 125, 50, .6);
            box-shadow: 0 1px 20px 1px rgba(46, 125, 50, .6);
}

.btn-green.btn-darken-3
{
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-darken-3:hover
{
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-darken-3:focus,
.btn-green.btn-darken-3:active
{
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}

.btn-outline-green.btn-outline-darken-3
{
    color: #2e7d32 !important; 
    border-color: #2e7d32 !important;
}
.btn-outline-green.btn-outline-darken-3:hover
{
    background-color: #2e7d32 !important;
}

input:focus ~ .bg-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #2e7d32 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #2e7d32 !important;
}

.border-green.border-darken-3
{
    border: 1px solid #2e7d32 !important;
}

.border-top-green.border-top-darken-3
{
    border-top: 1px solid #2e7d32 !important;
}

.border-bottom-green.border-bottom-darken-3
{
    border-bottom: 1px solid #2e7d32 !important;
}

.border-left-green.border-left-darken-3
{
    border-left: 1px solid #2e7d32 !important;
}

.border-right-green.border-right-darken-3
{
    border-right: 1px solid #2e7d32 !important;
}

.overlay-green.overlay-darken-3
{
    background: #2e7d32;
    /* The Fallback */
    background: rgba(46, 125, 50, .8);
}

.green.darken-4
{
    color: #1b5e20 !important;
}

.bg-green.bg-darken-4
{
    background-color: #1b5e20 !important;
}
.bg-green.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(27, 94, 32, .6);
            box-shadow: 0 1px 20px 1px rgba(27, 94, 32, .6);
}

.btn-green.btn-darken-4
{
    border-color: #388e3c !important;
    background-color: #1b5e20 !important;
}
.btn-green.btn-darken-4:hover
{
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-darken-4:focus,
.btn-green.btn-darken-4:active
{
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}

.btn-outline-green.btn-outline-darken-4
{
    color: #1b5e20 !important; 
    border-color: #1b5e20 !important;
}
.btn-outline-green.btn-outline-darken-4:hover
{
    background-color: #1b5e20 !important;
}

input:focus ~ .bg-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1b5e20 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1b5e20 !important;
}

.border-green.border-darken-4
{
    border: 1px solid #1b5e20 !important;
}

.border-top-green.border-top-darken-4
{
    border-top: 1px solid #1b5e20 !important;
}

.border-bottom-green.border-bottom-darken-4
{
    border-bottom: 1px solid #1b5e20 !important;
}

.border-left-green.border-left-darken-4
{
    border-left: 1px solid #1b5e20 !important;
}

.border-right-green.border-right-darken-4
{
    border-right: 1px solid #1b5e20 !important;
}

.overlay-green.overlay-darken-4
{
    background: #1b5e20;
    /* The Fallback */
    background: rgba(27, 94, 32, .8);
}

.green.accent-1
{
    color: #b9f6ca !important;
}

.bg-green.bg-accent-1
{
    background-color: #b9f6ca !important;
}
.bg-green.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(185, 246, 202, .6);
            box-shadow: 0 1px 20px 1px rgba(185, 246, 202, .6);
}

.btn-green.btn-accent-1
{
    border-color: #388e3c !important;
    background-color: #b9f6ca !important;
}
.btn-green.btn-accent-1:hover
{
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-accent-1:focus,
.btn-green.btn-accent-1:active
{
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}

.btn-outline-green.btn-outline-accent-1
{
    color: #b9f6ca !important; 
    border-color: #b9f6ca !important;
}
.btn-outline-green.btn-outline-accent-1:hover
{
    background-color: #b9f6ca !important;
}

input:focus ~ .bg-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b9f6ca !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b9f6ca !important;
}

.border-green.border-accent-1
{
    border: 1px solid #b9f6ca !important;
}

.border-top-green.border-top-accent-1
{
    border-top: 1px solid #b9f6ca !important;
}

.border-bottom-green.border-bottom-accent-1
{
    border-bottom: 1px solid #b9f6ca !important;
}

.border-left-green.border-left-accent-1
{
    border-left: 1px solid #b9f6ca !important;
}

.border-right-green.border-right-accent-1
{
    border-right: 1px solid #b9f6ca !important;
}

.overlay-green.overlay-accent-1
{
    background: #b9f6ca;
    /* The Fallback */
    background: rgba(185, 246, 202, .8);
}

.green.accent-2
{
    color: #69f0ae !important;
}

.bg-green.bg-accent-2
{
    background-color: #69f0ae !important;
}
.bg-green.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(105, 240, 174, .6);
            box-shadow: 0 1px 20px 1px rgba(105, 240, 174, .6);
}

.btn-green.btn-accent-2
{
    border-color: #388e3c !important;
    background-color: #69f0ae !important;
}
.btn-green.btn-accent-2:hover
{
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-accent-2:focus,
.btn-green.btn-accent-2:active
{
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}

.btn-outline-green.btn-outline-accent-2
{
    color: #69f0ae !important; 
    border-color: #69f0ae !important;
}
.btn-outline-green.btn-outline-accent-2:hover
{
    background-color: #69f0ae !important;
}

input:focus ~ .bg-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #69f0ae !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #69f0ae !important;
}

.border-green.border-accent-2
{
    border: 1px solid #69f0ae !important;
}

.border-top-green.border-top-accent-2
{
    border-top: 1px solid #69f0ae !important;
}

.border-bottom-green.border-bottom-accent-2
{
    border-bottom: 1px solid #69f0ae !important;
}

.border-left-green.border-left-accent-2
{
    border-left: 1px solid #69f0ae !important;
}

.border-right-green.border-right-accent-2
{
    border-right: 1px solid #69f0ae !important;
}

.overlay-green.overlay-accent-2
{
    background: #69f0ae;
    /* The Fallback */
    background: rgba(105, 240, 174, .8);
}

.green.accent-3
{
    color: #00e676 !important;
}

.bg-green.bg-accent-3
{
    background-color: #00e676 !important;
}
.bg-green.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 230, 118, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 230, 118, .6);
}

.btn-green.btn-accent-3
{
    border-color: #388e3c !important;
    background-color: #00e676 !important;
}
.btn-green.btn-accent-3:hover
{
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-accent-3:focus,
.btn-green.btn-accent-3:active
{
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}

.btn-outline-green.btn-outline-accent-3
{
    color: #00e676 !important; 
    border-color: #00e676 !important;
}
.btn-outline-green.btn-outline-accent-3:hover
{
    background-color: #00e676 !important;
}

input:focus ~ .bg-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00e676 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00e676 !important;
}

.border-green.border-accent-3
{
    border: 1px solid #00e676 !important;
}

.border-top-green.border-top-accent-3
{
    border-top: 1px solid #00e676 !important;
}

.border-bottom-green.border-bottom-accent-3
{
    border-bottom: 1px solid #00e676 !important;
}

.border-left-green.border-left-accent-3
{
    border-left: 1px solid #00e676 !important;
}

.border-right-green.border-right-accent-3
{
    border-right: 1px solid #00e676 !important;
}

.overlay-green.overlay-accent-3
{
    background: #00e676;
    /* The Fallback */
    background: rgba(0, 230, 118, .8);
}

.green.accent-4
{
    color: #00c853 !important;
}

.bg-green.bg-accent-4
{
    background-color: #00c853 !important;
}
.bg-green.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 200, 83, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 200, 83, .6);
}

.btn-green.btn-accent-4
{
    border-color: #388e3c !important;
    background-color: #00c853 !important;
}
.btn-green.btn-accent-4:hover
{
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-accent-4:focus,
.btn-green.btn-accent-4:active
{
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}

.btn-outline-green.btn-outline-accent-4
{
    color: #00c853 !important; 
    border-color: #00c853 !important;
}
.btn-outline-green.btn-outline-accent-4:hover
{
    background-color: #00c853 !important;
}

input:focus ~ .bg-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00c853 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00c853 !important;
}

.border-green.border-accent-4
{
    border: 1px solid #00c853 !important;
}

.border-top-green.border-top-accent-4
{
    border-top: 1px solid #00c853 !important;
}

.border-bottom-green.border-bottom-accent-4
{
    border-bottom: 1px solid #00c853 !important;
}

.border-left-green.border-left-accent-4
{
    border-left: 1px solid #00c853 !important;
}

.border-right-green.border-right-accent-4
{
    border-right: 1px solid #00c853 !important;
}

.overlay-green.overlay-accent-4
{
    background: #00c853;
    /* The Fallback */
    background: rgba(0, 200, 83, .8);
}

.light-green.lighten-5
{
    color: #f1f8a9 !important;
}

.bg-light-green.bg-lighten-5
{
    background-color: #f1f8a9 !important;
}
.bg-light-green.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(241, 248, 169, .6);
            box-shadow: 0 1px 20px 1px rgba(241, 248, 169, .6);
}

.btn-light-green.btn-lighten-5
{
    border-color: #689f38 !important;
    background-color: #f1f8a9 !important;
}
.btn-light-green.btn-lighten-5:hover
{
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-lighten-5:focus,
.btn-light-green.btn-lighten-5:active
{
    border-color: #558b2f !important;
    background-color: #33691e !important;
}

.btn-outline-light-green.btn-outline-lighten-5
{
    color: #f1f8a9 !important; 
    border-color: #f1f8a9 !important;
}
.btn-outline-light-green.btn-outline-lighten-5:hover
{
    background-color: #f1f8a9 !important;
}

input:focus ~ .bg-light-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f1f8a9 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f1f8a9 !important;
}

.border-light-green.border-lighten-5
{
    border: 1px solid #f1f8a9 !important;
}

.border-top-light-green.border-top-lighten-5
{
    border-top: 1px solid #f1f8a9 !important;
}

.border-bottom-light-green.border-bottom-lighten-5
{
    border-bottom: 1px solid #f1f8a9 !important;
}

.border-left-light-green.border-left-lighten-5
{
    border-left: 1px solid #f1f8a9 !important;
}

.border-right-light-green.border-right-lighten-5
{
    border-right: 1px solid #f1f8a9 !important;
}

.overlay-light-green.overlay-lighten-5
{
    background: #f1f8a9;
    /* The Fallback */
    background: rgba(241, 248, 169, .8);
}

.light-green.lighten-4
{
    color: #dcedc8 !important;
}

.bg-light-green.bg-lighten-4
{
    background-color: #dcedc8 !important;
}
.bg-light-green.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(220, 237, 200, .6);
            box-shadow: 0 1px 20px 1px rgba(220, 237, 200, .6);
}

.btn-light-green.btn-lighten-4
{
    border-color: #689f38 !important;
    background-color: #dcedc8 !important;
}
.btn-light-green.btn-lighten-4:hover
{
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-lighten-4:focus,
.btn-light-green.btn-lighten-4:active
{
    border-color: #558b2f !important;
    background-color: #33691e !important;
}

.btn-outline-light-green.btn-outline-lighten-4
{
    color: #dcedc8 !important; 
    border-color: #dcedc8 !important;
}
.btn-outline-light-green.btn-outline-lighten-4:hover
{
    background-color: #dcedc8 !important;
}

input:focus ~ .bg-light-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #dcedc8 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #dcedc8 !important;
}

.border-light-green.border-lighten-4
{
    border: 1px solid #dcedc8 !important;
}

.border-top-light-green.border-top-lighten-4
{
    border-top: 1px solid #dcedc8 !important;
}

.border-bottom-light-green.border-bottom-lighten-4
{
    border-bottom: 1px solid #dcedc8 !important;
}

.border-left-light-green.border-left-lighten-4
{
    border-left: 1px solid #dcedc8 !important;
}

.border-right-light-green.border-right-lighten-4
{
    border-right: 1px solid #dcedc8 !important;
}

.overlay-light-green.overlay-lighten-4
{
    background: #dcedc8;
    /* The Fallback */
    background: rgba(220, 237, 200, .8);
}

.light-green.lighten-3
{
    color: #c5e1a5 !important;
}

.bg-light-green.bg-lighten-3
{
    background-color: #c5e1a5 !important;
}
.bg-light-green.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(197, 225, 165, .6);
            box-shadow: 0 1px 20px 1px rgba(197, 225, 165, .6);
}

.btn-light-green.btn-lighten-3
{
    border-color: #689f38 !important;
    background-color: #c5e1a5 !important;
}
.btn-light-green.btn-lighten-3:hover
{
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-lighten-3:focus,
.btn-light-green.btn-lighten-3:active
{
    border-color: #558b2f !important;
    background-color: #33691e !important;
}

.btn-outline-light-green.btn-outline-lighten-3
{
    color: #c5e1a5 !important; 
    border-color: #c5e1a5 !important;
}
.btn-outline-light-green.btn-outline-lighten-3:hover
{
    background-color: #c5e1a5 !important;
}

input:focus ~ .bg-light-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c5e1a5 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c5e1a5 !important;
}

.border-light-green.border-lighten-3
{
    border: 1px solid #c5e1a5 !important;
}

.border-top-light-green.border-top-lighten-3
{
    border-top: 1px solid #c5e1a5 !important;
}

.border-bottom-light-green.border-bottom-lighten-3
{
    border-bottom: 1px solid #c5e1a5 !important;
}

.border-left-light-green.border-left-lighten-3
{
    border-left: 1px solid #c5e1a5 !important;
}

.border-right-light-green.border-right-lighten-3
{
    border-right: 1px solid #c5e1a5 !important;
}

.overlay-light-green.overlay-lighten-3
{
    background: #c5e1a5;
    /* The Fallback */
    background: rgba(197, 225, 165, .8);
}

.light-green.lighten-2
{
    color: #aed581 !important;
}

.bg-light-green.bg-lighten-2
{
    background-color: #aed581 !important;
}
.bg-light-green.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(174, 213, 129, .6);
            box-shadow: 0 1px 20px 1px rgba(174, 213, 129, .6);
}

.btn-light-green.btn-lighten-2
{
    border-color: #689f38 !important;
    background-color: #aed581 !important;
}
.btn-light-green.btn-lighten-2:hover
{
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-lighten-2:focus,
.btn-light-green.btn-lighten-2:active
{
    border-color: #558b2f !important;
    background-color: #33691e !important;
}

.btn-outline-light-green.btn-outline-lighten-2
{
    color: #aed581 !important; 
    border-color: #aed581 !important;
}
.btn-outline-light-green.btn-outline-lighten-2:hover
{
    background-color: #aed581 !important;
}

input:focus ~ .bg-light-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #aed581 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #aed581 !important;
}

.border-light-green.border-lighten-2
{
    border: 1px solid #aed581 !important;
}

.border-top-light-green.border-top-lighten-2
{
    border-top: 1px solid #aed581 !important;
}

.border-bottom-light-green.border-bottom-lighten-2
{
    border-bottom: 1px solid #aed581 !important;
}

.border-left-light-green.border-left-lighten-2
{
    border-left: 1px solid #aed581 !important;
}

.border-right-light-green.border-right-lighten-2
{
    border-right: 1px solid #aed581 !important;
}

.overlay-light-green.overlay-lighten-2
{
    background: #aed581;
    /* The Fallback */
    background: rgba(174, 213, 129, .8);
}

.light-green.lighten-1
{
    color: #9ccc65 !important;
}

.bg-light-green.bg-lighten-1
{
    background-color: #9ccc65 !important;
}
.bg-light-green.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(156, 204, 101, .6);
            box-shadow: 0 1px 20px 1px rgba(156, 204, 101, .6);
}

.btn-light-green.btn-lighten-1
{
    border-color: #689f38 !important;
    background-color: #9ccc65 !important;
}
.btn-light-green.btn-lighten-1:hover
{
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-lighten-1:focus,
.btn-light-green.btn-lighten-1:active
{
    border-color: #558b2f !important;
    background-color: #33691e !important;
}

.btn-outline-light-green.btn-outline-lighten-1
{
    color: #9ccc65 !important; 
    border-color: #9ccc65 !important;
}
.btn-outline-light-green.btn-outline-lighten-1:hover
{
    background-color: #9ccc65 !important;
}

input:focus ~ .bg-light-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #9ccc65 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #9ccc65 !important;
}

.border-light-green.border-lighten-1
{
    border: 1px solid #9ccc65 !important;
}

.border-top-light-green.border-top-lighten-1
{
    border-top: 1px solid #9ccc65 !important;
}

.border-bottom-light-green.border-bottom-lighten-1
{
    border-bottom: 1px solid #9ccc65 !important;
}

.border-left-light-green.border-left-lighten-1
{
    border-left: 1px solid #9ccc65 !important;
}

.border-right-light-green.border-right-lighten-1
{
    border-right: 1px solid #9ccc65 !important;
}

.overlay-light-green.overlay-lighten-1
{
    background: #9ccc65;
    /* The Fallback */
    background: rgba(156, 204, 101, .8);
}

.light-green
{
    color: #8bc34a !important;
}

.bg-light-green
{
    background-color: #8bc34a !important;
}
.bg-light-green .card-header,
.bg-light-green .card-footer
{
    background-color: transparent;
}
.bg-light-green.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(139, 195, 74, .6);
            box-shadow: 0 1px 20px 1px rgba(139, 195, 74, .6);
}

.selectboxit.selectboxit-btn.bg-light-green
{
    background-color: #8bc34a !important;
}

.alert-light-green
{
    color: #2f4417 !important; 
    border-color: #8bc34a !important;
    background-color: #add580 !important;
}
.alert-light-green .alert-link
{
    color: #1a250c !important;
}

.border-light-green
{
    border-color: #8bc34a;
}

.overlay-light-green
{
    background: #8bc34a;
    /* The Fallback */
    background: rgba(139, 195, 74, .8);
}

/* .card-outline-light-green{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-light-green
{
    color: #fff; 
    border-color: #8bc34a !important;
    background-color: #8bc34a !important;
}
.btn-light-green:hover
{
    color: #fff !important; 
    border-color: #689f38 !important;
    background-color: #7cb342 !important;
}
.btn-light-green:focus,
.btn-light-green:active
{
    color: #fff !important; 
    border-color: #558b2f !important;
    background-color: #689f38 !important;
}
.btn-light-green.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(139, 195, 74, .6);
            box-shadow: 0 1px 20px 1px rgba(139, 195, 74, .6);
}

.btn-outline-light-green
{
    color: #8bc34a; 
    border-color: #8bc34a;
    background-color: transparent;
}
.btn-outline-light-green:hover
{
    color: #fff !important; 
    background-color: #8bc34a;
}
.btn-outline-light-green.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(139, 195, 74, .6);
            box-shadow: 0 1px 20px 1px rgba(139, 195, 74, .6);
}

input[type='checkbox'].bg-light-green + .custom-control-label:before,
input[type='radio'].bg-light-green + .custom-control-label:before
{
    background-color: #8bc34a !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-light-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #8bc34a !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #8bc34a !important;
}

.border-light-green
{
    border: 1px solid #8bc34a !important;
}

.border-top-light-green
{
    border-top: 1px solid #8bc34a;
}

.border-bottom-light-green
{
    border-bottom: 1px solid #8bc34a;
}

.border-left-light-green
{
    border-left: 1px solid #8bc34a;
}

.border-right-light-green
{
    border-right: 1px solid #8bc34a;
}

.light-green.darken-1
{
    color: #7cb342 !important;
}

.bg-light-green.bg-darken-1
{
    background-color: #7cb342 !important;
}
.bg-light-green.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(124, 179, 66, .6);
            box-shadow: 0 1px 20px 1px rgba(124, 179, 66, .6);
}

.btn-light-green.btn-darken-1
{
    border-color: #689f38 !important;
    background-color: #7cb342 !important;
}
.btn-light-green.btn-darken-1:hover
{
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-darken-1:focus,
.btn-light-green.btn-darken-1:active
{
    border-color: #558b2f !important;
    background-color: #33691e !important;
}

.btn-outline-light-green.btn-outline-darken-1
{
    color: #7cb342 !important; 
    border-color: #7cb342 !important;
}
.btn-outline-light-green.btn-outline-darken-1:hover
{
    background-color: #7cb342 !important;
}

input:focus ~ .bg-light-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #7cb342 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #7cb342 !important;
}

.border-light-green.border-darken-1
{
    border: 1px solid #7cb342 !important;
}

.border-top-light-green.border-top-darken-1
{
    border-top: 1px solid #7cb342 !important;
}

.border-bottom-light-green.border-bottom-darken-1
{
    border-bottom: 1px solid #7cb342 !important;
}

.border-left-light-green.border-left-darken-1
{
    border-left: 1px solid #7cb342 !important;
}

.border-right-light-green.border-right-darken-1
{
    border-right: 1px solid #7cb342 !important;
}

.overlay-light-green.overlay-darken-1
{
    background: #7cb342;
    /* The Fallback */
    background: rgba(124, 179, 66, .8);
}

.light-green.darken-2
{
    color: #689f38 !important;
}

.bg-light-green.bg-darken-2
{
    background-color: #689f38 !important;
}
.bg-light-green.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(104, 159, 56, .6);
            box-shadow: 0 1px 20px 1px rgba(104, 159, 56, .6);
}

.btn-light-green.btn-darken-2
{
    border-color: #689f38 !important;
    background-color: #689f38 !important;
}
.btn-light-green.btn-darken-2:hover
{
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-darken-2:focus,
.btn-light-green.btn-darken-2:active
{
    border-color: #558b2f !important;
    background-color: #33691e !important;
}

.btn-outline-light-green.btn-outline-darken-2
{
    color: #689f38 !important; 
    border-color: #689f38 !important;
}
.btn-outline-light-green.btn-outline-darken-2:hover
{
    background-color: #689f38 !important;
}

input:focus ~ .bg-light-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #689f38 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #689f38 !important;
}

.border-light-green.border-darken-2
{
    border: 1px solid #689f38 !important;
}

.border-top-light-green.border-top-darken-2
{
    border-top: 1px solid #689f38 !important;
}

.border-bottom-light-green.border-bottom-darken-2
{
    border-bottom: 1px solid #689f38 !important;
}

.border-left-light-green.border-left-darken-2
{
    border-left: 1px solid #689f38 !important;
}

.border-right-light-green.border-right-darken-2
{
    border-right: 1px solid #689f38 !important;
}

.overlay-light-green.overlay-darken-2
{
    background: #689f38;
    /* The Fallback */
    background: rgba(104, 159, 56, .8);
}

.light-green.darken-3
{
    color: #558b2f !important;
}

.bg-light-green.bg-darken-3
{
    background-color: #558b2f !important;
}
.bg-light-green.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(85, 139, 47, .6);
            box-shadow: 0 1px 20px 1px rgba(85, 139, 47, .6);
}

.btn-light-green.btn-darken-3
{
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-darken-3:hover
{
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-darken-3:focus,
.btn-light-green.btn-darken-3:active
{
    border-color: #558b2f !important;
    background-color: #33691e !important;
}

.btn-outline-light-green.btn-outline-darken-3
{
    color: #558b2f !important; 
    border-color: #558b2f !important;
}
.btn-outline-light-green.btn-outline-darken-3:hover
{
    background-color: #558b2f !important;
}

input:focus ~ .bg-light-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #558b2f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #558b2f !important;
}

.border-light-green.border-darken-3
{
    border: 1px solid #558b2f !important;
}

.border-top-light-green.border-top-darken-3
{
    border-top: 1px solid #558b2f !important;
}

.border-bottom-light-green.border-bottom-darken-3
{
    border-bottom: 1px solid #558b2f !important;
}

.border-left-light-green.border-left-darken-3
{
    border-left: 1px solid #558b2f !important;
}

.border-right-light-green.border-right-darken-3
{
    border-right: 1px solid #558b2f !important;
}

.overlay-light-green.overlay-darken-3
{
    background: #558b2f;
    /* The Fallback */
    background: rgba(85, 139, 47, .8);
}

.light-green.darken-4
{
    color: #33691e !important;
}

.bg-light-green.bg-darken-4
{
    background-color: #33691e !important;
}
.bg-light-green.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(51, 105, 30, .6);
            box-shadow: 0 1px 20px 1px rgba(51, 105, 30, .6);
}

.btn-light-green.btn-darken-4
{
    border-color: #689f38 !important;
    background-color: #33691e !important;
}
.btn-light-green.btn-darken-4:hover
{
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-darken-4:focus,
.btn-light-green.btn-darken-4:active
{
    border-color: #558b2f !important;
    background-color: #33691e !important;
}

.btn-outline-light-green.btn-outline-darken-4
{
    color: #33691e !important; 
    border-color: #33691e !important;
}
.btn-outline-light-green.btn-outline-darken-4:hover
{
    background-color: #33691e !important;
}

input:focus ~ .bg-light-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #33691e !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #33691e !important;
}

.border-light-green.border-darken-4
{
    border: 1px solid #33691e !important;
}

.border-top-light-green.border-top-darken-4
{
    border-top: 1px solid #33691e !important;
}

.border-bottom-light-green.border-bottom-darken-4
{
    border-bottom: 1px solid #33691e !important;
}

.border-left-light-green.border-left-darken-4
{
    border-left: 1px solid #33691e !important;
}

.border-right-light-green.border-right-darken-4
{
    border-right: 1px solid #33691e !important;
}

.overlay-light-green.overlay-darken-4
{
    background: #33691e;
    /* The Fallback */
    background: rgba(51, 105, 30, .8);
}

.light-green.accent-1
{
    color: #ccff90 !important;
}

.bg-light-green.bg-accent-1
{
    background-color: #ccff90 !important;
}
.bg-light-green.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(204, 255, 144, .6);
            box-shadow: 0 1px 20px 1px rgba(204, 255, 144, .6);
}

.btn-light-green.btn-accent-1
{
    border-color: #689f38 !important;
    background-color: #ccff90 !important;
}
.btn-light-green.btn-accent-1:hover
{
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-accent-1:focus,
.btn-light-green.btn-accent-1:active
{
    border-color: #558b2f !important;
    background-color: #33691e !important;
}

.btn-outline-light-green.btn-outline-accent-1
{
    color: #ccff90 !important; 
    border-color: #ccff90 !important;
}
.btn-outline-light-green.btn-outline-accent-1:hover
{
    background-color: #ccff90 !important;
}

input:focus ~ .bg-light-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ccff90 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ccff90 !important;
}

.border-light-green.border-accent-1
{
    border: 1px solid #ccff90 !important;
}

.border-top-light-green.border-top-accent-1
{
    border-top: 1px solid #ccff90 !important;
}

.border-bottom-light-green.border-bottom-accent-1
{
    border-bottom: 1px solid #ccff90 !important;
}

.border-left-light-green.border-left-accent-1
{
    border-left: 1px solid #ccff90 !important;
}

.border-right-light-green.border-right-accent-1
{
    border-right: 1px solid #ccff90 !important;
}

.overlay-light-green.overlay-accent-1
{
    background: #ccff90;
    /* The Fallback */
    background: rgba(204, 255, 144, .8);
}

.light-green.accent-2
{
    color: #b2ff59 !important;
}

.bg-light-green.bg-accent-2
{
    background-color: #b2ff59 !important;
}
.bg-light-green.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(178, 255, 89, .6);
            box-shadow: 0 1px 20px 1px rgba(178, 255, 89, .6);
}

.btn-light-green.btn-accent-2
{
    border-color: #689f38 !important;
    background-color: #b2ff59 !important;
}
.btn-light-green.btn-accent-2:hover
{
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-accent-2:focus,
.btn-light-green.btn-accent-2:active
{
    border-color: #558b2f !important;
    background-color: #33691e !important;
}

.btn-outline-light-green.btn-outline-accent-2
{
    color: #b2ff59 !important; 
    border-color: #b2ff59 !important;
}
.btn-outline-light-green.btn-outline-accent-2:hover
{
    background-color: #b2ff59 !important;
}

input:focus ~ .bg-light-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b2ff59 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b2ff59 !important;
}

.border-light-green.border-accent-2
{
    border: 1px solid #b2ff59 !important;
}

.border-top-light-green.border-top-accent-2
{
    border-top: 1px solid #b2ff59 !important;
}

.border-bottom-light-green.border-bottom-accent-2
{
    border-bottom: 1px solid #b2ff59 !important;
}

.border-left-light-green.border-left-accent-2
{
    border-left: 1px solid #b2ff59 !important;
}

.border-right-light-green.border-right-accent-2
{
    border-right: 1px solid #b2ff59 !important;
}

.overlay-light-green.overlay-accent-2
{
    background: #b2ff59;
    /* The Fallback */
    background: rgba(178, 255, 89, .8);
}

.light-green.accent-3
{
    color: #76ff03 !important;
}

.bg-light-green.bg-accent-3
{
    background-color: #76ff03 !important;
}
.bg-light-green.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(118, 255, 3, .6);
            box-shadow: 0 1px 20px 1px rgba(118, 255, 3, .6);
}

.btn-light-green.btn-accent-3
{
    border-color: #689f38 !important;
    background-color: #76ff03 !important;
}
.btn-light-green.btn-accent-3:hover
{
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-accent-3:focus,
.btn-light-green.btn-accent-3:active
{
    border-color: #558b2f !important;
    background-color: #33691e !important;
}

.btn-outline-light-green.btn-outline-accent-3
{
    color: #76ff03 !important; 
    border-color: #76ff03 !important;
}
.btn-outline-light-green.btn-outline-accent-3:hover
{
    background-color: #76ff03 !important;
}

input:focus ~ .bg-light-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #76ff03 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #76ff03 !important;
}

.border-light-green.border-accent-3
{
    border: 1px solid #76ff03 !important;
}

.border-top-light-green.border-top-accent-3
{
    border-top: 1px solid #76ff03 !important;
}

.border-bottom-light-green.border-bottom-accent-3
{
    border-bottom: 1px solid #76ff03 !important;
}

.border-left-light-green.border-left-accent-3
{
    border-left: 1px solid #76ff03 !important;
}

.border-right-light-green.border-right-accent-3
{
    border-right: 1px solid #76ff03 !important;
}

.overlay-light-green.overlay-accent-3
{
    background: #76ff03;
    /* The Fallback */
    background: rgba(118, 255, 3, .8);
}

.light-green.accent-4
{
    color: #64dd17 !important;
}

.bg-light-green.bg-accent-4
{
    background-color: #64dd17 !important;
}
.bg-light-green.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(100, 221, 23, .6);
            box-shadow: 0 1px 20px 1px rgba(100, 221, 23, .6);
}

.btn-light-green.btn-accent-4
{
    border-color: #689f38 !important;
    background-color: #64dd17 !important;
}
.btn-light-green.btn-accent-4:hover
{
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-accent-4:focus,
.btn-light-green.btn-accent-4:active
{
    border-color: #558b2f !important;
    background-color: #33691e !important;
}

.btn-outline-light-green.btn-outline-accent-4
{
    color: #64dd17 !important; 
    border-color: #64dd17 !important;
}
.btn-outline-light-green.btn-outline-accent-4:hover
{
    background-color: #64dd17 !important;
}

input:focus ~ .bg-light-green
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #64dd17 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #64dd17 !important;
}

.border-light-green.border-accent-4
{
    border: 1px solid #64dd17 !important;
}

.border-top-light-green.border-top-accent-4
{
    border-top: 1px solid #64dd17 !important;
}

.border-bottom-light-green.border-bottom-accent-4
{
    border-bottom: 1px solid #64dd17 !important;
}

.border-left-light-green.border-left-accent-4
{
    border-left: 1px solid #64dd17 !important;
}

.border-right-light-green.border-right-accent-4
{
    border-right: 1px solid #64dd17 !important;
}

.overlay-light-green.overlay-accent-4
{
    background: #64dd17;
    /* The Fallback */
    background: rgba(100, 221, 23, .8);
}

.lime.lighten-5
{
    color: #f9fbe7 !important;
}

.bg-lime.bg-lighten-5
{
    background-color: #f9fbe7 !important;
}
.bg-lime.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(249, 251, 231, .6);
            box-shadow: 0 1px 20px 1px rgba(249, 251, 231, .6);
}

.btn-lime.btn-lighten-5
{
    border-color: #afb42b !important;
    background-color: #f9fbe7 !important;
}
.btn-lime.btn-lighten-5:hover
{
    border-color: #afb42b !important;
    background-color: #9e9d24 !important;
}
.btn-lime.btn-lighten-5:focus,
.btn-lime.btn-lighten-5:active
{
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}

.btn-outline-lime.btn-outline-lighten-5
{
    color: #f9fbe7 !important; 
    border-color: #f9fbe7 !important;
}
.btn-outline-lime.btn-outline-lighten-5:hover
{
    background-color: #f9fbe7 !important;
}

input:focus ~ .bg-lime
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f9fbe7 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f9fbe7 !important;
}

.border-lime.border-lighten-5
{
    border: 1px solid #f9fbe7 !important;
}

.border-top-lime.border-top-lighten-5
{
    border-top: 1px solid #f9fbe7 !important;
}

.border-bottom-lime.border-bottom-lighten-5
{
    border-bottom: 1px solid #f9fbe7 !important;
}

.border-left-lime.border-left-lighten-5
{
    border-left: 1px solid #f9fbe7 !important;
}

.border-right-lime.border-right-lighten-5
{
    border-right: 1px solid #f9fbe7 !important;
}

.overlay-lime.overlay-lighten-5
{
    background: #f9fbe7;
    /* The Fallback */
    background: rgba(249, 251, 231, .8);
}

.lime.lighten-4
{
    color: #f0f4c3 !important;
}

.bg-lime.bg-lighten-4
{
    background-color: #f0f4c3 !important;
}
.bg-lime.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(240, 244, 195, .6);
            box-shadow: 0 1px 20px 1px rgba(240, 244, 195, .6);
}

.btn-lime.btn-lighten-4
{
    border-color: #afb42b !important;
    background-color: #f0f4c3 !important;
}
.btn-lime.btn-lighten-4:hover
{
    border-color: #afb42b !important;
    background-color: #9e9d24 !important;
}
.btn-lime.btn-lighten-4:focus,
.btn-lime.btn-lighten-4:active
{
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}

.btn-outline-lime.btn-outline-lighten-4
{
    color: #f0f4c3 !important; 
    border-color: #f0f4c3 !important;
}
.btn-outline-lime.btn-outline-lighten-4:hover
{
    background-color: #f0f4c3 !important;
}

input:focus ~ .bg-lime
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f0f4c3 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f0f4c3 !important;
}

.border-lime.border-lighten-4
{
    border: 1px solid #f0f4c3 !important;
}

.border-top-lime.border-top-lighten-4
{
    border-top: 1px solid #f0f4c3 !important;
}

.border-bottom-lime.border-bottom-lighten-4
{
    border-bottom: 1px solid #f0f4c3 !important;
}

.border-left-lime.border-left-lighten-4
{
    border-left: 1px solid #f0f4c3 !important;
}

.border-right-lime.border-right-lighten-4
{
    border-right: 1px solid #f0f4c3 !important;
}

.overlay-lime.overlay-lighten-4
{
    background: #f0f4c3;
    /* The Fallback */
    background: rgba(240, 244, 195, .8);
}

.lime.lighten-3
{
    color: #e6ee9c !important;
}

.bg-lime.bg-lighten-3
{
    background-color: #e6ee9c !important;
}
.bg-lime.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(230, 238, 156, .6);
            box-shadow: 0 1px 20px 1px rgba(230, 238, 156, .6);
}

.btn-lime.btn-lighten-3
{
    border-color: #afb42b !important;
    background-color: #e6ee9c !important;
}
.btn-lime.btn-lighten-3:hover
{
    border-color: #afb42b !important;
    background-color: #9e9d24 !important;
}
.btn-lime.btn-lighten-3:focus,
.btn-lime.btn-lighten-3:active
{
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}

.btn-outline-lime.btn-outline-lighten-3
{
    color: #e6ee9c !important; 
    border-color: #e6ee9c !important;
}
.btn-outline-lime.btn-outline-lighten-3:hover
{
    background-color: #e6ee9c !important;
}

input:focus ~ .bg-lime
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e6ee9c !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e6ee9c !important;
}

.border-lime.border-lighten-3
{
    border: 1px solid #e6ee9c !important;
}

.border-top-lime.border-top-lighten-3
{
    border-top: 1px solid #e6ee9c !important;
}

.border-bottom-lime.border-bottom-lighten-3
{
    border-bottom: 1px solid #e6ee9c !important;
}

.border-left-lime.border-left-lighten-3
{
    border-left: 1px solid #e6ee9c !important;
}

.border-right-lime.border-right-lighten-3
{
    border-right: 1px solid #e6ee9c !important;
}

.overlay-lime.overlay-lighten-3
{
    background: #e6ee9c;
    /* The Fallback */
    background: rgba(230, 238, 156, .8);
}

.lime.lighten-2
{
    color: #dce775 !important;
}

.bg-lime.bg-lighten-2
{
    background-color: #dce775 !important;
}
.bg-lime.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(220, 231, 117, .6);
            box-shadow: 0 1px 20px 1px rgba(220, 231, 117, .6);
}

.btn-lime.btn-lighten-2
{
    border-color: #afb42b !important;
    background-color: #dce775 !important;
}
.btn-lime.btn-lighten-2:hover
{
    border-color: #afb42b !important;
    background-color: #9e9d24 !important;
}
.btn-lime.btn-lighten-2:focus,
.btn-lime.btn-lighten-2:active
{
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}

.btn-outline-lime.btn-outline-lighten-2
{
    color: #dce775 !important; 
    border-color: #dce775 !important;
}
.btn-outline-lime.btn-outline-lighten-2:hover
{
    background-color: #dce775 !important;
}

input:focus ~ .bg-lime
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #dce775 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #dce775 !important;
}

.border-lime.border-lighten-2
{
    border: 1px solid #dce775 !important;
}

.border-top-lime.border-top-lighten-2
{
    border-top: 1px solid #dce775 !important;
}

.border-bottom-lime.border-bottom-lighten-2
{
    border-bottom: 1px solid #dce775 !important;
}

.border-left-lime.border-left-lighten-2
{
    border-left: 1px solid #dce775 !important;
}

.border-right-lime.border-right-lighten-2
{
    border-right: 1px solid #dce775 !important;
}

.overlay-lime.overlay-lighten-2
{
    background: #dce775;
    /* The Fallback */
    background: rgba(220, 231, 117, .8);
}

.lime.lighten-1
{
    color: #d4e157 !important;
}

.bg-lime.bg-lighten-1
{
    background-color: #d4e157 !important;
}
.bg-lime.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(212, 225, 87, .6);
            box-shadow: 0 1px 20px 1px rgba(212, 225, 87, .6);
}

.btn-lime.btn-lighten-1
{
    border-color: #afb42b !important;
    background-color: #d4e157 !important;
}
.btn-lime.btn-lighten-1:hover
{
    border-color: #afb42b !important;
    background-color: #9e9d24 !important;
}
.btn-lime.btn-lighten-1:focus,
.btn-lime.btn-lighten-1:active
{
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}

.btn-outline-lime.btn-outline-lighten-1
{
    color: #d4e157 !important; 
    border-color: #d4e157 !important;
}
.btn-outline-lime.btn-outline-lighten-1:hover
{
    background-color: #d4e157 !important;
}

input:focus ~ .bg-lime
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d4e157 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d4e157 !important;
}

.border-lime.border-lighten-1
{
    border: 1px solid #d4e157 !important;
}

.border-top-lime.border-top-lighten-1
{
    border-top: 1px solid #d4e157 !important;
}

.border-bottom-lime.border-bottom-lighten-1
{
    border-bottom: 1px solid #d4e157 !important;
}

.border-left-lime.border-left-lighten-1
{
    border-left: 1px solid #d4e157 !important;
}

.border-right-lime.border-right-lighten-1
{
    border-right: 1px solid #d4e157 !important;
}

.overlay-lime.overlay-lighten-1
{
    background: #d4e157;
    /* The Fallback */
    background: rgba(212, 225, 87, .8);
}

.lime
{
    color: #cddc39 !important;
}

.bg-lime
{
    background-color: #cddc39 !important;
}
.bg-lime .card-header,
.bg-lime .card-footer
{
    background-color: transparent;
}
.bg-lime.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(205, 220, 57, .6);
            box-shadow: 0 1px 20px 1px rgba(205, 220, 57, .6);
}

.selectboxit.selectboxit-btn.bg-lime
{
    background-color: #cddc39 !important;
}

.alert-lime
{
    color: #4d540f !important; 
    border-color: #cddc39 !important;
    background-color: #dce776 !important;
}
.alert-lime .alert-link
{
    color: #2d3109 !important;
}

.border-lime
{
    border-color: #cddc39;
}

.overlay-lime
{
    background: #cddc39;
    /* The Fallback */
    background: rgba(205, 220, 57, .8);
}

/* .card-outline-lime{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-lime
{
    color: #fff; 
    border-color: #cddc39 !important;
    background-color: #cddc39 !important;
}
.btn-lime:hover
{
    color: #fff !important; 
    border-color: #afb42b !important;
    background-color: #c0ca33 !important;
}
.btn-lime:focus,
.btn-lime:active
{
    color: #fff !important; 
    border-color: #9e9d24 !important;
    background-color: #afb42b !important;
}
.btn-lime.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(205, 220, 57, .6);
            box-shadow: 0 1px 20px 1px rgba(205, 220, 57, .6);
}

.btn-outline-lime
{
    color: #cddc39; 
    border-color: #cddc39;
    background-color: transparent;
}
.btn-outline-lime:hover
{
    color: #fff !important; 
    background-color: #cddc39;
}
.btn-outline-lime.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(205, 220, 57, .6);
            box-shadow: 0 1px 20px 1px rgba(205, 220, 57, .6);
}

input[type='checkbox'].bg-lime + .custom-control-label:before,
input[type='radio'].bg-lime + .custom-control-label:before
{
    background-color: #cddc39 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-lime
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #cddc39 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #cddc39 !important;
}

.border-lime
{
    border: 1px solid #cddc39 !important;
}

.border-top-lime
{
    border-top: 1px solid #cddc39;
}

.border-bottom-lime
{
    border-bottom: 1px solid #cddc39;
}

.border-left-lime
{
    border-left: 1px solid #cddc39;
}

.border-right-lime
{
    border-right: 1px solid #cddc39;
}

.lime.darken-1
{
    color: #c0ca33 !important;
}

.bg-lime.bg-darken-1
{
    background-color: #c0ca33 !important;
}
.bg-lime.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(192, 202, 51, .6);
            box-shadow: 0 1px 20px 1px rgba(192, 202, 51, .6);
}

.btn-lime.btn-darken-1
{
    border-color: #afb42b !important;
    background-color: #c0ca33 !important;
}
.btn-lime.btn-darken-1:hover
{
    border-color: #afb42b !important;
    background-color: #9e9d24 !important;
}
.btn-lime.btn-darken-1:focus,
.btn-lime.btn-darken-1:active
{
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}

.btn-outline-lime.btn-outline-darken-1
{
    color: #c0ca33 !important; 
    border-color: #c0ca33 !important;
}
.btn-outline-lime.btn-outline-darken-1:hover
{
    background-color: #c0ca33 !important;
}

input:focus ~ .bg-lime
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c0ca33 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c0ca33 !important;
}

.border-lime.border-darken-1
{
    border: 1px solid #c0ca33 !important;
}

.border-top-lime.border-top-darken-1
{
    border-top: 1px solid #c0ca33 !important;
}

.border-bottom-lime.border-bottom-darken-1
{
    border-bottom: 1px solid #c0ca33 !important;
}

.border-left-lime.border-left-darken-1
{
    border-left: 1px solid #c0ca33 !important;
}

.border-right-lime.border-right-darken-1
{
    border-right: 1px solid #c0ca33 !important;
}

.overlay-lime.overlay-darken-1
{
    background: #c0ca33;
    /* The Fallback */
    background: rgba(192, 202, 51, .8);
}

.lime.darken-2
{
    color: #afb42b !important;
}

.bg-lime.bg-darken-2
{
    background-color: #afb42b !important;
}
.bg-lime.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(175, 180, 43, .6);
            box-shadow: 0 1px 20px 1px rgba(175, 180, 43, .6);
}

.btn-lime.btn-darken-2
{
    border-color: #afb42b !important;
    background-color: #afb42b !important;
}
.btn-lime.btn-darken-2:hover
{
    border-color: #afb42b !important;
    background-color: #9e9d24 !important;
}
.btn-lime.btn-darken-2:focus,
.btn-lime.btn-darken-2:active
{
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}

.btn-outline-lime.btn-outline-darken-2
{
    color: #afb42b !important; 
    border-color: #afb42b !important;
}
.btn-outline-lime.btn-outline-darken-2:hover
{
    background-color: #afb42b !important;
}

input:focus ~ .bg-lime
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #afb42b !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #afb42b !important;
}

.border-lime.border-darken-2
{
    border: 1px solid #afb42b !important;
}

.border-top-lime.border-top-darken-2
{
    border-top: 1px solid #afb42b !important;
}

.border-bottom-lime.border-bottom-darken-2
{
    border-bottom: 1px solid #afb42b !important;
}

.border-left-lime.border-left-darken-2
{
    border-left: 1px solid #afb42b !important;
}

.border-right-lime.border-right-darken-2
{
    border-right: 1px solid #afb42b !important;
}

.overlay-lime.overlay-darken-2
{
    background: #afb42b;
    /* The Fallback */
    background: rgba(175, 180, 43, .8);
}

.lime.darken-3
{
    color: #9e9d24 !important;
}

.bg-lime.bg-darken-3
{
    background-color: #9e9d24 !important;
}
.bg-lime.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(158, 157, 36, .6);
            box-shadow: 0 1px 20px 1px rgba(158, 157, 36, .6);
}

.btn-lime.btn-darken-3
{
    border-color: #afb42b !important;
    background-color: #9e9d24 !important;
}
.btn-lime.btn-darken-3:hover
{
    border-color: #afb42b !important;
    background-color: #9e9d24 !important;
}
.btn-lime.btn-darken-3:focus,
.btn-lime.btn-darken-3:active
{
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}

.btn-outline-lime.btn-outline-darken-3
{
    color: #9e9d24 !important; 
    border-color: #9e9d24 !important;
}
.btn-outline-lime.btn-outline-darken-3:hover
{
    background-color: #9e9d24 !important;
}

input:focus ~ .bg-lime
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #9e9d24 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #9e9d24 !important;
}

.border-lime.border-darken-3
{
    border: 1px solid #9e9d24 !important;
}

.border-top-lime.border-top-darken-3
{
    border-top: 1px solid #9e9d24 !important;
}

.border-bottom-lime.border-bottom-darken-3
{
    border-bottom: 1px solid #9e9d24 !important;
}

.border-left-lime.border-left-darken-3
{
    border-left: 1px solid #9e9d24 !important;
}

.border-right-lime.border-right-darken-3
{
    border-right: 1px solid #9e9d24 !important;
}

.overlay-lime.overlay-darken-3
{
    background: #9e9d24;
    /* The Fallback */
    background: rgba(158, 157, 36, .8);
}

.lime.darken-4
{
    color: #827717 !important;
}

.bg-lime.bg-darken-4
{
    background-color: #827717 !important;
}
.bg-lime.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(130, 119, 23, .6);
            box-shadow: 0 1px 20px 1px rgba(130, 119, 23, .6);
}

.btn-lime.btn-darken-4
{
    border-color: #afb42b !important;
    background-color: #827717 !important;
}
.btn-lime.btn-darken-4:hover
{
    border-color: #afb42b !important;
    background-color: #9e9d24 !important;
}
.btn-lime.btn-darken-4:focus,
.btn-lime.btn-darken-4:active
{
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}

.btn-outline-lime.btn-outline-darken-4
{
    color: #827717 !important; 
    border-color: #827717 !important;
}
.btn-outline-lime.btn-outline-darken-4:hover
{
    background-color: #827717 !important;
}

input:focus ~ .bg-lime
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #827717 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #827717 !important;
}

.border-lime.border-darken-4
{
    border: 1px solid #827717 !important;
}

.border-top-lime.border-top-darken-4
{
    border-top: 1px solid #827717 !important;
}

.border-bottom-lime.border-bottom-darken-4
{
    border-bottom: 1px solid #827717 !important;
}

.border-left-lime.border-left-darken-4
{
    border-left: 1px solid #827717 !important;
}

.border-right-lime.border-right-darken-4
{
    border-right: 1px solid #827717 !important;
}

.overlay-lime.overlay-darken-4
{
    background: #827717;
    /* The Fallback */
    background: rgba(130, 119, 23, .8);
}

.lime.accent-1
{
    color: #f4ff81 !important;
}

.bg-lime.bg-accent-1
{
    background-color: #f4ff81 !important;
}
.bg-lime.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(244, 255, 129, .6);
            box-shadow: 0 1px 20px 1px rgba(244, 255, 129, .6);
}

.btn-lime.btn-accent-1
{
    border-color: #afb42b !important;
    background-color: #f4ff81 !important;
}
.btn-lime.btn-accent-1:hover
{
    border-color: #afb42b !important;
    background-color: #9e9d24 !important;
}
.btn-lime.btn-accent-1:focus,
.btn-lime.btn-accent-1:active
{
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}

.btn-outline-lime.btn-outline-accent-1
{
    color: #f4ff81 !important; 
    border-color: #f4ff81 !important;
}
.btn-outline-lime.btn-outline-accent-1:hover
{
    background-color: #f4ff81 !important;
}

input:focus ~ .bg-lime
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f4ff81 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f4ff81 !important;
}

.border-lime.border-accent-1
{
    border: 1px solid #f4ff81 !important;
}

.border-top-lime.border-top-accent-1
{
    border-top: 1px solid #f4ff81 !important;
}

.border-bottom-lime.border-bottom-accent-1
{
    border-bottom: 1px solid #f4ff81 !important;
}

.border-left-lime.border-left-accent-1
{
    border-left: 1px solid #f4ff81 !important;
}

.border-right-lime.border-right-accent-1
{
    border-right: 1px solid #f4ff81 !important;
}

.overlay-lime.overlay-accent-1
{
    background: #f4ff81;
    /* The Fallback */
    background: rgba(244, 255, 129, .8);
}

.lime.accent-2
{
    color: #eeff41 !important;
}

.bg-lime.bg-accent-2
{
    background-color: #eeff41 !important;
}
.bg-lime.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(238, 255, 65, .6);
            box-shadow: 0 1px 20px 1px rgba(238, 255, 65, .6);
}

.btn-lime.btn-accent-2
{
    border-color: #afb42b !important;
    background-color: #eeff41 !important;
}
.btn-lime.btn-accent-2:hover
{
    border-color: #afb42b !important;
    background-color: #9e9d24 !important;
}
.btn-lime.btn-accent-2:focus,
.btn-lime.btn-accent-2:active
{
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}

.btn-outline-lime.btn-outline-accent-2
{
    color: #eeff41 !important; 
    border-color: #eeff41 !important;
}
.btn-outline-lime.btn-outline-accent-2:hover
{
    background-color: #eeff41 !important;
}

input:focus ~ .bg-lime
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #eeff41 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #eeff41 !important;
}

.border-lime.border-accent-2
{
    border: 1px solid #eeff41 !important;
}

.border-top-lime.border-top-accent-2
{
    border-top: 1px solid #eeff41 !important;
}

.border-bottom-lime.border-bottom-accent-2
{
    border-bottom: 1px solid #eeff41 !important;
}

.border-left-lime.border-left-accent-2
{
    border-left: 1px solid #eeff41 !important;
}

.border-right-lime.border-right-accent-2
{
    border-right: 1px solid #eeff41 !important;
}

.overlay-lime.overlay-accent-2
{
    background: #eeff41;
    /* The Fallback */
    background: rgba(238, 255, 65, .8);
}

.lime.accent-3
{
    color: #c6ff00 !important;
}

.bg-lime.bg-accent-3
{
    background-color: #c6ff00 !important;
}
.bg-lime.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(198, 255, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(198, 255, 0, .6);
}

.btn-lime.btn-accent-3
{
    border-color: #afb42b !important;
    background-color: #c6ff00 !important;
}
.btn-lime.btn-accent-3:hover
{
    border-color: #afb42b !important;
    background-color: #9e9d24 !important;
}
.btn-lime.btn-accent-3:focus,
.btn-lime.btn-accent-3:active
{
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}

.btn-outline-lime.btn-outline-accent-3
{
    color: #c6ff00 !important; 
    border-color: #c6ff00 !important;
}
.btn-outline-lime.btn-outline-accent-3:hover
{
    background-color: #c6ff00 !important;
}

input:focus ~ .bg-lime
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c6ff00 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c6ff00 !important;
}

.border-lime.border-accent-3
{
    border: 1px solid #c6ff00 !important;
}

.border-top-lime.border-top-accent-3
{
    border-top: 1px solid #c6ff00 !important;
}

.border-bottom-lime.border-bottom-accent-3
{
    border-bottom: 1px solid #c6ff00 !important;
}

.border-left-lime.border-left-accent-3
{
    border-left: 1px solid #c6ff00 !important;
}

.border-right-lime.border-right-accent-3
{
    border-right: 1px solid #c6ff00 !important;
}

.overlay-lime.overlay-accent-3
{
    background: #c6ff00;
    /* The Fallback */
    background: rgba(198, 255, 0, .8);
}

.lime.accent-4
{
    color: #aeea00 !important;
}

.bg-lime.bg-accent-4
{
    background-color: #aeea00 !important;
}
.bg-lime.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(174, 234, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(174, 234, 0, .6);
}

.btn-lime.btn-accent-4
{
    border-color: #afb42b !important;
    background-color: #aeea00 !important;
}
.btn-lime.btn-accent-4:hover
{
    border-color: #afb42b !important;
    background-color: #9e9d24 !important;
}
.btn-lime.btn-accent-4:focus,
.btn-lime.btn-accent-4:active
{
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}

.btn-outline-lime.btn-outline-accent-4
{
    color: #aeea00 !important; 
    border-color: #aeea00 !important;
}
.btn-outline-lime.btn-outline-accent-4:hover
{
    background-color: #aeea00 !important;
}

input:focus ~ .bg-lime
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #aeea00 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #aeea00 !important;
}

.border-lime.border-accent-4
{
    border: 1px solid #aeea00 !important;
}

.border-top-lime.border-top-accent-4
{
    border-top: 1px solid #aeea00 !important;
}

.border-bottom-lime.border-bottom-accent-4
{
    border-bottom: 1px solid #aeea00 !important;
}

.border-left-lime.border-left-accent-4
{
    border-left: 1px solid #aeea00 !important;
}

.border-right-lime.border-right-accent-4
{
    border-right: 1px solid #aeea00 !important;
}

.overlay-lime.overlay-accent-4
{
    background: #aeea00;
    /* The Fallback */
    background: rgba(174, 234, 0, .8);
}

.yellow.lighten-5
{
    color: #fffde7 !important;
}

.bg-yellow.bg-lighten-5
{
    background-color: #fffde7 !important;
}
.bg-yellow.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 253, 231, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 253, 231, .6);
}

.btn-yellow.btn-lighten-5
{
    border-color: #fbc02d !important;
    background-color: #fffde7 !important;
}
.btn-yellow.btn-lighten-5:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-lighten-5:focus,
.btn-yellow.btn-lighten-5:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-lighten-5
{
    color: #fffde7 !important; 
    border-color: #fffde7 !important;
}
.btn-outline-yellow.btn-outline-lighten-5:hover
{
    background-color: #fffde7 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fffde7 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fffde7 !important;
}

.border-yellow.border-lighten-5
{
    border: 1px solid #fffde7 !important;
}

.border-top-yellow.border-top-lighten-5
{
    border-top: 1px solid #fffde7 !important;
}

.border-bottom-yellow.border-bottom-lighten-5
{
    border-bottom: 1px solid #fffde7 !important;
}

.border-left-yellow.border-left-lighten-5
{
    border-left: 1px solid #fffde7 !important;
}

.border-right-yellow.border-right-lighten-5
{
    border-right: 1px solid #fffde7 !important;
}

.overlay-yellow.overlay-lighten-5
{
    background: #fffde7;
    /* The Fallback */
    background: rgba(255, 253, 231, .8);
}

.yellow.lighten-4
{
    color: #fff9c4 !important;
}

.bg-yellow.bg-lighten-4
{
    background-color: #fff9c4 !important;
}
.bg-yellow.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 249, 196, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 249, 196, .6);
}

.btn-yellow.btn-lighten-4
{
    border-color: #fbc02d !important;
    background-color: #fff9c4 !important;
}
.btn-yellow.btn-lighten-4:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-lighten-4:focus,
.btn-yellow.btn-lighten-4:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-lighten-4
{
    color: #fff9c4 !important; 
    border-color: #fff9c4 !important;
}
.btn-outline-yellow.btn-outline-lighten-4:hover
{
    background-color: #fff9c4 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff9c4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff9c4 !important;
}

.border-yellow.border-lighten-4
{
    border: 1px solid #fff9c4 !important;
}

.border-top-yellow.border-top-lighten-4
{
    border-top: 1px solid #fff9c4 !important;
}

.border-bottom-yellow.border-bottom-lighten-4
{
    border-bottom: 1px solid #fff9c4 !important;
}

.border-left-yellow.border-left-lighten-4
{
    border-left: 1px solid #fff9c4 !important;
}

.border-right-yellow.border-right-lighten-4
{
    border-right: 1px solid #fff9c4 !important;
}

.overlay-yellow.overlay-lighten-4
{
    background: #fff9c4;
    /* The Fallback */
    background: rgba(255, 249, 196, .8);
}

.yellow.lighten-3
{
    color: #fff59d !important;
}

.bg-yellow.bg-lighten-3
{
    background-color: #fff59d !important;
}
.bg-yellow.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 245, 157, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 245, 157, .6);
}

.btn-yellow.btn-lighten-3
{
    border-color: #fbc02d !important;
    background-color: #fff59d !important;
}
.btn-yellow.btn-lighten-3:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-lighten-3:focus,
.btn-yellow.btn-lighten-3:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-lighten-3
{
    color: #fff59d !important; 
    border-color: #fff59d !important;
}
.btn-outline-yellow.btn-outline-lighten-3:hover
{
    background-color: #fff59d !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff59d !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff59d !important;
}

.border-yellow.border-lighten-3
{
    border: 1px solid #fff59d !important;
}

.border-top-yellow.border-top-lighten-3
{
    border-top: 1px solid #fff59d !important;
}

.border-bottom-yellow.border-bottom-lighten-3
{
    border-bottom: 1px solid #fff59d !important;
}

.border-left-yellow.border-left-lighten-3
{
    border-left: 1px solid #fff59d !important;
}

.border-right-yellow.border-right-lighten-3
{
    border-right: 1px solid #fff59d !important;
}

.overlay-yellow.overlay-lighten-3
{
    background: #fff59d;
    /* The Fallback */
    background: rgba(255, 245, 157, .8);
}

.yellow.lighten-2
{
    color: #fff176 !important;
}

.bg-yellow.bg-lighten-2
{
    background-color: #fff176 !important;
}
.bg-yellow.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 241, 118, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 241, 118, .6);
}

.btn-yellow.btn-lighten-2
{
    border-color: #fbc02d !important;
    background-color: #fff176 !important;
}
.btn-yellow.btn-lighten-2:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-lighten-2:focus,
.btn-yellow.btn-lighten-2:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-lighten-2
{
    color: #fff176 !important; 
    border-color: #fff176 !important;
}
.btn-outline-yellow.btn-outline-lighten-2:hover
{
    background-color: #fff176 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff176 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff176 !important;
}

.border-yellow.border-lighten-2
{
    border: 1px solid #fff176 !important;
}

.border-top-yellow.border-top-lighten-2
{
    border-top: 1px solid #fff176 !important;
}

.border-bottom-yellow.border-bottom-lighten-2
{
    border-bottom: 1px solid #fff176 !important;
}

.border-left-yellow.border-left-lighten-2
{
    border-left: 1px solid #fff176 !important;
}

.border-right-yellow.border-right-lighten-2
{
    border-right: 1px solid #fff176 !important;
}

.overlay-yellow.overlay-lighten-2
{
    background: #fff176;
    /* The Fallback */
    background: rgba(255, 241, 118, .8);
}

.yellow.lighten-1
{
    color: #ffee58 !important;
}

.bg-yellow.bg-lighten-1
{
    background-color: #ffee58 !important;
}
.bg-yellow.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 238, 88, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 238, 88, .6);
}

.btn-yellow.btn-lighten-1
{
    border-color: #fbc02d !important;
    background-color: #ffee58 !important;
}
.btn-yellow.btn-lighten-1:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-lighten-1:focus,
.btn-yellow.btn-lighten-1:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-lighten-1
{
    color: #ffee58 !important; 
    border-color: #ffee58 !important;
}
.btn-outline-yellow.btn-outline-lighten-1:hover
{
    background-color: #ffee58 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffee58 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffee58 !important;
}

.border-yellow.border-lighten-1
{
    border: 1px solid #ffee58 !important;
}

.border-top-yellow.border-top-lighten-1
{
    border-top: 1px solid #ffee58 !important;
}

.border-bottom-yellow.border-bottom-lighten-1
{
    border-bottom: 1px solid #ffee58 !important;
}

.border-left-yellow.border-left-lighten-1
{
    border-left: 1px solid #ffee58 !important;
}

.border-right-yellow.border-right-lighten-1
{
    border-right: 1px solid #ffee58 !important;
}

.overlay-yellow.overlay-lighten-1
{
    background: #ffee58;
    /* The Fallback */
    background: rgba(255, 238, 88, .8);
}

.yellow
{
    color: #ffeb3b !important;
}

.bg-yellow
{
    background-color: #ffeb3b !important;
}
.bg-yellow .card-header,
.bg-yellow .card-footer
{
    background-color: transparent;
}
.bg-yellow.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 235, 59, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 235, 59, .6);
}

.selectboxit.selectboxit-btn.bg-yellow
{
    background-color: #ffeb3b !important;
}

.alert-yellow
{
    color: #887a00 !important; 
    border-color: #ffeb3b !important;
    background-color: #fff282 !important;
}
.alert-yellow .alert-link
{
    color: #5f5500 !important;
}

.border-yellow
{
    border-color: #ffeb3b;
}

.overlay-yellow
{
    background: #ffeb3b;
    /* The Fallback */
    background: rgba(255, 235, 59, .8);
}

/* .card-outline-yellow{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-yellow
{
    color: #fff; 
    border-color: #ffeb3b !important;
    background-color: #ffeb3b !important;
}
.btn-yellow:hover
{
    color: #fff !important; 
    border-color: #fbc02d !important;
    background-color: #fdd835 !important;
}
.btn-yellow:focus,
.btn-yellow:active
{
    color: #fff !important; 
    border-color: #f9a825 !important;
    background-color: #fbc02d !important;
}
.btn-yellow.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 235, 59, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 235, 59, .6);
}

.btn-outline-yellow
{
    color: #ffeb3b; 
    border-color: #ffeb3b;
    background-color: transparent;
}
.btn-outline-yellow:hover
{
    color: #fff !important; 
    background-color: #ffeb3b;
}
.btn-outline-yellow.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 235, 59, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 235, 59, .6);
}

input[type='checkbox'].bg-yellow + .custom-control-label:before,
input[type='radio'].bg-yellow + .custom-control-label:before
{
    background-color: #ffeb3b !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffeb3b !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffeb3b !important;
}

.border-yellow
{
    border: 1px solid #ffeb3b !important;
}

.border-top-yellow
{
    border-top: 1px solid #ffeb3b;
}

.border-bottom-yellow
{
    border-bottom: 1px solid #ffeb3b;
}

.border-left-yellow
{
    border-left: 1px solid #ffeb3b;
}

.border-right-yellow
{
    border-right: 1px solid #ffeb3b;
}

.yellow.darken-1
{
    color: #fdd835 !important;
}

.bg-yellow.bg-darken-1
{
    background-color: #fdd835 !important;
}
.bg-yellow.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(253, 216, 53, .6);
            box-shadow: 0 1px 20px 1px rgba(253, 216, 53, .6);
}

.btn-yellow.btn-darken-1
{
    border-color: #fbc02d !important;
    background-color: #fdd835 !important;
}
.btn-yellow.btn-darken-1:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-darken-1:focus,
.btn-yellow.btn-darken-1:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-darken-1
{
    color: #fdd835 !important; 
    border-color: #fdd835 !important;
}
.btn-outline-yellow.btn-outline-darken-1:hover
{
    background-color: #fdd835 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fdd835 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fdd835 !important;
}

.border-yellow.border-darken-1
{
    border: 1px solid #fdd835 !important;
}

.border-top-yellow.border-top-darken-1
{
    border-top: 1px solid #fdd835 !important;
}

.border-bottom-yellow.border-bottom-darken-1
{
    border-bottom: 1px solid #fdd835 !important;
}

.border-left-yellow.border-left-darken-1
{
    border-left: 1px solid #fdd835 !important;
}

.border-right-yellow.border-right-darken-1
{
    border-right: 1px solid #fdd835 !important;
}

.overlay-yellow.overlay-darken-1
{
    background: #fdd835;
    /* The Fallback */
    background: rgba(253, 216, 53, .8);
}

.yellow.darken-2
{
    color: #fbc02d !important;
}

.bg-yellow.bg-darken-2
{
    background-color: #fbc02d !important;
}
.bg-yellow.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(251, 192, 45, .6);
            box-shadow: 0 1px 20px 1px rgba(251, 192, 45, .6);
}

.btn-yellow.btn-darken-2
{
    border-color: #fbc02d !important;
    background-color: #fbc02d !important;
}
.btn-yellow.btn-darken-2:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-darken-2:focus,
.btn-yellow.btn-darken-2:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-darken-2
{
    color: #fbc02d !important; 
    border-color: #fbc02d !important;
}
.btn-outline-yellow.btn-outline-darken-2:hover
{
    background-color: #fbc02d !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fbc02d !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fbc02d !important;
}

.border-yellow.border-darken-2
{
    border: 1px solid #fbc02d !important;
}

.border-top-yellow.border-top-darken-2
{
    border-top: 1px solid #fbc02d !important;
}

.border-bottom-yellow.border-bottom-darken-2
{
    border-bottom: 1px solid #fbc02d !important;
}

.border-left-yellow.border-left-darken-2
{
    border-left: 1px solid #fbc02d !important;
}

.border-right-yellow.border-right-darken-2
{
    border-right: 1px solid #fbc02d !important;
}

.overlay-yellow.overlay-darken-2
{
    background: #fbc02d;
    /* The Fallback */
    background: rgba(251, 192, 45, .8);
}

.yellow.darken-3
{
    color: #f9a825 !important;
}

.bg-yellow.bg-darken-3
{
    background-color: #f9a825 !important;
}
.bg-yellow.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(249, 168, 37, .6);
            box-shadow: 0 1px 20px 1px rgba(249, 168, 37, .6);
}

.btn-yellow.btn-darken-3
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-darken-3:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-darken-3:focus,
.btn-yellow.btn-darken-3:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-darken-3
{
    color: #f9a825 !important; 
    border-color: #f9a825 !important;
}
.btn-outline-yellow.btn-outline-darken-3:hover
{
    background-color: #f9a825 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f9a825 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f9a825 !important;
}

.border-yellow.border-darken-3
{
    border: 1px solid #f9a825 !important;
}

.border-top-yellow.border-top-darken-3
{
    border-top: 1px solid #f9a825 !important;
}

.border-bottom-yellow.border-bottom-darken-3
{
    border-bottom: 1px solid #f9a825 !important;
}

.border-left-yellow.border-left-darken-3
{
    border-left: 1px solid #f9a825 !important;
}

.border-right-yellow.border-right-darken-3
{
    border-right: 1px solid #f9a825 !important;
}

.overlay-yellow.overlay-darken-3
{
    background: #f9a825;
    /* The Fallback */
    background: rgba(249, 168, 37, .8);
}

.yellow.darken-4
{
    color: #f57f17 !important;
}

.bg-yellow.bg-darken-4
{
    background-color: #f57f17 !important;
}
.bg-yellow.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(245, 127, 23, .6);
            box-shadow: 0 1px 20px 1px rgba(245, 127, 23, .6);
}

.btn-yellow.btn-darken-4
{
    border-color: #fbc02d !important;
    background-color: #f57f17 !important;
}
.btn-yellow.btn-darken-4:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-darken-4:focus,
.btn-yellow.btn-darken-4:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-darken-4
{
    color: #f57f17 !important; 
    border-color: #f57f17 !important;
}
.btn-outline-yellow.btn-outline-darken-4:hover
{
    background-color: #f57f17 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f57f17 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f57f17 !important;
}

.border-yellow.border-darken-4
{
    border: 1px solid #f57f17 !important;
}

.border-top-yellow.border-top-darken-4
{
    border-top: 1px solid #f57f17 !important;
}

.border-bottom-yellow.border-bottom-darken-4
{
    border-bottom: 1px solid #f57f17 !important;
}

.border-left-yellow.border-left-darken-4
{
    border-left: 1px solid #f57f17 !important;
}

.border-right-yellow.border-right-darken-4
{
    border-right: 1px solid #f57f17 !important;
}

.overlay-yellow.overlay-darken-4
{
    background: #f57f17;
    /* The Fallback */
    background: rgba(245, 127, 23, .8);
}

.yellow.accent-1
{
    color: #ffff8d !important;
}

.bg-yellow.bg-accent-1
{
    background-color: #ffff8d !important;
}
.bg-yellow.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 255, 141, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 255, 141, .6);
}

.btn-yellow.btn-accent-1
{
    border-color: #fbc02d !important;
    background-color: #ffff8d !important;
}
.btn-yellow.btn-accent-1:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-accent-1:focus,
.btn-yellow.btn-accent-1:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-accent-1
{
    color: #ffff8d !important; 
    border-color: #ffff8d !important;
}
.btn-outline-yellow.btn-outline-accent-1:hover
{
    background-color: #ffff8d !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffff8d !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffff8d !important;
}

.border-yellow.border-accent-1
{
    border: 1px solid #ffff8d !important;
}

.border-top-yellow.border-top-accent-1
{
    border-top: 1px solid #ffff8d !important;
}

.border-bottom-yellow.border-bottom-accent-1
{
    border-bottom: 1px solid #ffff8d !important;
}

.border-left-yellow.border-left-accent-1
{
    border-left: 1px solid #ffff8d !important;
}

.border-right-yellow.border-right-accent-1
{
    border-right: 1px solid #ffff8d !important;
}

.overlay-yellow.overlay-accent-1
{
    background: #ffff8d;
    /* The Fallback */
    background: rgba(255, 255, 141, .8);
}

.yellow.accent-2
{
    color: #ff0 !important;
}

.bg-yellow.bg-accent-2
{
    background-color: #ff0 !important;
}
.bg-yellow.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 255, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 255, 0, .6);
}

.btn-yellow.btn-accent-2
{
    border-color: #fbc02d !important;
    background-color: #ff0 !important;
}
.btn-yellow.btn-accent-2:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-accent-2:focus,
.btn-yellow.btn-accent-2:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-accent-2
{
    color: #ff0 !important; 
    border-color: #ff0 !important;
}
.btn-outline-yellow.btn-outline-accent-2:hover
{
    background-color: #ff0 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff0 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff0 !important;
}

.border-yellow.border-accent-2
{
    border: 1px solid #ff0 !important;
}

.border-top-yellow.border-top-accent-2
{
    border-top: 1px solid #ff0 !important;
}

.border-bottom-yellow.border-bottom-accent-2
{
    border-bottom: 1px solid #ff0 !important;
}

.border-left-yellow.border-left-accent-2
{
    border-left: 1px solid #ff0 !important;
}

.border-right-yellow.border-right-accent-2
{
    border-right: 1px solid #ff0 !important;
}

.overlay-yellow.overlay-accent-2
{
    background: #ff0;
    /* The Fallback */
    background: rgba(255, 255, 0, .8);
}

.yellow.accent-3
{
    color: #ffea00 !important;
}

.bg-yellow.bg-accent-3
{
    background-color: #ffea00 !important;
}
.bg-yellow.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 234, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 234, 0, .6);
}

.btn-yellow.btn-accent-3
{
    border-color: #fbc02d !important;
    background-color: #ffea00 !important;
}
.btn-yellow.btn-accent-3:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-accent-3:focus,
.btn-yellow.btn-accent-3:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-accent-3
{
    color: #ffea00 !important; 
    border-color: #ffea00 !important;
}
.btn-outline-yellow.btn-outline-accent-3:hover
{
    background-color: #ffea00 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffea00 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffea00 !important;
}

.border-yellow.border-accent-3
{
    border: 1px solid #ffea00 !important;
}

.border-top-yellow.border-top-accent-3
{
    border-top: 1px solid #ffea00 !important;
}

.border-bottom-yellow.border-bottom-accent-3
{
    border-bottom: 1px solid #ffea00 !important;
}

.border-left-yellow.border-left-accent-3
{
    border-left: 1px solid #ffea00 !important;
}

.border-right-yellow.border-right-accent-3
{
    border-right: 1px solid #ffea00 !important;
}

.overlay-yellow.overlay-accent-3
{
    background: #ffea00;
    /* The Fallback */
    background: rgba(255, 234, 0, .8);
}

.yellow.accent-4
{
    color: #ffd600 !important;
}

.bg-yellow.bg-accent-4
{
    background-color: #ffd600 !important;
}
.bg-yellow.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 214, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 214, 0, .6);
}

.btn-yellow.btn-accent-4
{
    border-color: #fbc02d !important;
    background-color: #ffd600 !important;
}
.btn-yellow.btn-accent-4:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-accent-4:focus,
.btn-yellow.btn-accent-4:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-accent-4
{
    color: #ffd600 !important; 
    border-color: #ffd600 !important;
}
.btn-outline-yellow.btn-outline-accent-4:hover
{
    background-color: #ffd600 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffd600 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffd600 !important;
}

.border-yellow.border-accent-4
{
    border: 1px solid #ffd600 !important;
}

.border-top-yellow.border-top-accent-4
{
    border-top: 1px solid #ffd600 !important;
}

.border-bottom-yellow.border-bottom-accent-4
{
    border-bottom: 1px solid #ffd600 !important;
}

.border-left-yellow.border-left-accent-4
{
    border-left: 1px solid #ffd600 !important;
}

.border-right-yellow.border-right-accent-4
{
    border-right: 1px solid #ffd600 !important;
}

.overlay-yellow.overlay-accent-4
{
    background: #ffd600;
    /* The Fallback */
    background: rgba(255, 214, 0, .8);
}

.amber.lighten-5
{
    color: #fff8e1 !important;
}

.bg-amber.bg-lighten-5
{
    background-color: #fff8e1 !important;
}
.bg-amber.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 248, 225, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 248, 225, .6);
}

.btn-amber.btn-lighten-5
{
    border-color: #ffa000 !important;
    background-color: #fff8e1 !important;
}
.btn-amber.btn-lighten-5:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-lighten-5:focus,
.btn-amber.btn-lighten-5:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-lighten-5
{
    color: #fff8e1 !important; 
    border-color: #fff8e1 !important;
}
.btn-outline-amber.btn-outline-lighten-5:hover
{
    background-color: #fff8e1 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff8e1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff8e1 !important;
}

.border-amber.border-lighten-5
{
    border: 1px solid #fff8e1 !important;
}

.border-top-amber.border-top-lighten-5
{
    border-top: 1px solid #fff8e1 !important;
}

.border-bottom-amber.border-bottom-lighten-5
{
    border-bottom: 1px solid #fff8e1 !important;
}

.border-left-amber.border-left-lighten-5
{
    border-left: 1px solid #fff8e1 !important;
}

.border-right-amber.border-right-lighten-5
{
    border-right: 1px solid #fff8e1 !important;
}

.overlay-amber.overlay-lighten-5
{
    background: #fff8e1;
    /* The Fallback */
    background: rgba(255, 248, 225, .8);
}

.amber.lighten-4
{
    color: #ffecb3 !important;
}

.bg-amber.bg-lighten-4
{
    background-color: #ffecb3 !important;
}
.bg-amber.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 236, 179, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 236, 179, .6);
}

.btn-amber.btn-lighten-4
{
    border-color: #ffa000 !important;
    background-color: #ffecb3 !important;
}
.btn-amber.btn-lighten-4:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-lighten-4:focus,
.btn-amber.btn-lighten-4:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-lighten-4
{
    color: #ffecb3 !important; 
    border-color: #ffecb3 !important;
}
.btn-outline-amber.btn-outline-lighten-4:hover
{
    background-color: #ffecb3 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffecb3 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffecb3 !important;
}

.border-amber.border-lighten-4
{
    border: 1px solid #ffecb3 !important;
}

.border-top-amber.border-top-lighten-4
{
    border-top: 1px solid #ffecb3 !important;
}

.border-bottom-amber.border-bottom-lighten-4
{
    border-bottom: 1px solid #ffecb3 !important;
}

.border-left-amber.border-left-lighten-4
{
    border-left: 1px solid #ffecb3 !important;
}

.border-right-amber.border-right-lighten-4
{
    border-right: 1px solid #ffecb3 !important;
}

.overlay-amber.overlay-lighten-4
{
    background: #ffecb3;
    /* The Fallback */
    background: rgba(255, 236, 179, .8);
}

.amber.lighten-3
{
    color: #ffe082 !important;
}

.bg-amber.bg-lighten-3
{
    background-color: #ffe082 !important;
}
.bg-amber.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 224, 130, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 224, 130, .6);
}

.btn-amber.btn-lighten-3
{
    border-color: #ffa000 !important;
    background-color: #ffe082 !important;
}
.btn-amber.btn-lighten-3:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-lighten-3:focus,
.btn-amber.btn-lighten-3:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-lighten-3
{
    color: #ffe082 !important; 
    border-color: #ffe082 !important;
}
.btn-outline-amber.btn-outline-lighten-3:hover
{
    background-color: #ffe082 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffe082 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffe082 !important;
}

.border-amber.border-lighten-3
{
    border: 1px solid #ffe082 !important;
}

.border-top-amber.border-top-lighten-3
{
    border-top: 1px solid #ffe082 !important;
}

.border-bottom-amber.border-bottom-lighten-3
{
    border-bottom: 1px solid #ffe082 !important;
}

.border-left-amber.border-left-lighten-3
{
    border-left: 1px solid #ffe082 !important;
}

.border-right-amber.border-right-lighten-3
{
    border-right: 1px solid #ffe082 !important;
}

.overlay-amber.overlay-lighten-3
{
    background: #ffe082;
    /* The Fallback */
    background: rgba(255, 224, 130, .8);
}

.amber.lighten-2
{
    color: #ffd54f !important;
}

.bg-amber.bg-lighten-2
{
    background-color: #ffd54f !important;
}
.bg-amber.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 213, 79, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 213, 79, .6);
}

.btn-amber.btn-lighten-2
{
    border-color: #ffa000 !important;
    background-color: #ffd54f !important;
}
.btn-amber.btn-lighten-2:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-lighten-2:focus,
.btn-amber.btn-lighten-2:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-lighten-2
{
    color: #ffd54f !important; 
    border-color: #ffd54f !important;
}
.btn-outline-amber.btn-outline-lighten-2:hover
{
    background-color: #ffd54f !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffd54f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffd54f !important;
}

.border-amber.border-lighten-2
{
    border: 1px solid #ffd54f !important;
}

.border-top-amber.border-top-lighten-2
{
    border-top: 1px solid #ffd54f !important;
}

.border-bottom-amber.border-bottom-lighten-2
{
    border-bottom: 1px solid #ffd54f !important;
}

.border-left-amber.border-left-lighten-2
{
    border-left: 1px solid #ffd54f !important;
}

.border-right-amber.border-right-lighten-2
{
    border-right: 1px solid #ffd54f !important;
}

.overlay-amber.overlay-lighten-2
{
    background: #ffd54f;
    /* The Fallback */
    background: rgba(255, 213, 79, .8);
}

.amber.lighten-1
{
    color: #ffca28 !important;
}

.bg-amber.bg-lighten-1
{
    background-color: #ffca28 !important;
}
.bg-amber.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 202, 40, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 202, 40, .6);
}

.btn-amber.btn-lighten-1
{
    border-color: #ffa000 !important;
    background-color: #ffca28 !important;
}
.btn-amber.btn-lighten-1:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-lighten-1:focus,
.btn-amber.btn-lighten-1:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-lighten-1
{
    color: #ffca28 !important; 
    border-color: #ffca28 !important;
}
.btn-outline-amber.btn-outline-lighten-1:hover
{
    background-color: #ffca28 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffca28 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffca28 !important;
}

.border-amber.border-lighten-1
{
    border: 1px solid #ffca28 !important;
}

.border-top-amber.border-top-lighten-1
{
    border-top: 1px solid #ffca28 !important;
}

.border-bottom-amber.border-bottom-lighten-1
{
    border-bottom: 1px solid #ffca28 !important;
}

.border-left-amber.border-left-lighten-1
{
    border-left: 1px solid #ffca28 !important;
}

.border-right-amber.border-right-lighten-1
{
    border-right: 1px solid #ffca28 !important;
}

.overlay-amber.overlay-lighten-1
{
    background: #ffca28;
    /* The Fallback */
    background: rgba(255, 202, 40, .8);
}

.amber
{
    color: #ffc107 !important;
}

.bg-amber
{
    background-color: #ffc107 !important;
}
.bg-amber .card-header,
.bg-amber .card-footer
{
    background-color: transparent;
}
.bg-amber.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 193, 7, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 193, 7, .6);
}

.selectboxit.selectboxit-btn.bg-amber
{
    background-color: #ffc107 !important;
}

.alert-amber
{
    color: #543f00 !important; 
    border-color: #ffc107 !important;
    background-color: #ffd34e !important;
}
.alert-amber .alert-link
{
    color: #2b2000 !important;
}

.border-amber
{
    border-color: #ffc107;
}

.overlay-amber
{
    background: #ffc107;
    /* The Fallback */
    background: rgba(255, 193, 7, .8);
}

/* .card-outline-amber{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-amber
{
    color: #fff; 
    border-color: #ffc107 !important;
    background-color: #ffc107 !important;
}
.btn-amber:hover
{
    color: #fff !important; 
    border-color: #ffa000 !important;
    background-color: #ffb300 !important;
}
.btn-amber:focus,
.btn-amber:active
{
    color: #fff !important; 
    border-color: #ff8f00 !important;
    background-color: #ffa000 !important;
}
.btn-amber.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 193, 7, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 193, 7, .6);
}

.btn-outline-amber
{
    color: #ffc107; 
    border-color: #ffc107;
    background-color: transparent;
}
.btn-outline-amber:hover
{
    color: #fff !important; 
    background-color: #ffc107;
}
.btn-outline-amber.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 193, 7, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 193, 7, .6);
}

input[type='checkbox'].bg-amber + .custom-control-label:before,
input[type='radio'].bg-amber + .custom-control-label:before
{
    background-color: #ffc107 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffc107 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffc107 !important;
}

.border-amber
{
    border: 1px solid #ffc107 !important;
}

.border-top-amber
{
    border-top: 1px solid #ffc107;
}

.border-bottom-amber
{
    border-bottom: 1px solid #ffc107;
}

.border-left-amber
{
    border-left: 1px solid #ffc107;
}

.border-right-amber
{
    border-right: 1px solid #ffc107;
}

.amber.darken-1
{
    color: #ffb300 !important;
}

.bg-amber.bg-darken-1
{
    background-color: #ffb300 !important;
}
.bg-amber.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 179, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 179, 0, .6);
}

.btn-amber.btn-darken-1
{
    border-color: #ffa000 !important;
    background-color: #ffb300 !important;
}
.btn-amber.btn-darken-1:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-darken-1:focus,
.btn-amber.btn-darken-1:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-darken-1
{
    color: #ffb300 !important; 
    border-color: #ffb300 !important;
}
.btn-outline-amber.btn-outline-darken-1:hover
{
    background-color: #ffb300 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffb300 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffb300 !important;
}

.border-amber.border-darken-1
{
    border: 1px solid #ffb300 !important;
}

.border-top-amber.border-top-darken-1
{
    border-top: 1px solid #ffb300 !important;
}

.border-bottom-amber.border-bottom-darken-1
{
    border-bottom: 1px solid #ffb300 !important;
}

.border-left-amber.border-left-darken-1
{
    border-left: 1px solid #ffb300 !important;
}

.border-right-amber.border-right-darken-1
{
    border-right: 1px solid #ffb300 !important;
}

.overlay-amber.overlay-darken-1
{
    background: #ffb300;
    /* The Fallback */
    background: rgba(255, 179, 0, .8);
}

.amber.darken-2
{
    color: #ffa000 !important;
}

.bg-amber.bg-darken-2
{
    background-color: #ffa000 !important;
}
.bg-amber.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 160, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 160, 0, .6);
}

.btn-amber.btn-darken-2
{
    border-color: #ffa000 !important;
    background-color: #ffa000 !important;
}
.btn-amber.btn-darken-2:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-darken-2:focus,
.btn-amber.btn-darken-2:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-darken-2
{
    color: #ffa000 !important; 
    border-color: #ffa000 !important;
}
.btn-outline-amber.btn-outline-darken-2:hover
{
    background-color: #ffa000 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffa000 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffa000 !important;
}

.border-amber.border-darken-2
{
    border: 1px solid #ffa000 !important;
}

.border-top-amber.border-top-darken-2
{
    border-top: 1px solid #ffa000 !important;
}

.border-bottom-amber.border-bottom-darken-2
{
    border-bottom: 1px solid #ffa000 !important;
}

.border-left-amber.border-left-darken-2
{
    border-left: 1px solid #ffa000 !important;
}

.border-right-amber.border-right-darken-2
{
    border-right: 1px solid #ffa000 !important;
}

.overlay-amber.overlay-darken-2
{
    background: #ffa000;
    /* The Fallback */
    background: rgba(255, 160, 0, .8);
}

.amber.darken-3
{
    color: #ff8f00 !important;
}

.bg-amber.bg-darken-3
{
    background-color: #ff8f00 !important;
}
.bg-amber.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 143, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 143, 0, .6);
}

.btn-amber.btn-darken-3
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-darken-3:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-darken-3:focus,
.btn-amber.btn-darken-3:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-darken-3
{
    color: #ff8f00 !important; 
    border-color: #ff8f00 !important;
}
.btn-outline-amber.btn-outline-darken-3:hover
{
    background-color: #ff8f00 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff8f00 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff8f00 !important;
}

.border-amber.border-darken-3
{
    border: 1px solid #ff8f00 !important;
}

.border-top-amber.border-top-darken-3
{
    border-top: 1px solid #ff8f00 !important;
}

.border-bottom-amber.border-bottom-darken-3
{
    border-bottom: 1px solid #ff8f00 !important;
}

.border-left-amber.border-left-darken-3
{
    border-left: 1px solid #ff8f00 !important;
}

.border-right-amber.border-right-darken-3
{
    border-right: 1px solid #ff8f00 !important;
}

.overlay-amber.overlay-darken-3
{
    background: #ff8f00;
    /* The Fallback */
    background: rgba(255, 143, 0, .8);
}

.amber.darken-4
{
    color: #ff6f00 !important;
}

.bg-amber.bg-darken-4
{
    background-color: #ff6f00 !important;
}
.bg-amber.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 111, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 111, 0, .6);
}

.btn-amber.btn-darken-4
{
    border-color: #ffa000 !important;
    background-color: #ff6f00 !important;
}
.btn-amber.btn-darken-4:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-darken-4:focus,
.btn-amber.btn-darken-4:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-darken-4
{
    color: #ff6f00 !important; 
    border-color: #ff6f00 !important;
}
.btn-outline-amber.btn-outline-darken-4:hover
{
    background-color: #ff6f00 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff6f00 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff6f00 !important;
}

.border-amber.border-darken-4
{
    border: 1px solid #ff6f00 !important;
}

.border-top-amber.border-top-darken-4
{
    border-top: 1px solid #ff6f00 !important;
}

.border-bottom-amber.border-bottom-darken-4
{
    border-bottom: 1px solid #ff6f00 !important;
}

.border-left-amber.border-left-darken-4
{
    border-left: 1px solid #ff6f00 !important;
}

.border-right-amber.border-right-darken-4
{
    border-right: 1px solid #ff6f00 !important;
}

.overlay-amber.overlay-darken-4
{
    background: #ff6f00;
    /* The Fallback */
    background: rgba(255, 111, 0, .8);
}

.amber.accent-1
{
    color: #ffe57f !important;
}

.bg-amber.bg-accent-1
{
    background-color: #ffe57f !important;
}
.bg-amber.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 229, 127, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 229, 127, .6);
}

.btn-amber.btn-accent-1
{
    border-color: #ffa000 !important;
    background-color: #ffe57f !important;
}
.btn-amber.btn-accent-1:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-accent-1:focus,
.btn-amber.btn-accent-1:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-accent-1
{
    color: #ffe57f !important; 
    border-color: #ffe57f !important;
}
.btn-outline-amber.btn-outline-accent-1:hover
{
    background-color: #ffe57f !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffe57f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffe57f !important;
}

.border-amber.border-accent-1
{
    border: 1px solid #ffe57f !important;
}

.border-top-amber.border-top-accent-1
{
    border-top: 1px solid #ffe57f !important;
}

.border-bottom-amber.border-bottom-accent-1
{
    border-bottom: 1px solid #ffe57f !important;
}

.border-left-amber.border-left-accent-1
{
    border-left: 1px solid #ffe57f !important;
}

.border-right-amber.border-right-accent-1
{
    border-right: 1px solid #ffe57f !important;
}

.overlay-amber.overlay-accent-1
{
    background: #ffe57f;
    /* The Fallback */
    background: rgba(255, 229, 127, .8);
}

.amber.accent-2
{
    color: #ffd740 !important;
}

.bg-amber.bg-accent-2
{
    background-color: #ffd740 !important;
}
.bg-amber.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 215, 64, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 215, 64, .6);
}

.btn-amber.btn-accent-2
{
    border-color: #ffa000 !important;
    background-color: #ffd740 !important;
}
.btn-amber.btn-accent-2:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-accent-2:focus,
.btn-amber.btn-accent-2:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-accent-2
{
    color: #ffd740 !important; 
    border-color: #ffd740 !important;
}
.btn-outline-amber.btn-outline-accent-2:hover
{
    background-color: #ffd740 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffd740 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffd740 !important;
}

.border-amber.border-accent-2
{
    border: 1px solid #ffd740 !important;
}

.border-top-amber.border-top-accent-2
{
    border-top: 1px solid #ffd740 !important;
}

.border-bottom-amber.border-bottom-accent-2
{
    border-bottom: 1px solid #ffd740 !important;
}

.border-left-amber.border-left-accent-2
{
    border-left: 1px solid #ffd740 !important;
}

.border-right-amber.border-right-accent-2
{
    border-right: 1px solid #ffd740 !important;
}

.overlay-amber.overlay-accent-2
{
    background: #ffd740;
    /* The Fallback */
    background: rgba(255, 215, 64, .8);
}

.amber.accent-3
{
    color: #ffc400 !important;
}

.bg-amber.bg-accent-3
{
    background-color: #ffc400 !important;
}
.bg-amber.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 196, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 196, 0, .6);
}

.btn-amber.btn-accent-3
{
    border-color: #ffa000 !important;
    background-color: #ffc400 !important;
}
.btn-amber.btn-accent-3:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-accent-3:focus,
.btn-amber.btn-accent-3:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-accent-3
{
    color: #ffc400 !important; 
    border-color: #ffc400 !important;
}
.btn-outline-amber.btn-outline-accent-3:hover
{
    background-color: #ffc400 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffc400 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffc400 !important;
}

.border-amber.border-accent-3
{
    border: 1px solid #ffc400 !important;
}

.border-top-amber.border-top-accent-3
{
    border-top: 1px solid #ffc400 !important;
}

.border-bottom-amber.border-bottom-accent-3
{
    border-bottom: 1px solid #ffc400 !important;
}

.border-left-amber.border-left-accent-3
{
    border-left: 1px solid #ffc400 !important;
}

.border-right-amber.border-right-accent-3
{
    border-right: 1px solid #ffc400 !important;
}

.overlay-amber.overlay-accent-3
{
    background: #ffc400;
    /* The Fallback */
    background: rgba(255, 196, 0, .8);
}

.amber.accent-4
{
    color: #ffab00 !important;
}

.bg-amber.bg-accent-4
{
    background-color: #ffab00 !important;
}
.bg-amber.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 171, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 171, 0, .6);
}

.btn-amber.btn-accent-4
{
    border-color: #ffa000 !important;
    background-color: #ffab00 !important;
}
.btn-amber.btn-accent-4:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-accent-4:focus,
.btn-amber.btn-accent-4:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-accent-4
{
    color: #ffab00 !important; 
    border-color: #ffab00 !important;
}
.btn-outline-amber.btn-outline-accent-4:hover
{
    background-color: #ffab00 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffab00 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffab00 !important;
}

.border-amber.border-accent-4
{
    border: 1px solid #ffab00 !important;
}

.border-top-amber.border-top-accent-4
{
    border-top: 1px solid #ffab00 !important;
}

.border-bottom-amber.border-bottom-accent-4
{
    border-bottom: 1px solid #ffab00 !important;
}

.border-left-amber.border-left-accent-4
{
    border-left: 1px solid #ffab00 !important;
}

.border-right-amber.border-right-accent-4
{
    border-right: 1px solid #ffab00 !important;
}

.overlay-amber.overlay-accent-4
{
    background: #ffab00;
    /* The Fallback */
    background: rgba(255, 171, 0, .8);
}

.orange.lighten-5
{
    color: #fff3e0 !important;
}

.bg-orange.bg-lighten-5
{
    background-color: #fff3e0 !important;
}
.bg-orange.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 243, 224, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 243, 224, .6);
}

.btn-orange.btn-lighten-5
{
    border-color: #f57c00 !important;
    background-color: #fff3e0 !important;
}
.btn-orange.btn-lighten-5:hover
{
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-lighten-5:focus,
.btn-orange.btn-lighten-5:active
{
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}

.btn-outline-orange.btn-outline-lighten-5
{
    color: #fff3e0 !important; 
    border-color: #fff3e0 !important;
}
.btn-outline-orange.btn-outline-lighten-5:hover
{
    background-color: #fff3e0 !important;
}

input:focus ~ .bg-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff3e0 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff3e0 !important;
}

.border-orange.border-lighten-5
{
    border: 1px solid #fff3e0 !important;
}

.border-top-orange.border-top-lighten-5
{
    border-top: 1px solid #fff3e0 !important;
}

.border-bottom-orange.border-bottom-lighten-5
{
    border-bottom: 1px solid #fff3e0 !important;
}

.border-left-orange.border-left-lighten-5
{
    border-left: 1px solid #fff3e0 !important;
}

.border-right-orange.border-right-lighten-5
{
    border-right: 1px solid #fff3e0 !important;
}

.overlay-orange.overlay-lighten-5
{
    background: #fff3e0;
    /* The Fallback */
    background: rgba(255, 243, 224, .8);
}

.orange.lighten-4
{
    color: #ffe0b2 !important;
}

.bg-orange.bg-lighten-4
{
    background-color: #ffe0b2 !important;
}
.bg-orange.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 224, 178, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 224, 178, .6);
}

.btn-orange.btn-lighten-4
{
    border-color: #f57c00 !important;
    background-color: #ffe0b2 !important;
}
.btn-orange.btn-lighten-4:hover
{
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-lighten-4:focus,
.btn-orange.btn-lighten-4:active
{
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}

.btn-outline-orange.btn-outline-lighten-4
{
    color: #ffe0b2 !important; 
    border-color: #ffe0b2 !important;
}
.btn-outline-orange.btn-outline-lighten-4:hover
{
    background-color: #ffe0b2 !important;
}

input:focus ~ .bg-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffe0b2 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffe0b2 !important;
}

.border-orange.border-lighten-4
{
    border: 1px solid #ffe0b2 !important;
}

.border-top-orange.border-top-lighten-4
{
    border-top: 1px solid #ffe0b2 !important;
}

.border-bottom-orange.border-bottom-lighten-4
{
    border-bottom: 1px solid #ffe0b2 !important;
}

.border-left-orange.border-left-lighten-4
{
    border-left: 1px solid #ffe0b2 !important;
}

.border-right-orange.border-right-lighten-4
{
    border-right: 1px solid #ffe0b2 !important;
}

.overlay-orange.overlay-lighten-4
{
    background: #ffe0b2;
    /* The Fallback */
    background: rgba(255, 224, 178, .8);
}

.orange.lighten-3
{
    color: #ffcc80 !important;
}

.bg-orange.bg-lighten-3
{
    background-color: #ffcc80 !important;
}
.bg-orange.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 204, 128, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 204, 128, .6);
}

.btn-orange.btn-lighten-3
{
    border-color: #f57c00 !important;
    background-color: #ffcc80 !important;
}
.btn-orange.btn-lighten-3:hover
{
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-lighten-3:focus,
.btn-orange.btn-lighten-3:active
{
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}

.btn-outline-orange.btn-outline-lighten-3
{
    color: #ffcc80 !important; 
    border-color: #ffcc80 !important;
}
.btn-outline-orange.btn-outline-lighten-3:hover
{
    background-color: #ffcc80 !important;
}

input:focus ~ .bg-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffcc80 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffcc80 !important;
}

.border-orange.border-lighten-3
{
    border: 1px solid #ffcc80 !important;
}

.border-top-orange.border-top-lighten-3
{
    border-top: 1px solid #ffcc80 !important;
}

.border-bottom-orange.border-bottom-lighten-3
{
    border-bottom: 1px solid #ffcc80 !important;
}

.border-left-orange.border-left-lighten-3
{
    border-left: 1px solid #ffcc80 !important;
}

.border-right-orange.border-right-lighten-3
{
    border-right: 1px solid #ffcc80 !important;
}

.overlay-orange.overlay-lighten-3
{
    background: #ffcc80;
    /* The Fallback */
    background: rgba(255, 204, 128, .8);
}

.orange.lighten-2
{
    color: #ffb74d !important;
}

.bg-orange.bg-lighten-2
{
    background-color: #ffb74d !important;
}
.bg-orange.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 183, 77, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 183, 77, .6);
}

.btn-orange.btn-lighten-2
{
    border-color: #f57c00 !important;
    background-color: #ffb74d !important;
}
.btn-orange.btn-lighten-2:hover
{
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-lighten-2:focus,
.btn-orange.btn-lighten-2:active
{
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}

.btn-outline-orange.btn-outline-lighten-2
{
    color: #ffb74d !important; 
    border-color: #ffb74d !important;
}
.btn-outline-orange.btn-outline-lighten-2:hover
{
    background-color: #ffb74d !important;
}

input:focus ~ .bg-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffb74d !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffb74d !important;
}

.border-orange.border-lighten-2
{
    border: 1px solid #ffb74d !important;
}

.border-top-orange.border-top-lighten-2
{
    border-top: 1px solid #ffb74d !important;
}

.border-bottom-orange.border-bottom-lighten-2
{
    border-bottom: 1px solid #ffb74d !important;
}

.border-left-orange.border-left-lighten-2
{
    border-left: 1px solid #ffb74d !important;
}

.border-right-orange.border-right-lighten-2
{
    border-right: 1px solid #ffb74d !important;
}

.overlay-orange.overlay-lighten-2
{
    background: #ffb74d;
    /* The Fallback */
    background: rgba(255, 183, 77, .8);
}

.orange.lighten-1
{
    color: #ffa726 !important;
}

.bg-orange.bg-lighten-1
{
    background-color: #ffa726 !important;
}
.bg-orange.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 167, 38, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 167, 38, .6);
}

.btn-orange.btn-lighten-1
{
    border-color: #f57c00 !important;
    background-color: #ffa726 !important;
}
.btn-orange.btn-lighten-1:hover
{
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-lighten-1:focus,
.btn-orange.btn-lighten-1:active
{
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}

.btn-outline-orange.btn-outline-lighten-1
{
    color: #ffa726 !important; 
    border-color: #ffa726 !important;
}
.btn-outline-orange.btn-outline-lighten-1:hover
{
    background-color: #ffa726 !important;
}

input:focus ~ .bg-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffa726 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffa726 !important;
}

.border-orange.border-lighten-1
{
    border: 1px solid #ffa726 !important;
}

.border-top-orange.border-top-lighten-1
{
    border-top: 1px solid #ffa726 !important;
}

.border-bottom-orange.border-bottom-lighten-1
{
    border-bottom: 1px solid #ffa726 !important;
}

.border-left-orange.border-left-lighten-1
{
    border-left: 1px solid #ffa726 !important;
}

.border-right-orange.border-right-lighten-1
{
    border-right: 1px solid #ffa726 !important;
}

.overlay-orange.overlay-lighten-1
{
    background: #ffa726;
    /* The Fallback */
    background: rgba(255, 167, 38, .8);
}

.orange
{
    color: #ff9800 !important;
}

.bg-orange
{
    background-color: #ff9800 !important;
}
.bg-orange .card-header,
.bg-orange .card-footer
{
    background-color: transparent;
}
.bg-orange.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 152, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 152, 0, .6);
}

.selectboxit.selectboxit-btn.bg-orange
{
    background-color: #ff9800 !important;
}

.alert-orange
{
    color: #4d2e00 !important; 
    border-color: #ff9800 !important;
    background-color: #ffb547 !important;
}
.alert-orange .alert-link
{
    color: #241500 !important;
}

.border-orange
{
    border-color: #ff9800;
}

.overlay-orange
{
    background: #ff9800;
    /* The Fallback */
    background: rgba(255, 152, 0, .8);
}

/* .card-outline-orange{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-orange
{
    color: #fff; 
    border-color: #ff9800 !important;
    background-color: #ff9800 !important;
}
.btn-orange:hover
{
    color: #fff !important; 
    border-color: #f57c00 !important;
    background-color: #fb8c00 !important;
}
.btn-orange:focus,
.btn-orange:active
{
    color: #fff !important; 
    border-color: #ef6c00 !important;
    background-color: #f57c00 !important;
}
.btn-orange.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 152, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 152, 0, .6);
}

.btn-outline-orange
{
    color: #ff9800; 
    border-color: #ff9800;
    background-color: transparent;
}
.btn-outline-orange:hover
{
    color: #fff !important; 
    background-color: #ff9800;
}
.btn-outline-orange.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 152, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 152, 0, .6);
}

input[type='checkbox'].bg-orange + .custom-control-label:before,
input[type='radio'].bg-orange + .custom-control-label:before
{
    background-color: #ff9800 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff9800 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff9800 !important;
}

.border-orange
{
    border: 1px solid #ff9800 !important;
}

.border-top-orange
{
    border-top: 1px solid #ff9800;
}

.border-bottom-orange
{
    border-bottom: 1px solid #ff9800;
}

.border-left-orange
{
    border-left: 1px solid #ff9800;
}

.border-right-orange
{
    border-right: 1px solid #ff9800;
}

.orange.darken-1
{
    color: #fb8c00 !important;
}

.bg-orange.bg-darken-1
{
    background-color: #fb8c00 !important;
}
.bg-orange.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(251, 140, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(251, 140, 0, .6);
}

.btn-orange.btn-darken-1
{
    border-color: #f57c00 !important;
    background-color: #fb8c00 !important;
}
.btn-orange.btn-darken-1:hover
{
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-darken-1:focus,
.btn-orange.btn-darken-1:active
{
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}

.btn-outline-orange.btn-outline-darken-1
{
    color: #fb8c00 !important; 
    border-color: #fb8c00 !important;
}
.btn-outline-orange.btn-outline-darken-1:hover
{
    background-color: #fb8c00 !important;
}

input:focus ~ .bg-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fb8c00 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fb8c00 !important;
}

.border-orange.border-darken-1
{
    border: 1px solid #fb8c00 !important;
}

.border-top-orange.border-top-darken-1
{
    border-top: 1px solid #fb8c00 !important;
}

.border-bottom-orange.border-bottom-darken-1
{
    border-bottom: 1px solid #fb8c00 !important;
}

.border-left-orange.border-left-darken-1
{
    border-left: 1px solid #fb8c00 !important;
}

.border-right-orange.border-right-darken-1
{
    border-right: 1px solid #fb8c00 !important;
}

.overlay-orange.overlay-darken-1
{
    background: #fb8c00;
    /* The Fallback */
    background: rgba(251, 140, 0, .8);
}

.orange.darken-2
{
    color: #f57c00 !important;
}

.bg-orange.bg-darken-2
{
    background-color: #f57c00 !important;
}
.bg-orange.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(245, 124, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(245, 124, 0, .6);
}

.btn-orange.btn-darken-2
{
    border-color: #f57c00 !important;
    background-color: #f57c00 !important;
}
.btn-orange.btn-darken-2:hover
{
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-darken-2:focus,
.btn-orange.btn-darken-2:active
{
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}

.btn-outline-orange.btn-outline-darken-2
{
    color: #f57c00 !important; 
    border-color: #f57c00 !important;
}
.btn-outline-orange.btn-outline-darken-2:hover
{
    background-color: #f57c00 !important;
}

input:focus ~ .bg-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f57c00 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f57c00 !important;
}

.border-orange.border-darken-2
{
    border: 1px solid #f57c00 !important;
}

.border-top-orange.border-top-darken-2
{
    border-top: 1px solid #f57c00 !important;
}

.border-bottom-orange.border-bottom-darken-2
{
    border-bottom: 1px solid #f57c00 !important;
}

.border-left-orange.border-left-darken-2
{
    border-left: 1px solid #f57c00 !important;
}

.border-right-orange.border-right-darken-2
{
    border-right: 1px solid #f57c00 !important;
}

.overlay-orange.overlay-darken-2
{
    background: #f57c00;
    /* The Fallback */
    background: rgba(245, 124, 0, .8);
}

.orange.darken-3
{
    color: #ef6c00 !important;
}

.bg-orange.bg-darken-3
{
    background-color: #ef6c00 !important;
}
.bg-orange.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(239, 108, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(239, 108, 0, .6);
}

.btn-orange.btn-darken-3
{
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-darken-3:hover
{
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-darken-3:focus,
.btn-orange.btn-darken-3:active
{
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}

.btn-outline-orange.btn-outline-darken-3
{
    color: #ef6c00 !important; 
    border-color: #ef6c00 !important;
}
.btn-outline-orange.btn-outline-darken-3:hover
{
    background-color: #ef6c00 !important;
}

input:focus ~ .bg-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ef6c00 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ef6c00 !important;
}

.border-orange.border-darken-3
{
    border: 1px solid #ef6c00 !important;
}

.border-top-orange.border-top-darken-3
{
    border-top: 1px solid #ef6c00 !important;
}

.border-bottom-orange.border-bottom-darken-3
{
    border-bottom: 1px solid #ef6c00 !important;
}

.border-left-orange.border-left-darken-3
{
    border-left: 1px solid #ef6c00 !important;
}

.border-right-orange.border-right-darken-3
{
    border-right: 1px solid #ef6c00 !important;
}

.overlay-orange.overlay-darken-3
{
    background: #ef6c00;
    /* The Fallback */
    background: rgba(239, 108, 0, .8);
}

.orange.darken-4
{
    color: #e65100 !important;
}

.bg-orange.bg-darken-4
{
    background-color: #e65100 !important;
}
.bg-orange.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(230, 81, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(230, 81, 0, .6);
}

.btn-orange.btn-darken-4
{
    border-color: #f57c00 !important;
    background-color: #e65100 !important;
}
.btn-orange.btn-darken-4:hover
{
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-darken-4:focus,
.btn-orange.btn-darken-4:active
{
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}

.btn-outline-orange.btn-outline-darken-4
{
    color: #e65100 !important; 
    border-color: #e65100 !important;
}
.btn-outline-orange.btn-outline-darken-4:hover
{
    background-color: #e65100 !important;
}

input:focus ~ .bg-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e65100 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e65100 !important;
}

.border-orange.border-darken-4
{
    border: 1px solid #e65100 !important;
}

.border-top-orange.border-top-darken-4
{
    border-top: 1px solid #e65100 !important;
}

.border-bottom-orange.border-bottom-darken-4
{
    border-bottom: 1px solid #e65100 !important;
}

.border-left-orange.border-left-darken-4
{
    border-left: 1px solid #e65100 !important;
}

.border-right-orange.border-right-darken-4
{
    border-right: 1px solid #e65100 !important;
}

.overlay-orange.overlay-darken-4
{
    background: #e65100;
    /* The Fallback */
    background: rgba(230, 81, 0, .8);
}

.orange.accent-1
{
    color: #ffd180 !important;
}

.bg-orange.bg-accent-1
{
    background-color: #ffd180 !important;
}
.bg-orange.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 209, 128, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 209, 128, .6);
}

.btn-orange.btn-accent-1
{
    border-color: #f57c00 !important;
    background-color: #ffd180 !important;
}
.btn-orange.btn-accent-1:hover
{
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-accent-1:focus,
.btn-orange.btn-accent-1:active
{
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}

.btn-outline-orange.btn-outline-accent-1
{
    color: #ffd180 !important; 
    border-color: #ffd180 !important;
}
.btn-outline-orange.btn-outline-accent-1:hover
{
    background-color: #ffd180 !important;
}

input:focus ~ .bg-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffd180 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffd180 !important;
}

.border-orange.border-accent-1
{
    border: 1px solid #ffd180 !important;
}

.border-top-orange.border-top-accent-1
{
    border-top: 1px solid #ffd180 !important;
}

.border-bottom-orange.border-bottom-accent-1
{
    border-bottom: 1px solid #ffd180 !important;
}

.border-left-orange.border-left-accent-1
{
    border-left: 1px solid #ffd180 !important;
}

.border-right-orange.border-right-accent-1
{
    border-right: 1px solid #ffd180 !important;
}

.overlay-orange.overlay-accent-1
{
    background: #ffd180;
    /* The Fallback */
    background: rgba(255, 209, 128, .8);
}

.orange.accent-2
{
    color: #ffab40 !important;
}

.bg-orange.bg-accent-2
{
    background-color: #ffab40 !important;
}
.bg-orange.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 171, 64, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 171, 64, .6);
}

.btn-orange.btn-accent-2
{
    border-color: #f57c00 !important;
    background-color: #ffab40 !important;
}
.btn-orange.btn-accent-2:hover
{
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-accent-2:focus,
.btn-orange.btn-accent-2:active
{
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}

.btn-outline-orange.btn-outline-accent-2
{
    color: #ffab40 !important; 
    border-color: #ffab40 !important;
}
.btn-outline-orange.btn-outline-accent-2:hover
{
    background-color: #ffab40 !important;
}

input:focus ~ .bg-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffab40 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffab40 !important;
}

.border-orange.border-accent-2
{
    border: 1px solid #ffab40 !important;
}

.border-top-orange.border-top-accent-2
{
    border-top: 1px solid #ffab40 !important;
}

.border-bottom-orange.border-bottom-accent-2
{
    border-bottom: 1px solid #ffab40 !important;
}

.border-left-orange.border-left-accent-2
{
    border-left: 1px solid #ffab40 !important;
}

.border-right-orange.border-right-accent-2
{
    border-right: 1px solid #ffab40 !important;
}

.overlay-orange.overlay-accent-2
{
    background: #ffab40;
    /* The Fallback */
    background: rgba(255, 171, 64, .8);
}

.orange.accent-3
{
    color: #ff9100 !important;
}

.bg-orange.bg-accent-3
{
    background-color: #ff9100 !important;
}
.bg-orange.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 145, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 145, 0, .6);
}

.btn-orange.btn-accent-3
{
    border-color: #f57c00 !important;
    background-color: #ff9100 !important;
}
.btn-orange.btn-accent-3:hover
{
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-accent-3:focus,
.btn-orange.btn-accent-3:active
{
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}

.btn-outline-orange.btn-outline-accent-3
{
    color: #ff9100 !important; 
    border-color: #ff9100 !important;
}
.btn-outline-orange.btn-outline-accent-3:hover
{
    background-color: #ff9100 !important;
}

input:focus ~ .bg-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff9100 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff9100 !important;
}

.border-orange.border-accent-3
{
    border: 1px solid #ff9100 !important;
}

.border-top-orange.border-top-accent-3
{
    border-top: 1px solid #ff9100 !important;
}

.border-bottom-orange.border-bottom-accent-3
{
    border-bottom: 1px solid #ff9100 !important;
}

.border-left-orange.border-left-accent-3
{
    border-left: 1px solid #ff9100 !important;
}

.border-right-orange.border-right-accent-3
{
    border-right: 1px solid #ff9100 !important;
}

.overlay-orange.overlay-accent-3
{
    background: #ff9100;
    /* The Fallback */
    background: rgba(255, 145, 0, .8);
}

.orange.accent-4
{
    color: #ff6d00 !important;
}

.bg-orange.bg-accent-4
{
    background-color: #ff6d00 !important;
}
.bg-orange.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 109, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 109, 0, .6);
}

.btn-orange.btn-accent-4
{
    border-color: #f57c00 !important;
    background-color: #ff6d00 !important;
}
.btn-orange.btn-accent-4:hover
{
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-accent-4:focus,
.btn-orange.btn-accent-4:active
{
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}

.btn-outline-orange.btn-outline-accent-4
{
    color: #ff6d00 !important; 
    border-color: #ff6d00 !important;
}
.btn-outline-orange.btn-outline-accent-4:hover
{
    background-color: #ff6d00 !important;
}

input:focus ~ .bg-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff6d00 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff6d00 !important;
}

.border-orange.border-accent-4
{
    border: 1px solid #ff6d00 !important;
}

.border-top-orange.border-top-accent-4
{
    border-top: 1px solid #ff6d00 !important;
}

.border-bottom-orange.border-bottom-accent-4
{
    border-bottom: 1px solid #ff6d00 !important;
}

.border-left-orange.border-left-accent-4
{
    border-left: 1px solid #ff6d00 !important;
}

.border-right-orange.border-right-accent-4
{
    border-right: 1px solid #ff6d00 !important;
}

.overlay-orange.overlay-accent-4
{
    background: #ff6d00;
    /* The Fallback */
    background: rgba(255, 109, 0, .8);
}

.deep-orange.lighten-5
{
    color: #fbe9e7 !important;
}

.bg-deep-orange.bg-lighten-5
{
    background-color: #fbe9e7 !important;
}
.bg-deep-orange.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(251, 233, 231, .6);
            box-shadow: 0 1px 20px 1px rgba(251, 233, 231, .6);
}

.btn-deep-orange.btn-lighten-5
{
    border-color: #e64a19 !important;
    background-color: #fbe9e7 !important;
}
.btn-deep-orange.btn-lighten-5:hover
{
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-lighten-5:focus,
.btn-deep-orange.btn-lighten-5:active
{
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}

.btn-outline-deep-orange.btn-outline-lighten-5
{
    color: #fbe9e7 !important; 
    border-color: #fbe9e7 !important;
}
.btn-outline-deep-orange.btn-outline-lighten-5:hover
{
    background-color: #fbe9e7 !important;
}

input:focus ~ .bg-deep-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fbe9e7 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fbe9e7 !important;
}

.border-deep-orange.border-lighten-5
{
    border: 1px solid #fbe9e7 !important;
}

.border-top-deep-orange.border-top-lighten-5
{
    border-top: 1px solid #fbe9e7 !important;
}

.border-bottom-deep-orange.border-bottom-lighten-5
{
    border-bottom: 1px solid #fbe9e7 !important;
}

.border-left-deep-orange.border-left-lighten-5
{
    border-left: 1px solid #fbe9e7 !important;
}

.border-right-deep-orange.border-right-lighten-5
{
    border-right: 1px solid #fbe9e7 !important;
}

.overlay-deep-orange.overlay-lighten-5
{
    background: #fbe9e7;
    /* The Fallback */
    background: rgba(251, 233, 231, .8);
}

.deep-orange.lighten-4
{
    color: #ffccbc !important;
}

.bg-deep-orange.bg-lighten-4
{
    background-color: #ffccbc !important;
}
.bg-deep-orange.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 204, 188, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 204, 188, .6);
}

.btn-deep-orange.btn-lighten-4
{
    border-color: #e64a19 !important;
    background-color: #ffccbc !important;
}
.btn-deep-orange.btn-lighten-4:hover
{
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-lighten-4:focus,
.btn-deep-orange.btn-lighten-4:active
{
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}

.btn-outline-deep-orange.btn-outline-lighten-4
{
    color: #ffccbc !important; 
    border-color: #ffccbc !important;
}
.btn-outline-deep-orange.btn-outline-lighten-4:hover
{
    background-color: #ffccbc !important;
}

input:focus ~ .bg-deep-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffccbc !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffccbc !important;
}

.border-deep-orange.border-lighten-4
{
    border: 1px solid #ffccbc !important;
}

.border-top-deep-orange.border-top-lighten-4
{
    border-top: 1px solid #ffccbc !important;
}

.border-bottom-deep-orange.border-bottom-lighten-4
{
    border-bottom: 1px solid #ffccbc !important;
}

.border-left-deep-orange.border-left-lighten-4
{
    border-left: 1px solid #ffccbc !important;
}

.border-right-deep-orange.border-right-lighten-4
{
    border-right: 1px solid #ffccbc !important;
}

.overlay-deep-orange.overlay-lighten-4
{
    background: #ffccbc;
    /* The Fallback */
    background: rgba(255, 204, 188, .8);
}

.deep-orange.lighten-3
{
    color: #ffab91 !important;
}

.bg-deep-orange.bg-lighten-3
{
    background-color: #ffab91 !important;
}
.bg-deep-orange.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 171, 145, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 171, 145, .6);
}

.btn-deep-orange.btn-lighten-3
{
    border-color: #e64a19 !important;
    background-color: #ffab91 !important;
}
.btn-deep-orange.btn-lighten-3:hover
{
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-lighten-3:focus,
.btn-deep-orange.btn-lighten-3:active
{
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}

.btn-outline-deep-orange.btn-outline-lighten-3
{
    color: #ffab91 !important; 
    border-color: #ffab91 !important;
}
.btn-outline-deep-orange.btn-outline-lighten-3:hover
{
    background-color: #ffab91 !important;
}

input:focus ~ .bg-deep-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffab91 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffab91 !important;
}

.border-deep-orange.border-lighten-3
{
    border: 1px solid #ffab91 !important;
}

.border-top-deep-orange.border-top-lighten-3
{
    border-top: 1px solid #ffab91 !important;
}

.border-bottom-deep-orange.border-bottom-lighten-3
{
    border-bottom: 1px solid #ffab91 !important;
}

.border-left-deep-orange.border-left-lighten-3
{
    border-left: 1px solid #ffab91 !important;
}

.border-right-deep-orange.border-right-lighten-3
{
    border-right: 1px solid #ffab91 !important;
}

.overlay-deep-orange.overlay-lighten-3
{
    background: #ffab91;
    /* The Fallback */
    background: rgba(255, 171, 145, .8);
}

.deep-orange.lighten-2
{
    color: #ff8a65 !important;
}

.bg-deep-orange.bg-lighten-2
{
    background-color: #ff8a65 !important;
}
.bg-deep-orange.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 138, 101, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 138, 101, .6);
}

.btn-deep-orange.btn-lighten-2
{
    border-color: #e64a19 !important;
    background-color: #ff8a65 !important;
}
.btn-deep-orange.btn-lighten-2:hover
{
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-lighten-2:focus,
.btn-deep-orange.btn-lighten-2:active
{
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}

.btn-outline-deep-orange.btn-outline-lighten-2
{
    color: #ff8a65 !important; 
    border-color: #ff8a65 !important;
}
.btn-outline-deep-orange.btn-outline-lighten-2:hover
{
    background-color: #ff8a65 !important;
}

input:focus ~ .bg-deep-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff8a65 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff8a65 !important;
}

.border-deep-orange.border-lighten-2
{
    border: 1px solid #ff8a65 !important;
}

.border-top-deep-orange.border-top-lighten-2
{
    border-top: 1px solid #ff8a65 !important;
}

.border-bottom-deep-orange.border-bottom-lighten-2
{
    border-bottom: 1px solid #ff8a65 !important;
}

.border-left-deep-orange.border-left-lighten-2
{
    border-left: 1px solid #ff8a65 !important;
}

.border-right-deep-orange.border-right-lighten-2
{
    border-right: 1px solid #ff8a65 !important;
}

.overlay-deep-orange.overlay-lighten-2
{
    background: #ff8a65;
    /* The Fallback */
    background: rgba(255, 138, 101, .8);
}

.deep-orange.lighten-1
{
    color: #ff7043 !important;
}

.bg-deep-orange.bg-lighten-1
{
    background-color: #ff7043 !important;
}
.bg-deep-orange.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 112, 67, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 112, 67, .6);
}

.btn-deep-orange.btn-lighten-1
{
    border-color: #e64a19 !important;
    background-color: #ff7043 !important;
}
.btn-deep-orange.btn-lighten-1:hover
{
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-lighten-1:focus,
.btn-deep-orange.btn-lighten-1:active
{
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}

.btn-outline-deep-orange.btn-outline-lighten-1
{
    color: #ff7043 !important; 
    border-color: #ff7043 !important;
}
.btn-outline-deep-orange.btn-outline-lighten-1:hover
{
    background-color: #ff7043 !important;
}

input:focus ~ .bg-deep-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff7043 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff7043 !important;
}

.border-deep-orange.border-lighten-1
{
    border: 1px solid #ff7043 !important;
}

.border-top-deep-orange.border-top-lighten-1
{
    border-top: 1px solid #ff7043 !important;
}

.border-bottom-deep-orange.border-bottom-lighten-1
{
    border-bottom: 1px solid #ff7043 !important;
}

.border-left-deep-orange.border-left-lighten-1
{
    border-left: 1px solid #ff7043 !important;
}

.border-right-deep-orange.border-right-lighten-1
{
    border-right: 1px solid #ff7043 !important;
}

.overlay-deep-orange.overlay-lighten-1
{
    background: #ff7043;
    /* The Fallback */
    background: rgba(255, 112, 67, .8);
}

.deep-orange
{
    color: #ff5722 !important;
}

.bg-deep-orange
{
    background-color: #ff5722 !important;
}
.bg-deep-orange .card-header,
.bg-deep-orange .card-footer
{
    background-color: transparent;
}
.bg-deep-orange.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 87, 34, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 87, 34, .6);
}

.selectboxit.selectboxit-btn.bg-deep-orange
{
    background-color: #ff5722 !important;
}

.alert-deep-orange
{
    color: #6f1b00 !important; 
    border-color: #ff5722 !important;
    background-color: #ff8d69 !important;
}
.alert-deep-orange .alert-link
{
    color: #461100 !important;
}

.border-deep-orange
{
    border-color: #ff5722;
}

.overlay-deep-orange
{
    background: #ff5722;
    /* The Fallback */
    background: rgba(255, 87, 34, .8);
}

/* .card-outline-deep-orange{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-deep-orange
{
    color: #fff; 
    border-color: #ff5722 !important;
    background-color: #ff5722 !important;
}
.btn-deep-orange:hover
{
    color: #fff !important; 
    border-color: #e64a19 !important;
    background-color: #f4511e !important;
}
.btn-deep-orange:focus,
.btn-deep-orange:active
{
    color: #fff !important; 
    border-color: #d84315 !important;
    background-color: #e64a19 !important;
}
.btn-deep-orange.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 87, 34, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 87, 34, .6);
}

.btn-outline-deep-orange
{
    color: #ff5722; 
    border-color: #ff5722;
    background-color: transparent;
}
.btn-outline-deep-orange:hover
{
    color: #fff !important; 
    background-color: #ff5722;
}
.btn-outline-deep-orange.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 87, 34, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 87, 34, .6);
}

input[type='checkbox'].bg-deep-orange + .custom-control-label:before,
input[type='radio'].bg-deep-orange + .custom-control-label:before
{
    background-color: #ff5722 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-deep-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff5722 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff5722 !important;
}

.border-deep-orange
{
    border: 1px solid #ff5722 !important;
}

.border-top-deep-orange
{
    border-top: 1px solid #ff5722;
}

.border-bottom-deep-orange
{
    border-bottom: 1px solid #ff5722;
}

.border-left-deep-orange
{
    border-left: 1px solid #ff5722;
}

.border-right-deep-orange
{
    border-right: 1px solid #ff5722;
}

.deep-orange.darken-1
{
    color: #f4511e !important;
}

.bg-deep-orange.bg-darken-1
{
    background-color: #f4511e !important;
}
.bg-deep-orange.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(244, 81, 30, .6);
            box-shadow: 0 1px 20px 1px rgba(244, 81, 30, .6);
}

.btn-deep-orange.btn-darken-1
{
    border-color: #e64a19 !important;
    background-color: #f4511e !important;
}
.btn-deep-orange.btn-darken-1:hover
{
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-darken-1:focus,
.btn-deep-orange.btn-darken-1:active
{
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}

.btn-outline-deep-orange.btn-outline-darken-1
{
    color: #f4511e !important; 
    border-color: #f4511e !important;
}
.btn-outline-deep-orange.btn-outline-darken-1:hover
{
    background-color: #f4511e !important;
}

input:focus ~ .bg-deep-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f4511e !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f4511e !important;
}

.border-deep-orange.border-darken-1
{
    border: 1px solid #f4511e !important;
}

.border-top-deep-orange.border-top-darken-1
{
    border-top: 1px solid #f4511e !important;
}

.border-bottom-deep-orange.border-bottom-darken-1
{
    border-bottom: 1px solid #f4511e !important;
}

.border-left-deep-orange.border-left-darken-1
{
    border-left: 1px solid #f4511e !important;
}

.border-right-deep-orange.border-right-darken-1
{
    border-right: 1px solid #f4511e !important;
}

.overlay-deep-orange.overlay-darken-1
{
    background: #f4511e;
    /* The Fallback */
    background: rgba(244, 81, 30, .8);
}

.deep-orange.darken-2
{
    color: #e64a19 !important;
}

.bg-deep-orange.bg-darken-2
{
    background-color: #e64a19 !important;
}
.bg-deep-orange.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(230, 74, 25, .6);
            box-shadow: 0 1px 20px 1px rgba(230, 74, 25, .6);
}

.btn-deep-orange.btn-darken-2
{
    border-color: #e64a19 !important;
    background-color: #e64a19 !important;
}
.btn-deep-orange.btn-darken-2:hover
{
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-darken-2:focus,
.btn-deep-orange.btn-darken-2:active
{
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}

.btn-outline-deep-orange.btn-outline-darken-2
{
    color: #e64a19 !important; 
    border-color: #e64a19 !important;
}
.btn-outline-deep-orange.btn-outline-darken-2:hover
{
    background-color: #e64a19 !important;
}

input:focus ~ .bg-deep-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e64a19 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e64a19 !important;
}

.border-deep-orange.border-darken-2
{
    border: 1px solid #e64a19 !important;
}

.border-top-deep-orange.border-top-darken-2
{
    border-top: 1px solid #e64a19 !important;
}

.border-bottom-deep-orange.border-bottom-darken-2
{
    border-bottom: 1px solid #e64a19 !important;
}

.border-left-deep-orange.border-left-darken-2
{
    border-left: 1px solid #e64a19 !important;
}

.border-right-deep-orange.border-right-darken-2
{
    border-right: 1px solid #e64a19 !important;
}

.overlay-deep-orange.overlay-darken-2
{
    background: #e64a19;
    /* The Fallback */
    background: rgba(230, 74, 25, .8);
}

.deep-orange.darken-3
{
    color: #d84315 !important;
}

.bg-deep-orange.bg-darken-3
{
    background-color: #d84315 !important;
}
.bg-deep-orange.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(216, 67, 21, .6);
            box-shadow: 0 1px 20px 1px rgba(216, 67, 21, .6);
}

.btn-deep-orange.btn-darken-3
{
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-darken-3:hover
{
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-darken-3:focus,
.btn-deep-orange.btn-darken-3:active
{
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}

.btn-outline-deep-orange.btn-outline-darken-3
{
    color: #d84315 !important; 
    border-color: #d84315 !important;
}
.btn-outline-deep-orange.btn-outline-darken-3:hover
{
    background-color: #d84315 !important;
}

input:focus ~ .bg-deep-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d84315 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d84315 !important;
}

.border-deep-orange.border-darken-3
{
    border: 1px solid #d84315 !important;
}

.border-top-deep-orange.border-top-darken-3
{
    border-top: 1px solid #d84315 !important;
}

.border-bottom-deep-orange.border-bottom-darken-3
{
    border-bottom: 1px solid #d84315 !important;
}

.border-left-deep-orange.border-left-darken-3
{
    border-left: 1px solid #d84315 !important;
}

.border-right-deep-orange.border-right-darken-3
{
    border-right: 1px solid #d84315 !important;
}

.overlay-deep-orange.overlay-darken-3
{
    background: #d84315;
    /* The Fallback */
    background: rgba(216, 67, 21, .8);
}

.deep-orange.darken-4
{
    color: #bf360c !important;
}

.bg-deep-orange.bg-darken-4
{
    background-color: #bf360c !important;
}
.bg-deep-orange.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(191, 54, 12, .6);
            box-shadow: 0 1px 20px 1px rgba(191, 54, 12, .6);
}

.btn-deep-orange.btn-darken-4
{
    border-color: #e64a19 !important;
    background-color: #bf360c !important;
}
.btn-deep-orange.btn-darken-4:hover
{
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-darken-4:focus,
.btn-deep-orange.btn-darken-4:active
{
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}

.btn-outline-deep-orange.btn-outline-darken-4
{
    color: #bf360c !important; 
    border-color: #bf360c !important;
}
.btn-outline-deep-orange.btn-outline-darken-4:hover
{
    background-color: #bf360c !important;
}

input:focus ~ .bg-deep-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bf360c !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bf360c !important;
}

.border-deep-orange.border-darken-4
{
    border: 1px solid #bf360c !important;
}

.border-top-deep-orange.border-top-darken-4
{
    border-top: 1px solid #bf360c !important;
}

.border-bottom-deep-orange.border-bottom-darken-4
{
    border-bottom: 1px solid #bf360c !important;
}

.border-left-deep-orange.border-left-darken-4
{
    border-left: 1px solid #bf360c !important;
}

.border-right-deep-orange.border-right-darken-4
{
    border-right: 1px solid #bf360c !important;
}

.overlay-deep-orange.overlay-darken-4
{
    background: #bf360c;
    /* The Fallback */
    background: rgba(191, 54, 12, .8);
}

.deep-orange.accent-1
{
    color: #ff9e80 !important;
}

.bg-deep-orange.bg-accent-1
{
    background-color: #ff9e80 !important;
}
.bg-deep-orange.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 158, 128, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 158, 128, .6);
}

.btn-deep-orange.btn-accent-1
{
    border-color: #e64a19 !important;
    background-color: #ff9e80 !important;
}
.btn-deep-orange.btn-accent-1:hover
{
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-accent-1:focus,
.btn-deep-orange.btn-accent-1:active
{
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}

.btn-outline-deep-orange.btn-outline-accent-1
{
    color: #ff9e80 !important; 
    border-color: #ff9e80 !important;
}
.btn-outline-deep-orange.btn-outline-accent-1:hover
{
    background-color: #ff9e80 !important;
}

input:focus ~ .bg-deep-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff9e80 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff9e80 !important;
}

.border-deep-orange.border-accent-1
{
    border: 1px solid #ff9e80 !important;
}

.border-top-deep-orange.border-top-accent-1
{
    border-top: 1px solid #ff9e80 !important;
}

.border-bottom-deep-orange.border-bottom-accent-1
{
    border-bottom: 1px solid #ff9e80 !important;
}

.border-left-deep-orange.border-left-accent-1
{
    border-left: 1px solid #ff9e80 !important;
}

.border-right-deep-orange.border-right-accent-1
{
    border-right: 1px solid #ff9e80 !important;
}

.overlay-deep-orange.overlay-accent-1
{
    background: #ff9e80;
    /* The Fallback */
    background: rgba(255, 158, 128, .8);
}

.deep-orange.accent-2
{
    color: #ff6e40 !important;
}

.bg-deep-orange.bg-accent-2
{
    background-color: #ff6e40 !important;
}
.bg-deep-orange.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 110, 64, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 110, 64, .6);
}

.btn-deep-orange.btn-accent-2
{
    border-color: #e64a19 !important;
    background-color: #ff6e40 !important;
}
.btn-deep-orange.btn-accent-2:hover
{
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-accent-2:focus,
.btn-deep-orange.btn-accent-2:active
{
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}

.btn-outline-deep-orange.btn-outline-accent-2
{
    color: #ff6e40 !important; 
    border-color: #ff6e40 !important;
}
.btn-outline-deep-orange.btn-outline-accent-2:hover
{
    background-color: #ff6e40 !important;
}

input:focus ~ .bg-deep-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff6e40 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff6e40 !important;
}

.border-deep-orange.border-accent-2
{
    border: 1px solid #ff6e40 !important;
}

.border-top-deep-orange.border-top-accent-2
{
    border-top: 1px solid #ff6e40 !important;
}

.border-bottom-deep-orange.border-bottom-accent-2
{
    border-bottom: 1px solid #ff6e40 !important;
}

.border-left-deep-orange.border-left-accent-2
{
    border-left: 1px solid #ff6e40 !important;
}

.border-right-deep-orange.border-right-accent-2
{
    border-right: 1px solid #ff6e40 !important;
}

.overlay-deep-orange.overlay-accent-2
{
    background: #ff6e40;
    /* The Fallback */
    background: rgba(255, 110, 64, .8);
}

.deep-orange.accent-3
{
    color: #ff3d00 !important;
}

.bg-deep-orange.bg-accent-3
{
    background-color: #ff3d00 !important;
}
.bg-deep-orange.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 61, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 61, 0, .6);
}

.btn-deep-orange.btn-accent-3
{
    border-color: #e64a19 !important;
    background-color: #ff3d00 !important;
}
.btn-deep-orange.btn-accent-3:hover
{
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-accent-3:focus,
.btn-deep-orange.btn-accent-3:active
{
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}

.btn-outline-deep-orange.btn-outline-accent-3
{
    color: #ff3d00 !important; 
    border-color: #ff3d00 !important;
}
.btn-outline-deep-orange.btn-outline-accent-3:hover
{
    background-color: #ff3d00 !important;
}

input:focus ~ .bg-deep-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff3d00 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff3d00 !important;
}

.border-deep-orange.border-accent-3
{
    border: 1px solid #ff3d00 !important;
}

.border-top-deep-orange.border-top-accent-3
{
    border-top: 1px solid #ff3d00 !important;
}

.border-bottom-deep-orange.border-bottom-accent-3
{
    border-bottom: 1px solid #ff3d00 !important;
}

.border-left-deep-orange.border-left-accent-3
{
    border-left: 1px solid #ff3d00 !important;
}

.border-right-deep-orange.border-right-accent-3
{
    border-right: 1px solid #ff3d00 !important;
}

.overlay-deep-orange.overlay-accent-3
{
    background: #ff3d00;
    /* The Fallback */
    background: rgba(255, 61, 0, .8);
}

.deep-orange.accent-4
{
    color: #dd2c00 !important;
}

.bg-deep-orange.bg-accent-4
{
    background-color: #dd2c00 !important;
}
.bg-deep-orange.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(221, 44, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(221, 44, 0, .6);
}

.btn-deep-orange.btn-accent-4
{
    border-color: #e64a19 !important;
    background-color: #dd2c00 !important;
}
.btn-deep-orange.btn-accent-4:hover
{
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-accent-4:focus,
.btn-deep-orange.btn-accent-4:active
{
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}

.btn-outline-deep-orange.btn-outline-accent-4
{
    color: #dd2c00 !important; 
    border-color: #dd2c00 !important;
}
.btn-outline-deep-orange.btn-outline-accent-4:hover
{
    background-color: #dd2c00 !important;
}

input:focus ~ .bg-deep-orange
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #dd2c00 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #dd2c00 !important;
}

.border-deep-orange.border-accent-4
{
    border: 1px solid #dd2c00 !important;
}

.border-top-deep-orange.border-top-accent-4
{
    border-top: 1px solid #dd2c00 !important;
}

.border-bottom-deep-orange.border-bottom-accent-4
{
    border-bottom: 1px solid #dd2c00 !important;
}

.border-left-deep-orange.border-left-accent-4
{
    border-left: 1px solid #dd2c00 !important;
}

.border-right-deep-orange.border-right-accent-4
{
    border-right: 1px solid #dd2c00 !important;
}

.overlay-deep-orange.overlay-accent-4
{
    background: #dd2c00;
    /* The Fallback */
    background: rgba(221, 44, 0, .8);
}

.brown.lighten-5
{
    color: #efebe9 !important;
}

.bg-brown.bg-lighten-5
{
    background-color: #efebe9 !important;
}
.bg-brown.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(239, 235, 233, .6);
            box-shadow: 0 1px 20px 1px rgba(239, 235, 233, .6);
}

.btn-brown.btn-lighten-5
{
    border-color: #5d4037 !important;
    background-color: #efebe9 !important;
}
.btn-brown.btn-lighten-5:hover
{
    border-color: #5d4037 !important;
    background-color: #4e342e !important;
}
.btn-brown.btn-lighten-5:focus,
.btn-brown.btn-lighten-5:active
{
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}

.btn-outline-brown.btn-outline-lighten-5
{
    color: #efebe9 !important; 
    border-color: #efebe9 !important;
}
.btn-outline-brown.btn-outline-lighten-5:hover
{
    background-color: #efebe9 !important;
}

input:focus ~ .bg-brown
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #efebe9 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #efebe9 !important;
}

.border-brown.border-lighten-5
{
    border: 1px solid #efebe9 !important;
}

.border-top-brown.border-top-lighten-5
{
    border-top: 1px solid #efebe9 !important;
}

.border-bottom-brown.border-bottom-lighten-5
{
    border-bottom: 1px solid #efebe9 !important;
}

.border-left-brown.border-left-lighten-5
{
    border-left: 1px solid #efebe9 !important;
}

.border-right-brown.border-right-lighten-5
{
    border-right: 1px solid #efebe9 !important;
}

.overlay-brown.overlay-lighten-5
{
    background: #efebe9;
    /* The Fallback */
    background: rgba(239, 235, 233, .8);
}

.brown.lighten-4
{
    color: #d7ccc8 !important;
}

.bg-brown.bg-lighten-4
{
    background-color: #d7ccc8 !important;
}
.bg-brown.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(215, 204, 200, .6);
            box-shadow: 0 1px 20px 1px rgba(215, 204, 200, .6);
}

.btn-brown.btn-lighten-4
{
    border-color: #5d4037 !important;
    background-color: #d7ccc8 !important;
}
.btn-brown.btn-lighten-4:hover
{
    border-color: #5d4037 !important;
    background-color: #4e342e !important;
}
.btn-brown.btn-lighten-4:focus,
.btn-brown.btn-lighten-4:active
{
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}

.btn-outline-brown.btn-outline-lighten-4
{
    color: #d7ccc8 !important; 
    border-color: #d7ccc8 !important;
}
.btn-outline-brown.btn-outline-lighten-4:hover
{
    background-color: #d7ccc8 !important;
}

input:focus ~ .bg-brown
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d7ccc8 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d7ccc8 !important;
}

.border-brown.border-lighten-4
{
    border: 1px solid #d7ccc8 !important;
}

.border-top-brown.border-top-lighten-4
{
    border-top: 1px solid #d7ccc8 !important;
}

.border-bottom-brown.border-bottom-lighten-4
{
    border-bottom: 1px solid #d7ccc8 !important;
}

.border-left-brown.border-left-lighten-4
{
    border-left: 1px solid #d7ccc8 !important;
}

.border-right-brown.border-right-lighten-4
{
    border-right: 1px solid #d7ccc8 !important;
}

.overlay-brown.overlay-lighten-4
{
    background: #d7ccc8;
    /* The Fallback */
    background: rgba(215, 204, 200, .8);
}

.brown.lighten-3
{
    color: #bcaaa4 !important;
}

.bg-brown.bg-lighten-3
{
    background-color: #bcaaa4 !important;
}
.bg-brown.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(188, 170, 164, .6);
            box-shadow: 0 1px 20px 1px rgba(188, 170, 164, .6);
}

.btn-brown.btn-lighten-3
{
    border-color: #5d4037 !important;
    background-color: #bcaaa4 !important;
}
.btn-brown.btn-lighten-3:hover
{
    border-color: #5d4037 !important;
    background-color: #4e342e !important;
}
.btn-brown.btn-lighten-3:focus,
.btn-brown.btn-lighten-3:active
{
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}

.btn-outline-brown.btn-outline-lighten-3
{
    color: #bcaaa4 !important; 
    border-color: #bcaaa4 !important;
}
.btn-outline-brown.btn-outline-lighten-3:hover
{
    background-color: #bcaaa4 !important;
}

input:focus ~ .bg-brown
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bcaaa4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bcaaa4 !important;
}

.border-brown.border-lighten-3
{
    border: 1px solid #bcaaa4 !important;
}

.border-top-brown.border-top-lighten-3
{
    border-top: 1px solid #bcaaa4 !important;
}

.border-bottom-brown.border-bottom-lighten-3
{
    border-bottom: 1px solid #bcaaa4 !important;
}

.border-left-brown.border-left-lighten-3
{
    border-left: 1px solid #bcaaa4 !important;
}

.border-right-brown.border-right-lighten-3
{
    border-right: 1px solid #bcaaa4 !important;
}

.overlay-brown.overlay-lighten-3
{
    background: #bcaaa4;
    /* The Fallback */
    background: rgba(188, 170, 164, .8);
}

.brown.lighten-2
{
    color: #a1887f !important;
}

.bg-brown.bg-lighten-2
{
    background-color: #a1887f !important;
}
.bg-brown.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(161, 136, 127, .6);
            box-shadow: 0 1px 20px 1px rgba(161, 136, 127, .6);
}

.btn-brown.btn-lighten-2
{
    border-color: #5d4037 !important;
    background-color: #a1887f !important;
}
.btn-brown.btn-lighten-2:hover
{
    border-color: #5d4037 !important;
    background-color: #4e342e !important;
}
.btn-brown.btn-lighten-2:focus,
.btn-brown.btn-lighten-2:active
{
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}

.btn-outline-brown.btn-outline-lighten-2
{
    color: #a1887f !important; 
    border-color: #a1887f !important;
}
.btn-outline-brown.btn-outline-lighten-2:hover
{
    background-color: #a1887f !important;
}

input:focus ~ .bg-brown
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #a1887f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #a1887f !important;
}

.border-brown.border-lighten-2
{
    border: 1px solid #a1887f !important;
}

.border-top-brown.border-top-lighten-2
{
    border-top: 1px solid #a1887f !important;
}

.border-bottom-brown.border-bottom-lighten-2
{
    border-bottom: 1px solid #a1887f !important;
}

.border-left-brown.border-left-lighten-2
{
    border-left: 1px solid #a1887f !important;
}

.border-right-brown.border-right-lighten-2
{
    border-right: 1px solid #a1887f !important;
}

.overlay-brown.overlay-lighten-2
{
    background: #a1887f;
    /* The Fallback */
    background: rgba(161, 136, 127, .8);
}

.brown.lighten-1
{
    color: #8d6e63 !important;
}

.bg-brown.bg-lighten-1
{
    background-color: #8d6e63 !important;
}
.bg-brown.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(141, 110, 99, .6);
            box-shadow: 0 1px 20px 1px rgba(141, 110, 99, .6);
}

.btn-brown.btn-lighten-1
{
    border-color: #5d4037 !important;
    background-color: #8d6e63 !important;
}
.btn-brown.btn-lighten-1:hover
{
    border-color: #5d4037 !important;
    background-color: #4e342e !important;
}
.btn-brown.btn-lighten-1:focus,
.btn-brown.btn-lighten-1:active
{
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}

.btn-outline-brown.btn-outline-lighten-1
{
    color: #8d6e63 !important; 
    border-color: #8d6e63 !important;
}
.btn-outline-brown.btn-outline-lighten-1:hover
{
    background-color: #8d6e63 !important;
}

input:focus ~ .bg-brown
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #8d6e63 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #8d6e63 !important;
}

.border-brown.border-lighten-1
{
    border: 1px solid #8d6e63 !important;
}

.border-top-brown.border-top-lighten-1
{
    border-top: 1px solid #8d6e63 !important;
}

.border-bottom-brown.border-bottom-lighten-1
{
    border-bottom: 1px solid #8d6e63 !important;
}

.border-left-brown.border-left-lighten-1
{
    border-left: 1px solid #8d6e63 !important;
}

.border-right-brown.border-right-lighten-1
{
    border-right: 1px solid #8d6e63 !important;
}

.overlay-brown.overlay-lighten-1
{
    background: #8d6e63;
    /* The Fallback */
    background: rgba(141, 110, 99, .8);
}

.brown
{
    color: #795548 !important;
}

.bg-brown
{
    background-color: #795548 !important;
}
.bg-brown .card-header,
.bg-brown .card-footer
{
    background-color: transparent;
}
.bg-brown.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(121, 85, 72, .6);
            box-shadow: 0 1px 20px 1px rgba(121, 85, 72, .6);
}

.selectboxit.selectboxit-btn.bg-brown
{
    background-color: #795548 !important;
}

.alert-brown
{
    color: #090605 !important; 
    border-color: #795548 !important;
    background-color: #a37665 !important;
}
.alert-brown .alert-link
{
    color: black !important;
}

.border-brown
{
    border-color: #795548;
}

.overlay-brown
{
    background: #795548;
    /* The Fallback */
    background: rgba(121, 85, 72, .8);
}

/* .card-outline-brown{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-brown
{
    color: #fff; 
    border-color: #795548 !important;
    background-color: #795548 !important;
}
.btn-brown:hover
{
    color: #fff !important; 
    border-color: #5d4037 !important;
    background-color: #6d4c41 !important;
}
.btn-brown:focus,
.btn-brown:active
{
    color: #fff !important; 
    border-color: #4e342e !important;
    background-color: #5d4037 !important;
}
.btn-brown.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(121, 85, 72, .6);
            box-shadow: 0 1px 20px 1px rgba(121, 85, 72, .6);
}

.btn-outline-brown
{
    color: #795548; 
    border-color: #795548;
    background-color: transparent;
}
.btn-outline-brown:hover
{
    color: #fff !important; 
    background-color: #795548;
}
.btn-outline-brown.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(121, 85, 72, .6);
            box-shadow: 0 1px 20px 1px rgba(121, 85, 72, .6);
}

input[type='checkbox'].bg-brown + .custom-control-label:before,
input[type='radio'].bg-brown + .custom-control-label:before
{
    background-color: #795548 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-brown
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #795548 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #795548 !important;
}

.border-brown
{
    border: 1px solid #795548 !important;
}

.border-top-brown
{
    border-top: 1px solid #795548;
}

.border-bottom-brown
{
    border-bottom: 1px solid #795548;
}

.border-left-brown
{
    border-left: 1px solid #795548;
}

.border-right-brown
{
    border-right: 1px solid #795548;
}

.brown.darken-1
{
    color: #6d4c41 !important;
}

.bg-brown.bg-darken-1
{
    background-color: #6d4c41 !important;
}
.bg-brown.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(109, 76, 65, .6);
            box-shadow: 0 1px 20px 1px rgba(109, 76, 65, .6);
}

.btn-brown.btn-darken-1
{
    border-color: #5d4037 !important;
    background-color: #6d4c41 !important;
}
.btn-brown.btn-darken-1:hover
{
    border-color: #5d4037 !important;
    background-color: #4e342e !important;
}
.btn-brown.btn-darken-1:focus,
.btn-brown.btn-darken-1:active
{
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}

.btn-outline-brown.btn-outline-darken-1
{
    color: #6d4c41 !important; 
    border-color: #6d4c41 !important;
}
.btn-outline-brown.btn-outline-darken-1:hover
{
    background-color: #6d4c41 !important;
}

input:focus ~ .bg-brown
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #6d4c41 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #6d4c41 !important;
}

.border-brown.border-darken-1
{
    border: 1px solid #6d4c41 !important;
}

.border-top-brown.border-top-darken-1
{
    border-top: 1px solid #6d4c41 !important;
}

.border-bottom-brown.border-bottom-darken-1
{
    border-bottom: 1px solid #6d4c41 !important;
}

.border-left-brown.border-left-darken-1
{
    border-left: 1px solid #6d4c41 !important;
}

.border-right-brown.border-right-darken-1
{
    border-right: 1px solid #6d4c41 !important;
}

.overlay-brown.overlay-darken-1
{
    background: #6d4c41;
    /* The Fallback */
    background: rgba(109, 76, 65, .8);
}

.brown.darken-2
{
    color: #5d4037 !important;
}

.bg-brown.bg-darken-2
{
    background-color: #5d4037 !important;
}
.bg-brown.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(93, 64, 55, .6);
            box-shadow: 0 1px 20px 1px rgba(93, 64, 55, .6);
}

.btn-brown.btn-darken-2
{
    border-color: #5d4037 !important;
    background-color: #5d4037 !important;
}
.btn-brown.btn-darken-2:hover
{
    border-color: #5d4037 !important;
    background-color: #4e342e !important;
}
.btn-brown.btn-darken-2:focus,
.btn-brown.btn-darken-2:active
{
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}

.btn-outline-brown.btn-outline-darken-2
{
    color: #5d4037 !important; 
    border-color: #5d4037 !important;
}
.btn-outline-brown.btn-outline-darken-2:hover
{
    background-color: #5d4037 !important;
}

input:focus ~ .bg-brown
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #5d4037 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #5d4037 !important;
}

.border-brown.border-darken-2
{
    border: 1px solid #5d4037 !important;
}

.border-top-brown.border-top-darken-2
{
    border-top: 1px solid #5d4037 !important;
}

.border-bottom-brown.border-bottom-darken-2
{
    border-bottom: 1px solid #5d4037 !important;
}

.border-left-brown.border-left-darken-2
{
    border-left: 1px solid #5d4037 !important;
}

.border-right-brown.border-right-darken-2
{
    border-right: 1px solid #5d4037 !important;
}

.overlay-brown.overlay-darken-2
{
    background: #5d4037;
    /* The Fallback */
    background: rgba(93, 64, 55, .8);
}

.brown.darken-3
{
    color: #4e342e !important;
}

.bg-brown.bg-darken-3
{
    background-color: #4e342e !important;
}
.bg-brown.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(78, 52, 46, .6);
            box-shadow: 0 1px 20px 1px rgba(78, 52, 46, .6);
}

.btn-brown.btn-darken-3
{
    border-color: #5d4037 !important;
    background-color: #4e342e !important;
}
.btn-brown.btn-darken-3:hover
{
    border-color: #5d4037 !important;
    background-color: #4e342e !important;
}
.btn-brown.btn-darken-3:focus,
.btn-brown.btn-darken-3:active
{
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}

.btn-outline-brown.btn-outline-darken-3
{
    color: #4e342e !important; 
    border-color: #4e342e !important;
}
.btn-outline-brown.btn-outline-darken-3:hover
{
    background-color: #4e342e !important;
}

input:focus ~ .bg-brown
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4e342e !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4e342e !important;
}

.border-brown.border-darken-3
{
    border: 1px solid #4e342e !important;
}

.border-top-brown.border-top-darken-3
{
    border-top: 1px solid #4e342e !important;
}

.border-bottom-brown.border-bottom-darken-3
{
    border-bottom: 1px solid #4e342e !important;
}

.border-left-brown.border-left-darken-3
{
    border-left: 1px solid #4e342e !important;
}

.border-right-brown.border-right-darken-3
{
    border-right: 1px solid #4e342e !important;
}

.overlay-brown.overlay-darken-3
{
    background: #4e342e;
    /* The Fallback */
    background: rgba(78, 52, 46, .8);
}

.brown.darken-4
{
    color: #3e2723 !important;
}

.bg-brown.bg-darken-4
{
    background-color: #3e2723 !important;
}
.bg-brown.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(62, 39, 35, .6);
            box-shadow: 0 1px 20px 1px rgba(62, 39, 35, .6);
}

.btn-brown.btn-darken-4
{
    border-color: #5d4037 !important;
    background-color: #3e2723 !important;
}
.btn-brown.btn-darken-4:hover
{
    border-color: #5d4037 !important;
    background-color: #4e342e !important;
}
.btn-brown.btn-darken-4:focus,
.btn-brown.btn-darken-4:active
{
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}

.btn-outline-brown.btn-outline-darken-4
{
    color: #3e2723 !important; 
    border-color: #3e2723 !important;
}
.btn-outline-brown.btn-outline-darken-4:hover
{
    background-color: #3e2723 !important;
}

input:focus ~ .bg-brown
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #3e2723 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #3e2723 !important;
}

.border-brown.border-darken-4
{
    border: 1px solid #3e2723 !important;
}

.border-top-brown.border-top-darken-4
{
    border-top: 1px solid #3e2723 !important;
}

.border-bottom-brown.border-bottom-darken-4
{
    border-bottom: 1px solid #3e2723 !important;
}

.border-left-brown.border-left-darken-4
{
    border-left: 1px solid #3e2723 !important;
}

.border-right-brown.border-right-darken-4
{
    border-right: 1px solid #3e2723 !important;
}

.overlay-brown.overlay-darken-4
{
    background: #3e2723;
    /* The Fallback */
    background: rgba(62, 39, 35, .8);
}

.blue-grey.lighten-5
{
    color: #eceff1 !important;
}

.bg-blue-grey.bg-lighten-5
{
    background-color: #eceff1 !important;
}
.bg-blue-grey.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(236, 239, 241, .6);
            box-shadow: 0 1px 20px 1px rgba(236, 239, 241, .6);
}

.btn-blue-grey.btn-lighten-5
{
    border-color: #455a64 !important;
    background-color: #eceff1 !important;
}
.btn-blue-grey.btn-lighten-5:hover
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-lighten-5:focus,
.btn-blue-grey.btn-lighten-5:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-blue-grey.btn-outline-lighten-5
{
    color: #eceff1 !important; 
    border-color: #eceff1 !important;
}
.btn-outline-blue-grey.btn-outline-lighten-5:hover
{
    background-color: #eceff1 !important;
}

input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #eceff1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #eceff1 !important;
}

.border-blue-grey.border-lighten-5
{
    border: 1px solid #eceff1 !important;
}

.border-top-blue-grey.border-top-lighten-5
{
    border-top: 1px solid #eceff1 !important;
}

.border-bottom-blue-grey.border-bottom-lighten-5
{
    border-bottom: 1px solid #eceff1 !important;
}

.border-left-blue-grey.border-left-lighten-5
{
    border-left: 1px solid #eceff1 !important;
}

.border-right-blue-grey.border-right-lighten-5
{
    border-right: 1px solid #eceff1 !important;
}

.overlay-blue-grey.overlay-lighten-5
{
    background: #eceff1;
    /* The Fallback */
    background: rgba(236, 239, 241, .8);
}

.blue-grey.lighten-4
{
    color: #cfd8dc !important;
}

.bg-blue-grey.bg-lighten-4
{
    background-color: #cfd8dc !important;
}
.bg-blue-grey.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(207, 216, 220, .6);
            box-shadow: 0 1px 20px 1px rgba(207, 216, 220, .6);
}

.btn-blue-grey.btn-lighten-4
{
    border-color: #455a64 !important;
    background-color: #cfd8dc !important;
}
.btn-blue-grey.btn-lighten-4:hover
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-lighten-4:focus,
.btn-blue-grey.btn-lighten-4:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-blue-grey.btn-outline-lighten-4
{
    color: #cfd8dc !important; 
    border-color: #cfd8dc !important;
}
.btn-outline-blue-grey.btn-outline-lighten-4:hover
{
    background-color: #cfd8dc !important;
}

input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #cfd8dc !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #cfd8dc !important;
}

.border-blue-grey.border-lighten-4
{
    border: 1px solid #cfd8dc !important;
}

.border-top-blue-grey.border-top-lighten-4
{
    border-top: 1px solid #cfd8dc !important;
}

.border-bottom-blue-grey.border-bottom-lighten-4
{
    border-bottom: 1px solid #cfd8dc !important;
}

.border-left-blue-grey.border-left-lighten-4
{
    border-left: 1px solid #cfd8dc !important;
}

.border-right-blue-grey.border-right-lighten-4
{
    border-right: 1px solid #cfd8dc !important;
}

.overlay-blue-grey.overlay-lighten-4
{
    background: #cfd8dc;
    /* The Fallback */
    background: rgba(207, 216, 220, .8);
}

.blue-grey.lighten-3
{
    color: #b0bec5 !important;
}

.bg-blue-grey.bg-lighten-3
{
    background-color: #b0bec5 !important;
}
.bg-blue-grey.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(176, 190, 197, .6);
            box-shadow: 0 1px 20px 1px rgba(176, 190, 197, .6);
}

.btn-blue-grey.btn-lighten-3
{
    border-color: #455a64 !important;
    background-color: #b0bec5 !important;
}
.btn-blue-grey.btn-lighten-3:hover
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-lighten-3:focus,
.btn-blue-grey.btn-lighten-3:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-blue-grey.btn-outline-lighten-3
{
    color: #b0bec5 !important; 
    border-color: #b0bec5 !important;
}
.btn-outline-blue-grey.btn-outline-lighten-3:hover
{
    background-color: #b0bec5 !important;
}

input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b0bec5 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b0bec5 !important;
}

.border-blue-grey.border-lighten-3
{
    border: 1px solid #b0bec5 !important;
}

.border-top-blue-grey.border-top-lighten-3
{
    border-top: 1px solid #b0bec5 !important;
}

.border-bottom-blue-grey.border-bottom-lighten-3
{
    border-bottom: 1px solid #b0bec5 !important;
}

.border-left-blue-grey.border-left-lighten-3
{
    border-left: 1px solid #b0bec5 !important;
}

.border-right-blue-grey.border-right-lighten-3
{
    border-right: 1px solid #b0bec5 !important;
}

.overlay-blue-grey.overlay-lighten-3
{
    background: #b0bec5;
    /* The Fallback */
    background: rgba(176, 190, 197, .8);
}

.blue-grey.lighten-2
{
    color: #90a4ae !important;
}

.bg-blue-grey.bg-lighten-2
{
    background-color: #90a4ae !important;
}
.bg-blue-grey.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(144, 164, 174, .6);
            box-shadow: 0 1px 20px 1px rgba(144, 164, 174, .6);
}

.btn-blue-grey.btn-lighten-2
{
    border-color: #455a64 !important;
    background-color: #90a4ae !important;
}
.btn-blue-grey.btn-lighten-2:hover
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-lighten-2:focus,
.btn-blue-grey.btn-lighten-2:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-blue-grey.btn-outline-lighten-2
{
    color: #90a4ae !important; 
    border-color: #90a4ae !important;
}
.btn-outline-blue-grey.btn-outline-lighten-2:hover
{
    background-color: #90a4ae !important;
}

input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #90a4ae !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #90a4ae !important;
}

.border-blue-grey.border-lighten-2
{
    border: 1px solid #90a4ae !important;
}

.border-top-blue-grey.border-top-lighten-2
{
    border-top: 1px solid #90a4ae !important;
}

.border-bottom-blue-grey.border-bottom-lighten-2
{
    border-bottom: 1px solid #90a4ae !important;
}

.border-left-blue-grey.border-left-lighten-2
{
    border-left: 1px solid #90a4ae !important;
}

.border-right-blue-grey.border-right-lighten-2
{
    border-right: 1px solid #90a4ae !important;
}

.overlay-blue-grey.overlay-lighten-2
{
    background: #90a4ae;
    /* The Fallback */
    background: rgba(144, 164, 174, .8);
}

.blue-grey.lighten-1
{
    color: #78909c !important;
}

.bg-blue-grey.bg-lighten-1
{
    background-color: #78909c !important;
}
.bg-blue-grey.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(120, 144, 156, .6);
            box-shadow: 0 1px 20px 1px rgba(120, 144, 156, .6);
}

.btn-blue-grey.btn-lighten-1
{
    border-color: #455a64 !important;
    background-color: #78909c !important;
}
.btn-blue-grey.btn-lighten-1:hover
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-lighten-1:focus,
.btn-blue-grey.btn-lighten-1:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-blue-grey.btn-outline-lighten-1
{
    color: #78909c !important; 
    border-color: #78909c !important;
}
.btn-outline-blue-grey.btn-outline-lighten-1:hover
{
    background-color: #78909c !important;
}

input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #78909c !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #78909c !important;
}

.border-blue-grey.border-lighten-1
{
    border: 1px solid #78909c !important;
}

.border-top-blue-grey.border-top-lighten-1
{
    border-top: 1px solid #78909c !important;
}

.border-bottom-blue-grey.border-bottom-lighten-1
{
    border-bottom: 1px solid #78909c !important;
}

.border-left-blue-grey.border-left-lighten-1
{
    border-left: 1px solid #78909c !important;
}

.border-right-blue-grey.border-right-lighten-1
{
    border-right: 1px solid #78909c !important;
}

.overlay-blue-grey.overlay-lighten-1
{
    background: #78909c;
    /* The Fallback */
    background: rgba(120, 144, 156, .8);
}

.blue-grey
{
    color: #607d8b !important;
}

.bg-blue-grey
{
    background-color: #607d8b !important;
}
.bg-blue-grey .card-header,
.bg-blue-grey .card-footer
{
    background-color: transparent;
}
.bg-blue-grey.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(96, 125, 139, .6);
            box-shadow: 0 1px 20px 1px rgba(96, 125, 139, .6);
}

.selectboxit.selectboxit-btn.bg-blue-grey
{
    background-color: #607d8b !important;
}

.alert-blue-grey
{
    color: #171e21 !important; 
    border-color: #607d8b !important;
    background-color: #87a0ac !important;
}
.alert-blue-grey .alert-link
{
    color: #060809 !important;
}

.border-blue-grey
{
    border-color: #607d8b;
}

.overlay-blue-grey
{
    background: #607d8b;
    /* The Fallback */
    background: rgba(96, 125, 139, .8);
}

/* .card-outline-blue-grey{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-blue-grey
{
    color: #fff; 
    border-color: #607d8b !important;
    background-color: #607d8b !important;
}
.btn-blue-grey:hover
{
    color: #fff !important; 
    border-color: #455a64 !important;
    background-color: #546e7a !important;
}
.btn-blue-grey:focus,
.btn-blue-grey:active
{
    color: #fff !important; 
    border-color: #37474f !important;
    background-color: #455a64 !important;
}
.btn-blue-grey.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(96, 125, 139, .6);
            box-shadow: 0 1px 20px 1px rgba(96, 125, 139, .6);
}

.btn-outline-blue-grey
{
    color: #607d8b; 
    border-color: #607d8b;
    background-color: transparent;
}
.btn-outline-blue-grey:hover
{
    color: #fff !important; 
    background-color: #607d8b;
}
.btn-outline-blue-grey.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(96, 125, 139, .6);
            box-shadow: 0 1px 20px 1px rgba(96, 125, 139, .6);
}

input[type='checkbox'].bg-blue-grey + .custom-control-label:before,
input[type='radio'].bg-blue-grey + .custom-control-label:before
{
    background-color: #607d8b !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #607d8b !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #607d8b !important;
}

.border-blue-grey
{
    border: 1px solid #607d8b !important;
}

.border-top-blue-grey
{
    border-top: 1px solid #607d8b;
}

.border-bottom-blue-grey
{
    border-bottom: 1px solid #607d8b;
}

.border-left-blue-grey
{
    border-left: 1px solid #607d8b;
}

.border-right-blue-grey
{
    border-right: 1px solid #607d8b;
}

.blue-grey.darken-1
{
    color: #546e7a !important;
}

.bg-blue-grey.bg-darken-1
{
    background-color: #546e7a !important;
}
.bg-blue-grey.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(84, 110, 122, .6);
            box-shadow: 0 1px 20px 1px rgba(84, 110, 122, .6);
}

.btn-blue-grey.btn-darken-1
{
    border-color: #455a64 !important;
    background-color: #546e7a !important;
}
.btn-blue-grey.btn-darken-1:hover
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-darken-1:focus,
.btn-blue-grey.btn-darken-1:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-blue-grey.btn-outline-darken-1
{
    color: #546e7a !important; 
    border-color: #546e7a !important;
}
.btn-outline-blue-grey.btn-outline-darken-1:hover
{
    background-color: #546e7a !important;
}

input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #546e7a !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #546e7a !important;
}

.border-blue-grey.border-darken-1
{
    border: 1px solid #546e7a !important;
}

.border-top-blue-grey.border-top-darken-1
{
    border-top: 1px solid #546e7a !important;
}

.border-bottom-blue-grey.border-bottom-darken-1
{
    border-bottom: 1px solid #546e7a !important;
}

.border-left-blue-grey.border-left-darken-1
{
    border-left: 1px solid #546e7a !important;
}

.border-right-blue-grey.border-right-darken-1
{
    border-right: 1px solid #546e7a !important;
}

.overlay-blue-grey.overlay-darken-1
{
    background: #546e7a;
    /* The Fallback */
    background: rgba(84, 110, 122, .8);
}

.blue-grey.darken-2
{
    color: #455a64 !important;
}

.bg-blue-grey.bg-darken-2
{
    background-color: #455a64 !important;
}
.bg-blue-grey.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(69, 90, 100, .6);
            box-shadow: 0 1px 20px 1px rgba(69, 90, 100, .6);
}

.btn-blue-grey.btn-darken-2
{
    border-color: #455a64 !important;
    background-color: #455a64 !important;
}
.btn-blue-grey.btn-darken-2:hover
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-darken-2:focus,
.btn-blue-grey.btn-darken-2:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-blue-grey.btn-outline-darken-2
{
    color: #455a64 !important; 
    border-color: #455a64 !important;
}
.btn-outline-blue-grey.btn-outline-darken-2:hover
{
    background-color: #455a64 !important;
}

input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #455a64 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #455a64 !important;
}

.border-blue-grey.border-darken-2
{
    border: 1px solid #455a64 !important;
}

.border-top-blue-grey.border-top-darken-2
{
    border-top: 1px solid #455a64 !important;
}

.border-bottom-blue-grey.border-bottom-darken-2
{
    border-bottom: 1px solid #455a64 !important;
}

.border-left-blue-grey.border-left-darken-2
{
    border-left: 1px solid #455a64 !important;
}

.border-right-blue-grey.border-right-darken-2
{
    border-right: 1px solid #455a64 !important;
}

.overlay-blue-grey.overlay-darken-2
{
    background: #455a64;
    /* The Fallback */
    background: rgba(69, 90, 100, .8);
}

.blue-grey.darken-3
{
    color: #37474f !important;
}

.bg-blue-grey.bg-darken-3
{
    background-color: #37474f !important;
}
.bg-blue-grey.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(55, 71, 79, .6);
            box-shadow: 0 1px 20px 1px rgba(55, 71, 79, .6);
}

.btn-blue-grey.btn-darken-3
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-darken-3:hover
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-darken-3:focus,
.btn-blue-grey.btn-darken-3:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-blue-grey.btn-outline-darken-3
{
    color: #37474f !important; 
    border-color: #37474f !important;
}
.btn-outline-blue-grey.btn-outline-darken-3:hover
{
    background-color: #37474f !important;
}

input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #37474f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #37474f !important;
}

.border-blue-grey.border-darken-3
{
    border: 1px solid #37474f !important;
}

.border-top-blue-grey.border-top-darken-3
{
    border-top: 1px solid #37474f !important;
}

.border-bottom-blue-grey.border-bottom-darken-3
{
    border-bottom: 1px solid #37474f !important;
}

.border-left-blue-grey.border-left-darken-3
{
    border-left: 1px solid #37474f !important;
}

.border-right-blue-grey.border-right-darken-3
{
    border-right: 1px solid #37474f !important;
}

.overlay-blue-grey.overlay-darken-3
{
    background: #37474f;
    /* The Fallback */
    background: rgba(55, 71, 79, .8);
}

.blue-grey.darken-4
{
    color: #263238 !important;
}

.bg-blue-grey.bg-darken-4
{
    background-color: #263238 !important;
}
.bg-blue-grey.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(38, 50, 56, .6);
            box-shadow: 0 1px 20px 1px rgba(38, 50, 56, .6);
}

.btn-blue-grey.btn-darken-4
{
    border-color: #455a64 !important;
    background-color: #263238 !important;
}
.btn-blue-grey.btn-darken-4:hover
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-darken-4:focus,
.btn-blue-grey.btn-darken-4:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-blue-grey.btn-outline-darken-4
{
    color: #263238 !important; 
    border-color: #263238 !important;
}
.btn-outline-blue-grey.btn-outline-darken-4:hover
{
    background-color: #263238 !important;
}

input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #263238 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #263238 !important;
}

.border-blue-grey.border-darken-4
{
    border: 1px solid #263238 !important;
}

.border-top-blue-grey.border-top-darken-4
{
    border-top: 1px solid #263238 !important;
}

.border-bottom-blue-grey.border-bottom-darken-4
{
    border-bottom: 1px solid #263238 !important;
}

.border-left-blue-grey.border-left-darken-4
{
    border-left: 1px solid #263238 !important;
}

.border-right-blue-grey.border-right-darken-4
{
    border-right: 1px solid #263238 !important;
}

.overlay-blue-grey.overlay-darken-4
{
    background: #263238;
    /* The Fallback */
    background: rgba(38, 50, 56, .8);
}

.grey.lighten-5
{
    color: #fafafa !important;
}

.bg-grey.bg-lighten-5
{
    background-color: #fafafa !important;
}
.bg-grey.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(250, 250, 250, .6);
            box-shadow: 0 1px 20px 1px rgba(250, 250, 250, .6);
}

.btn-grey.btn-lighten-5
{
    border-color: #616161 !important;
    background-color: #fafafa !important;
}
.btn-grey.btn-lighten-5:hover
{
    border-color: #616161 !important;
    background-color: #424242 !important;
}
.btn-grey.btn-lighten-5:focus,
.btn-grey.btn-lighten-5:active
{
    border-color: #424242 !important;
    background-color: #212121 !important;
}

.btn-outline-grey.btn-outline-lighten-5
{
    color: #fafafa !important; 
    border-color: #fafafa !important;
}
.btn-outline-grey.btn-outline-lighten-5:hover
{
    background-color: #fafafa !important;
}

input:focus ~ .bg-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fafafa !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fafafa !important;
}

.border-grey.border-lighten-5
{
    border: 1px solid #fafafa !important;
}

.border-top-grey.border-top-lighten-5
{
    border-top: 1px solid #fafafa !important;
}

.border-bottom-grey.border-bottom-lighten-5
{
    border-bottom: 1px solid #fafafa !important;
}

.border-left-grey.border-left-lighten-5
{
    border-left: 1px solid #fafafa !important;
}

.border-right-grey.border-right-lighten-5
{
    border-right: 1px solid #fafafa !important;
}

.overlay-grey.overlay-lighten-5
{
    background: #fafafa;
    /* The Fallback */
    background: rgba(250, 250, 250, .8);
}

.grey.lighten-4
{
    color: #f5f5f5 !important;
}

.bg-grey.bg-lighten-4
{
    background-color: #f5f5f5 !important;
}
.bg-grey.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(245, 245, 245, .6);
            box-shadow: 0 1px 20px 1px rgba(245, 245, 245, .6);
}

.btn-grey.btn-lighten-4
{
    border-color: #616161 !important;
    background-color: #f5f5f5 !important;
}
.btn-grey.btn-lighten-4:hover
{
    border-color: #616161 !important;
    background-color: #424242 !important;
}
.btn-grey.btn-lighten-4:focus,
.btn-grey.btn-lighten-4:active
{
    border-color: #424242 !important;
    background-color: #212121 !important;
}

.btn-outline-grey.btn-outline-lighten-4
{
    color: #f5f5f5 !important; 
    border-color: #f5f5f5 !important;
}
.btn-outline-grey.btn-outline-lighten-4:hover
{
    background-color: #f5f5f5 !important;
}

input:focus ~ .bg-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f5f5f5 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f5f5f5 !important;
}

.border-grey.border-lighten-4
{
    border: 1px solid #f5f5f5 !important;
}

.border-top-grey.border-top-lighten-4
{
    border-top: 1px solid #f5f5f5 !important;
}

.border-bottom-grey.border-bottom-lighten-4
{
    border-bottom: 1px solid #f5f5f5 !important;
}

.border-left-grey.border-left-lighten-4
{
    border-left: 1px solid #f5f5f5 !important;
}

.border-right-grey.border-right-lighten-4
{
    border-right: 1px solid #f5f5f5 !important;
}

.overlay-grey.overlay-lighten-4
{
    background: #f5f5f5;
    /* The Fallback */
    background: rgba(245, 245, 245, .8);
}

.grey.lighten-3
{
    color: #eee !important;
}

.bg-grey.bg-lighten-3
{
    background-color: #eee !important;
}
.bg-grey.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(238, 238, 238, .6);
            box-shadow: 0 1px 20px 1px rgba(238, 238, 238, .6);
}

.btn-grey.btn-lighten-3
{
    border-color: #616161 !important;
    background-color: #eee !important;
}
.btn-grey.btn-lighten-3:hover
{
    border-color: #616161 !important;
    background-color: #424242 !important;
}
.btn-grey.btn-lighten-3:focus,
.btn-grey.btn-lighten-3:active
{
    border-color: #424242 !important;
    background-color: #212121 !important;
}

.btn-outline-grey.btn-outline-lighten-3
{
    color: #eee !important; 
    border-color: #eee !important;
}
.btn-outline-grey.btn-outline-lighten-3:hover
{
    background-color: #eee !important;
}

input:focus ~ .bg-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #eee !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #eee !important;
}

.border-grey.border-lighten-3
{
    border: 1px solid #eee !important;
}

.border-top-grey.border-top-lighten-3
{
    border-top: 1px solid #eee !important;
}

.border-bottom-grey.border-bottom-lighten-3
{
    border-bottom: 1px solid #eee !important;
}

.border-left-grey.border-left-lighten-3
{
    border-left: 1px solid #eee !important;
}

.border-right-grey.border-right-lighten-3
{
    border-right: 1px solid #eee !important;
}

.overlay-grey.overlay-lighten-3
{
    background: #eee;
    /* The Fallback */
    background: rgba(238, 238, 238, .8);
}

.grey.lighten-2
{
    color: #e0e0e0 !important;
}

.bg-grey.bg-lighten-2
{
    background-color: #e0e0e0 !important;
}
.bg-grey.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(224, 224, 224, .6);
            box-shadow: 0 1px 20px 1px rgba(224, 224, 224, .6);
}

.btn-grey.btn-lighten-2
{
    border-color: #616161 !important;
    background-color: #e0e0e0 !important;
}
.btn-grey.btn-lighten-2:hover
{
    border-color: #616161 !important;
    background-color: #424242 !important;
}
.btn-grey.btn-lighten-2:focus,
.btn-grey.btn-lighten-2:active
{
    border-color: #424242 !important;
    background-color: #212121 !important;
}

.btn-outline-grey.btn-outline-lighten-2
{
    color: #e0e0e0 !important; 
    border-color: #e0e0e0 !important;
}
.btn-outline-grey.btn-outline-lighten-2:hover
{
    background-color: #e0e0e0 !important;
}

input:focus ~ .bg-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e0e0e0 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e0e0e0 !important;
}

.border-grey.border-lighten-2
{
    border: 1px solid #e0e0e0 !important;
}

.border-top-grey.border-top-lighten-2
{
    border-top: 1px solid #e0e0e0 !important;
}

.border-bottom-grey.border-bottom-lighten-2
{
    border-bottom: 1px solid #e0e0e0 !important;
}

.border-left-grey.border-left-lighten-2
{
    border-left: 1px solid #e0e0e0 !important;
}

.border-right-grey.border-right-lighten-2
{
    border-right: 1px solid #e0e0e0 !important;
}

.overlay-grey.overlay-lighten-2
{
    background: #e0e0e0;
    /* The Fallback */
    background: rgba(224, 224, 224, .8);
}

.grey.lighten-1
{
    color: #bdbdbd !important;
}

.bg-grey.bg-lighten-1
{
    background-color: #bdbdbd !important;
}
.bg-grey.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(189, 189, 189, .6);
            box-shadow: 0 1px 20px 1px rgba(189, 189, 189, .6);
}

.btn-grey.btn-lighten-1
{
    border-color: #616161 !important;
    background-color: #bdbdbd !important;
}
.btn-grey.btn-lighten-1:hover
{
    border-color: #616161 !important;
    background-color: #424242 !important;
}
.btn-grey.btn-lighten-1:focus,
.btn-grey.btn-lighten-1:active
{
    border-color: #424242 !important;
    background-color: #212121 !important;
}

.btn-outline-grey.btn-outline-lighten-1
{
    color: #bdbdbd !important; 
    border-color: #bdbdbd !important;
}
.btn-outline-grey.btn-outline-lighten-1:hover
{
    background-color: #bdbdbd !important;
}

input:focus ~ .bg-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bdbdbd !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bdbdbd !important;
}

.border-grey.border-lighten-1
{
    border: 1px solid #bdbdbd !important;
}

.border-top-grey.border-top-lighten-1
{
    border-top: 1px solid #bdbdbd !important;
}

.border-bottom-grey.border-bottom-lighten-1
{
    border-bottom: 1px solid #bdbdbd !important;
}

.border-left-grey.border-left-lighten-1
{
    border-left: 1px solid #bdbdbd !important;
}

.border-right-grey.border-right-lighten-1
{
    border-right: 1px solid #bdbdbd !important;
}

.overlay-grey.overlay-lighten-1
{
    background: #bdbdbd;
    /* The Fallback */
    background: rgba(189, 189, 189, .8);
}

.grey
{
    color: #9e9e9e !important;
}

.bg-grey
{
    background-color: #9e9e9e !important;
}
.bg-grey .card-header,
.bg-grey .card-footer
{
    background-color: transparent;
}
.bg-grey.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(158, 158, 158, .6);
            box-shadow: 0 1px 20px 1px rgba(158, 158, 158, .6);
}

.selectboxit.selectboxit-btn.bg-grey
{
    background-color: #9e9e9e !important;
}

.alert-grey
{
    color: #454545 !important; 
    border-color: #9e9e9e !important;
    background-color: #c2c2c2 !important;
}
.alert-grey .alert-link
{
    color: #303030 !important;
}

.border-grey
{
    border-color: #9e9e9e;
}

.overlay-grey
{
    background: #9e9e9e;
    /* The Fallback */
    background: rgba(158, 158, 158, .8);
}

/* .card-outline-grey{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-grey
{
    color: #fff; 
    border-color: #9e9e9e !important;
    background-color: #9e9e9e !important;
}
.btn-grey:hover
{
    color: #fff !important; 
    border-color: #616161 !important;
    background-color: #757575 !important;
}
.btn-grey:focus,
.btn-grey:active
{
    color: #fff !important; 
    border-color: #424242 !important;
    background-color: #616161 !important;
}
.btn-grey.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(158, 158, 158, .6);
            box-shadow: 0 1px 20px 1px rgba(158, 158, 158, .6);
}

.btn-outline-grey
{
    color: #9e9e9e; 
    border-color: #9e9e9e;
    background-color: transparent;
}
.btn-outline-grey:hover
{
    color: #fff !important; 
    background-color: #9e9e9e;
}
.btn-outline-grey.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(158, 158, 158, .6);
            box-shadow: 0 1px 20px 1px rgba(158, 158, 158, .6);
}

input[type='checkbox'].bg-grey + .custom-control-label:before,
input[type='radio'].bg-grey + .custom-control-label:before
{
    background-color: #9e9e9e !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #9e9e9e !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #9e9e9e !important;
}

.border-grey
{
    border: 1px solid #9e9e9e !important;
}

.border-top-grey
{
    border-top: 1px solid #9e9e9e;
}

.border-bottom-grey
{
    border-bottom: 1px solid #9e9e9e;
}

.border-left-grey
{
    border-left: 1px solid #9e9e9e;
}

.border-right-grey
{
    border-right: 1px solid #9e9e9e;
}

.grey.darken-1
{
    color: #757575 !important;
}

.bg-grey.bg-darken-1
{
    background-color: #757575 !important;
}
.bg-grey.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(117, 117, 117, .6);
            box-shadow: 0 1px 20px 1px rgba(117, 117, 117, .6);
}

.btn-grey.btn-darken-1
{
    border-color: #616161 !important;
    background-color: #757575 !important;
}
.btn-grey.btn-darken-1:hover
{
    border-color: #616161 !important;
    background-color: #424242 !important;
}
.btn-grey.btn-darken-1:focus,
.btn-grey.btn-darken-1:active
{
    border-color: #424242 !important;
    background-color: #212121 !important;
}

.btn-outline-grey.btn-outline-darken-1
{
    color: #757575 !important; 
    border-color: #757575 !important;
}
.btn-outline-grey.btn-outline-darken-1:hover
{
    background-color: #757575 !important;
}

input:focus ~ .bg-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #757575 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #757575 !important;
}

.border-grey.border-darken-1
{
    border: 1px solid #757575 !important;
}

.border-top-grey.border-top-darken-1
{
    border-top: 1px solid #757575 !important;
}

.border-bottom-grey.border-bottom-darken-1
{
    border-bottom: 1px solid #757575 !important;
}

.border-left-grey.border-left-darken-1
{
    border-left: 1px solid #757575 !important;
}

.border-right-grey.border-right-darken-1
{
    border-right: 1px solid #757575 !important;
}

.overlay-grey.overlay-darken-1
{
    background: #757575;
    /* The Fallback */
    background: rgba(117, 117, 117, .8);
}

.grey.darken-2
{
    color: #616161 !important;
}

.bg-grey.bg-darken-2
{
    background-color: #616161 !important;
}
.bg-grey.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(97, 97, 97, .6);
            box-shadow: 0 1px 20px 1px rgba(97, 97, 97, .6);
}

.btn-grey.btn-darken-2
{
    border-color: #616161 !important;
    background-color: #616161 !important;
}
.btn-grey.btn-darken-2:hover
{
    border-color: #616161 !important;
    background-color: #424242 !important;
}
.btn-grey.btn-darken-2:focus,
.btn-grey.btn-darken-2:active
{
    border-color: #424242 !important;
    background-color: #212121 !important;
}

.btn-outline-grey.btn-outline-darken-2
{
    color: #616161 !important; 
    border-color: #616161 !important;
}
.btn-outline-grey.btn-outline-darken-2:hover
{
    background-color: #616161 !important;
}

input:focus ~ .bg-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #616161 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #616161 !important;
}

.border-grey.border-darken-2
{
    border: 1px solid #616161 !important;
}

.border-top-grey.border-top-darken-2
{
    border-top: 1px solid #616161 !important;
}

.border-bottom-grey.border-bottom-darken-2
{
    border-bottom: 1px solid #616161 !important;
}

.border-left-grey.border-left-darken-2
{
    border-left: 1px solid #616161 !important;
}

.border-right-grey.border-right-darken-2
{
    border-right: 1px solid #616161 !important;
}

.overlay-grey.overlay-darken-2
{
    background: #616161;
    /* The Fallback */
    background: rgba(97, 97, 97, .8);
}

.grey.darken-3
{
    color: #424242 !important;
}

.bg-grey.bg-darken-3
{
    background-color: #424242 !important;
}
.bg-grey.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(66, 66, 66, .6);
            box-shadow: 0 1px 20px 1px rgba(66, 66, 66, .6);
}

.btn-grey.btn-darken-3
{
    border-color: #616161 !important;
    background-color: #424242 !important;
}
.btn-grey.btn-darken-3:hover
{
    border-color: #616161 !important;
    background-color: #424242 !important;
}
.btn-grey.btn-darken-3:focus,
.btn-grey.btn-darken-3:active
{
    border-color: #424242 !important;
    background-color: #212121 !important;
}

.btn-outline-grey.btn-outline-darken-3
{
    color: #424242 !important; 
    border-color: #424242 !important;
}
.btn-outline-grey.btn-outline-darken-3:hover
{
    background-color: #424242 !important;
}

input:focus ~ .bg-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #424242 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #424242 !important;
}

.border-grey.border-darken-3
{
    border: 1px solid #424242 !important;
}

.border-top-grey.border-top-darken-3
{
    border-top: 1px solid #424242 !important;
}

.border-bottom-grey.border-bottom-darken-3
{
    border-bottom: 1px solid #424242 !important;
}

.border-left-grey.border-left-darken-3
{
    border-left: 1px solid #424242 !important;
}

.border-right-grey.border-right-darken-3
{
    border-right: 1px solid #424242 !important;
}

.overlay-grey.overlay-darken-3
{
    background: #424242;
    /* The Fallback */
    background: rgba(66, 66, 66, .8);
}

.grey.darken-4
{
    color: #212121 !important;
}

.bg-grey.bg-darken-4
{
    background-color: #212121 !important;
}
.bg-grey.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(33, 33, 33, .6);
            box-shadow: 0 1px 20px 1px rgba(33, 33, 33, .6);
}

.btn-grey.btn-darken-4
{
    border-color: #616161 !important;
    background-color: #212121 !important;
}
.btn-grey.btn-darken-4:hover
{
    border-color: #616161 !important;
    background-color: #424242 !important;
}
.btn-grey.btn-darken-4:focus,
.btn-grey.btn-darken-4:active
{
    border-color: #424242 !important;
    background-color: #212121 !important;
}

.btn-outline-grey.btn-outline-darken-4
{
    color: #212121 !important; 
    border-color: #212121 !important;
}
.btn-outline-grey.btn-outline-darken-4:hover
{
    background-color: #212121 !important;
}

input:focus ~ .bg-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #212121 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #212121 !important;
}

.border-grey.border-darken-4
{
    border: 1px solid #212121 !important;
}

.border-top-grey.border-top-darken-4
{
    border-top: 1px solid #212121 !important;
}

.border-bottom-grey.border-bottom-darken-4
{
    border-bottom: 1px solid #212121 !important;
}

.border-left-grey.border-left-darken-4
{
    border-left: 1px solid #212121 !important;
}

.border-right-grey.border-right-darken-4
{
    border-right: 1px solid #212121 !important;
}

.overlay-grey.overlay-darken-4
{
    background: #212121;
    /* The Fallback */
    background: rgba(33, 33, 33, .8);
}

.grey-blue.lighten-5
{
    color: #eceff1 !important;
}

.bg-grey-blue.bg-lighten-5
{
    background-color: #eceff1 !important;
}
.bg-grey-blue.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(236, 239, 241, .6);
            box-shadow: 0 1px 20px 1px rgba(236, 239, 241, .6);
}

.btn-grey-blue.btn-lighten-5
{
    border-color: #2c303b !important;
    background-color: #eceff1 !important;
}
.btn-grey-blue.btn-lighten-5:hover
{
    border-color: #2c303b !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-lighten-5:focus,
.btn-grey-blue.btn-lighten-5:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-grey-blue.btn-outline-lighten-5
{
    color: #eceff1 !important; 
    border-color: #eceff1 !important;
}
.btn-outline-grey-blue.btn-outline-lighten-5:hover
{
    background-color: #eceff1 !important;
}

input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #eceff1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #eceff1 !important;
}

.border-grey-blue.border-lighten-5
{
    border: 1px solid #eceff1 !important;
}

.border-top-grey-blue.border-top-lighten-5
{
    border-top: 1px solid #eceff1 !important;
}

.border-bottom-grey-blue.border-bottom-lighten-5
{
    border-bottom: 1px solid #eceff1 !important;
}

.border-left-grey-blue.border-left-lighten-5
{
    border-left: 1px solid #eceff1 !important;
}

.border-right-grey-blue.border-right-lighten-5
{
    border-right: 1px solid #eceff1 !important;
}

.overlay-grey-blue.overlay-lighten-5
{
    background: #eceff1;
    /* The Fallback */
    background: rgba(236, 239, 241, .8);
}

.grey-blue.lighten-4
{
    color: #cfd8dc !important;
}

.bg-grey-blue.bg-lighten-4
{
    background-color: #cfd8dc !important;
}
.bg-grey-blue.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(207, 216, 220, .6);
            box-shadow: 0 1px 20px 1px rgba(207, 216, 220, .6);
}

.btn-grey-blue.btn-lighten-4
{
    border-color: #2c303b !important;
    background-color: #cfd8dc !important;
}
.btn-grey-blue.btn-lighten-4:hover
{
    border-color: #2c303b !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-lighten-4:focus,
.btn-grey-blue.btn-lighten-4:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-grey-blue.btn-outline-lighten-4
{
    color: #cfd8dc !important; 
    border-color: #cfd8dc !important;
}
.btn-outline-grey-blue.btn-outline-lighten-4:hover
{
    background-color: #cfd8dc !important;
}

input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #cfd8dc !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #cfd8dc !important;
}

.border-grey-blue.border-lighten-4
{
    border: 1px solid #cfd8dc !important;
}

.border-top-grey-blue.border-top-lighten-4
{
    border-top: 1px solid #cfd8dc !important;
}

.border-bottom-grey-blue.border-bottom-lighten-4
{
    border-bottom: 1px solid #cfd8dc !important;
}

.border-left-grey-blue.border-left-lighten-4
{
    border-left: 1px solid #cfd8dc !important;
}

.border-right-grey-blue.border-right-lighten-4
{
    border-right: 1px solid #cfd8dc !important;
}

.overlay-grey-blue.overlay-lighten-4
{
    background: #cfd8dc;
    /* The Fallback */
    background: rgba(207, 216, 220, .8);
}

.grey-blue.lighten-3
{
    color: #b0bec5 !important;
}

.bg-grey-blue.bg-lighten-3
{
    background-color: #b0bec5 !important;
}
.bg-grey-blue.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(176, 190, 197, .6);
            box-shadow: 0 1px 20px 1px rgba(176, 190, 197, .6);
}

.btn-grey-blue.btn-lighten-3
{
    border-color: #2c303b !important;
    background-color: #b0bec5 !important;
}
.btn-grey-blue.btn-lighten-3:hover
{
    border-color: #2c303b !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-lighten-3:focus,
.btn-grey-blue.btn-lighten-3:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-grey-blue.btn-outline-lighten-3
{
    color: #b0bec5 !important; 
    border-color: #b0bec5 !important;
}
.btn-outline-grey-blue.btn-outline-lighten-3:hover
{
    background-color: #b0bec5 !important;
}

input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b0bec5 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b0bec5 !important;
}

.border-grey-blue.border-lighten-3
{
    border: 1px solid #b0bec5 !important;
}

.border-top-grey-blue.border-top-lighten-3
{
    border-top: 1px solid #b0bec5 !important;
}

.border-bottom-grey-blue.border-bottom-lighten-3
{
    border-bottom: 1px solid #b0bec5 !important;
}

.border-left-grey-blue.border-left-lighten-3
{
    border-left: 1px solid #b0bec5 !important;
}

.border-right-grey-blue.border-right-lighten-3
{
    border-right: 1px solid #b0bec5 !important;
}

.overlay-grey-blue.overlay-lighten-3
{
    background: #b0bec5;
    /* The Fallback */
    background: rgba(176, 190, 197, .8);
}

.grey-blue.lighten-2
{
    color: #6f85ad !important;
}

.bg-grey-blue.bg-lighten-2
{
    background-color: #6f85ad !important;
}
.bg-grey-blue.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(111, 133, 173, .6);
            box-shadow: 0 1px 20px 1px rgba(111, 133, 173, .6);
}

.btn-grey-blue.btn-lighten-2
{
    border-color: #2c303b !important;
    background-color: #6f85ad !important;
}
.btn-grey-blue.btn-lighten-2:hover
{
    border-color: #2c303b !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-lighten-2:focus,
.btn-grey-blue.btn-lighten-2:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-grey-blue.btn-outline-lighten-2
{
    color: #6f85ad !important; 
    border-color: #6f85ad !important;
}
.btn-outline-grey-blue.btn-outline-lighten-2:hover
{
    background-color: #6f85ad !important;
}

input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #6f85ad !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #6f85ad !important;
}

.border-grey-blue.border-lighten-2
{
    border: 1px solid #6f85ad !important;
}

.border-top-grey-blue.border-top-lighten-2
{
    border-top: 1px solid #6f85ad !important;
}

.border-bottom-grey-blue.border-bottom-lighten-2
{
    border-bottom: 1px solid #6f85ad !important;
}

.border-left-grey-blue.border-left-lighten-2
{
    border-left: 1px solid #6f85ad !important;
}

.border-right-grey-blue.border-right-lighten-2
{
    border-right: 1px solid #6f85ad !important;
}

.overlay-grey-blue.overlay-lighten-2
{
    background: #6f85ad;
    /* The Fallback */
    background: rgba(111, 133, 173, .8);
}

.grey-blue.lighten-1
{
    color: #78909c !important;
}

.bg-grey-blue.bg-lighten-1
{
    background-color: #78909c !important;
}
.bg-grey-blue.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(120, 144, 156, .6);
            box-shadow: 0 1px 20px 1px rgba(120, 144, 156, .6);
}

.btn-grey-blue.btn-lighten-1
{
    border-color: #2c303b !important;
    background-color: #78909c !important;
}
.btn-grey-blue.btn-lighten-1:hover
{
    border-color: #2c303b !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-lighten-1:focus,
.btn-grey-blue.btn-lighten-1:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-grey-blue.btn-outline-lighten-1
{
    color: #78909c !important; 
    border-color: #78909c !important;
}
.btn-outline-grey-blue.btn-outline-lighten-1:hover
{
    background-color: #78909c !important;
}

input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #78909c !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #78909c !important;
}

.border-grey-blue.border-lighten-1
{
    border: 1px solid #78909c !important;
}

.border-top-grey-blue.border-top-lighten-1
{
    border-top: 1px solid #78909c !important;
}

.border-bottom-grey-blue.border-bottom-lighten-1
{
    border-bottom: 1px solid #78909c !important;
}

.border-left-grey-blue.border-left-lighten-1
{
    border-left: 1px solid #78909c !important;
}

.border-right-grey-blue.border-right-lighten-1
{
    border-right: 1px solid #78909c !important;
}

.overlay-grey-blue.overlay-lighten-1
{
    background: #78909c;
    /* The Fallback */
    background: rgba(120, 144, 156, .8);
}

.grey-blue
{
    color: #1b2942 !important;
}

.bg-grey-blue
{
    background-color: #1b2942 !important;
}
.bg-grey-blue .card-header,
.bg-grey-blue .card-footer
{
    background-color: transparent;
}
.bg-grey-blue.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(27, 41, 66, .6);
            box-shadow: 0 1px 20px 1px rgba(27, 41, 66, .6);
}

.selectboxit.selectboxit-btn.bg-grey-blue
{
    background-color: #1b2942 !important;
}

.alert-grey-blue
{
    color: black !important; 
    border-color: #1b2942 !important;
    background-color: #304875 !important;
}
.alert-grey-blue .alert-link
{
    color: black !important;
}

.border-grey-blue
{
    border-color: #1b2942;
}

.overlay-grey-blue
{
    background: #1b2942;
    /* The Fallback */
    background: rgba(27, 41, 66, .8);
}

/* .card-outline-grey-blue{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-grey-blue
{
    color: #fff; 
    border-color: #1b2942 !important;
    background-color: #1b2942 !important;
}
.btn-grey-blue:hover
{
    color: #fff !important; 
    border-color: #2c303b !important;
    background-color: #546e7a !important;
}
.btn-grey-blue:focus,
.btn-grey-blue:active
{
    color: #fff !important; 
    border-color: #37474f !important;
    background-color: #2c303b !important;
}
.btn-grey-blue.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(27, 41, 66, .6);
            box-shadow: 0 1px 20px 1px rgba(27, 41, 66, .6);
}

.btn-outline-grey-blue
{
    color: #1b2942; 
    border-color: #1b2942;
    background-color: transparent;
}
.btn-outline-grey-blue:hover
{
    color: #fff !important; 
    background-color: #1b2942;
}
.btn-outline-grey-blue.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(27, 41, 66, .6);
            box-shadow: 0 1px 20px 1px rgba(27, 41, 66, .6);
}

input[type='checkbox'].bg-grey-blue + .custom-control-label:before,
input[type='radio'].bg-grey-blue + .custom-control-label:before
{
    background-color: #1b2942 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1b2942 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1b2942 !important;
}

.border-grey-blue
{
    border: 1px solid #1b2942 !important;
}

.border-top-grey-blue
{
    border-top: 1px solid #1b2942;
}

.border-bottom-grey-blue
{
    border-bottom: 1px solid #1b2942;
}

.border-left-grey-blue
{
    border-left: 1px solid #1b2942;
}

.border-right-grey-blue
{
    border-right: 1px solid #1b2942;
}

.grey-blue.darken-1
{
    color: #546e7a !important;
}

.bg-grey-blue.bg-darken-1
{
    background-color: #546e7a !important;
}
.bg-grey-blue.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(84, 110, 122, .6);
            box-shadow: 0 1px 20px 1px rgba(84, 110, 122, .6);
}

.btn-grey-blue.btn-darken-1
{
    border-color: #2c303b !important;
    background-color: #546e7a !important;
}
.btn-grey-blue.btn-darken-1:hover
{
    border-color: #2c303b !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-darken-1:focus,
.btn-grey-blue.btn-darken-1:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-grey-blue.btn-outline-darken-1
{
    color: #546e7a !important; 
    border-color: #546e7a !important;
}
.btn-outline-grey-blue.btn-outline-darken-1:hover
{
    background-color: #546e7a !important;
}

input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #546e7a !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #546e7a !important;
}

.border-grey-blue.border-darken-1
{
    border: 1px solid #546e7a !important;
}

.border-top-grey-blue.border-top-darken-1
{
    border-top: 1px solid #546e7a !important;
}

.border-bottom-grey-blue.border-bottom-darken-1
{
    border-bottom: 1px solid #546e7a !important;
}

.border-left-grey-blue.border-left-darken-1
{
    border-left: 1px solid #546e7a !important;
}

.border-right-grey-blue.border-right-darken-1
{
    border-right: 1px solid #546e7a !important;
}

.overlay-grey-blue.overlay-darken-1
{
    background: #546e7a;
    /* The Fallback */
    background: rgba(84, 110, 122, .8);
}

.grey-blue.darken-2
{
    color: #2c303b !important;
}

.bg-grey-blue.bg-darken-2
{
    background-color: #2c303b !important;
}
.bg-grey-blue.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(44, 48, 59, .6);
            box-shadow: 0 1px 20px 1px rgba(44, 48, 59, .6);
}

.btn-grey-blue.btn-darken-2
{
    border-color: #2c303b !important;
    background-color: #2c303b !important;
}
.btn-grey-blue.btn-darken-2:hover
{
    border-color: #2c303b !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-darken-2:focus,
.btn-grey-blue.btn-darken-2:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-grey-blue.btn-outline-darken-2
{
    color: #2c303b !important; 
    border-color: #2c303b !important;
}
.btn-outline-grey-blue.btn-outline-darken-2:hover
{
    background-color: #2c303b !important;
}

input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #2c303b !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #2c303b !important;
}

.border-grey-blue.border-darken-2
{
    border: 1px solid #2c303b !important;
}

.border-top-grey-blue.border-top-darken-2
{
    border-top: 1px solid #2c303b !important;
}

.border-bottom-grey-blue.border-bottom-darken-2
{
    border-bottom: 1px solid #2c303b !important;
}

.border-left-grey-blue.border-left-darken-2
{
    border-left: 1px solid #2c303b !important;
}

.border-right-grey-blue.border-right-darken-2
{
    border-right: 1px solid #2c303b !important;
}

.overlay-grey-blue.overlay-darken-2
{
    background: #2c303b;
    /* The Fallback */
    background: rgba(44, 48, 59, .8);
}

.grey-blue.darken-3
{
    color: #37474f !important;
}

.bg-grey-blue.bg-darken-3
{
    background-color: #37474f !important;
}
.bg-grey-blue.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(55, 71, 79, .6);
            box-shadow: 0 1px 20px 1px rgba(55, 71, 79, .6);
}

.btn-grey-blue.btn-darken-3
{
    border-color: #2c303b !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-darken-3:hover
{
    border-color: #2c303b !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-darken-3:focus,
.btn-grey-blue.btn-darken-3:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-grey-blue.btn-outline-darken-3
{
    color: #37474f !important; 
    border-color: #37474f !important;
}
.btn-outline-grey-blue.btn-outline-darken-3:hover
{
    background-color: #37474f !important;
}

input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #37474f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #37474f !important;
}

.border-grey-blue.border-darken-3
{
    border: 1px solid #37474f !important;
}

.border-top-grey-blue.border-top-darken-3
{
    border-top: 1px solid #37474f !important;
}

.border-bottom-grey-blue.border-bottom-darken-3
{
    border-bottom: 1px solid #37474f !important;
}

.border-left-grey-blue.border-left-darken-3
{
    border-left: 1px solid #37474f !important;
}

.border-right-grey-blue.border-right-darken-3
{
    border-right: 1px solid #37474f !important;
}

.overlay-grey-blue.overlay-darken-3
{
    background: #37474f;
    /* The Fallback */
    background: rgba(55, 71, 79, .8);
}

.grey-blue.darken-4
{
    color: #263238 !important;
}

.bg-grey-blue.bg-darken-4
{
    background-color: #263238 !important;
}
.bg-grey-blue.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(38, 50, 56, .6);
            box-shadow: 0 1px 20px 1px rgba(38, 50, 56, .6);
}

.btn-grey-blue.btn-darken-4
{
    border-color: #2c303b !important;
    background-color: #263238 !important;
}
.btn-grey-blue.btn-darken-4:hover
{
    border-color: #2c303b !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-darken-4:focus,
.btn-grey-blue.btn-darken-4:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-grey-blue.btn-outline-darken-4
{
    color: #263238 !important; 
    border-color: #263238 !important;
}
.btn-outline-grey-blue.btn-outline-darken-4:hover
{
    background-color: #263238 !important;
}

input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #263238 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #263238 !important;
}

.border-grey-blue.border-darken-4
{
    border: 1px solid #263238 !important;
}

.border-top-grey-blue.border-top-darken-4
{
    border-top: 1px solid #263238 !important;
}

.border-bottom-grey-blue.border-bottom-darken-4
{
    border-bottom: 1px solid #263238 !important;
}

.border-left-grey-blue.border-left-darken-4
{
    border-left: 1px solid #263238 !important;
}

.border-right-grey-blue.border-right-darken-4
{
    border-right: 1px solid #263238 !important;
}

.overlay-grey-blue.overlay-darken-4
{
    background: #263238;
    /* The Fallback */
    background: rgba(38, 50, 56, .8);
}

.shades.black
{
    color: #000 !important;
}

.bg-shades.bg-black
{
    background-color: #000 !important;
}
.bg-shades.bg-black.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
}

.btn-shades.btn-black
{
    border-color: !important;
    background-color: #000 !important;
}
.btn-shades.btn-black:hover
{
    border-color: !important;
    background-color: !important;
}
.btn-shades.btn-black:focus,
.btn-shades.btn-black:active
{
    border-color: !important;
    background-color: !important;
}

.btn-outline-shades.btn-outline-black
{
    color: #000 !important; 
    border-color: #000 !important;
}
.btn-outline-shades.btn-outline-black:hover
{
    background-color: #000 !important;
}

input:focus ~ .bg-shades
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #000 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #000 !important;
}

.border-shades.border-black
{
    border: 1px solid #000 !important;
}

.border-top-shades.border-top-black
{
    border-top: 1px solid #000 !important;
}

.border-bottom-shades.border-bottom-black
{
    border-bottom: 1px solid #000 !important;
}

.border-left-shades.border-left-black
{
    border-left: 1px solid #000 !important;
}

.border-right-shades.border-right-black
{
    border-right: 1px solid #000 !important;
}

.overlay-shades.overlay-black
{
    background: #000;
    /* The Fallback */
    background: rgba(0, 0, 0, .8);
}

.shades.white
{
    color: #fff !important;
}

.bg-shades.bg-white
{
    background-color: #fff !important;
}
.bg-shades.bg-white.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
}

.btn-shades.btn-white
{
    border-color: !important;
    background-color: #fff !important;
}
.btn-shades.btn-white:hover
{
    border-color: !important;
    background-color: !important;
}
.btn-shades.btn-white:focus,
.btn-shades.btn-white:active
{
    border-color: !important;
    background-color: !important;
}

.btn-outline-shades.btn-outline-white
{
    color: #fff !important; 
    border-color: #fff !important;
}
.btn-outline-shades.btn-outline-white:hover
{
    background-color: #fff !important;
}

input:focus ~ .bg-shades
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff !important;
}

.border-shades.border-white
{
    border: 1px solid #fff !important;
}

.border-top-shades.border-top-white
{
    border-top: 1px solid #fff !important;
}

.border-bottom-shades.border-bottom-white
{
    border-bottom: 1px solid #fff !important;
}

.border-left-shades.border-left-white
{
    border-left: 1px solid #fff !important;
}

.border-right-shades.border-right-white
{
    border-right: 1px solid #fff !important;
}

.overlay-shades.overlay-white
{
    background: #fff;
    /* The Fallback */
    background: rgba(255, 255, 255, .8);
}

.shades.transparent
{
    color: transparent !important;
}

.bg-shades.bg-transparent
{
    background-color: transparent !important;
}
.bg-shades.bg-transparent.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
}

.btn-shades.btn-transparent
{
    border-color: !important;
    background-color: transparent !important;
}
.btn-shades.btn-transparent:hover
{
    border-color: !important;
    background-color: !important;
}
.btn-shades.btn-transparent:focus,
.btn-shades.btn-transparent:active
{
    border-color: !important;
    background-color: !important;
}

.btn-outline-shades.btn-outline-transparent
{
    color: transparent !important; 
    border-color: transparent !important;
}
.btn-outline-shades.btn-outline-transparent:hover
{
    background-color: transparent !important;
}

input:focus ~ .bg-shades
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem transparent !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem transparent !important;
}

.border-shades.border-transparent
{
    border: 1px solid transparent !important;
}

.border-top-shades.border-top-transparent
{
    border-top: 1px solid transparent !important;
}

.border-bottom-shades.border-bottom-transparent
{
    border-bottom: 1px solid transparent !important;
}

.border-left-shades.border-left-transparent
{
    border-left: 1px solid transparent !important;
}

.border-right-shades.border-right-transparent
{
    border-right: 1px solid transparent !important;
}

.overlay-shades.overlay-transparent
{
    background: transparent;
    /* The Fallback */
    background: rgba(0, 0, 0, .8);
}

.black
{
    color: #000;
}

.bg-black
{
    background-color: #000;
}
.bg-black.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
}

input:focus ~ .bg-black
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #000;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #000;
}

.border-black
{
    border: 1px solid #000;
}

.border-top-black
{
    border-top: 1px solid #000;
}

.border-bottom-black
{
    border-bottom: 1px solid #000;
}

.border-left-black
{
    border-left: 1px solid #000;
}

.border-right-black
{
    border-right: 1px solid #000;
}

.white
{
    color: #fff;
}

.bg-white
{
    background-color: #fff;
}
.bg-white.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
}

input:focus ~ .bg-white
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff;
}

.border-white
{
    border: 1px solid #fff;
}

.border-top-white
{
    border-top: 1px solid #fff;
}

.border-bottom-white
{
    border-bottom: 1px solid #fff;
}

.border-left-white
{
    border-left: 1px solid #fff;
}

.border-right-white
{
    border-right: 1px solid #fff;
}

.transparent
{
    color: transparent;
}

.bg-transparent
{
    background-color: transparent;
}
.bg-transparent.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
}

input:focus ~ .bg-transparent
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem transparent;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem transparent;
}

.border-transparent
{
    border: 1px solid transparent;
}

.border-top-transparent
{
    border-top: 1px solid transparent;
}

.border-bottom-transparent
{
    border-bottom: 1px solid transparent;
}

.border-left-transparent
{
    border-left: 1px solid transparent;
}

.border-right-transparent
{
    border-right: 1px solid transparent;
}
