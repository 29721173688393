.form-simple input[type='text']
{
    margin-bottom: -1px;

    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-simple input[type='mail']
{
    margin-bottom: -1px;

    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-simple input[type='password']
{
    margin-bottom: 10px;

    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.help-block ul
{
    margin: 0  !important;
    padding: 0 !important;
}
.help-block ul li
{
    list-style: none;
}
