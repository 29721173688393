.dropdown-item:hover {
    background-color: #A24015 !important;
    color: #fff !important;
}

.nav-item {
    width: 100px;
    border-bottom: 2px solid #fff;
    font-weight: bold;

}

.nav-item:hover {
    border-bottom: 2px solid #A24015;
    color: #A24015;
}